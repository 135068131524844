import React from "react";
import { usePostHog } from "posthog-js/react";
import { Box, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";

import { UserFeatureNames } from "src/types/UserGroup";
import ConfirmDialog from "src/view/components/ConfirmDialog";
import { HogEvent } from "src/types/PosthogEvents";

import { Colors } from "../../constants/colors";
import { IFeatureItem } from ".";

const StyledContainer = styled("div")(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: 14,
}));

const StyledTitle = styled("div")(() => ({
  fontSize: 16,
  lineHeight: "19.62px",
  fontWeight: "700",
  color: Colors.textDark100,
}));

const StyledDescription = styled("div")(() => ({
  fontSize: 14,
  lineHeight: "17.17px",
  fontWeight: "400",
  color: Colors.textDark100,
}));

const FeatureItem = (props: IFeatureItem) => {
  const posthog = usePostHog();

  const showDisableSearchHistoryConfirmation =
    props.name === UserFeatureNames.SEARCH_HISTORY && !!props.value;

  const handleChange = () => {
    if (showDisableSearchHistoryConfirmation) {
      return;
    }
  };

  const switchItem = (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {showDisableSearchHistoryConfirmation ? (
        <ConfirmDialog
          onConfirm={() => {
            props.onChange(false);
            posthog.capture(HogEvent.FEATURE_DISABLED, {
              feature: props.featureKey,
            });
          }}
          title="Disable search history"
          description="Are you sure you want to disable search history? This will permanently delete all recent searches."
          confirmText="Yes"
          cancelText="No"
        >
          <Switch
            color="secondary"
            checked={props.value}
            onChange={handleChange}
          />
        </ConfirmDialog>
      ) : (
        <Switch
          color="secondary"
          checked={props.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.onChange(e.target.checked);
            posthog.capture(HogEvent.FEATURE_ENABLED, {
              feature: props.featureKey,
            });
          }}
        />
      )}
    </Box>
  );

  return (
    <StyledContainer>
      <Box display="flex" alignItems="center" sx={{ gap: 3 }}>
        <img src={props.icon} width="48" height="48" />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <StyledTitle>{props.name}</StyledTitle>
          <StyledDescription>{props.description}</StyledDescription>
        </Box>
      </Box>
      {switchItem}
    </StyledContainer>
  );
};

export default FeatureItem;
