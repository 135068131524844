import Footer from "../layout/Footer";
import Header from "../layout/Header";
import Banner from "./components/Banner";
import FeaturesSection from "./components/FeaturesSection";
import ScreeningListSection from "./components/ScreeningList";

const LandingPage = () => {
  return (
    <div>
      <Header />
      <Banner />
      <ScreeningListSection />
      <FeaturesSection />
      <Footer />
    </div>
  );
};

export default LandingPage;
