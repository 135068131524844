import { Box, IconButton, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Colors } from "src/view/constants/colors";
import queryGroupActions from "src/modules/bulk-search/query-group/queryGroupActions";
import { useDispatch } from "react-redux";

const StyledQueryGroupHeader = styled("div")(() => ({
  position: "fixed",
  top: 104,
  left: 0,
  height: 36,
  width: "100%",
  backgroundColor: "#FFFFFF",
  borderBottom: "solid 1px #A0B9D0",
  zIndex: 2,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: "20px 26px",
}));

const StyledGroupTitle = styled("div")(() => ({
  fontSize: 24,
  lineHeight: "28px",
  fontWeight: "700",
  color: "#000000",
}));

const StyledTermsCount = styled("div")(() => ({
  fontSize: 12,
  lineHeight: "20px",
  fontWeight: "700",
  color: Colors.twilight,
  padding: "1px 10px",
  backgroundColor: Colors.bgGray1,
  borderRadius: 20,
  marginTop: 2,
}));

const QueryGroupHeader = () => {
  const location = useLocation();
  const [groupData, setGroupData] = useState(null);
  const [amount, setAmount] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mobileView = useMediaQuery("(max-width:678px)");
  const { id } = useParams();

  useEffect(() => {
    if (location?.state && location?.state["groupData"]) {
      setGroupData(location.state["groupData"]);
    }
    if (location?.state && location?.state["count"]) {
      setAmount(location.state["count"]);
    }
  }, [location]);

  const goBack = () => {
    dispatch({
      type: queryGroupActions.GET_SEARCH_RESULT,
      payload: null,
    });
    dispatch({
      type: queryGroupActions.SELECT_SEARCH_TERM,
      payload: null,
    });
    navigate("/bulk-search/query-group/" + id, {
      replace: true,
      state: {
        showFullList: location.state["showFullList"],
      },
    });
  };

  return (
    <StyledQueryGroupHeader sx={{ top: mobileView ? "149px" : "104px" }}>
      <IconButton onClick={goBack}>
        <ArrowBackIosNewIcon color="primary" />
      </IconButton>
      <Box sx={{ width: 8 }}></Box>
      <StyledGroupTitle>{groupData?.title}</StyledGroupTitle>
      <Box sx={{ width: 8 }}></Box>
      <StyledTermsCount>{`${amount} ${
        amount <= 1 ? "Query" : "Queries"
      }`}</StyledTermsCount>
    </StyledQueryGroupHeader>
  );
};

export default QueryGroupHeader;
