import { Button, ButtonGroup, Grid, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Colors } from "src/view/constants/colors";

const CustomButtonGroup = styled(ButtonGroup)(() => ({
  width: "100%",
  backgroundColor: "#536378",
  borderRadius: 0,
  whiteSpace: "nowrap",
}));

const StyledButton = styled(Button)(() => ({
  // backgroundColor: 'rgba(20, 74, 104, 0.2)',
  borderRadius: 0,
  backgroundColor: "#536378",
  height: 52,
  color: "white",
  fontWeight: "700",
  fontSize: 14,
  lineHeight: "17px",
  textTransform: "capitalize",
  border: "none",
  "&:hover": {
    border: "none",
  },
}));

const MaterialUISwitch = styled(Switch)(() => ({
  width: 34,
  height: 18,
  padding: 3,
  marginRight: 5,
  "& .MuiSwitch-switchBase": {
    margin: 0,
    padding: 0,
    transform: "translateX(0px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(14px)",
      backgroundColor: "white",
      "&+.MuiSwitch-track": {
        opacity: "1 !important",
        backgroundColor: Colors.secondary,
        borderRadius: 20 / 2,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#FFFFFF",
    width: 18,
    height: 18,
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#9BA5B1",
    borderRadius: 20 / 2,
  },
}));

export default function SearchTabs(props: {
  tabs: { freeText: boolean; entity: boolean };
  handleChange: (tabs: unknown) => void;
}) {
  const [tab, setTab] = useState("");

  useEffect(() => {
    if (props.tabs.freeText) {
      setTab("free-text-search");
    } else {
      setTab("");
    }
  }, [props.tabs]);

  const handleChangeSwitch = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const _tabs = props.tabs;
    if (e.target.checked) {
      setTab(key);
      if (key == "free-text-search") {
        _tabs["freeText"] = true;
      }
      if (key == "entity-search") {
        _tabs["entity"] = true;
      }
      props.handleChange(_tabs);
    } else {
      setTab("");
      if (key == "free-text-search") {
        _tabs["freeText"] = false;
      }
      if (key == "entity-search") {
        _tabs["entity"] = false;
      }
      props.handleChange(_tabs);
    }
  };

  return (
    <Grid item sx={{ alignItems: "flex-start" }}>
      <CustomButtonGroup
        color="primary"
        aria-label="medium secondary button group"
      >
        <StyledButton
          key="free-text-search"
          value="free-text-search"
          disableRipple
        >
          <MaterialUISwitch
            checked={tab === "free-text-search"}
            onChange={(event) => handleChangeSwitch(event, "free-text-search")}
          />
          Query Builder
        </StyledButton>
        {/* <StyledButton key="entity-search" value="entity-search" sx={{
                    backgroundColor: tab === 'entity-search' ? Colors.twilight : 'primary', color: tab === 'entity-search' ? 'white' : 'primary', '&:hover': {
                        backgroundColor: tab === 'entity-search' ? Colors.twilight : 'primary'
                    }
                }}>
                    <MaterialUISwitch checked={tab === 'entity-search'} onChange={(event) => handleChangeSwitch(event, 'entity-search')} />
                    Entity Search
                </StyledButton> */}
      </CustomButtonGroup>
    </Grid>
  );
}
