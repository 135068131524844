import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import dataSelector from "src/modules/bulk-search/query-group/queryGroupSelectors";
import LoadingText from "src/view/components/LoadingText";
import { MAX_RESULT_LIMIT } from "src/utils/constants";

const StyledResultHeader = styled(Box)(() => ({
  height: 64,
  borderBottom: "solid 1px #A0B9D0",
  display: "flex",
  alignItems: "center",
  paddingLeft: 25,
  paddingRight: 25,
}));

const StyledText = styled(Typography)(() => ({
  fontSize: 18,
  fontWeight: 700,
  color: "#5A7290",
}));

const ResultHeader = () => {
  const searchResultData = useSelector(dataSelector.selectSearchResult);
  const loading = useSelector(dataSelector.selectLoading);
  const totalResultCount = searchResultData?.total_counts;

  return (
    <StyledResultHeader>
      <StyledText>
        {loading ? (
          <LoadingText text="Searching" />
        ) : !totalResultCount ? (
          "No Results"
        ) : (
          `Found ${
            totalResultCount === MAX_RESULT_LIMIT &&
            searchResultData?.hits.total.relation === "gte"
              ? `+${totalResultCount.toLocaleString()}`
              : totalResultCount.toLocaleString()
          } ${totalResultCount == 1 ? "result" : "results"}`
        )}
      </StyledText>
    </StyledResultHeader>
  );
};

export default ResultHeader;
