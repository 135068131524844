import SehoLogo from "src/assets/images/logo.png";
import { Box, LinearProgress } from "@mui/material";

export default function LoadingPage() {
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 6,
      }}
    >
      <img src={SehoLogo} />
      <LinearProgress color="primary" sx={{ width: 240 }} />
    </Box>
  );
}
