import React, { useState, useEffect } from "react";
import {
  Box,
  InputBase,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import { Colors } from "src/view/constants/colors";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import ConfirmDialog from "src/view/components/ConfirmDialog";
import { User } from "src/types/UserGroup";
import { getFullName } from "src/utils";

const StyledHeader = styled(Box)(() => ({
  width: "calc(100% - 42px)",
  height: 32,
  padding: "14px 20px 16px 22px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}));

const StyledImage = styled("img")(() => ({
  width: 20,
  height: 20,
  objectFit: "contain",
}));

const Flex = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const StyledButton = styled(Button)(() => ({
  height: 34,
  color: "white",
  fontSize: 14,
  lineHeight: "17px",
  fontWeight: "700",
  textTransform: "capitalize",
}));

const StyledMenuItem = styled(MenuItem)(() => ({
  color: Colors.twilight,
  fontSize: 18,
  lineHeight: "20px",
  fontWeight: "700",
  height: 45,
}));

const TableHeadCell = styled(TableCell)(() => ({
  textTransform: "capitalize",
  fontSize: 11,
  lineHeight: "20px",
  color: "rgba(20, 74, 104, 0.5)",
  paddingTop: 8,
  paddingBottom: 8,
}));

const TableBodyCell = styled(TableCell)(() => ({
  fontSize: 14,
  fontWeight: "700",
  lineHeight: "20px",
  color: Colors.twilight,
  paddingTop: 12,
  paddingBottom: 12,
}));

interface Props {
  openModal?: () => void;
  openImportCSVModal?: () => void;
  users?: User[];
  handleRemoveUser?: (user: User) => void;
}

const UserList = (props: Props) => {
  const { openModal, openImportCSVModal, users, handleRemoveUser } = props;
  const [userList, setUserList] = useState([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setUserList(users);
  }, [users]);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleFilterUsers = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const keyword = e.target.value;
    const filtered = users.filter((user: User) =>
      getFullName(user).includes(keyword)
    );
    setUserList(filtered);
  };

  return (
    <Box sx={{ mt: 0 }}>
      <StyledHeader>
        <Flex>
          <InputBase
            sx={{
              ml: 1,
              flex: 1,
              bgcolor: "#E5EAED",
              borderRadius: "4px",
              height: "36px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
            placeholder="Search users"
            inputProps={{ "aria-label": "search users" }}
            onChange={handleFilterUsers}
            startAdornment={
              <img
                src={require("src/assets/images/search-black.png")}
                style={{ width: 16, height: 16, marginRight: 12 }}
              />
            }
          />

          <Box sx={{ width: 29 }}></Box>
          <StyledButton
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleOpenMenu}
          >
            Add
          </StyledButton>
        </Flex>
      </StyledHeader>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Table
          sx={{
            borderCollapse: "separate",
            overflow: "hidden",
          }}
        >
          <TableHead sx={{}}>
            <TableRow>
              <TableHeadCell sx={{ border: "none" }}>
                <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                  <Box>NAME</Box>
                </Box>
              </TableHeadCell>
              <TableHeadCell sx={{ border: "none" }}>
                <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                  <Box>EMAIL ADDRESS</Box>
                </Box>
              </TableHeadCell>
              <TableHeadCell sx={{ border: "none" }}>
                <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                  <Box>DATE JOINED</Box>
                  <Box sx={{ width: 12 }}></Box>
                  <img
                    src={require("src/assets/images/sort.png")}
                    style={{ width: 18, height: 18 }}
                  />
                </Box>
              </TableHeadCell>
              <TableHeadCell sx={{ border: "none" }}>
                <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                  <Box>STATUS</Box>
                </Box>
              </TableHeadCell>
              <TableHeadCell sx={{ border: "none", width: 80 }}>
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <Box>ACTION</Box>
                </Box>
              </TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userList.map((member: User, index: number) => {
              return (
                <TableRow key={index}>
                  <TableBodyCell sx={{ border: "none" }}>
                    {getFullName(member)}
                  </TableBodyCell>
                  <TableBodyCell sx={{ border: "none" }}>
                    {member.email}
                  </TableBodyCell>
                  <TableBodyCell sx={{ border: "none" }}>--</TableBodyCell>
                  <TableBodyCell sx={{ border: "none" }}></TableBodyCell>
                  <TableBodyCell sx={{ border: "none" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <IconButton size="small">
                        <StyledImage
                          src={require("src/assets/images/edit.png")}
                          style={{ width: 16, height: 16 }}
                        />
                      </IconButton>
                      <ConfirmDialog
                        title={"Delete User"}
                        description={
                          "You are about to delete this user from this group.\nAre you sure?"
                        }
                        confirmText={"Delete"}
                        cancelText={"Cancel"}
                        onConfirm={() => handleRemoveUser(member)}
                      >
                        <IconButton size="small">
                          <StyledImage
                            src={require("src/assets/images/delete.png")}
                            style={{ width: 16, height: 16 }}
                          />
                        </IconButton>
                      </ConfirmDialog>
                    </Box>
                  </TableBodyCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            bgcolor: "white",
            boxShadow: "0px 5px 26px rgba(0, 0, 0, 0.08)",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: "''",
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <StyledMenuItem onClick={openModal}>
          <ListItemIcon>
            <AddIcon color="primary" fontSize="small" />
          </ListItemIcon>
          Add New
        </StyledMenuItem>
        <StyledMenuItem onClick={openImportCSVModal}>
          <ListItemIcon>
            <StyledImage
              src={require("src/assets/images/upload-primary.png")}
            />
          </ListItemIcon>
          Upload via CSV
        </StyledMenuItem>
      </Menu>
    </Box>
  );
};

export default UserList;
