import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { ChevronRight } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import selector from "src/modules/bulk-search/filter/searchFilterSelectors";
import actions from "src/modules/bulk-search/filter/searchFilterActions";
import queryGroupActions from "src/modules/bulk-search/query-group/queryGroupActions";
import regions from "src/assets/json/regions.json";
import { FilterKeys, SearchFilter } from "src/types/SearchFilter";
import FilterTabs from "./FilterTabs";
import DatasetFilter from "./DatasetFilter";
import DocTypeFilter from "./DocTypeFilter";
import CountryFilter from "./CountryFilter";
import RegionFilter from "./RegionFilter";
import LanguageFilter from "./LanguageFilter";
import EntityFilter from "./EntityFilter";
import { Colors } from "../constants/colors";

const StyledFilterBox = styled("div")(() => ({
  height: "100%",
  width: 370,
  backgroundColor: "white",
  borderRight: "solid 1px #A0B9D0",
}));

const StyledFilterHeader = styled(Box)(() => ({
  height: 64,
  width: "100%",
  borderBottom: "solid 1px #A0B9D0",
  display: "flex",
  alignItems: "center",
}));

const StyledFilterBody = styled(Box)(() => ({
  height: "calc(100% - 65px)",
  width: "100%",
  overflow: "auto",
}));

const StyledActionButtonContainer = styled("div")(() => ({
  width: "calc(100% - 50px)",
  padding: 14,
  position: "absolute",
  bottom: 100,
}));

const StyledActionButton = styled(Button)(() => ({
  width: 300,
  height: 40,
  backgroundColor: Colors.secondary,
  color: "white",
  fontSize: 14,
  lineHeight: "20px",
  fontWeight: "700",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: Colors.secondary2,
  },
}));

const Filters = (props: {
  handleClose: () => void;
  editable: boolean;
  groupid: string;
}) => {
  const { handleClose, editable, groupid } = props;

  const filter = useSelector(selector.selectFilter);
  const dispatch = useDispatch();
  const [selectedFilter, setSelectedFilter] = useState<FilterKeys>(
    FilterKeys.DATASET_IDS
  );
  const [updated, setUpdated] = useState(false);
  const [originalData, setOriginalData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!filter) {
      return;
    }
    if (!originalData) {
      setOriginalData(filter);
      return;
    }
    if (isEqual(filter, originalData)) {
      setUpdated(false);
    } else {
      setUpdated(true);
    }
    setLoading(false);
  }, [filter, originalData]);

  const handleCollapseFilter = () => {
    handleClose();
  };

  const setFilter = (filter: SearchFilter) => {
    dispatch(actions.setFilter(filter));
  };

  const handleRemoveFilters = (key: FilterKeys) => {
    const _filter = { ...filter };
    _filter[key] = [];
    if (key == FilterKeys.DATASET_TYPES) _filter[key] = null;
    setFilter(_filter);
  };

  const updateDatasetFilter = () => {
    if (groupid) {
      const payload = {
        dataset_filters: filter?.dataset_ids || [],
        docs: filter?.doc_types || [],
        dataset_types: filter?.dataset_types || [],
        countries: filter?.countries || [],
        regions:
          regions
            .filter((region) => filter?.regions.includes(region.label))
            .map(({ value }) => value) || [],
        languages: filter?.languages || [],
      };
      dispatch(queryGroupActions.update(payload, groupid));
      setOriginalData(filter);
      setTimeout(() => {
        dispatch({
          type: queryGroupActions.CLEAR_UPDATE_STATUS,
        });
        handleClose();
      }, 1000);
    } else {
      handleClose();
    }
  };

  const selectedFilterComponent = useMemo(() => {
    return {
      [FilterKeys.DATASET_IDS]: <DatasetFilter />,
      [FilterKeys.DOC_TYPES]: <EntityFilter />,
      [FilterKeys.DATASET_TYPES]: <DocTypeFilter />,
      [FilterKeys.COUNTRIES]: <CountryFilter />,
      [FilterKeys.REGIONS]: <RegionFilter />,
      [FilterKeys.LANGUAGES]: <LanguageFilter />,
      // satisfy FilterKeys enum, render nothing
      [FilterKeys.DATA_COLLECTION_IDS]: undefined,
      [FilterKeys.EXCLUDE_DATASET_IDS]: undefined,
    }[selectedFilter];
  }, [selectedFilter]);

  return (
    <StyledFilterBox>
      <StyledFilterHeader>
        <IconButton
          size="large"
          aria-label="collapse of filters"
          aria-controls="toggle filter"
          aria-haspopup="true"
          color="inherit"
          onClick={() => handleCollapseFilter()}
        >
          <ChevronRight />
        </IconButton>
        <Typography
          variant="h6"
          sx={{ fontSize: "16px", fontWeight: "700", color: "#043D5D" }}
        >
          Dataset Filter
        </Typography>
      </StyledFilterHeader>
      <StyledFilterBody>
        <Grid
          container
          sx={{ px: "6px", py: "14px", borderBottom: "1px solid #CED3D9" }}
        >
          {loading && (
            <Box
              sx={{
                flex: 1,
                margin: 3,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={20} />
            </Box>
          )}
          <FilterTabs
            selectedTab={selectedFilter}
            setSelectedTab={setSelectedFilter}
            filter={filter}
            setFilter={setFilter}
            handleRemoveFiltersCustom={handleRemoveFilters}
          />
        </Grid>
        {selectedFilterComponent}
        {editable && (
          <StyledActionButtonContainer>
            <StyledActionButton
              disabled={!updated}
              onClick={updateDatasetFilter}
            >
              Update
            </StyledActionButton>
          </StyledActionButtonContainer>
        )}
      </StyledFilterBody>
    </StyledFilterBox>
  );
};

export default Filters;
