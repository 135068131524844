import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { Colors } from "src/view/constants/colors";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MyTextField from "../../../components/MyTextField";
import MySelect from "./MySelect";
import regions from "src/assets/json/regions.json";
import languages from "src/assets/json/languages.json";
import CustomSelect from "src/view/components/CustomSelect";
import { SelectOption } from "src/types/Shared";
import CustomDatePicker from "src/view/components/CustomDatePicker";
import { KnowledgeWiki } from "src/types/KnowledgeWiki";
import ds_properties from "src/assets/json/dataset_properties.json";
import datasetTypes from "src/assets/json/dataset_types.json";
import { format } from "date-fns";
import { Role } from "src/types/Auth";
import { useKnowledgeWiki } from "src/modules/api/knowledgeWiki";
import { useUser } from "src/modules/api/auth";
import { useFilterOptions } from "src/modules/api/filters";
import { snackbar } from "src/view/toaster";

import {
  dictionaryDetailsSections,
  KnowledgeWikiMarkDownKeys,
} from "../../utilIObjects";

import DictionaryDetailsMDEditor from "./DictionaryDetailsMDEditor";

const StyledBanner = styled("div")(() => ({
  position: "relative",
  marginTop: 100,
  height: "100%",
  backgroundImage: `url(${"/images/landing-bg.png"})`,
  backgroundSize: "50vw",
  backgroundPosition: "right 0px",
  backgroundRepeat: "no-repeat",
}));

const StyledBackground = styled("div")(() => ({
  background: "linear-gradient(0deg, #DDE4EC 0%, #FFFFFF 110.92%)",
  // transform: 'rotate(-180deg)'
}));

const StyledButton = styled(Button)(() => ({
  color: "#FFFFFF",
  fontSize: 16,
  lineHeight: "20px",
  fontWeight: "400",
  // backgroundColor: Colors.secondary,
  height: 42,
  marginLeft: 15,
  textTransform: "capitalize",
  "&:hover": {
    // backgroundColor: Colors.secondary2,
  },
}));

const StyledDetailContainer = styled(Box)(() => ({
  backgroundColor: "white",
  border: "1px solid #D9D9D9",
  borderRadius: 8,
  padding: 20,
  width: "calc(100% - 48px)",
  marginBottom: 20,
  position: "relative",
}));

const StyledBlockTitle = styled(Typography)(() => ({
  color: "#122945",
  fontWeight: "700",
  lineHeight: "25px",
  fontSize: "20px",
  // marginTop: "3px",
  // marginBottom: "10px",
}));

const LabeledCheckbox = styled(FormControlLabel)(() => ({
  "& .MuiTypography-root": {
    color: "#122945",
    fontWeight: "700 !important",
    lineHeight: "17px !important",
    fontSize: "14px !important",
  },
}));

const DataEdit = () => {
  const location = useLocation();
  const { id } = useParams();
  const { getKnowledgeWiki, updateKnowledgeWiki } = useKnowledgeWiki();
  const { data: detail, isLoading: loading } = getKnowledgeWiki(id);
  const navigate = useNavigate();
  const [formData, setFormData] = useState<KnowledgeWiki>(null);
  const [enableProps, setEnableProps] = useState<
    Record<KnowledgeWikiMarkDownKeys, boolean>
  >({
    summary: true,
    credibility: true,
    coverage: false,
    search_tips_tricks: false,
    sample_records: false,
    article: false,
  });
  const { user } = useUser();
  const [datasetProperties, setDatasetProperties] = useState<SelectOption[]>(
    []
  );
  const { countries } = useFilterOptions();

  useEffect(() => {
    if (detail) {
      const dsp = ds_properties[detail.dataset_type];
      setDatasetProperties(dsp);
    }
  }, [detail]);

  useEffect(() => {
    if (user?.role != Role.ADMIN && !user?.kw_write?.includes(detail?.id)) {
      goToDetail();
    }
  }, [user, detail]);

  useEffect(() => {
    if (detail) {
      setFormData(detail);
      const _enableProps = { ...enableProps };
      if (detail.coverage) _enableProps.coverage = true;
      if (detail.search_tips_tricks) _enableProps.search_tips_tricks = true;
      if (detail.sample_records) _enableProps.sample_records = true;
      if (detail.article) _enableProps.article = true;
      setEnableProps(_enableProps);
    }
  }, [detail]);

  const handleChangeFormData = (type: keyof KnowledgeWiki, data: unknown) => {
    let formDataPart = data;

    if (type === "regions" && Array.isArray(data)) {
      // select only regions that are in the list of available regions
      const selectedRegions = regions.filter((r) => data.includes(r.value));
      formDataPart = selectedRegions.map((r) => r.label);
    }
    if (type === "languages" && Array.isArray(data)) {
      // select only languages that are in the list of available languages
      const selectedLanguages = languages.filter((r) => data.includes(r.value));
      formDataPart = selectedLanguages.map((r) => r.label);
    }
    if (type === "dataset_type" && typeof data === "number") {
      // find selected dataset type in the list of available dataset types
      const datasetType = datasetTypes?.find((d) => d.value == data);
      formDataPart = datasetType.label;
    }
    if (type === "dataset_properties" && Array.isArray(data)) {
      const selectedProperties = datasetProperties.filter((r) =>
        data.includes(r.value)
      );
      formDataPart = selectedProperties.map((r) => r.label);
    }

    setFormData((state) => ({ ...state, [type]: formDataPart }));
  };

  const goToDetail = () => {
    navigate({
      pathname: `/data-dictionary/data/${id}`,
      search: location.search,
    });
  };

  const validateAction = (action: () => void) => {
    if (formData.dataset_type) return action();
    snackbar.error("Please select the dataset type");
  };

  const handleSave = async () => {
    const payload = { ...formData };
    if (!payload.countries || !payload.countries.length) {
      delete payload.countries;
    }
    await updateKnowledgeWiki.mutateAsync(
      { id, payload },
      {
        onSuccess: () => {
          validateAction(goToDetail);
        },
      }
    );
  };

  const handleChangeProps = (key: string, value: boolean) => {
    const original = { ...enableProps };
    original[key] = value;
    setEnableProps(original);
  };

  return (
    <Box>
      <StyledBackground>
        <StyledBanner>
          <Container>
            <Box
              sx={{
                pt: 14,
                pb: 1,
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "space-between",
                paddingRight: "54px",
                paddingLeft: "48px",
              }}
            >
              <Box sx={{ alignItems: "flex-start" }}>
                <Grid item>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      cursor: "pointer",
                    }}
                    onClick={() => validateAction(goToDetail)}
                  >
                    <img
                      src={require("src/assets/images/arrow_left_black.png")}
                      style={{ width: 24, height: 24 }}
                    />
                    <Typography
                      variant="inherit"
                      sx={{
                        color: "#000000",
                        fontWeight: "700",
                        lineHeight: "17px",
                        fontSize: "14px",
                        marginLeft: "4px",
                      }}
                    >
                      Back
                    </Typography>
                  </Box>
                </Grid>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <StyledButton
                  variant="contained"
                  startIcon={
                    loading ? (
                      <CircularProgress
                        size="small"
                        sx={{ color: "#FFFFFF", width: 24, height: 24 }}
                      />
                    ) : null
                  }
                  sx={{ backgroundColor: Colors.secondary }}
                  onClick={() => validateAction(handleSave)}
                >
                  Save
                </StyledButton>
              </Box>
            </Box>
          </Container>
          <Container sx={{ pt: 4, pb: 4 }}>
            <Box sx={{ paddingRight: "48px", paddingLeft: "48px" }}>
              <StyledDetailContainer>
                <Grid container spacing={4} sx={{ mb: "10px" }}>
                  <Grid item xs={6}>
                    <MyTextField
                      type="text"
                      label="Alias Name"
                      name="name"
                      value={formData?.name || ""}
                      onChange={(e: { target: { value: string } }) =>
                        handleChangeFormData("name", e.target.value)
                      }
                      helperText=""
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MyTextField
                      type="text"
                      label="Sensitivity of dataset"
                      name="sensitivity"
                      value={detail?.sensitivity || ""}
                      helperText=""
                      fullWidth
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <CustomDatePicker
                      label="Date of Snapshot"
                      name="date_snapshot"
                      date={formData?.date_snapshot}
                      placeholder="Select Date of Snapshot"
                      onChange={(date: Date) => {
                        handleChangeFormData(
                          "date_snapshot",
                          format(new Date(date), "yyyy-MM-dd")
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MySelect
                      label="Countries"
                      name="countries"
                      placeholder="Select Country"
                      onChange={(data: (string | number)[]) =>
                        handleChangeFormData("countries", data)
                      }
                      selectedValues={
                        formData
                          ? countries.filter((r: SelectOption) =>
                              formData.countries?.includes(r.value)
                            )
                          : []
                      }
                      options={countries}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <MySelect
                      label="Regions (optional)"
                      name="regions"
                      placeholder="Select Regions"
                      onChange={(data: (string | number)[]) =>
                        handleChangeFormData("regions", data)
                      }
                      options={regions}
                      selectedValues={
                        formData
                          ? regions.filter((r: SelectOption) =>
                              formData.regions?.includes(r.label)
                            )
                          : []
                      }
                      fullWidth
                      // disabled={formData?.regions?.length != 0}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MySelect
                      label="Languages"
                      name="languages"
                      placeholder="Select Languages"
                      onChange={(data: (string | number)[]) =>
                        handleChangeFormData("languages", data)
                      }
                      options={languages}
                      selectedValues={
                        formData
                          ? languages.filter((r: SelectOption) =>
                              formData.languages?.includes(r.label)
                            )
                          : []
                      }
                      fullWidth
                      // disabled={formData?.languages?.length != 0}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4} sx={{ mb: "10px" }}>
                  <Grid item xs={6}>
                    <MyTextField
                      type="text"
                      label="Foundry URL (optional)"
                      name="foundry_link"
                      value={formData?.foundry_link || ""}
                      onChange={(e: { target: { value: string } }) =>
                        handleChangeFormData("foundry_link", e.target.value)
                      }
                      placeholder="Enter url"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MyTextField
                      type="text"
                      label="External Resource URL (optional)"
                      name="external_source"
                      value={formData?.external_source || ""}
                      onChange={(e: { target: { value: string } }) =>
                        handleChangeFormData("external_source", e.target.value)
                      }
                      placeholder="Enter url"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <CustomSelect
                      label="Type"
                      name="dataset_types"
                      placeholder="Select Dataset type"
                      onChange={(data: string) =>
                        handleChangeFormData("dataset_type", data)
                      }
                      options={datasetTypes}
                      initialValue={
                        datasetTypes.find(
                          (item) => item.label == formData?.dataset_type
                        )?.value || ""
                      }
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </StyledDetailContainer>
              <StyledDetailContainer>
                <StyledBlockTitle>
                  Add Optional Dataset Properties
                </StyledBlockTitle>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12}>
                    <MySelect
                      label="Dataset Properties (optional)"
                      name="dataset_properties"
                      placeholder="Select"
                      onChange={(data: (string | number)[]) =>
                        handleChangeFormData("dataset_properties", data)
                      }
                      options={datasetProperties}
                      selectedValues={
                        formData
                          ? datasetProperties?.filter((r: SelectOption) =>
                              formData.dataset_properties?.includes(r.label)
                            )
                          : []
                      }
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </StyledDetailContainer>
              <StyledDetailContainer>
                <Grid container>
                  <Grid item xs={6}>
                    <LabeledCheckbox
                      control={<Checkbox color="secondary" />}
                      label="Leaked Data"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <LabeledCheckbox
                      control={<Checkbox color="secondary" />}
                      label="Script or Parser Available"
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <LabeledCheckbox
                      control={<Checkbox color="secondary" />}
                      label="OCR'ed"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <LabeledCheckbox
                      control={<Checkbox color="secondary" />}
                      label="Partner Data"
                    />
                  </Grid>
                </Grid>
              </StyledDetailContainer>

              {detail &&
                dictionaryDetailsSections.map(({ key, label }) => {
                  return (
                    <DictionaryDetailsMDEditor
                      key={`dictionary-details-${key}`}
                      label={label}
                      fieldname={key}
                      content={(detail[key] as string) || ""}
                      isEnabled={enableProps?.[key]}
                      handleChangeProps={handleChangeProps}
                      handleChangeFormData={handleChangeFormData}
                    />
                  );
                })}
            </Box>
          </Container>
        </StyledBanner>
      </StyledBackground>
    </Box>
  );
};

export default DataEdit;
