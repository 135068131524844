import { memo } from "react";
import { Box, Typography } from "@mui/material";
import { FilterOption } from "src/types/SearchFilter";
import { areEqual } from "react-window";
import ExcludableCheckbox from "src/view/components/ExcludableCheckbox";

export const FilterOptionRow = memo(({ data, index, style }: any) => {
  const {
    options,
    filterKey,
    filters,
    updateFilters,
    excludable,
    areAnyChecked,
    implicitDatasetIds = [],
    implicitExcludeDatasetIds = [],
  } = data;
  if (!options) {
    return null;
  }
  const option = options[index];
  const { label, value, count } = option;

  let checked = filters[filterKey].includes(value);
  let excluded = (filters[`exclude_${filterKey}`] ?? []).includes(value);

  if (excludable) {
    excluded = excluded || implicitExcludeDatasetIds.includes(value);
    checked = (checked || implicitDatasetIds.includes(value)) && !excluded;
  }

  const opacity = areAnyChecked ? (checked ? 1 : 0.6) : 1;

  const onClick = (option: FilterOption) => {
    let payload;
    if (excludable) {
      if (filters.dataset_ids.includes(option.value)) {
        payload = {
          filters: {
            dataset_ids: filters.dataset_ids.filter(
              (id: string) => id !== option.value
            ),
            exclude_dataset_ids: [...filters.exclude_dataset_ids, option.value],
          },
        };
      } else if (filters.exclude_dataset_ids.includes(option.value)) {
        payload = {
          filters: {
            exclude_dataset_ids: filters.exclude_dataset_ids.filter(
              (id: string) => id !== option.value
            ),
          },
        };
      } else {
        payload = {
          filters: {
            dataset_ids: [...filters.dataset_ids, option.value],
          },
        };
      }
    } else {
      payload = {
        filters: {
          ...filters,
          [filterKey]: checked
            ? filters[filterKey].filter((v: string) => v !== option.value)
            : [...filters[filterKey], option.value],
        },
      };
    }
    updateFilters(payload.filters);
  };

  return (
    <div
      style={{
        ...style,
        opacity,
        cursor: "pointer",
        userSelect: "none",
      }}
      onClick={() => onClick(option)}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0px 8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <ExcludableCheckbox checked={checked} excluded={excluded} />
          <Typography variant="caption" sx={{ flex: 1 }}>
            {label}
          </Typography>
        </Box>
        {count > 0 && (
          <Typography
            variant="caption"
            sx={{
              bgcolor: "#F0F1F3",
              color: "#536378",
              px: 0.5,
              py: 0.25,
              borderRadius: 1,
              minWidth: 14,
              textAlign: "center",
              fontWeight: 700,
            }}
          >
            {count.toLocaleString()}
          </Typography>
        )}
      </Box>
    </div>
  );
}, areEqual);
