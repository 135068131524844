import { styled } from "@mui/material/styles";
import { DataGridPremium } from "@mui/x-data-grid-premium";

const DataGrid = styled(DataGridPremium)(({ theme }) => ({
  border: 0,
  color: theme.palette.primary.main,
  fontFamily: [
    "Inter",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.grey["50"],
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader": {
    "&:focus-within": {
      outline: "none",
    },
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    color: theme.palette.grey["600"],
    fontWeight: 700,
    "&:focus-within": {
      outline: "none",
    },
  },
  "& .MuiDataGrid-cell": {
    cursor: "pointer",
    fontWeight: 400,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    "&:focus-within": {
      outline: "none",
    },
  },
}));

export default DataGrid;
