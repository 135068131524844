export const SampleSearches: string[] = [
  "World Logistic Group",
  '("Yevgeny Prigozhin" OR "Wagner Group")',
  "Sinno Electronics Co",
  "بنك ام درمان الوطنى",
  "Xinjiang Production and Construction Corps",
];

export const ThingInheritors: string[] = [
  "Address",
  "Assessment",
  "Asset",
  "CallForTenders",
  "CourtCase",
  "CryptoWallet",
  "Document",
  "Article",
  "Audio",
  "Folder",
  "HyperText",
  "Image",
  "Pages",
  "PlainText",
  "Table",
  "Video",
  "Event",
  "Trip",
  "LegalEntity",
  "Person",
  "Organization",
  "Company",
  "PublicBody",
  "Note",
  "Position",
  "Project",
  "UserAccount",
  "BankAccount",
  "Company",
  "Contract",
  "Licence",
  "RealEstate",
  "Security",
  "Vehicle",
  "Airplane",
  "Vessel",
];

export const MAX_RESULT_LIMIT = 10000;
