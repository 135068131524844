import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SortIcon from "@mui/icons-material/Sort";
import { useLocation } from "react-router-dom";
import SearchTerm from "./SearchTerm";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Colors } from "src/view/constants/colors";
import actions from "src/modules/bulk-search/query-group/queryGroupActions";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import dataSelector from "src/modules/bulk-search/query-group/queryGroupSelectors";
import { SearchTerm as ISearchTermModel } from "src/types/BulkSearch";
import BulkSearchService from "src/modules/bulk-search/bulkSearchService";

const StyledFilterBox = styled("div")(() => ({
  position: "fixed",
  top: 181,
  left: 0,
  height: "100%",
  width: 370,
  borderRight: "solid 1px #A0B9D0",
  overflow: "hidden",
}));

const StyledQueueHeader = styled(Box)(() => ({
  height: 64,
  width: "100%",
  borderBottom: "solid 1px #A0B9D0",
  display: "flex",
  alignItems: "center",
  paddingLeft: 20,
  position: "relative",
}));

const StyledQueueBody = styled(Box)(() => ({
  height: "calc(100% - 64px - 127px - 76px)",
  width: "calc(100% - 52px)",
  overflow: "auto",
  padding: "0 26px 20px",
  position: "relative",
}));

const StyledImageIcon = styled("img")(() => ({
  width: 16,
  height: 16,
  objectFit: "contain",
}));

const SearchInput = styled("input")(() => ({
  width: 240,
  border: "none",
  paddingLeft: 0,
  paddingRight: 10,
  fontSize: 14,
  fontWeight: "bold",
  color: "#5A7291",
  "&:focus-visible": {
    outline: "none",
  },
}));

const StyledToggleButton = styled(IconButton)(() => ({
  position: "absolute",
  right: 10,
  top: 14,
  backgroundColor: `${Colors.textGray}55`,
}));

const StyledSortHeader = styled(Box)(() => ({
  fontSize: 13,
  fontWeight: 700,
  color: Colors.twilight,
  padding: "0 4px 10px 8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  cursor: "pointer",
}));

const QueueList = (props: { handleClose: () => void }) => {
  const { handleClose } = props;
  const [filter, setFilter] = useState("");
  const inputRef = useRef(null);

  const location = useLocation();
  const [terms, setTerms] = useState<ISearchTermModel[]>([]);
  const [showToggleButton, setShowTottleButton] = useState(false);
  const dispatch = useDispatch();
  const [showSortButton, setShowSortButton] = useState(false);
  const data = useSelector(dataSelector.selectSearchResult);
  const [selectedItem, setSelectedItem] = useState(0);
  const mobileView = useMediaQuery("(max-width:678px)");
  const [page, setPage] = useState(1);
  const [ordering, setOrdering] = useState({
    query: 0,
    total_results: 0,
  });
  const listRef = useRef(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [screeningListId, setScreeningListId] = useState(null);

  useEffect(() => {
    if (location?.state && location?.state["data"]) {
      setTerms(location.state["data"]);
      if (location.state["data"].length > 0) {
        setSelectedItem(location.state["selected"]);
        dispatch({
          type: actions.SELECT_SEARCH_TERM,
          payload: location.state["data"][location.state["selected"]],
        });
      }
      if (location.state["page"]) {
        setPage(location.state["page"]);
      }
      if (location.state["ordering"]) {
        setOrdering(location.state["ordering"]);
      }
      if (location.state["hasNextPage"]) {
        setHasNextPage(location.state["hasNextPage"]);
      }
      if (location.state["screeningListId"]) {
        setScreeningListId(location.state["screeningListId"]);
      }
    }
  }, [location]);

  useEffect(() => {
    // getTerms();
    updateTotalResults();
  }, [data]);

  useEffect(() => {
    getSearchTerms(1, true);
  }, [ordering]);

  const updateSelectedItem = (idx: number) => {
    setSelectedItem(idx);
  };

  const updateTotalResults = () => {
    if (data) {
      const tr = data.total_counts;
      const _terms = [...terms];
      if (_terms[selectedItem]) {
        _terms[selectedItem].total_results = tr;
        _terms[selectedItem].relation = data.hits.total.relation;
      }
      setTerms(_terms);
    }
  };

  const handleCollapseFilter = () => {
    handleClose();
  };

  const handleMouseOver = () => {
    setShowTottleButton(true);
  };

  const handleMouseLeave = () => {
    setShowTottleButton(false);
  };

  const handleChangeOrder = (key: string) => {
    const order = { ...ordering };
    order[key] = order[key] == 0 ? 1 : order[key] == 1 ? -1 : 0;
    setOrdering(order);
  };

  const getSearchTerms = async (p: number, clear?: boolean) => {
    if (!screeningListId) return;
    if (filter && filter.length <= 2) return;
    setLoading(true);
    const res = await BulkSearchService.getSearchItems(
      screeningListId,
      filter,
      ordering,
      p
    );
    if (res) {
      const _data = clear ? res.results : [...terms, ...res.results];
      setTerms(_data);
      setHasNextPage(Math.ceil(res.count / 50) > res.page_number);
      setPage(res.page_number);
      setLoading(false);
    }
  };

  const onScroll = () => {
    if (listRef.current && !loading) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      if (scrollTop + clientHeight > scrollHeight - 20) {
        if (hasNextPage) {
          setPage(page + 1);
          getSearchTerms(page + 1, false);
        }
      }
    }
  };

  const handleSearchTerms = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == "Enter") {
      getSearchTerms(1, true);
    }
  };

  return (
    <StyledFilterBox
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      sx={{ top: mobileView ? "224px" : "181px" }}
    >
      <StyledQueueHeader>
        <IconButton
          size="large"
          aria-label="collapse of filters"
          aria-controls="toggle filter"
          aria-haspopup="true"
          color="inherit"
          onClick={() => getSearchTerms(1, true)}
        >
          <StyledImageIcon
            src={require("src/assets/images/search-black.png")}
            alt="Search Icon"
          />
        </IconButton>
        <SearchInput
          placeholder="Search Search Terms"
          ref={inputRef}
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          onKeyUpCapture={handleSearchTerms}
        />
        <IconButton
          size="small"
          aria-label="collapse of filters"
          aria-controls="toggle filter"
          aria-haspopup="true"
          color="inherit"
          onClick={() => setShowSortButton(!showSortButton)}
        >
          <SortIcon color="primary" />
        </IconButton>
        {/*  */}
        {showToggleButton && (
          <StyledToggleButton
            size="small"
            onClick={() => handleCollapseFilter()}
          >
            <ChevronLeftIcon color="primary" />
          </StyledToggleButton>
        )}
      </StyledQueueHeader>
      <StyledQueueBody
        ref={listRef}
        onScroll={onScroll}
        sx={{ paddingTop: showSortButton ? 0 : "20px" }}
      >
        <Collapse in={showSortButton} sx={{ position: "sticky", top: 0 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: 1,
              background: "#FFFFFF",
            }}
          >
            <StyledSortHeader onClick={() => handleChangeOrder("query")}>
              Query{" "}
              {ordering?.query != 0 ? (
                ordering?.query == 1 ? (
                  <ArrowDownwardIcon />
                ) : (
                  <ArrowUpwardIcon />
                )
              ) : (
                <ArrowDownwardIcon />
              )}
            </StyledSortHeader>
            <StyledSortHeader
              onClick={() => handleChangeOrder("total_results")}
            >
              Result Count{" "}
              {ordering?.total_results != 0 ? (
                ordering?.total_results == 1 ? (
                  <ArrowDownwardIcon />
                ) : (
                  <ArrowUpwardIcon />
                )
              ) : (
                <ArrowDownwardIcon />
              )}
            </StyledSortHeader>
          </Box>
        </Collapse>
        {/*  */}
        {terms.map((term, index) => {
          return (
            <SearchTerm
              key={term.id}
              data={term}
              updateSelectedItem={() => updateSelectedItem(index)}
            />
          );
        })}
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: 2,
            }}
          >
            <CircularProgress size={20} />
          </Box>
        )}
      </StyledQueueBody>
    </StyledFilterBox>
  );
};

export default QueueList;
