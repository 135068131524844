import { useState } from "react";
import { every } from "lodash";
import { Box, Typography } from "@mui/material";

import { useUser } from "src/modules/api/auth";
import { UserFeatureNames, UserFeatures } from "src/types/UserGroup";
import { ImgIconSearchHistory } from "src/assets/icons";
import useToggleSearchHistory from "./FeatureToggles";

import FeatureItem from "./FeatureItem";

export interface IFeatureItem {
  name: UserFeatureNames;
  icon: string;
  featureKey: UserFeatures;
  description: string;
  value: boolean;
  onChange: (checked: boolean) => void;
  featureFlagEnabled?: boolean;
}

const Settings = () => {
  const { user } = useUser();
  const [searchHistoryState] = useState<boolean>(user?.search_history_opted_in);
  const toggleSearchHistory = useToggleSearchHistory();

  const handleUpdateSearchHistoryToggle = async (checked: boolean) => {
    toggleSearchHistory.mutate(checked);
  };

  const featureItemsMemo: IFeatureItem[] = [
    {
      name: UserFeatureNames.SEARCH_HISTORY,
      icon: ImgIconSearchHistory,
      featureKey: UserFeatures.SEARCH_HISTORY,
      description:
        "Easily refer back to your recent searches, designed for convenience while keeping your experience private.",
      value: searchHistoryState,
      onChange: handleUpdateSearchHistoryToggle,
    },
  ];

  if (every(featureItemsMemo, { featureFlagEnabled: false })) {
    return (
      <Typography marginTop={3}>
        Here you will find optional features and configurations. There are
        currently none available for activation within your account.
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexWrap: "wrap",
        alignItems: "center",
        mt: 3,
        mb: 40,
      }}
    >
      {featureItemsMemo.map(
        ({ name, icon, featureKey, description, value, onChange }) => (
          <FeatureItem
            key={`feature-item${featureKey}`}
            featureKey={featureKey}
            name={name}
            description={description}
            icon={icon}
            value={value}
            onChange={onChange}
          />
        )
      )}
    </Box>
  );
};

export default Settings;
