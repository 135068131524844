// TODO: ENG-1002 sort out select components
import Multiselect from "multiselect-react-dropdown";
import { IMultiselectProps } from "multiselect-react-dropdown/dist/multiselect/interface";
import { styled } from "@mui/material/styles";
import { SelectOption } from "src/types/Shared";

const StyledMultiselect = styled(Multiselect)(({ theme }) => ({
  fontSize: "12px",
  lineHeight: "16px",
  color: theme.palette.primary.light,
  width: "100%",
  boxShadow: "0px 6px 8px rgba(18, 41, 69, 0.08)",
  "& .searchBox ": {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "20px",
    color: "inherit",
    minHeight: "29px",
    paddingLeft: 10,
  },
  "& .searchWrapper": {
    border: "none",
    padding: 3,
  },
  "& .chip": {
    background: theme.palette.secondary.main,
    padding: "0px 6px",
  },
}));

const StyledMultiSelectWrapper = styled("div")(() => ({
  width: "100%",
  background: "#FFFFFF",
  border: "1px solid rgba(65, 64, 66, 0.16)",
  borderRadius: 4,
  marginBottom: 10,
}));

interface Props extends IMultiselectProps {
  options: Array<SelectOption>;
  value?: Array<SelectOption>;
  fullWidth?: boolean;
  onChange?: (value: (string | number)[]) => void;
  displayKey?: string;
  valueKey?: string;
}

const CustomMultiselect = (props: Props) => {
  const {
    options,
    value = [],
    onChange,
    displayKey = "label",
    valueKey = "value",
    ...restProps
  } = props;

  const handleChangeSelected = (props: Array<SelectOption>) => {
    const values = props.map((item: SelectOption) => item[valueKey]);
    onChange(values);
  };

  return (
    <StyledMultiSelectWrapper>
      <StyledMultiselect
        displayValue={displayKey}
        onRemove={handleChangeSelected}
        onSelect={handleChangeSelected}
        options={options}
        selectedValues={value}
        showArrow
        {...props}
        {...restProps}
      />
    </StyledMultiSelectWrapper>
  );
};

export default CustomMultiselect;
