import { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import anime from "animejs";

export interface CarouselPoint {
  x: number;
  z: number;
}

export interface CarouselChildProps {
  imageUrl?: string;
  coordinate: CarouselPoint;
  onClick?: () => void;
}

const CarouselChild = ({
  imageUrl,
  coordinate,
  onClick,
}: CarouselChildProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current == null) {
      return;
    }

    const resizeHandler = () => {
      const partWidth = 540 / 2;
      // const partWidth = window.innerWidth / 2;

      anime({
        targets: [ref.current],
        translateX: coordinate.x * partWidth,
        translateZ: coordinate.z * 50,
        scale: coordinate.z,
        easing: "spring(1, 80, 10, 0)",
      });
    };
    resizeHandler();
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [coordinate, ref]);

  return (
    <Box
      ref={ref}
      onClick={onClick}
      sx={{
        position: "absolute",
        backgroundColor: "#cccccc",
        boxShadow: "0 4px 30px 0 #0000001A",
        borderRadius: "8px",
        width: "475px",
        height: "225px",
      }}
    >
      <img src={imageUrl} width="475" height="225px" />
    </Box>
  );
};

export default CarouselChild;
