import { Box, Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import { Colors } from "src/view/constants/colors";
import NotFoundImgUrl from "src/assets/images/not-found.png";

const Wrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: "16px",
  maxWidth: "350px",
  margin: "0 auto",
  textAlign: "center",
});

interface Props {
  onStart?: () => void;
}

const StartAddingTerms = ({ onStart }: Props) => {
  return (
    <Wrapper>
      <img src={NotFoundImgUrl} alt="Not Found" height="226"></img>
      <Typography color={Colors.textDark} fontSize={18} fontWeight="700">
        No Search Terms Found
      </Typography>
      {onStart && (
        <>
          <Typography color={Colors.textGray300} fontSize={14}>
            There aren’t any search terms in this query group. Start adding some
            by clicking below.
          </Typography>
          <Button
            onClick={onStart}
            startIcon={<AddIcon />}
            sx={{
              height: 46,
              textTransform: "capitalize",
              fontWeight: "700",
            }}
            variant="contained"
          >
            Start Adding Terms
          </Button>
        </>
      )}
    </Wrapper>
  );
};

export default StartAddingTerms;
