import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { PreviewDocProps } from "src/modules/search/view/searchViewReducers";
import actions from "src/modules/search/view/searchViewActions";

export const useDocument = (previewDocInfo?: PreviewDocProps) => {
  const dispatch = useDispatch();
  const [documentURL, setDocumentURL] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    if (!previewDocInfo) {
      return;
    }
    (async () => {
      try {
        const downloadURL = await dispatch(
          actions.downloadDoc(
            previewDocInfo._id,
            previewDocInfo.source_type,
            true
          )
        );
        if (downloadURL) {
          setDocumentURL(downloadURL);
        } else {
          setError("Failed to load document for preview.");
          setDocumentURL("");
        }
      } catch (error) {
        setError(JSON.stringify(error));
      } finally {
        setIsLoading(false);
      }
    })();
  }, [previewDocInfo]);

  return { documentURL, isLoading, error };
};
