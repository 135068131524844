import { Box, Container, Grid, Typography } from "@mui/material";
import { Colors } from "src/view/constants/colors";
import CustomCard from "src/view/components/Card";
import bulk_search from "src/assets/images/bulk-search-light.svg";
import knowledge_wiki from "src/assets/images/knowledge-wiki-light.svg";
import { Features } from "src/types/Auth";
import { useUser } from "src/modules/api/auth";

export default function FeaturesSection() {
  const { user } = useUser();

  return (
    <Container>
      <Grid
        item
        sx={{
          pt: 10,
          pb: 7,
          alignItems: "flex-start",
          px: { xs: 2, sm: 4 },
        }}
      >
        <Grid item>
          <Typography
            variant="caption"
            sx={{
              color: Colors.textDark100,
              fontSize: "32px",
              lineHeight: "39.23px",
              fontWeight: "700",
            }}
          >
            Other Features
          </Typography>
        </Grid>
        <Box
          sx={{
            width: 56,
            height: 5,
            backgroundColor: Colors.secondary,
            mt: "14px",
            mb: "14px",
          }}
        ></Box>
        <Grid item sx={{ width: { md: "60%", sm: "100%" } }}>
          <Typography
            variant="inherit"
            sx={{
              color: Colors.textDark,
              fontWeight: "400",
              lineHeight: "24px",
              fontSize: "14px",
            }}
          >
            An analytical toolkit for investigators to aid in open-source
            research and support ongoing work disrupting transnational illicit
            networks.
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        sx={{
          alignItems: "space-between",
          display: { sm: "flex", xs: "block" },
          justifyContent: "space-between",
          px: { xs: 2, sm: 4 },
        }}
      >
        <Grid
          item
          sx={{ width: { sm: "48%", xs: "100%" }, mb: { xs: "20px" } }}
        >
          <CustomCard
            image={bulk_search}
            title="Bulk Search"
            description="Query the data lake using screening lists of entities. Monitor and receive notifications for updates when new data is added."
            height={281}
            link={
              user?.features?.includes(Features.BULK_SEARCH)
                ? "/bulk-search"
                : null
            }
          />
        </Grid>
        <Grid item sx={{ width: { sm: "48%", xs: "100%" } }}>
          <CustomCard
            image={knowledge_wiki}
            title="Data Dictionary"
            description="View information about each dataset including provenance, sourcing, credibility, and other important details."
            height={281}
            link={
              user?.features?.includes(Features.KNOWLEDGE_WIKI)
                ? "/knowledge-wiki/data"
                : null
            }
          />
        </Grid>
      </Grid>
      {/*<Box sx={{ height: 50 }}></Box>
            <Grid container sx={{ alignItems: 'space-between', display: { sm: 'flex', xs: 'block' }, justifyContent: 'space-around', paddingRight: 4, paddingLeft: 4 }}>
                <Grid item xs={2}>
                    <Tag icon={<BusinessCenterIcon />} name="Toolkit" />
                </Grid>
                <Grid item xs={2}>
                    <Tag icon={<ApiIcon />} name="API Search" />
                </Grid>
                <Grid item xs={2}>
                    <Tag icon={<BookIcon />} name="OCR Tool" />
                </Grid>
                <Grid item xs={2}>
                    <Tag icon={<FindInPageIcon />} name="OSINT Search" />
                </Grid>
                <Grid item xs={2}>
                    <Tag icon={<CodeIcon />} name="Data Support" />
                </Grid>
            </Grid>
            <Box sx={{ height: 50 }}></Box>*/}
      {/* <Grid item sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                <StyledLink href={'/'}>
                    <Typography variant="body2" sx={{ color: Colors.textDark, fontWeight: '700', lineHeight: '20px', fontSize: 16, pr: 2 }}>Explore Tools</Typography>
                    <ArrowForwardIcon color='secondary' />
                </StyledLink>
            </Grid> */}
      <Box sx={{ height: 100 }}></Box>
    </Container>
  );
}
