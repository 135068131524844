import { useMemo, useState } from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import DatasetTable from "src/view/indexing/datasetList/components/DatasetTable";
import { useDatasetMetadata } from "src/modules/api/datasetMetadata";
import JobSearchBar from "./components/JobSearchBar";

const Wrapper = styled(Box)(() => ({
  marginTop: 127,
  paddingBottom: 100,
  paddingLeft: "64px",
  paddingRight: "64px",
}));
const Header = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "40px",
}));
const StyledHeader = styled("h3")(() => ({
  fontSize: 32,
  fontWeight: "700",
  margin: 0,
  marginBottom: "8px",
}));

const CreateButton = styled(Button)(() => ({
  minWidth: "106px",
  height: "46px",
  padding: "14px",
  borderRadius: "4px",
  color: "#FFF",
  background: "#F75151",
  textTransform: "none",
  "&:hover": {
    background: "#F75151",
  },
}));

const DatasetList = () => {
  const navigate = useNavigate();
  const { getDatasetMetadata } = useDatasetMetadata();
  const [searchKey, setSearchKey] = useState("");

  const { data, isLoading } = getDatasetMetadata();

  const displayData = useMemo(() => {
    if (searchKey.length && data) {
      return data.filter(({ name }) =>
        name.toLowerCase().includes(searchKey.toLowerCase())
      );
    }

    return data;
  }, [data, searchKey]);

  return (
    <Wrapper>
      <Header>
        <Box>
          <StyledHeader>Indexing Dashboard</StyledHeader>
          <Typography sx={{ maxWidth: "100%", fontSize: "14px" }}>
            Create, manage, and monitor datasets.
          </Typography>
        </Box>

        <CreateButton
          startIcon={<AddIcon />}
          onClick={() => navigate("/indexing/add")}
        >
          Create
        </CreateButton>
      </Header>

      <JobSearchBar searchKey={searchKey} setSearchKey={setSearchKey} />

      <DatasetTable datasets={displayData} isLoading={isLoading} />
    </Wrapper>
  );
};

export default DatasetList;
