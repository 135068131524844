import {
  Box,
  Container,
  Grid,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  DialogContent,
  useMediaQuery,
} from "@mui/material";

import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "src/view/constants/colors";
import * as React from "react";
import { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import actions from "src/modules/groups/view/groupsViewActions";
import selector from "src/modules/groups/view/groupsViewSelectors";
import { useDispatch, useSelector } from "react-redux";
import { snackbar } from "src/view/toaster";
import EmptyUserList from "./EmptyUserList";
import UserList from "./UserList";
import CloseIcon from "@mui/icons-material/Close";
import DropZone from "./DropZone";
import { User } from "src/types/UserGroup";
import AddNewUserModal, {
  UserFields,
} from "src/view/users-groups/shared/AddNewUsersModal";
import { APIValidationErrors } from "src/types/Shared";
import { isAPIValidationErrors } from "src/utils";
import { useToggle } from "usehooks-ts";

const StyledContainer = styled(Button)(() => ({
  borderRadius: 4,
  backgroundColor: "#F75151",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#ffffff",
  fontSize: 14,
  lineHeight: "17px",
  margin: 5,
  fontWeight: "700",
  padding: 15,
  height: 46,
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#F7515188",
  },
}));

const StyledBackButton = styled(Button)(() => ({
  border: "none",
  backgroundColor: "transparent",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  color: Colors.textDark,
  fontSize: 16,
  lineHeight: "20px",
  margin: 5,
  marginLeft: -15,
  fontWeight: "700",
  padding: 15,
  height: 46,
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

const StyledBulkScaffolding = styled(Container)(() => ({
  marginTop: 127,
  paddingBottom: 100,
}));

const StyledListContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "calc(100% - 127px - 76px - 95px - 33px - 20px",
  position: "relative",
}));

const StyledDialogTitle = styled(DialogTitle)(() => ({
  fontSize: 24,
  // lineHeight: '20px',
  fontWeight: "700",
  color: Colors.twilight,
  paddingBottom: 8,
}));

const StyledHeader = styled("h3")(() => ({
  fontSize: 32,
  lineHeight: "39px",
  fontWeight: "700",
  color: Colors.textDark,
  marginRight: 18,
}));

const AddUsers = () => {
  const navigate = useNavigate();
  const mobileView = useMediaQuery("(max-width:678px)");
  const dispatch = useDispatch();
  const loading = useSelector(selector.selectLoading);
  const [newUsers, setNewUsers] = useState([]);
  const [addUserAPIValidationErrors, setAddUserAPIValidationErrors] = useState<
    APIValidationErrors<UserFields>
  >([]);
  const [isAddNewUserModalOpen, toggleAddNewUserModalOpen] = useToggle();
  const [isImportCSVModalOpen, toggleImportCSVModalOpen] = useToggle();

  const handleRemoveUser = (user: User) => {
    let _users = [...newUsers];
    _users = newUsers.filter((u: User) => u.email != user.email);
    setNewUsers(_users);
  };

  const goBack = () => {
    navigate("/groups", { replace: true, state: { tabIndex: 1 } });
  };

  const handleSave = async () => {
    const data = newUsers;
    if (newUsers?.length == 0) {
      snackbar.error("No users have been added.");
      return false;
    }
    const res = await dispatch(actions.saveUsers(data));
    if (res.error) {
      if (res.detail && isAPIValidationErrors(res.detail)) {
        setAddUserAPIValidationErrors(res.detail);
      } else {
        snackbar.error("Something went wrong");
      }
    } else {
      snackbar.success("Users have been added successfully.");
      goBack();
    }
  };

  const onOpenUserCreateModal = () => {
    setNewUsers([]);
    toggleAddNewUserModalOpen();
  };

  const handleUpdateCSVdata = async (data: User[]) => {
    if (data && data.length > 0) {
      setNewUsers(data);
      toggleImportCSVModalOpen();
      toggleAddNewUserModalOpen();
    }
  };

  return (
    <StyledBulkScaffolding sx={{ mt: mobileView ? "149px" : "104px" }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ paddingTop: 2 }}
      >
        <Grid item>
          <StyledBackButton
            startIcon={<ArrowBackIcon />}
            color="primary"
            onClick={goBack}
          >
            Back
          </StyledBackButton>
        </Grid>
        <Grid item>
          <Box sx={{ display: "flex" }}>
            <StyledContainer
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <img
                    src={require("src/assets/images/check.png")}
                    style={{ width: 18, height: 18 }}
                  />
                )
              }
              onClick={handleSave}
            >
              Save
            </StyledContainer>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        sx={{ pt: 2 }}
      >
        <Grid item>
          <StyledHeader>Add Users</StyledHeader>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#E7EDF0",
          borderRadius: "8px",
        }}
      >
        <Box sx={{ width: "100%", mt: 2 }}>
          {!newUsers.length && (
            <StyledListContainer>
              <EmptyUserList
                openModal={onOpenUserCreateModal}
                openImportCSVModal={toggleImportCSVModalOpen}
              />
            </StyledListContainer>
          )}
          {!!newUsers.length && (
            <UserList
              users={newUsers}
              openModal={onOpenUserCreateModal}
              openImportCSVModal={toggleImportCSVModalOpen}
              handleRemoveUser={handleRemoveUser}
            />
          )}
        </Box>
      </Box>

      <AddNewUserModal
        open={isAddNewUserModalOpen}
        handleClose={toggleAddNewUserModalOpen}
        newUsers={newUsers}
        setNewUsers={setNewUsers}
        handleSaveUsers={handleSave}
        savingUsers={loading}
        apiErrors={addUserAPIValidationErrors}
        setApiErrors={setAddUserAPIValidationErrors}
      />

      {/*TODO: reuse*/}
      <Dialog
        open={isImportCSVModalOpen}
        onClose={toggleImportCSVModalOpen}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={false}
        sx={{}}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "8px 0px",
          }}
        >
          <Box>
            <StyledDialogTitle id="scroll-dialog">
              Upload User CSV File
            </StyledDialogTitle>
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", paddingRight: "24px" }}
          >
            <IconButton
              aria-label="close"
              sx={{
                // width: 35,
                marginLeft: 1,
              }}
              onClick={toggleImportCSVModalOpen}
            >
              <CloseIcon color="primary" />
            </IconButton>
          </Box>
        </Box>
        <DialogContent
          dividers={false}
          sx={{ minWidth: "60vw", overflowY: "unset" }}
        >
          {
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: 200,
                  width: "100%",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                <DropZone updateCSVdata={handleUpdateCSVdata} />
                <Box sx={{ height: "50px" }}></Box>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "18px",
                    lineHeight: "20px",
                    fontWeight: "700",
                    color: Colors.twilight,
                  }}
                >
                  Things to keep in mind
                </Typography>
                <Box sx={{ height: 10 }}></Box>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "13px",
                    lineHeight: "17px",
                    fontWeight: "400",
                    color: Colors.textGray100,
                  }}
                >
                  1. Please follow the format as per the sample file. Only .csv
                  files are supported.
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "13px",
                    lineHeight: "17px",
                    fontWeight: "400",
                    color: Colors.textGray100,
                  }}
                >
                  2. All columns except 'email' are optional.
                </Typography>
              </Box>
            </Box>
          }
        </DialogContent>
      </Dialog>
    </StyledBulkScaffolding>
  );
};

export default AddUsers;
