import { createSelector } from "reselect";
import { Props } from "./queryGroupReducers";

const selectRaw = (state: { bulk_search: { query_group: Props } }) =>
  state.bulk_search.query_group;

const selectLoading = createSelector([selectRaw], (raw) => raw.loading);

const selectCreated = createSelector([selectRaw], (raw) => raw.created);

const selectError = createSelector([selectRaw], (raw) => raw.error);

const selectQueryGroupList = createSelector(
  [selectRaw],
  (raw) => raw.queryGroups
);
const selectUpdatedStatus = createSelector([selectRaw], (raw) => raw.updated);
const selectSelectedSearchTerm = createSelector(
  [selectRaw],
  (raw) => raw.selectedSearchTerm
);
const selectSearchResult = createSelector(
  [selectRaw],
  (raw) => raw.searchResult
);
const selectSearchQueryGroupResult = createSelector(
  [selectRaw],
  (raw) => raw.searchResultQueryGroup
);

const screeningSelectors = {
  selectRaw,
  selectLoading,
  selectCreated,
  selectError,
  selectQueryGroupList,
  selectUpdatedStatus,
  selectSelectedSearchTerm,
  selectSearchResult,
  selectSearchQueryGroupResult,
};

export default screeningSelectors;
