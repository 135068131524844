import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  TextField,
  Typography,
  InputAdornment,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { DataCollection } from "src/types/DataCatalog";
import { useDatasetMetadata } from "src/modules/api/datasetMetadata";
import React, { useMemo, useState } from "react";
import { without, chain } from "lodash";

export interface AddDatasetDialogProps {
  open: boolean;
  collection: DataCollection;
  handleUpdateDatasets: (datasets: string[]) => void;
  handleClose: () => void;
}

const ListWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing(0.5),
}));

const StyledList = styled(List)(({ theme }) => ({
  height: 400,
  width: 400,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.spacing(1),
  overflow: "auto",
}));

const NoDatasetsFound = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: 400,
      width: "100%",
    }}
  >
    <img
      height={200}
      width={200}
      src={require("src/assets/images/not-found.png")}
      alt="Not Found"
    />
    No Datasets
  </Box>
);

export default function AddDatasetDialog({
  open,
  collection,
  handleUpdateDatasets,
  handleClose,
}: AddDatasetDialogProps) {
  const { getDatasetMetadata } = useDatasetMetadata();
  const { data: datasets } = getDatasetMetadata();
  const [filter, setFilter] = useState<string>("");
  const [checkedDatasetIds, setCheckedDatasetIds] = useState<string[]>([]);

  const handleCheck = (id: string) => {
    setCheckedDatasetIds((prev) => {
      return prev.includes(id) ? without(prev, id) : [id, ...prev];
    });
  };

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  const { unaddedDatasetIds, addedDatasetIds } = useMemo(() => {
    const filteredDatasets =
      filter === ""
        ? datasets
        : datasets.filter(({ name }) =>
            name.toLowerCase().includes(filter.toLowerCase())
          );

    const unaddedDatasetIds = chain(filteredDatasets)
      .filter(({ id }) => !collection?.datasets?.includes(id))
      .filter(({ id }) => !checkedDatasetIds.includes(id))
      .map(({ id }) => id)
      .value();

    const addedDatasetIds = chain(filteredDatasets)
      .filter(
        ({ id }) =>
          collection?.datasets?.includes(id) || checkedDatasetIds.includes(id)
      )
      .map(({ id }) => id)
      .sortBy((id) => !checkedDatasetIds.includes(id))
      .value();

    return { unaddedDatasetIds, addedDatasetIds };
  }, [datasets, collection, filter, checkedDatasetIds]);

  const handleClearCheckedDatasetIds = () => {
    setCheckedDatasetIds([]);
  };

  const handleSubmit = () => {
    handleUpdateDatasets([...checkedDatasetIds, ...collection.datasets]);
    handleClose();
    setFilter("");
    handleClearCheckedDatasetIds();
  };

  const handleCancel = () => {
    handleClose();
    setFilter("");
    handleClearCheckedDatasetIds();
  };

  const isChecked = (id: string) => {
    return addedDatasetIds.includes(id);
  };

  const isDisabled = (id: string) => {
    return collection.datasets.includes(id);
  };

  return (
    <Dialog open={open} maxWidth={"lg"} scroll={"paper"} onClose={handleClose}>
      <DialogTitle>Add Datasets</DialogTitle>
      <IconButton
        aria-label="close"
        color="primary"
        onClick={handleCancel}
        sx={{
          position: "absolute",
          right: 8,
          top: 9,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText>
          Select datasets to add to this collection.
        </DialogContentText>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mb: 1,
          }}
        >
          <TextField
            variant="outlined"
            size="small"
            sx={{
              "& input": { fontSize: "0.9rem" },
            }}
            placeholder="Search datasets"
            inputProps={{ "aria-label": "search" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={handleFilter}
          />
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <ListWrapper>
            <Typography variant="h6" color="primary">
              All Datasets
            </Typography>
            <StyledList dense>
              {unaddedDatasetIds.length > 0 ? (
                unaddedDatasetIds.map((id, index) => (
                  <ListItem
                    key={`unadded-${index}`}
                    onClick={() => handleCheck(id)}
                  >
                    <ListItemIcon>
                      <Checkbox checked={isChecked(id)} />
                    </ListItemIcon>
                    <ListItemText
                      primary={datasets.find((d) => d.id === id).name}
                      secondary={datasets.find((d) => d.id === id).sensitivity}
                      primaryTypographyProps={{ color: "primary" }}
                      secondaryTypographyProps={{ color: "textSecondary" }}
                    />
                  </ListItem>
                ))
              ) : (
                <NoDatasetsFound />
              )}
            </StyledList>
          </ListWrapper>
          <ListWrapper>
            <Typography variant="h6" color="primary">
              Selected Datasets
            </Typography>
            <StyledList dense>
              {addedDatasetIds.length > 0 ? (
                addedDatasetIds.map((id, index) => (
                  <ListItem
                    key={`added-${index}`}
                    onClick={() => handleCheck(id)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        disabled={isDisabled(id)}
                        checked={isChecked(id)}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={datasets.find((d) => d.id === id).name}
                      secondary={datasets.find((d) => d.id === id).sensitivity}
                      primaryTypographyProps={{ color: "primary" }}
                      secondaryTypographyProps={{ color: "textSecondary" }}
                    />
                  </ListItem>
                ))
              ) : (
                <NoDatasetsFound />
              )}
            </StyledList>
          </ListWrapper>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          disabled={checkedDatasetIds.length === 0}
        >
          Add
        </Button>
        <Button variant="text" color="primary" onClick={handleCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
