function selectErrorKeyOrMessage(error: unknown) {
  if (error && error["response"] && error["response"].data) {
    const data = error["response"].data;

    if (data.error && data.error.message) {
      return data.error.message;
    }

    return String(data);
  }

  return error["message"];
}

function selectErrorMessage(error: unknown) {
  const key = selectErrorKeyOrMessage(error);

  return key;
}

function selectErrorCode(error: unknown) {
  if (error && error["response"] && error["response"].status) {
    return error["response"].status;
  }

  return 500;
}

export default class Errors {
  static handle(error: unknown) {
    if (process.env.NODE_ENV !== "test") {
      console.error(selectErrorMessage(error));
      console.error(error);
    }

    if (selectErrorCode(error) === 401) {
      window.location.reload();
      return;
    }

    if (selectErrorCode(error) === 403) {
      return;
    }

    if ([400, 429].includes(selectErrorCode(error))) {
      return;
    }
  }

  static errorCode(error: unknown) {
    return selectErrorCode(error);
  }

  static selectMessage(error: unknown) {
    return selectErrorMessage(error);
  }
}
