import { AuthToken } from "../auth/authToken";
import { merge } from "lodash";

const prepURL = (path: string | URL): URL => {
  let newURL = path;
  const isFullURL = (url: string | URL) => {
    try {
      return Boolean(new URL(url));
    } catch (e) {
      return false;
    }
  };

  // check for possible external URLs, default to own API otherwise
  if (!isFullURL(path)) {
    newURL = process.env.REACT_APP_BACKEND_URL + path;
  }

  return newURL as URL;
};

export const fetchApi = async (
  path: string | URL,
  initOptions: RequestInit = {},
  skipDefaults = false
) => {
  try {
    const url = prepURL(path);

    const token = AuthToken.get();
    const defaultHeaders = {
      Authorization: `Bearer ${token}`,
    };

    // FormData is used for file uploads, so we don't want to set the content type
    if (!(initOptions.body instanceof FormData)) {
      defaultHeaders["Content-Type"] = "application/json";
    }

    const defaultInitOptions = {
      headers: defaultHeaders,
      timeout: 1000,
    };

    const res = await fetch(
      url,
      merge(skipDefaults ? {} : defaultInitOptions, initOptions)
    );
    if (res.status === 403) {
      // wut dis anyway?
      if (url.pathname.includes("add_to_group")) return res;

      window.dispatchEvent(
        new CustomEvent("APIErrorOccurred", {
          detail: { message: "Unauthorized", code: res.status },
        })
      );
      return res;
    }
    if (res.status == 401) {
      AuthToken.logout();
      return;
    }
    if (res.status === 400 || res.status === 404) {
      return res;
    }

    if (res.status >= 500) {
      window.dispatchEvent(
        new CustomEvent("APIErrorOccurred", {
          detail: { message: "Something went wrong", code: res.status },
        })
      );
      return res;
    }
    return res;
  } catch (err: any) {
    if (typeof err === "object" && err?.message?.includes("Failed to fetch")) {
      window.dispatchEvent(
        new CustomEvent("APIErrorOccurred", {
          detail: {
            message:
              "Something went wrong, please check your network or contact a site administrator",
          },
        })
      );
    }
  }
};
