import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Button, Badge } from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Colors } from "src/view/constants/colors";
import filterSelector from "src/modules/bulk-search/filter/searchFilterSelectors";
import FilterIconUrl from "src/assets/images/data-filter.png";
import CheckIconUrl from "src/assets/images/check.png";

const Wrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "12px",
  button: {
    height: 46,
    textTransform: "capitalize",
    fontWeight: "700",
    lineHeight: 1,
  },
});

const BackButton = styled(Button)({
  color: Colors.textDark,
  fontSize: 16,
  padding: "0 15px",
  marginLeft: "-15px",
  marginRight: "auto",
});

const SaveButton = styled(Button)({
  backgroundColor: "#F75151",
  color: "#ffffff",
  padding: 15,
  "&:hover": {
    backgroundColor: "#F7515188",
  },
});

interface Props {
  onSave: () => void;
  onBack: () => void;
}

const Header = ({ onSave, onBack }: Props) => {
  const location = useLocation();
  const filter = useSelector(filterSelector.selectFilter);

  const appliedCount = useMemo(() => {
    if (!filter) {
      return null;
    }
    return (
      filter.dataset_ids.length +
      filter.doc_types.length +
      filter.dataset_types.length +
      filter.countries.length +
      filter.regions.length +
      filter.languages.length
    );
  }, [filter]);

  const handleDrawerOpenWithEditOption = () => {
    const { groupData } = location.state;
    window.dispatchEvent(
      new CustomEvent("OpenDatasetFilterDrawer", {
        detail: {
          isMine: groupData.isMine || false,
          groupid: groupData.data?.id || null,
        },
      })
    );
  };

  return (
    <Wrapper>
      <BackButton startIcon={<ArrowBackIcon />} onClick={onBack}>
        Back
      </BackButton>

      <Button
        startIcon={<img src={FilterIconUrl} width="18" height="18" />}
        variant="outlined"
        onClick={handleDrawerOpenWithEditOption}
      >
        Dataset Filter
        {appliedCount > 0 && (
          <Badge
            badgeContent={appliedCount}
            color="secondary"
            sx={{ marginLeft: "18px", marginRight: "4px" }}
          />
        )}
      </Button>

      <SaveButton
        startIcon={<img src={CheckIconUrl} width="18" height="18" />}
        onClick={onSave}
      >
        Save
      </SaveButton>
    </Wrapper>
  );
};

export default Header;
