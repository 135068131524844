import { useEffect, useMemo, useRef } from "react";
import { useIntersectionObserver } from "usehooks-ts";
import { Box, Button, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import _ from "lodash";
import { User } from "src/types/UserGroup";
import { getFullName } from "src/utils";
import { useUsers } from "src/modules/api/users";

const ITEM_H = 45;

const Wrapper = styled(Box)({
  position: "absolute",
  left: 14,
  right: 14,
  backgroundColor: "#fff",
  border: "1px solid #CED3D9",
  marginTop: -3,
  boxSizing: "border-box",
  boxShadow: "0px 24px 54px rgba(165, 184, 206, 0.25)",
  borderRadius: "0px 0px 4px 4px",
  overflow: "hidden",
  zIndex: 1,
});

const Item = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingLeft: "16px",
  boxSizing: "border-box",
  color: "#122945",
  fontSize: "14px",
  ":not(:last-child)": {
    borderBottom: "1px solid #f5f5f5",
  },
});

const AddButton = styled(Button)({
  color: "#F75151",
  padding: "0 10px",
  height: 38,
  textTransform: "capitalize",
  "&:hover": {
    background: "transparent",
  },
});

interface Props {
  searchKey: string;
  selectedUsers: User[];
  groupAdminIds: string[];
  onAdd?: (user: User) => void;
}

const UserList = ({
  searchKey,
  groupAdminIds,
  selectedUsers,
  onAdd,
}: Props) => {
  const refList = useRef<HTMLDivElement>();
  const { getUsersInfinite } = useUsers();
  const {
    data: usersInfiniteData,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = getUsersInfinite({
    searchKey,
  });

  const { ref: endOfListRef, isIntersecting } = useIntersectionObserver();

  useEffect(() => {
    if (isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  }, [isIntersecting, hasNextPage]);

  const displayedUsers = useMemo(() => {
    // exclude users both already admins and selected to be granted admin from the options
    if (!usersInfiniteData?.pages) return [];
    return _.flatMap(usersInfiniteData.pages, (page) => {
      return _.reject(page.results, (user) => {
        return (
          _.includes(groupAdminIds, user.id) ||
          _.includes(selectedUsers, { id: user.id })
        );
      });
    });
  }, [usersInfiniteData, groupAdminIds, selectedUsers]);

  return (
    <Wrapper
      height={`min(calc(100% - 132px), ${
        (displayedUsers.length || 1) * ITEM_H
      }px)`}
    >
      <Box height="100%">
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              outerRef={refList}
              width={width}
              height={height}
              itemSize={ITEM_H}
              itemCount={displayedUsers.length + (isFetchingNextPage ? 1 : 0)}
            >
              {({ index, style }) => {
                const user: User = displayedUsers[index];
                return user ? (
                  <Item style={style}>
                    <Box display="flex" flexDirection="column">
                      <strong>{getFullName(user) || "--"}</strong>
                      <span>{user.email}</span>
                    </Box>
                    <AddButton
                      startIcon={<AddIcon />}
                      onClick={(e) => {
                        e.stopPropagation();
                        onAdd(user);
                      }}
                      disableRipple
                    >
                      Add
                    </AddButton>
                  </Item>
                ) : (
                  <Box
                    style={style}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <CircularProgress size={20} />
                  </Box>
                );
              }}
            </FixedSizeList>
          )}
        </AutoSizer>
        {!isFetchingNextPage && <div ref={endOfListRef} />}
      </Box>
    </Wrapper>
  );
};

export default UserList;
