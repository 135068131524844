import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Divider,
  IconButton,
  InputBase,
  styled,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  fixedLayoutHeights,
  PreviewContext,
  PreviewTabs,
} from "src/view/search-result/components/Preview/Source/PreviewContext";
import { Colors } from "src/view/constants/colors";
import { Add as PlusIcon, Remove as MinusIcon } from "@mui/icons-material";
import { ReactComponent as IconSplitVertical } from "src/assets/icons/split_vertical.svg";
import { ReactComponent as IconSplitHorizontal } from "src/assets/icons/split_horizontal.svg";
import { getTotalMatches } from "src/utils";

export const StyledHeader = styled("div")(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  paddingLeft: 12,
  paddingRight: 12,
  fontWeight: 700,
  fontSize: 14,
  color: "#122945",
  height: fixedLayoutHeights.controlBar,
  boxSizing: "border-box",
}));

export const AntTabs = styled(Tabs)({
  paddingLeft: 14,
  overflow: "visible",
  "& .MuiTabs-indicator": {
    display: "none",
  },
  minHeight: "33px",
  marginRight: "30px",
});

interface StyledTabProps {
  label: React.ReactElement | string;
}
export const AntTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  minHeight: "33px",
  padding: "0 25px",
  fontSize: 12,
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightBold,
  color: "#B8BFC7",
  "&:hover": {
    color: "#40a9ff",
    opacity: 1,
  },
  "&.Mui-selected": {
    color: "#fff",
    backgroundColor: Colors.secondary,
    borderColor: Colors.secondary,
  },
  border: "1px solid #B8BFC7",
  "&:first-of-type": {
    borderRadius: "4px 0 0 4px",
  },
  "&:last-of-type": {
    borderRadius: "0 4px 4px 0",
  },
}));

const StylePageInput = styled(InputBase)(() => ({
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
}));

const featureSet = {
  // Document - for embedded pdfs in entity previews
  Document: ["tabs", "pages", "scale", "split"],
  pdf: ["tabs", "pages", "scale", "split"],
  docx: ["pages", "font", "split"],
  doc: ["font"],
};

const ControlBar = () => {
  const {
    tab,
    setTab,
    page,
    setPage,
    numPages,
    font,
    setFont,
    scale,
    setScale,
    setContentHeight,
    split,
    setSplit,
    query,
    previewDocInfo,
  } = useContext(PreviewContext);

  const features = featureSet?.[previewDocInfo?.type] || [];

  const [pageInput, setPageInput] = useState<string | number>(page);

  useEffect(() => {
    setPageInput(page);
  }, [page]);

  const handlePageInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageInput(event.target.value);
  };

  const handlePageInputBlur = () => {
    const value = Number(pageInput);
    if (!value) {
      setPageInput(1);
    }
    if (value > numPages) {
      setPageInput(numPages);
    }
    setPage(value > numPages ? numPages : value || 1);
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleSetScale = (action: "add" | "subtract") => {
    const step = 10;
    const triggerResizeObserver = () =>
      setContentHeight((value: number) => (value % 2 ? value + 1 : value - 1));
    if (action === "add") {
      setScale((prevState: number) => prevState + step);
      triggerResizeObserver();
    }
    if (action === "subtract" && scale !== step) {
      setScale((prevState: number) => prevState - step);
      triggerResizeObserver();
    }
  };

  const handleSetFont = (action: "add" | "subtract") => {
    const step = 1;
    const minValue = 10;
    const maxValue = 20;
    if (action === "add" && font < maxValue) {
      setFont((prevState: number) => prevState + step);
    }
    if (action === "subtract" && font !== minValue) {
      setFont((prevState: number) => prevState - step);
    }
  };

  const toggleSplit = () => setSplit((prevSplit: boolean) => !prevSplit);

  const renderFeatureDivider = (feature: string) => {
    if (
      features.findIndex((item: string) => item === feature) ===
      features.length - 1
    ) {
      return null;
    }

    return (
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          margin: "0 30px",
        }}
      />
    );
  };

  const totalMatches = getTotalMatches(previewDocInfo?.highlight?.content);
  return (
    <StyledHeader>
      <Box
        sx={{
          flex: "1 0",
        }}
      >
        {features.includes("tabs") && (
          <Box sx={{ display: "flex" }}>
            <AntTabs
              value={tab}
              onChange={handleTabChange}
              aria-label="preview tabs"
              sx={{}}
            >
              <AntTab label={PreviewTabs.PREVIEW} />
              <AntTab label={PreviewTabs.RAW_TEXT} />
            </AntTabs>
          </Box>
        )}

        {query && previewDocInfo?.highlight?.content?.length && (
          <Box
            sx={{
              display: "flex",
              fontSize: "14px",
            }}
          >
            <Typography
              sx={{
                fontSize: "inherit",
                fontWeight: "inherit",
                color: "#536378",
              }}
            >
              {getTotalMatches(previewDocInfo?.highlight?.content)}{" "}
              {`result${totalMatches > 1 ? "s" : ""} for`}:
            </Typography>
            <Typography
              sx={{
                marginLeft: "5px",
                fontSize: "inherit",
                fontWeight: "inherit",
              }}
            >
              {query}
            </Typography>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          flex: "1 0",
          justifyContent: "flex-end",
          fontWeight: "inherit",
          display: "flex",
          alignItems: "center",
          marginLeft: "auto",
        }}
      >
        {features.includes("pages") && (
          <>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <StylePageInput
                inputProps={{
                  min: 1,
                  max: numPages,
                }}
                type="number"
                value={pageInput}
                onChange={handlePageInput}
                onBlur={handlePageInputBlur}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handlePageInputBlur();
                  }
                }}
                sx={{
                  width: pageInput
                    ? `${pageInput.toString().length + 1}ch`
                    : "auto",
                  minWidth: "26px",
                  maxWidth: "52px",
                  height: "33px",
                  fontWeight: "inherit",
                  padding: "6px 5px",
                  border: "1px solid #CED3D9",
                  borderRadius: "4px",
                  marginRight: "10px",
                }}
              />
            </Box>

            <Box
              sx={{
                marginRight: "10px",
              }}
            >
              /
            </Box>
            <Box>{numPages}</Box>

            {renderFeatureDivider("pages")}
          </>
        )}

        {features.includes("scale") && (
          <>
            <IconButton
              onClick={() => handleSetScale("add")}
              sx={{
                padding: 0,
              }}
            >
              <PlusIcon />
            </IconButton>
            <Box
              sx={{
                padding: "6px 12px",
                border: "1px solid #CED3D9",
                borderRadius: "4px",
                margin: "0 11px",
              }}
            >
              {scale}%
            </Box>

            <IconButton
              onClick={() => handleSetScale("subtract")}
              sx={{
                padding: 0,
              }}
            >
              <MinusIcon />
            </IconButton>

            {renderFeatureDivider("scale")}
          </>
        )}

        {features.includes("font") && (
          <>
            <IconButton
              onClick={() => handleSetFont("add")}
              sx={{
                padding: 0,
              }}
            >
              <PlusIcon />
            </IconButton>
            <Box
              sx={{
                padding: "6px 12px",
                border: "1px solid #CED3D9",
                borderRadius: "4px",
                margin: "0 11px",
              }}
            >
              {font}pt
            </Box>

            <IconButton
              onClick={() => handleSetFont("subtract")}
              sx={{
                padding: 0,
              }}
            >
              <MinusIcon />
            </IconButton>

            {renderFeatureDivider("font")}
          </>
        )}

        {features.includes("split") && (
          <IconButton onClick={toggleSplit}>
            {split ? <IconSplitHorizontal /> : <IconSplitVertical />}
          </IconButton>
        )}
      </Box>
    </StyledHeader>
  );
};

export default ControlBar;
