let inMemoryToken: string | null = null;

export class AuthToken {
  static get() {
    return inMemoryToken || localStorage.getItem("jwt") || null;
  }

  static set(token: string | null) {
    localStorage.setItem("jwt", token || "");
    inMemoryToken = token;
  }

  static applyFromLocationUrlIfExists() {
    const urlParams = new URLSearchParams(window.location.search);
    const authToken = urlParams.get("authToken");

    if (!authToken) {
      return;
    }

    this.set(authToken);
    window.history.replaceState({}, document.title, window.location.origin);
  }

  static logout() {
    localStorage.removeItem("jwt");
  }
}
