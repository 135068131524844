import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Tab,
  Tabs,
} from "@mui/material";

import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "../constants/colors";
import { useEffect, useState } from "react";
import actions from "src/modules/auth/actions";
import selector from "src/modules/auth/selectors";
import { useDispatch, useSelector } from "react-redux";
import CustomTextField from "../components/CustomTextField";
import PasswordManagement from "./PasswordManagement";
import Settings from "./Settings";
import { useUser } from "src/modules/api/auth";
import { useLocation } from "react-router-dom";
import { snackbar } from "src/view/toaster";

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: Colors.secondary,
    height: 3,
  },
});

const AntTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontSize: 16,
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: Colors.textDark100,
  "&:hover": {
    color: "#40a9ff",
    opacity: 1,
  },
  "&.Mui-selected": {
    color: Colors.textDark100,
    fontWeight: "bold",
    "&>p>span": {
      color: Colors.secondary,
    },
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#d1eaff",
  },
}));

interface StyledTabProps {
  label: string;
}
const StyledBulkScaffolding = styled(Container)(() => ({
  marginTop: 127,
  paddingBottom: 100,
}));

const StyledHeader = styled("h3")(() => ({
  fontSize: 32,
  lineHeight: "39px",
  fontWeight: "700",
  color: Colors.textDark100,
  marginRight: 18,
}));

const StyledName = styled("div")(() => ({
  fontSize: 24,
  lineHeight: "29.43px",
  fontWeight: "700",
  color: Colors.textDark100,
}));

const StyledEmail = styled(Box)(() => ({
  fontSize: 16,
  lineHeight: "24px",
  fontWeight: "400",
  color: Colors.textDark100,
}));

const StyledAvatar = styled(Box)(() => ({
  width: 100,
  height: 100,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  backgroundColor: "#12294588",
  fontSize: 32,
  lineHeight: "39.23px",
  fontWeight: "600",
  color: "white",
}));

const StyledIcon = styled("img")(() => ({
  width: 20,
  height: 20,
  marginLeft: 10,
  objectFit: "contain",
  cursor: "pointer",
}));

const StyledButton = styled(Button)(() => ({
  borderRadius: 4,
  backgroundColor: "#F75151",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#ffffff",
  fontSize: 14,
  lineHeight: "17px",
  fontWeight: "700",
  padding: 15,
  height: 46,
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#F7515188",
  },
}));

const ScaffoldingPage = () => {
  const location = useLocation();
  const { user } = useUser();
  const loading = useSelector(selector.selectLoading);
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [editUserName, setEditUserName] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
  });
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (user) {
      const _fd = { ...formData };
      _fd.first_name = user.first_name;
      _fd.last_name = user.last_name;
      setFormData(_fd);
    }
  }, [user]);

  useEffect(() => {
    if (location.state?.tabIndex !== undefined) {
      setTabIndex(location.state.tabIndex);
    }
  }, [location]);

  const getData = () => {
    dispatch(actions.getUserDetail(user?.id));
  };

  const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleChangeFormData = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const _fd = { ...formData };
    _fd[e.target.name] = e.target.value;
    setFormData(_fd);
  };

  const handleUpdateUserName = async () => {
    if (!formData.first_name || !formData.last_name) {
      snackbar.warning("Please enter user names.");
      return;
    }

    setUpdating(true);
    const res = await dispatch(actions.updateUserDetail(user?.id, formData));
    if (!res?.error) {
      setUpdating(false);
      snackbar.success("Your names were updated successfully.");
      setEditUserName(false);
      const _fd = { ...formData };
      _fd.first_name = "";
      _fd.last_name = "";
      setFormData(_fd);
      getData();
    } else {
      snackbar.error("Something went wrong!");
    }
  };

  return (
    <StyledBulkScaffolding>
      <Grid
        justifyContent="space-between"
        alignItems="center"
        sx={{ paddingTop: 2, display: "flex" }}
        style={{ marginLeft: 0, marginRight: 0 }}
      >
        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              <StyledHeader>Profile</StyledHeader>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "flex-start",
          flexWrap: "wrap",
        }}
      >
        {!loading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              flex: "1 1",
            }}
          >
            {user && (
              <>
                <StyledAvatar>
                  {user.first_name ? user.first_name[0] : "N"}
                  {user.last_name ? user.last_name[0] : "N"}
                </StyledAvatar>
                <Box sx={{ ml: 3 }}>
                  <StyledName>
                    {user.first_name + " " + user.last_name}{" "}
                    {!editUserName && (
                      <StyledIcon
                        src={require("src/assets/images/edit.png")}
                        onClick={() => setEditUserName(true)}
                      />
                    )}
                  </StyledName>
                  <StyledEmail sx={{ mt: 1 }}>{user.email}</StyledEmail>
                </Box>
              </>
            )}
          </Box>
        ) : (
          <CircularProgress />
        )}
        {editUserName && (
          <Box
            sx={{
              ml: 3,
              mt: 2,
              display: "flex",
              gap: 2,
              flexWrap: "wrap",
              flex: "3 1",
            }}
          >
            <CustomTextField
              type={"text"}
              value={formData.first_name || ""}
              name="first_name"
              tabIndex={1}
              autoFocus
              containerStyle={{ maxWidth: 200, minWidth: 100 }}
              onChange={handleChangeFormData}
            />
            <CustomTextField
              type={"text"}
              value={formData.last_name || ""}
              name="last_name"
              tabIndex={2}
              containerStyle={{ maxWidth: 200, minWidth: 100 }}
              onChange={handleChangeFormData}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: 2,
                margin: 0,
              }}
            >
              <StyledButton
                onClick={() => setEditUserName(false)}
                sx={{
                  background: "white",
                  color: Colors.textDark100,
                  border: "1px solid #122945",
                  "&:hover": { background: "white" },
                }}
              >
                Cancel
              </StyledButton>
              <StyledButton
                onClick={handleUpdateUserName}
                startIcon={
                  updating ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <CircularProgress size={"small"} color="primary" />
                  )
                }
              >
                Save
              </StyledButton>
            </Box>
          </Box>
        )}
      </Box>
      <Box sx={{ height: 32 }} />
      <Box sx={{ bgcolor: "#fff", width: "100%" }}>
        <AntTabs
          value={tabIndex}
          onChange={handleChangeTab}
          aria-label="ant example"
        >
          <AntTab label={"Settings"} />
          <AntTab label={"Forgot Password"} />
        </AntTabs>
        {tabIndex === 0 ? <Settings /> : <PasswordManagement />}
      </Box>
    </StyledBulkScaffolding>
  );
};

export default ScaffoldingPage;
