import { Dataset } from "src/types/Dataset";
import { SearchFilter } from "src/types/SearchFilter";
import { QueryItem } from "src/types/BulkSearch";
import actions from "./searchFilterActions";

export interface SearchFilterProps {
  loading: boolean;
  filter: SearchFilter;
  query: QueryItem[];
}

const initialData: SearchFilterProps = {
  loading: false,
  filter: null,
  query: null,
};

export default (
  state = initialData,
  {
    type,
    payload,
  }: {
    type: string;
    payload?: boolean | Dataset[] | SearchFilter | QueryItem[] | null;
  }
) => {
  if (type === actions.FIND_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.FIND_SUCCESS) {
    return {
      ...state,
      datasets: payload,
      loading: false,
    };
  }

  if (type === actions.FIND_ERROR) {
    return {
      ...state,
      loading: false,
    };
  }

  if (type === actions.SET_FILTER) {
    return {
      ...state,
      filter: payload,
      loading: false,
    };
  }

  if (type === actions.SET_QUERY) {
    return {
      ...state,
      query: payload,
    };
  }

  return state;
};
