import { Box, Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "src/view/constants/colors";
import { Features } from "src/types/Auth";
import { useUser } from "src/modules/api/auth";

interface DownloadMenuProps {
  anchorEl: Element | ((element: Element) => Element);
  handleCloseMenu: (e: React.MouseEvent) => void;
  isMenuOpen: boolean;
  type: string;
  handleDownloadDoc: (entireFile?: boolean) => void;
  handleDownloadDocBulk?: () => void;
  source_type: number;
}

const StyledMenuItem = styled(MenuItem)(() => ({
  color: Colors.twilight,
  fontSize: 12,
  lineHeight: "20px",
  fontWeight: "700",
  height: 35,
}));

export default function DownloadMenu({
  anchorEl,
  handleCloseMenu,
  isMenuOpen,
  type,
  handleDownloadDoc,
  handleDownloadDocBulk,
  source_type,
}: DownloadMenuProps) {
  const { user } = useUser();

  const isSingleFileResult =
    type === "htm" ||
    type === "html" ||
    type === "jhtml" ||
    type === "shtml" ||
    type === "jpg" ||
    type === "jpeg" ||
    type === "png";
  const isEntireFileAllowed =
    !(type === "csv" || type === "parquet") &&
    !isSingleFileResult &&
    source_type === 1;

  // 3 download options are supported
  // 1: download just the result
  // 2: download the entire source file
  // 3: bulk download
  // For anything that isn't a CSV, modeled data or single-file result, we should offer options 1 and 2
  // For CSV and modeled data, we should offer 1 and 3
  const getDownloadOptions = () => {
    return (
      <Box>
        <StyledMenuItem onClick={() => handleDownloadDoc()}>
          Download This Page
        </StyledMenuItem>
        {isEntireFileAllowed ? (
          <StyledMenuItem onClick={() => handleDownloadDoc(true)}>
            Download Source File
          </StyledMenuItem>
        ) : (
          handleDownloadDocBulk &&
          !isSingleFileResult &&
          user?.features?.includes(Features.BULK_DOWNLOAD) && (
            <StyledMenuItem onClick={handleDownloadDocBulk}>
              Download Preview
            </StyledMenuItem>
          )
        )}
      </Box>
    );
  };

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={isMenuOpen}
      onClose={handleCloseMenu}
      onClick={handleCloseMenu}
      sx={{
        zIndex: 1505,
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          bgcolor: "white",
          boxShadow: "0px 5px 26px rgba(0, 0, 0, 0.08)",
          mt: 1.5,
          "&:before": {
            content: "''",
            display: "block",
            position: "absolute",
            top: 0,
            right: 0,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
    >
      {getDownloadOptions()}
    </Menu>
  );
}
