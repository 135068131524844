import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Container, Input, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { snackbar } from "src/view/toaster";
import _ from "lodash";
import queryGroupSelector from "src/modules/bulk-search/query-group/queryGroupSelectors";
import filterSelector from "src/modules/bulk-search/filter/searchFilterSelectors";
import queryGroupActions from "src/modules/bulk-search/query-group/queryGroupActions";
import filterActions from "src/modules/bulk-search/filter/searchFilterActions";
import BulkSearchService from "src/modules/bulk-search/bulkSearchService";
import regions from "src/assets/json/regions.json";
import Header from "./components/Header";
import ImportCSV from "./components/ImportCSV";
import FilterTags from "./components/FilterTags";
import TermsList from "./components/TermsList";
import { TermItem } from "./type";

const Wrapper = styled(Container)({
  display: "flex",
  flexDirection: "column",
  gap: 26,
  paddingTop: 21,
  paddingBottom: 21,
});

const Name = styled(Input)({
  height: "100%",
  width: "50%",
  minWidth: 250,
  maxWidth: 700,
  borderRadius: 4,
  fontSize: 24,
  lineHeight: "29px",
  fontWeight: "700",
  color: "rgba(20, 74, 104)",
  border: "none",
  "&::after": {
    border: "none",
  },
  "&::before": {
    border: "none",
  },
  "&:focus-visible": {
    outline: "none",
  },
  "&:hover": {
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const QueryGroupEdit = () => {
  const mobileView = useMediaQuery("(max-width:678px)");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [groupName, setGroupName] = useState(
    location.state?.groupData?.title || ""
  );
  const filter = useSelector(filterSelector.selectFilter);
  const queryGroups = useSelector(queryGroupSelector.selectQueryGroupList);
  const refData = useRef<Record<string, TermItem>>({});
  const [searchKey] = useState("");
  const screeningListId = location.state?.data?.id;
  const groupId = location.state?.groupData?.id;

  useEffect(() => {
    if (!screeningListId) {
      navigate("/bulk-search/");
      return;
    }
    if (groupId) {
      dispatch(queryGroupActions.getQueryGroups(screeningListId));
    }
  }, []);

  useEffect(() => {
    const targetGroup = queryGroups?.find(({ id }) => id === groupId);
    if (targetGroup) {
      dispatch(
        filterActions.setFilter({
          dataset_ids: targetGroup.dataset_filters.map(({ id }) => id),
          doc_types: targetGroup.docs,
          dataset_types: targetGroup.dataset_types,
          countries: targetGroup.countries,
          regions:
            regions
              .filter((region) => targetGroup.regions.includes(region.value))
              .map(({ label }) => label) || [],
          languages: targetGroup.languages,
        })
      );
    }
  }, [queryGroups]);

  useEffect(
    () => () => {
      /** clean up filters in store on unmount */
      dispatch(
        filterActions.setFilter({
          dataset_ids: [],
          doc_types: [],
          dataset_types: [],
          countries: [],
          regions: [],
          languages: [],
        })
      );
    },
    []
  );

  const goBack = useCallback(() => {
    dispatch(
      filterActions.setFilter({
        dataset_ids: [],
        doc_types: [],
        dataset_types: [],
        countries: [],
        regions: [],
        languages: [],
      })
    );
    const state = { ...location?.state };
    delete state.groupData;
    navigate("/bulk-search/query-group/" + screeningListId, {
      replace: true,
      state,
    });
  }, [navigate, dispatch]);

  const handleSave = () => {
    if (!groupName) {
      snackbar.error("The name is required.");
      return;
    }

    const deletedIds = Object.values(refData.current)
      .filter((term) => term.deleted)
      .map(({ id }) => id);
    Promise.all(deletedIds.map((id) => BulkSearchService.deleteSearchTerm(id)));

    const search_terms: unknown[] = [];
    Object.values(refData.current)
      .filter((term) => !term.deleted)
      .forEach((term) => {
        const search_term = term.items
          .filter(({ value }) => value.trim())
          .map(({ value, match }) => `"${value}"` + (match ? `~${match}` : ""))
          .join(" AND ");
        if (!term.query) {
          search_terms.push({ id: "", search_term });
        } else if (term.query !== search_term) {
          search_terms.push({ id: term.id, search_term });
        }
      });

    const filterData = {
      dataset_filters: filter.dataset_ids,
      doc_types: filter.doc_types,
      dataset_types: filter.dataset_types,
      countries: filter.countries,
      regions: filter.regions.map(
        (label) => _.find(regions, { label })?.["value"]
      ),
      languages: filter.languages,
    };

    if (groupId) {
      dispatch(
        queryGroupActions.update(
          {
            name: groupName,
            search_terms: search_terms,
            ...filterData,
          },
          groupId
        )
      );
    } else {
      dispatch(
        queryGroupActions.create({
          screening_list: screeningListId,
          name: groupName,
          search_terms: search_terms.map((term) => term["search_term"]),
          ...filterData,
        })
      );
    }

    goBack();
  };

  return (
    <Wrapper sx={{ mt: mobileView ? "149px" : "104px" }}>
      <Header onSave={handleSave} onBack={goBack} />
      <Name
        placeholder="Enter Query Group Name"
        value={groupName}
        onChange={(e) => setGroupName(e.target.value)}
      />
      <ImportCSV
        groupId={groupId}
        screeningListId={screeningListId}
        groupName={groupName}
        onBack={goBack}
      />
      <FilterTags />
      <TermsList
        key={searchKey}
        groupId={groupId}
        searchKey={searchKey}
        modifiedTerms={refData.current}
      />
    </Wrapper>
  );
};

export default QueryGroupEdit;
