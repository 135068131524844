import { useState, useEffect } from "react";
import {
  styled,
  Box,
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
  StepIconProps,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import CheckIcon from "@mui/icons-material/Check";
import { useUser } from "src/modules/api/auth";
import { useNavigate } from "react-router-dom";
import SehoLogo from "src/assets/images/logo.png";
import { delay } from "lodash";
import { useFetch } from "src/modules/api/fetch";
import { usePostHog } from "posthog-js/react";

const StepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.grey[700],
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: theme.palette.primary.main,
    }),
    "& .StepIcon-completedIcon": {
      color: theme.palette.primary.main,
      zIndex: 1,
      fontSize: 18,
    },
    "& .StepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  })
);

function StepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <StepIconRoot ownerState={{ active }} className={className}>
      {active ? (
        <CircularProgress size={8} />
      ) : completed ? (
        <CheckIcon className="StepIcon-completedIcon" />
      ) : (
        <div className="StepIcon-circle" />
      )}
    </StepIconRoot>
  );
}

export default function CallbackPage() {
  const { user } = useUser();
  const posthog = usePostHog();
  const navigate = useNavigate();
  const { postApi } = useFetch();
  const [activeStep, setActiveStep] = useState<number>(0);
  const steps = ["Loading User", "Checking Permissions", "Starting Session"];

  const startSessionFn = async () => {
    const data = await postApi(["users", "track_login"]);
    return data;
  };

  const { status, error } = useQuery({
    enabled: !!user,
    queryFn: startSessionFn,
    queryKey: ["startSession"],
  });

  useEffect(() => {
    if (status === "loading") {
      delay(() => {
        setActiveStep(1);
      }, 1000);
      delay(() => {
        setActiveStep(2);
      }, 1000);
    }

    if (status === "success") {
      posthog.identify(user.email);

      navigate("/", { replace: true });
    }

    if (status === "error") {
      console.error(error);
      navigate("/", { replace: true });
    }
  }, [error, status, posthog]);

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 8,
      }}
    >
      <img src={SehoLogo} />
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel StepIconComponent={StepIcon}>{step}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
