import { AnyAction, Dispatch } from "redux";
import Service from "src/modules/auth/authService";
import Errors from "src/modules/shared/error/errors";
import { AuthToken } from "./authToken";

const prefix = "AUTH_";

const Actions: AnyAction = {
  LOADING: `${prefix}_LOADING`,
  LOGIN_ERROR: `${prefix}_LOGIN_ERROR`,
  LOGIN_SUCCESS: `${prefix}_LOGIN_SUCCESS`,
  RESET_PASS_ERROR: `${prefix}_RESET_PASS_ERROR`,
  RESET_PASS_SUCCESS: `${prefix}_RESET_PASS_SUCCESS`,
  USER_PROFILE: `${prefix}_USER_PROFILE`,

  login:
    (data: { email: string; password: string }) =>
    async (dispatch: Dispatch<AnyAction>) => {
      try {
        dispatch({
          type: Actions.LOADING,
          payload: true,
        });

        const res = await Service.login(data);
        if (res.error) {
          dispatch({
            type: Actions.LOGIN_ERROR,
            payload: res.detail,
          });
        } else {
          dispatch({
            type: Actions.LOGIN_SUCCESS,
            payload: res,
          });
        }
        return res;
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: Actions.LOGIN_ERROR,
          payload: error,
        });
      }
    },
  logout: () => {
    AuthToken.logout();
    window.location.reload();
  },
  resetPassword:
    (data: { email: string }) => async (dispatch: Dispatch<AnyAction>) => {
      try {
        dispatch({
          type: Actions.LOADING,
          payload: true,
        });

        const res = await Service.resetPassword(data);
        if (res.error) {
          dispatch({
            type: Actions.RESET_PASS_ERROR,
            payload: res.detail,
          });
        } else {
          dispatch({
            type: Actions.RESET_PASS_SUCCESS,
            payload: res,
          });
        }
        return res;
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: Actions.RESET_PASS_ERROR,
          payload: error,
        });
      }
    },
  setAgree: () => async () => {
    try {
      const res = await Service.setAgree();
      return res;
    } catch (error) {
      Errors.handle(error);
    }
  },
  setSeenNewFeatures: () => async () => {
    try {
      const res = await Service.setSeenNewFeatures();
      return res;
    } catch (error) {
      Errors.handle(error);
    }
  },
  getUserDetail: (id: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({
        type: Actions.LOADING,
        payload: true,
      });

      const res = await Service.getUserDetail(id);
      if (res?.error) {
        dispatch({
          type: Actions.USER_PROFILE,
          payload: null,
        });
      } else {
        dispatch({
          type: Actions.USER_PROFILE,
          payload: res,
        });
      }
      return res;
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: Actions.USER_PROFILE,
        payload: null,
      });
    }
  },
  updateUserDetail: (id: string, data: unknown) => async () => {
    try {
      const res = await Service.updateUserDetail(id, data);
      return res;
    } catch (error) {
      Errors.handle(error);
    }
  },
  updatePasswords: (passwords: unknown) => async () => {
    try {
      const res = await Service.updatePasswords(passwords);
      return res;
    } catch (error) {
      return error;
    }
  },
  trackUserLogin: () => async () => {
    try {
      const res = await Service.trackUserLogin();
      return res;
    } catch (error) {
      return error;
    }
  },
  type: undefined,
};

export default Actions;
