import { Dataset } from "src/types/Dataset";

import { fetchApi } from "../shared/api";

export default class Service {
  static async find(query: string) {
    try {
      const url = "knowledge_wiki/wiki/?" + query;

      const res = await fetchApi(url, {
        method: "GET",
      });
      const response = res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response["detail"],
        };
      }
    } catch (err) {
      return false;
    }
  }
  static async getItemDetail(id: string) {
    try {
      const res = await fetchApi("knowledge_wiki/wiki/" + id + "/", {
        method: "GET",
      });
      const response = res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response["detail"],
        };
      }
    } catch (err) {
      return false;
    }
  }
  static async getRecordCountForKnowledgeWiki(id: string) {
    try {
      const res = await fetchApi("dataset_metadata/" + id + "/count/", {
        method: "GET",
      });
      const response = res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response["detail"],
        };
      }
    } catch (err) {
      return false;
    }
  }
  static async deleteKnowledgeWiki(id: string) {
    try {
      const res = await fetchApi("knowledge_wiki/wiki/" + id + "/", {
        method: "DELETE",
      });
      const response = await res?.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response["detail"],
        };
      }
    } catch (err) {
      console.log(err);

      return false;
    }
  }
  static async updateKnowledgeWiki(data: unknown) {
    try {
      const id = data["id"];
      delete data["id"];

      const res = await fetchApi("knowledge_wiki/wiki/" + id + "/", {
        method: "PUT",
        body: JSON.stringify(data),
      });
      const response = await res?.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response,
        };
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  }
  static async updateDataset(data: Dataset) {
    try {
      const id = data["id"];
      delete data["id"];

      const res = await fetchApi("dataset_metadata/" + id + "/", {
        method: "PUT",
        body: JSON.stringify(data),
      });
      const response = await res?.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response,
        };
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  }
  static async deleteDataset(id: string) {
    try {
      const res = await fetchApi(`dataset_metadata/${id}/`, {
        method: "DELETE",
      });
      const response = await res?.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response,
        };
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  }
}
