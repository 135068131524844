import { useState, useEffect } from "react";
import { Box, PaperProps } from "@mui/material";

import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";

import { Colors } from "src/view/constants/colors";
import { User } from "src/types/UserGroup";

import UserSearchInput from "./UserSearchInput";
import TableItself from "./TableItself";
import EmptyUserList from "./EmptyUserList";

const MenuPaperSXProp: PaperProps = {
  elevation: 0,
  sx: {
    overflow: "visible",
    bgcolor: "white",
    boxShadow: "0px 5px 26px rgba(0, 0, 0, 0.08)",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: "''",
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};

const StyledHeader = styled(Box)(() => ({
  width: "calc(100%)",
  height: 32,
  padding: "14px 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}));

const StyledImage = styled("img")(() => ({
  width: 16,
  height: 16,
  objectFit: "contain",
}));

const Flex = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const StyledButton = styled(Button)(() => ({
  height: 34,
  color: "#122945",
  backgroundColor: "#E7EAEC",
  fontSize: 14,
  lineHeight: "17px",
  fontWeight: "700",
}));

const StyledMenuItem = styled(MenuItem)(() => ({
  color: Colors.twilight,
  fontSize: 18,
  lineHeight: "20px",
  fontWeight: "700",
  height: 45,
}));

interface Props {
  openCreateUserModal?: () => void;
  openImportExistingModal?: () => void;
  openImportCSVModal?: () => void;
  users?: User[];
  handleRemoveUser?: (user: User) => void;
  handleResendEmail?: (data: { user_id: string }) => void;
}

const UsersTableControllsWrapper = ({
  openCreateUserModal,
  openImportExistingModal,
  openImportCSVModal,
  users,
  handleRemoveUser,
  handleResendEmail,
}: Props) => {
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setFilteredUserList(users);
  }, [users]);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ mt: "-55px" }}>
      <StyledHeader>
        <Flex>
          <UserSearchInput users={users} setUserList={setFilteredUserList} />

          <StyledButton
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleOpenMenu}
          >
            Add User(s)
          </StyledButton>
        </Flex>
      </StyledHeader>

      {users.length === 0 ? (
        <EmptyUserList />
      ) : (
        <TableItself
          userList={filteredUserList}
          handleRemoveUser={handleRemoveUser}
          handleResendEmail={handleResendEmail}
        />
      )}

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: MenuPaperSXProp,
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <StyledMenuItem onClick={openCreateUserModal}>
          <ListItemIcon>
            <AddIcon color="primary" fontSize="small" />
          </ListItemIcon>
          Add New
        </StyledMenuItem>
        <StyledMenuItem onClick={openImportExistingModal}>
          <ListItemIcon>
            <AddIcon color="primary" fontSize="small" />
          </ListItemIcon>
          Import from existing users
        </StyledMenuItem>
        <StyledMenuItem onClick={openImportCSVModal}>
          <ListItemIcon>
            <StyledImage
              src={require("src/assets/images/upload-primary.png")}
            />
          </ListItemIcon>
          Upload via CSV
        </StyledMenuItem>
      </Menu>
    </Box>
  );
};

export default UsersTableControllsWrapper;
