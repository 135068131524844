import { Drawer, useMediaQuery } from "@mui/material";
import Content from "./Content";

interface Props {
  open: boolean;
  onClose: () => void;
}

const GroupAdminsDrawer = ({ open, onClose }: Props) => {
  const mobileView = useMediaQuery("(max-width:678px)");

  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={onClose}
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "transparent",
        },
      }}
      PaperProps={{
        sx: {
          top: mobileView ? 149 : 104,
          bottom: 0,
          height: "auto",
          borderLeft: "solid 1px #A0B9D0",
          boxShadow: "-20px 4px 80px rgba(0, 0, 0, 0.15)",
        },
      }}
      transitionDuration={{ enter: 400, exit: 400 }}
    >
      <Content onClose={onClose} />
    </Drawer>
  );
};

export default GroupAdminsDrawer;
