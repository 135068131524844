import { ScreeningList } from "src/types/BulkSearch";
import actions from "./screeningActions";

export interface Props {
  loading: boolean;
  selectedScreeningList: ScreeningList;
  screeningList: ScreeningListCombined;
  updated: boolean;
}

interface ScreeningListCombined {
  created_by: ScreeningList[];
  shared_with_groups: ScreeningList[];
}

const initialData: Props = {
  loading: false,
  selectedScreeningList: null,
  screeningList: {
    created_by: [],
    shared_with_groups: [],
  },
  updated: false,
};

export default (
  state = initialData,
  {
    type,
    payload,
  }: { type: string; payload: boolean | ScreeningList | ScreeningListCombined }
) => {
  if (type === actions.LOADING) {
    return {
      ...state,
      loading: payload,
    };
  }

  if (type === actions.CREATE) {
    return {
      ...state,
      selectedScreeningList: payload,
    };
  }

  if (type === actions.UPDATE) {
    return {
      ...state,
      updated: true,
      selectedScreeningList: payload,
      loading: false,
    };
  }

  if (type === actions.EDIT) {
    return {
      ...state,
      selectedScreeningList: payload,
    };
  }

  if (type === actions.GET_SCREENING_LISTS) {
    return {
      ...state,
      loading: false,
      screeningList: payload,
    };
  }

  if (type === actions.DELETE_ONE) {
    return {
      ...state,
      loading: false,
      updated: true,
    };
  }

  if (type === actions.CLEAR_UPDATE_STATUS) {
    return {
      ...state,
      loading: false,
      updated: false,
    };
  }
  return state;
};
