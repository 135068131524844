import { useMutation, useQuery } from "@tanstack/react-query";
import { useFetch } from "src/modules/api/fetch";
import { useUser } from "src/modules/api/auth";
import { snackbar } from "src/view/toaster";
import QueryKeys from "src/modules/api/queryKeys";
import queryKeys from "src/modules/api/queryKeys";
import { queryClient } from "src/index";
import { SearchHistoryResponse } from "src/types/Search";

export function useSearchHistory() {
  const { user } = useUser();
  const { getApi, deleteApi } = useFetch();

  const getSearchHistory = useQuery<SearchHistoryResponse>({
    enabled: !!user && user.search_history_opted_in,
    queryFn: () => getApi(["users", "search_history"]),
    queryKey: [QueryKeys.SEARCH_HISTORY],
    initialData: { record_count: 0, search_history_entries: [] },
  });

  const deleteSearchHistoryItem = useMutation({
    mutationFn: (id: string) => {
      return deleteApi(["users", id, "search_history"]);
    },
    onSuccess: (_, id) => {
      queryClient.setQueryData(
        [queryKeys.SEARCH_HISTORY],
        (oldSearchHistory: SearchHistoryResponse) => ({
          ...oldSearchHistory,
          search_history_entries:
            oldSearchHistory.search_history_entries.filter(
              (item) => item.id !== id
            ),
        })
      );
    },
    onError: () => {
      snackbar.error("Failed to delete search history item");
    },
  });

  const clearSearchHistory = useMutation<SearchHistoryResponse>({
    mutationFn: () => {
      return deleteApi(["users", "clear_search_history"]);
    },
    onSuccess: () => {
      queryClient.setQueryData([queryKeys.SEARCH_HISTORY], () => ({
        record_count: 0,
        search_history_entries: [],
      }));
    },
    onError: () => {
      snackbar.error("Failed to clear search history");
    },
  });

  return {
    getSearchHistory,
    deleteSearchHistoryItem,
    clearSearchHistory,
  };
}
