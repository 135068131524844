import { DataCollection } from "src/types/DataCatalog";
import { fetchApi } from "../shared/api";

export default class DataCatalogService {
  static async createDataCatalog(data: DataCollection) {
    try {
      const res = await fetchApi("data_collection/", {
        method: "POST",
        body: JSON.stringify(data),
      });
      const response = await res.json();
      if (response.detail) return [];
      return response;
    } catch (err) {
      return [];
    }
  }
  static async updateDataCatalog(data: DataCollection) {
    try {
      const id = data.id;
      delete data.id;

      const res = await fetchApi("data_collection/" + id + "/", {
        method: "PUT",
        body: JSON.stringify(data),
      });
      if (res.status === 500) {
        return [];
      }
      const response = await res.json();
      if (response.detail) return [];
      return response;
    } catch (err) {
      return [];
    }
  }

  static async getDataCatalog() {
    try {
      const res = await fetchApi("data_collection/", {
        method: "GET",
      });
      if (res.status == 500) {
        return {
          error: true,
          detail: "Something went wrong on the server side",
        };
      }
      const response = res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response["detail"],
        };
      }
    } catch (err) {
      return false;
    }
  }
  static async deleteDataCatalog(id: string) {
    try {
      const res = await fetchApi("data_collection/" + id + "/", {
        method: "DELETE",
      });
      if (res.status === 500) {
        return [];
      }
      const response = await res.json();
      if (response.detail) return [];
      return response;
    } catch (err) {
      return [];
    }
  }
}
