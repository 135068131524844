import { Routes, Route } from "react-router-dom";
import SearchHeader from "../layout/SearchHeader";
import ScaffoldingPage from "./Scaffolding";

const DatasetSensitivityPage = () => {
  return (
    <div>
      <SearchHeader />
      <Routes>
        <Route path="/" element={<ScaffoldingPage />} />
      </Routes>
    </div>
  );
};

export default DatasetSensitivityPage;
