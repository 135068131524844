import { createSelector } from "reselect";
import { Props } from "./reducers";

const selectRaw = (state: { dataset_sensitivity: Props }) =>
  state.dataset_sensitivity;

const selectLoading = createSelector([selectRaw], (raw) =>
  Boolean(raw.loading)
);

const selectError = createSelector([selectRaw], (raw) => raw.error);

const Selectors = {
  selectLoading,
  selectError,
  selectRaw,
};

export default Selectors;
