import SearchHeader from "../../layout/SearchHeader";
import PageLayout from "./components/PageLayout";
import ResultContent from "./components/ResultContent";
import QueryGroupHeader from "./components/QueryGroupHeader";

const QueryGroupRun = () => {
  return (
    <div>
      <SearchHeader />
      <QueryGroupHeader />
      <PageLayout>
        <ResultContent />
      </PageLayout>
    </div>
  );
};

export default QueryGroupRun;
