import {
  styled,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import { Check as CheckIcon } from "@mui/icons-material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";

import { StyledTextFieldLabel } from "src/view/components/MyTextField";
import { Dataset, SensitivityString } from "src/types/Dataset";
import { Colors } from "src/view/constants/colors";
import { useDatasetMetadata } from "src/modules/api/datasetMetadata";
import { snackbar } from "src/view/toaster";
import FormTextField from "./FormTextField";

const StyledForm = styled("form")(() => ({
  maxWidth: 860,
  marginTop: 127,
  paddingBottom: 100,
  paddingLeft: "64px",
  paddingRight: "64px",
}));

const Header = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "40px",
}));

const StyledHeader = styled("h3")(() => ({
  fontSize: 32,
  fontWeight: "700",
  margin: 0,
  marginBottom: "8px",
}));

const StyledBackButton = styled(Button)(() => ({
  fontSize: 16,
  fontWeight: "400",
  paddingLeft: 0,
  textTransform: "none",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

// TODO: often reused. Create own MUI button variant?
const SubmitButton = styled(Button)(() => ({
  padding: "14px",
  width: 133,
  backgroundColor: Colors.secondary,
  color: "white",
  fontSize: 14,
  fontWeight: "bold",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: Colors.secondary2,
  },
}));

const StyledSelect = styled(Select)(() => ({
  backgroundColor: "#F9F9FA",
  fontSize: "14px",
  padding: "2 10",
  borderRadius: 8,
}));

export type IndexDatasetFormFields = Pick<
  Dataset,
  "name" | "bucket_name" | "bucket_prefix" | "sensitivity" | "was_ocrd"
>;

const IndexNewDataset = () => {
  const { createDataset } = useDatasetMetadata();
  const navigate = useNavigate();
  const { handleSubmit, control } = useForm<IndexDatasetFormFields>({
    defaultValues: {
      name: "",
      bucket_name: "",
      bucket_prefix: "",
      sensitivity: SensitivityString.NONSENSITIVE,
      was_ocrd: false,
    },
  });

  const onSubmit: SubmitHandler<IndexDatasetFormFields> = (form) => {
    createDataset.mutate(form, {
      onSuccess: () => {
        snackbar.success("Indexing request created");
        navigate("/indexing");
      },
      onError: () => {
        snackbar.error("Failed to create indexing request");
      },
    });
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <StyledBackButton
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate("/indexing")}
      >
        Back
      </StyledBackButton>
      <Header>
        <Box>
          <StyledHeader>Indexing a New Dataset</StyledHeader>
          <Typography sx={{ maxWidth: "100%", fontSize: "14px" }}>
            Create a new dataset by linking to an accessible S3 bucket.
          </Typography>
        </Box>
      </Header>

      <FormTextField
        control={control}
        label="Dataset Name"
        name="name"
        placeholder="Give a name to this dataset"
      />

      <Box sx={{ display: "flex", my: "24px", gap: "40px" }}>
        <FormTextField
          control={control}
          label="Bucket Name"
          name="bucket_name"
          placeholder="Enter the name of the s3 bucket"
        />

        <FormTextField
          control={control}
          label="S3 Path"
          name="bucket_prefix"
          placeholder="Enter where to find your data within the bucket"
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "60px",
          gap: "40px",
        }}
      >
        <Controller
          control={control}
          name="sensitivity"
          rules={{ required: true }}
          render={({ field }) => (
            <Box sx={{ flex: 1 }}>
              <StyledTextFieldLabel id="sensitivity">
                Sensitivity Level
              </StyledTextFieldLabel>
              <StyledSelect
                fullWidth
                {...field}
                placeholder="Select Sensitivity"
                labelId="sensitivity"
              >
                {Object.values(SensitivityString).map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </StyledSelect>
            </Box>
          )}
        />
        <Controller
          control={control}
          name="was_ocrd"
          rules={{ required: false }}
          render={({ field }) => (
            <Box sx={{ flex: 1 }}>
              <StyledTextFieldLabel id="sensitivity">
                Force OCR?
              </StyledTextFieldLabel>
              <StyledSelect
                {...field}
                fullWidth
                placeholder="Select Sensitivity"
                labelId="sensitivity"
                value={`${field.value}`}
                onChange={(e) => field.onChange(e.target.value === "true")}
              >
                <MenuItem value="true">True</MenuItem>
                <MenuItem value="false">False</MenuItem>
              </StyledSelect>
            </Box>
          )}
        />
      </Box>

      <SubmitButton startIcon={<CheckIcon />} type="submit">
        Save
      </SubmitButton>
    </StyledForm>
  );
};

export default IndexNewDataset;
