export interface QueryItem {
  value?: string;
  match?: number;
}

export interface TermItem {
  id: string;
  query?: string;
  items: QueryItem[];
  deleted?: boolean;
}

export const MATCHES = [
  "Exact Search",
  "Proximity 1",
  "Proximity 2",
  "Proximity 3",
  "Proximity 4",
  "Proximity 5",
];
