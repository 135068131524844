import React, { createContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useResizeObserver } from "usehooks-ts";
import viewSelector from "src/modules/search/view/searchViewSelectors";
import { PreviewDocProps } from "src/modules/search/view/searchViewReducers";

interface PreviewContextProps {
  tab: number;
  setTab: (tab: number) => void;
  page: number;
  setPage: (num: number) => void;
  originalPage: number;
  selectedResult: any;
  setSelectedResult: React.Dispatch<React.SetStateAction<any>>;
  numPages: number;
  setNumPages: (numPages: number) => void;
  headerHeight: number;
  font: number;
  setFont: React.Dispatch<React.SetStateAction<number>>;
  scale: number;
  setScale: React.Dispatch<React.SetStateAction<number>>;
  fontSize: number;
  setFontSize: (fontSize: number) => void;
  contentHeight: number;
  setContentHeight: React.Dispatch<React.SetStateAction<number>>;
  split: boolean;
  setSplit: React.Dispatch<React.SetStateAction<boolean>>;
  query: any;
  previewDocInfo: PreviewDocProps;
  headerRef: React.MutableRefObject<HTMLDivElement>;
}

export const PreviewContext = createContext<PreviewContextProps | null>(null);

export enum PreviewTabs {
  PREVIEW = "Preview",
  RAW_TEXT = "Original Search Result",
}

export const fixedLayoutHeights = {
  content: 700,
  controlBar: 35,
  padding: 20,
};

const PreviewProvider = (props: { children: JSX.Element | JSX.Element[] }) => {
  // const { previewData } = useSearch();
  const previewDocInfo = useSelector(viewSelector.selectPreviewDocInfo);
  const location = useLocation();
  const [tab, setTab] = useState(0);
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const [font, setFont] = useState(14);
  const [scale, setScale] = useState(100);
  const [contentHeight, setContentHeight] = useState(
    fixedLayoutHeights.content
  );
  const [fontSize, setFontSize] = useState(14);
  const [split, setSplit] = useState(false);

  const [selectedResult, setSelectedResult] = useState();
  const [originalPage, setOriginalPage] = useState(1);

  const headerRef = useRef(null);
  const { height: headerHeight = 0 } = useResizeObserver({
    ref: headerRef,
  });
  useEffect(() => {
    if (previewDocInfo) {
      // set current page to whichever was the original search result with highlights
      const originalPage =
        previewDocInfo?._id && Number(/[^_]*$/.exec(previewDocInfo?._id)[0]);

      if (originalPage) {
        setOriginalPage(originalPage);
      }
    }
  }, [previewDocInfo]);

  return (
    <PreviewContext.Provider
      value={{
        tab,
        setTab,
        page,
        setPage,
        numPages,
        setNumPages,
        font,
        setFont,
        scale,
        setScale,
        contentHeight,
        setContentHeight,
        fontSize,
        setFontSize,
        split,
        setSplit,
        query: location?.state?.query,
        previewDocInfo,
        headerRef,
        headerHeight,
        originalPage,
        selectedResult,
        setSelectedResult,
      }}
    >
      {props.children}
    </PreviewContext.Provider>
  );
};

PreviewProvider.displayName = "PreviewProvider";

export default PreviewProvider;
