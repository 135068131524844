import { createSelector } from "reselect";
import { AuthProps } from "./reducers";

const selectRaw = (state: { auth: AuthProps }) => state.auth;

const selectLoading = createSelector([selectRaw], (raw) =>
  Boolean(raw.loading)
);

const selectError = createSelector([selectRaw], (raw) => raw.error);

const selectResetPassError = createSelector(
  [selectRaw],
  (raw) => raw.resetPassError
);

const selectPermissions = createSelector([selectRaw], (raw) => raw.permissions);

const Selectors = {
  selectLoading,
  selectError,
  selectResetPassError,
  selectRaw,
  selectPermissions,
};

export default Selectors;
