import { styled, IconButton, IconButtonProps } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

export interface ExcludableCheckboxProps extends IconButtonProps {
  checked: boolean;
  excluded: boolean;
  uncheckedColor?: string;
  uncheckedIcon?: React.ReactNode;
}

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) =>
    !["checked", "excluded", "uncheckedColor"].includes(prop as string),
})<ExcludableCheckboxProps>(({ checked, excluded, uncheckedColor, theme }) => ({
  width: 16,
  height: 16,
  fontSize: 14,
  margin: theme.spacing(1),
  padding: theme.spacing(0.75),
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: theme.spacing(0.25),
  ...(!checked &&
    !excluded &&
    uncheckedColor && {
      border: `2px solid ${uncheckedColor}`,
      backgroundColor: uncheckedColor,
      color: "white",
    }),
  ...(checked && {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
    },
  }),
  ...(excluded && {
    backgroundColor: theme.palette.grey[600],
    borderColor: theme.palette.grey[600],
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.grey[500],
      borderColor: theme.palette.grey[500],
    },
  }),
}));

export default function ExcludableCheckbox({
  uncheckedIcon,
  ...props
}: ExcludableCheckboxProps) {
  const { checked, excluded } = props;

  const renderIcon = () => {
    if (checked) {
      return <CheckIcon fontSize="inherit" color="inherit" />;
    }

    if (excluded) {
      return <CloseIcon fontSize="inherit" color="inherit" />;
    }

    if (uncheckedIcon) {
      return uncheckedIcon;
    }

    return null;
  };

  return <StyledIconButton {...props}>{renderIcon()}</StyledIconButton>;
}
