import DataEdit from "./components/edit";
import { styled } from "@mui/material/styles";
import SearchHeader from "src/view/layout/SearchHeader";

const RootContainer = styled("div")(() => ({
  position: "relative",
}));

const Edit = () => {
  return (
    <RootContainer>
      <SearchHeader />
      <DataEdit />
    </RootContainer>
  );
};

export default Edit;
