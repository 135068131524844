import React, { useContext } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  Tooltip,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { getHighlightText } from "src/utils/get-highlight-text";
import { PreviewDocProps } from "src/modules/search/view/searchViewReducers";
import {
  renderContent,
  StyledContainer,
} from "src/view/search-result/components/Preview/Source/SourcePreviews";
import HighlightActionMenu from "src/view/components/HighlightActionMenu";
import FileResults from "src/view/search-result/components/Preview/Source/Type/DataTablePreview/FileResults";
import { PreviewContext } from "src/view/search-result/components/Preview/Source/PreviewContext";
import theme from "src/theme";

interface DataTablePreviewProps {
  data?: { id: string; content: Record<string, any>[] }[];
  loading: boolean;
  previewDocInfo: PreviewDocProps;
  handleClosePreview: (shouldSetURLParam?: boolean) => void;
}

const DataTablePreview: React.FC<DataTablePreviewProps> = ({
  loading,
  data,
  previewDocInfo,
  handleClosePreview,
}) => {
  const { selectedResult } = useContext(PreviewContext);

  if (!selectedResult) return null;

  return (
    <StyledContainer
      sx={{
        px: 4,
        boxSizing: "border-box",
      }}
    >
      {renderContent(
        data,
        loading,
        <Box>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              fontSize: "14px",
              fontWeight: 600,
              color: theme.palette.grey[900],
              marginTop: 4,
              marginBottom: 3,
            }}
          >
            <Typography sx={{ fontWeight: "inherit", fontSize: "inherit" }}>
              {previewDocInfo?.dataset["fileName"]}
            </Typography>
            <Typography sx={{ opacity: "50%", fontSize: "inherit" }}>
              •
            </Typography>
            <Typography
              sx={{
                fontWeight: "inherit",
                fontSize: "inherit",
                opacity: "50%",
              }}
            >
              Selected Record
            </Typography>
          </Box>

          <Table
            sx={{
              mb: 4,
              overflow: "auto",
              display: "block",
              border: "1px solid",
              borderRadius: "4px",
              borderColor: theme.palette.grey[400],
            }}
            aria-label="simple table"
          >
            <TableHead
              sx={{
                backgroundColor: "rgba(231, 231, 231, 0.5)",
              }}
            >
              <TableRow>
                {Object.entries(selectedResult).map(([key]) => {
                  return (
                    <TableCell
                      sx={{
                        whiteSpace: "nowrap",
                        pt: 1,
                        pb: 1,
                        fontSize: "11px",
                        lineHeight: "13px",
                        color: "rgba(0, 0, 0, 0.7)",
                      }}
                    >
                      <HighlightActionMenu onSearchInTab={handleClosePreview}>
                        {getHighlightText(key as unknown as string)}
                      </HighlightActionMenu>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {Object.entries(selectedResult).map((entry, index) => {
                  return (
                    <TableCell
                      key={`csv-result-cell-${index}`}
                      sx={{
                        pt: 2,
                        pb: 2,
                        fontSize: "12px",
                        lineHeight: "15px",
                        color: "#000000",
                        maxWidth: "400px",
                      }}
                    >
                      <Box className="table__cell">
                        <Tooltip
                          placement={"bottom-start"}
                          title={
                            <>
                              {typeof entry[1] === "string"
                                ? getHighlightText(entry[1])
                                : entry[1]}
                            </>
                          }
                          arrow
                          sx={{
                            mr: "20px",
                          }}
                          componentsProps={{
                            tooltip: {
                              sx: {
                                p: 1,
                                bgcolor: "#122945",
                                "& .MuiTooltip-arrow": {
                                  color: "#122945",
                                },
                              },
                            },
                          }}
                          PopperProps={{
                            style: { zIndex: 1501 },
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, -5],
                                },
                              },
                            ],
                          }}
                        >
                          <div>
                            <HighlightActionMenu
                              onSearchInTab={handleClosePreview}
                            >
                              <>
                                {typeof entry[1] === "string"
                                  ? getHighlightText(entry[1])
                                  : entry[1]}
                              </>
                            </HighlightActionMenu>
                          </div>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>

          <FileResults
            handleClosePreview={handleClosePreview}
            loading={loading}
            previewDocInfo={previewDocInfo}
            data={data}
          />
        </Box>
      )}
    </StyledContainer>
  );
};

export default DataTablePreview;
