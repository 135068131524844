import MyTextField from "src/view/components/MyTextField";
import SearchIconUrl from "src/assets/images/search-primary.png";

interface Props {
  searchInput: string;
  setSearchInput: (searchInput: string) => void;
  setSearchKey: (searchInput: string) => void;
  placeholder: string;
}

const SearchBox = ({
  searchInput,
  setSearchInput,
  placeholder,
  setSearchKey,
}: Props) => {
  const handleSearch = () => {
    setSearchKey(searchInput);
  };

  return (
    <MyTextField
      value={searchInput}
      containerStyle={{
        width: "25%",
        minWidth: "200px",
        height: "36px",
        "& div": { height: "36px", borderRadius: "4px" },
        "& input": {
          fontSize: "14px",
          height: "16px",
        },
        "& input::placeholder": {
          color: "#122945",
          opacity: 1,
          fontWeight: "400",
        },
      }}
      prefixIcon={
        <img
          src={SearchIconUrl}
          width="16"
          height="16"
          style={{ cursor: "pointer" }}
          onClick={handleSearch}
        />
      }
      placeholder={placeholder}
      onChange={(e) => setSearchInput(e.target.value)}
      onKeyPress={(e) => e.key == "Enter" && handleSearch()}
    />
  );
};

export default SearchBox;
