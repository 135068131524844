import { Box, Drawer, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import logo from "src/assets/logo-vertical.svg";
import { Colors } from "../constants/colors";
import { useNavigate } from "react-router-dom";
import { Features, Role } from "src/types/Auth";
import { useUser } from "src/modules/api/auth";
import { useSearch } from "src/modules/api/search";
import { emptyQueryVariables } from "src/modules/api/search/SearchProvider";

const RootContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
}));

const FlexWrapper = styled(Box)(() => ({
  display: "flex",
  width: 232,
  alignItems: "center",
  justifyContent: "flex-start",
  padding: "24px 26px",
}));

const Divider = styled(Box)(() => ({
  width: "100%",
  borderBottom: "1px solid rgba(18, 41, 69, 0.1)",
}));

const LogoWrapper = styled("div")(() => ({
  height: 100,
  width: 160,
  minWidth: 160,
  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    cursor: "pointer",
  },
}));

const SectionHeader = styled(Typography)(() => ({
  fontSize: 14,
  lineHeight: "17px",
  fontWeight: "700",
  color: Colors.twilight,
}));

const ListText = styled(Typography)(() => ({
  fontSize: 14,
  lineHeight: "17px",
  fontWeight: "400",
  color: Colors.twilight,
  letterSpacing: "0.01em",
}));

interface ListItemProps {
  id?: string;
  icon?: string;
  icon_type?: string;
  title?: string;
  handleClick?: () => void;
}
const ListItem = (props: ListItemProps) => {
  const { id, icon, icon_type = "png", title, handleClick } = props;

  return (
    <FlexWrapper id={id} sx={{ padding: "24px 0px 4px", cursor: "pointer" }}>
      <img
        src={require("src/assets/images/" + icon + "." + icon_type)}
        style={{ width: 16, height: 16, marginRight: 12 }}
      />
      <ListText onClick={handleClick}>{title}</ListText>
    </FlexWrapper>
  );
};

const Sidebar = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();
  const { user } = useUser();
  const { handleSearch } = useSearch();

  useEffect(() => {
    document.addEventListener("openSideBar", handleOpenSideBar);
    return () => {
      document.addEventListener("openSideBar", handleOpenSideBar);
    };
  }, []);

  useEffect(() => {
    const launchWidget = document.getElementById("launchnotes-widget");
    if (launchWidget) {
      if (openDrawer) {
        launchWidget.style.display = "block";
      } else {
        launchWidget.style.display = "none";
      }
    }
  }, [openDrawer]);

  const handleOpenSideBar = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const openFeedbackModal = () => {
    document.dispatchEvent(new CustomEvent("openFeedbackModal"));
    setOpenDrawer(false);
  };

  const handleClick = (link: string) => {
    navigate(link, { replace: false });
    handleCloseDrawer();
  };

  const handleSearchClick = () => {
    if (location.pathname !== "/search") {
      handleSearch(emptyQueryVariables);
    }
    handleCloseDrawer();
  };

  const handleFeedbackModalOpen = () => {
    openFeedbackModal();
  };

  return (
    <Drawer
      open={openDrawer}
      onClose={handleCloseDrawer}
      anchor="left"
      transitionDuration={{ enter: 400, exit: 400 }}
      sx={{
        padding: 10,
        zIndex: 1501,
      }}
    >
      <RootContainer>
        <FlexWrapper sx={{ justifyContent: "space-between" }}>
          <LogoWrapper onClick={() => navigate("/")}>
            <img src={logo} />
          </LogoWrapper>
          <IconButton size="small" onClick={handleCloseDrawer}>
            <img
              src={require("src/assets/images/close.png")}
              style={{ width: 24, height: 24 }}
            />
          </IconButton>
        </FlexWrapper>
        <Divider />
        {user?.features.length > 0 && (
          <FlexWrapper
            sx={{ flexDirection: "column", alignItems: "flex-start" }}
          >
            <SectionHeader>Data Lake</SectionHeader>
            {(user?.role === Role.ADMIN ||
              user?.features.includes(Features.SEARCH)) && (
              <ListItem
                icon={"search-primary"}
                title="Search"
                handleClick={() => handleSearchClick()}
              />
            )}
            {(user?.role === Role.ADMIN ||
              user?.features.includes(Features.BULK_SEARCH)) && (
              <ListItem
                icon={"bulk-search-sidebar"}
                title="Bulk Search"
                handleClick={() => handleClick("/bulk-search")}
              />
            )}
            {(user?.role === Role.ADMIN ||
              user?.features.includes(Features.KNOWLEDGE_WIKI)) && (
              <ListItem
                icon={"book-primary"}
                title="Data Dictionary"
                handleClick={() => handleClick("/data-dictionary/data")}
              />
            )}
          </FlexWrapper>
        )}
        <Divider />
        <FlexWrapper sx={{ flexDirection: "column", alignItems: "flex-start" }}>
          <SectionHeader>My Data</SectionHeader>
          {(user?.role === Role.ADMIN ||
            user?.features?.includes(Features.DATA_COLLECTION)) && (
            <ListItem
              icon={"catalog-primary"}
              title="Data Collections"
              handleClick={() => handleClick("/data-collections")}
            />
          )}
          <ListItem
            icon={"ocr"}
            icon_type={"svg"}
            title="Document OCR"
            handleClick={() => handleClick("/ocr")}
          />
        </FlexWrapper>
        <Divider />
        {/*<FlexWrapper sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                    <SectionHeader>C4ADS Software</SectionHeader>
                    <ListItem icon={"circles-primary"} title="Icarus Flights" link={"https://icarus.flights/"} />
                </FlexWrapper>
                <Divider />*/}
        <FlexWrapper sx={{ flexDirection: "column", alignItems: "flex-start" }}>
          <SectionHeader>C4ADS Software</SectionHeader>
          <ListItem
            icon={"four-circle"}
            title="Toolkit (Legacy)"
            handleClick={() => {
              window.open("https://toolkit.seamlesshorizons.com/", "_blank");
            }}
          />
          <ListItem
            icon={"four-circle"}
            title="Icarus Flights"
            handleClick={() => {
              window.open("https://app.icarus.flights/", "_blank");
            }}
          />
        </FlexWrapper>
        <Divider />
        <FlexWrapper sx={{ flexDirection: "column", alignItems: "flex-start" }}>
          <SectionHeader>Administration</SectionHeader>
          {user?.role === Role.ADMIN && (
            <>
              <ListItem
                icon={"storage"}
                title="Indexing Dashboard"
                handleClick={() => handleClick("/indexing")}
              />
              <ListItem
                icon={"question-mark-primary"}
                title="Dataset Sensitivity"
                handleClick={() => handleClick("/dataset-sensitivity")}
              />
            </>
          )}
          {user?.features?.includes(Features.USER_GROUP) && (
            <ListItem
              icon={"users-primary"}
              title="Users &amp; Groups"
              handleClick={() => handleClick("/groups")}
            />
          )}
          <ListItem
            icon={"question-mark-primary"}
            title="Support"
            handleClick={() => {
              window.open("http://support.seamlesshorizons.com/en/", "_blank");
            }}
          />
          <ListItem
            icon={"comment"}
            title="Send Feedback"
            handleClick={handleFeedbackModalOpen}
          />
          <ListItem
            icon={"user-primary"}
            title="My Account"
            handleClick={() => handleClick("/profile")}
          />
          <ListItem
            icon={"twinkle"}
            id="whats_new_link"
            icon_type={"svg"}
            title="What's new"
          />
        </FlexWrapper>
      </RootContainer>
    </Drawer>
  );
};

export default Sidebar;
