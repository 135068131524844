import { createSelector } from "reselect";
import { InitialDataProps } from "./searchViewReducers";

const selectRaw = (state: { search: { view: InitialDataProps } }) =>
  state.search.view;

const selectSearchResult = createSelector(
  [selectRaw],
  (raw) => raw.searchResults
);

const selectSearchViewResult = createSelector(
  [selectRaw],
  (raw) => raw.searchViewResult
);

const selectLoading = createSelector([selectRaw], (raw) =>
  Boolean(raw.loading)
);

const selectPreviewDocumentList = createSelector(
  [selectRaw],
  (raw) => raw.previewDocumentList
);

const selectPreviewDocInfo = createSelector(
  [selectRaw],
  (raw) => raw.previewDocInfo
);

const searchViewSelectors = {
  selectLoading,
  selectSearchResult,
  selectRaw,
  selectSearchViewResult,
  selectPreviewDocumentList,
  selectPreviewDocInfo,
};

export default searchViewSelectors;
