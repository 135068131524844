import { Box, Skeleton, TableCell, TableRow, Tooltip } from "@mui/material";
import theme from "src/theme";
import { LoadingButton } from "@mui/lab";
import {
  Dataset,
  DatasetIndexingTask,
  TaskStatusFiltersKeys,
  TaskStatusFiltersLabels,
} from "src/types/Dataset";
import { snackbar } from "src/view/toaster";
import { useDatasetMetadata } from "src/modules/api/datasetMetadata";
import TaskStatus from "./TaskStatus";

const TaskStatusOptions: Record<
  TaskStatusFiltersKeys,
  TaskStatusFiltersLabels
> = {
  [TaskStatusFiltersKeys.IN_QUEUE]: TaskStatusFiltersLabels.IN_QUEUE,
  [TaskStatusFiltersKeys.PROCESSING]: TaskStatusFiltersLabels.PROCESSING,
  [TaskStatusFiltersKeys.COMPLETED]: TaskStatusFiltersLabels.COMPLETED,
  [TaskStatusFiltersKeys.FAILED]: TaskStatusFiltersLabels.FAILED,
};

interface Props {
  task: DatasetIndexingTask;
  dataset: Dataset;
  isFetchingTasks: boolean;
  isFetchingRecordCounts: boolean;
}

const TaskRow = ({
  task,
  dataset,
  isFetchingTasks,
  isFetchingRecordCounts,
}: Props) => {
  const { reindexTask } = useDatasetMetadata();

  const { mutate: reindex, isLoading: isReindexLoading } = reindexTask(
    task.task_id
  );

  const handleReindexTask = (task_id: string) => {
    reindex(
      { task_id },
      {
        onSuccess: () => {
          snackbar.success("Retrying indexing task");
          close();
        },
        onError: () => {
          snackbar.error("Failed to retry indexing task");
        },
      }
    );
  };

  return (
    <TableRow>
      <TableCell sx={{ maxWidth: 400, color: theme.palette.primary[800] }}>
        {isFetchingRecordCounts ? (
          <Skeleton height="30px" width="400px" />
        ) : (
          <Tooltip
            title={`${dataset.bucket_name}/${dataset.bucket_prefix}/${task.file_key}`}
            placement={"bottom-start"}
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  p: 1,
                  bgcolor: "#122945",
                  "& .MuiTooltip-arrow": {
                    color: "#122945",
                  },
                },
              },
            }}
            PopperProps={{
              modifiers: [{ name: "offset", options: { offset: [0, -5] } }],
            }}
          >
            <Box className="ellipsis">
              {`${dataset.bucket_name}/${dataset.bucket_prefix}/${task.file_key}`}
            </Box>
          </Tooltip>
        )}
      </TableCell>
      <TableCell>
        {isFetchingTasks ? (
          <Skeleton height="30px" width="60px" />
        ) : (
          <TaskStatus status={TaskStatusOptions[task.task_status]} />
        )}
      </TableCell>
      <TableCell className="ellipsis">
        {isFetchingTasks ? (
          <Skeleton height="30px" width="60px" />
        ) : (
          <>
            {task.error_message ? (
              <Tooltip
                title={task.error_message}
                placement={"bottom-start"}
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxHeight: 200,
                      overflowY: "auto",
                      overflowX: "hidden",
                      p: 1,
                      bgcolor: "#122945",
                      "& .MuiTooltip-arrow": {
                        color: "#122945",
                      },
                    },
                  },
                }}
                PopperProps={{
                  modifiers: [{ name: "offset", options: { offset: [0, -5] } }],
                }}
              >
                <Box className="ellipsis">
                  {task.error_message.substring(0, 200)}
                </Box>
              </Tooltip>
            ) : (
              "---"
            )}
          </>
        )}
      </TableCell>
      <TableCell sx={{ textAlign: "end" }}>
        <LoadingButton
          loading={isReindexLoading}
          onClick={() => handleReindexTask(task.task_id)}
          variant="text"
          color={"secondary"}
          sx={{
            fontWeight: 400,
            padding: 0,
            minHeight: 0,
            minWidth: 0,
          }}
        >
          Retry
        </LoadingButton>
      </TableCell>
    </TableRow>
  );
};

export default TaskRow;
