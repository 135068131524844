import { SetStateAction, Dispatch } from "react";
import { Box, Pagination, Typography } from "@mui/material";
import theme from "src/theme";

interface Props {
  size: number;
  total_counts: number;
  pageCount: number;
  activePageIndex: number;
  setActivePageIndex: Dispatch<SetStateAction<number>>;
}

export default function BulkSearchPagination({
  size,
  total_counts,
  pageCount,
  activePageIndex,
  setActivePageIndex,
}: Props) {
  const fromIndex = size * (activePageIndex - 1) + 1;
  const toIndex = Math.min(size * (activePageIndex - 1) + size, total_counts);

  if (total_counts === 0) {
    return null;
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setActivePageIndex(value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: theme.palette.grey[500],
      }}
    >
      <Typography variant="caption">
        Showing {fromIndex} - {toIndex} of {total_counts}
      </Typography>
      <Pagination
        shape="rounded"
        count={pageCount}
        page={activePageIndex}
        onChange={handleChange}
      />
      <Typography variant="caption">Show {size} results</Typography>
    </Box>
  );
}
