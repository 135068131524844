import { useRef, useState } from "react";
import { Box, Divider } from "@mui/material";
import { JSONView } from "./JSONView";
import { EMLView } from "./EMLView";
import DataTableView from "./DataTableView";
import RawTextView from "./RawTextView";
import ObjectView from "./ObjectView";
import CardPreviewBox from "./CardPreviewBox";
import LoadMoreButton from "./LoadMoreButton";

export interface SearchResultCardPreviewProps {
  id: string;
  data: any;
  type: string;
  getDocumentPreview: () => void;
  hasMatches: boolean;
  highlight?: string;
}

const SearchResultCardPreview = ({
  id,
  data,
  highlight,
  type,
  getDocumentPreview,
  hasMatches,
}: SearchResultCardPreviewProps) => {
  const ref = useRef<HTMLDivElement>();
  const [expand, setExpand] = useState<boolean>(hasMatches);

  const toggleExpand = () => {
    setExpand(!expand);
  };

  const renderContent = (type: string, data: any, id: string) => {
    switch (type) {
      case "csv":
      case "parquet":
        return (
          <DataTableView
            data={data}
            docId={id}
            getDocumentPreview={getDocumentPreview}
          />
        );
      case "json":
      case "jsonl":
        return (
          <JSONView data={`<pre>${JSON.stringify(data, null, 2)}</pre>`} />
        );
      case "eml":
      case "msg":
        return <EMLView data={data} />;
      default:
        break;
    }

    if (typeof data === "string") {
      return <RawTextView data={highlight || data} />;
    }

    if (Array.isArray(data)) {
      return <ObjectView data={data[0]} />;
    }
  };

  return (
    <Box>
      <Box
        id="card-preview"
        sx={{
          mt: 1,
          flexGrow: 1,
          marginLeft: "80px",
        }}
      >
        {expand && <Divider sx={{ mb: 1 }} />}

        <CardPreviewBox ref={ref} expand={expand}>
          {renderContent(type, data, id)}
        </CardPreviewBox>
      </Box>
      <LoadMoreButton
        expand={expand}
        isOverflow={true}
        onClick={toggleExpand}
      />
    </Box>
  );
};

SearchResultCardPreview.displayName = "SearchResultCardPreview";

export default SearchResultCardPreview;
