import { Box, BoxProps, ButtonBase, Popover, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import FilterListIcon from "@mui/icons-material/FilterList";
import { SearchHistory } from "src/types/Search";
import { useMemo, useState } from "react";
import { filterOptions } from "../Filters/FilterTabs";
import theme from "src/theme";
import { useFilterOptions } from "src/modules/api/filters";
import { FilterKeys } from "src/types/SearchFilter";

const StyledHeader = styled(Typography)(() => ({
  fontSize: 12,
  lineHeight: "12.1px",
  fontWeight: "400",
  color: theme.palette.grey[800],
}));

const StyledFilterBubble = styled(Box, {
  shouldForwardProp: (prop) => !["value"].includes(prop as string),
})<FilterBubbleProps>(({ value, theme }) => ({
  minWidth: 14,
  height: 14,
  display: "flex",
  gap: 2,
  alignItems: "center",
  justifyContent: "center",
  fontSize: 11,
  fontWeight: 700,
  color: theme.palette.grey[900],
  backgroundColor: theme.palette.grey[300],
  borderRadius: theme.spacing(2),
  padding:
    value && Math.abs(value) < 10
      ? theme.spacing(0.5)
      : theme.spacing(0.5, 0.75),
  cursor: value ? "pointer" : "auto",
  "& svg": {
    width: 12,
    height: 12,
  },
}));

const StyledContent = styled(Typography)(() => ({
  fontSize: 12,
  lineHeight: "14px",
  fontWeight: "400",
  color: theme.palette.primary.main,
}));

interface FilterBubbleProps extends BoxProps {
  value?: number;
}

const SearchHistoryAppliedFilters = (props: { item: SearchHistory }) => {
  const { datasets, data_collections } = useFilterOptions();

  const getFilterCount = (item: SearchHistory) => {
    let filterCount = 0;
    filterOptions.forEach((filter) => {
      if (Array.isArray(item[filter.key])) {
        filterCount += item[filter.key].length;
      }
    });
    filterCount += item["exclude_dataset_ids"].length;
    filterCount += item["data_collection_ids"].length;

    return filterCount;
  };
  const filterCount = getFilterCount(props.item);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleExpandFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (filterCount != 0) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleCloseFilters = () => {
    setAnchorEl(null);
  };

  const showExpandFilters = Boolean(anchorEl);

  const appliedFilterNameList = useMemo(() => {
    const result: Record<string, string[]> = {
      [FilterKeys.DATASET_IDS]: [],
      [FilterKeys.DOC_TYPES]: [],
      [FilterKeys.DATASET_TYPES]: [],
      [FilterKeys.REGIONS]: [],
      [FilterKeys.LANGUAGES]: [],
    };

    filterOptions.forEach((filter) => {
      if (
        filter.key !== FilterKeys.DATASET_IDS &&
        Object.prototype.hasOwnProperty.call(result, filter.key) &&
        props.item[filter.key].length > 0
      ) {
        result[filter.key] = result[filter.key].concat(props.item[filter.key]);
      }
    });

    // list all dataset_ids as labels
    if (props.item.dataset_ids || props.item.exclude_dataset_ids) {
      const allDatasetIds = [
        ...(props.item?.dataset_ids || []),
        ...(props.item?.exclude_dataset_ids || []),
      ];
      const dataset_names = allDatasetIds.map((item) => {
        return datasets.find((obj) => obj.value === item)?.label;
      });

      result[FilterKeys.DATASET_IDS] =
        result[FilterKeys.DATASET_IDS].concat(dataset_names);
    }

    // include data collections into dataset_ids list
    if (props.item.data_collection_ids) {
      const data_collection_names = props.item[
        FilterKeys.DATA_COLLECTION_IDS
      ].map((item) => {
        return data_collections.find((obj) => obj.value === item)?.label;
      });

      result[FilterKeys.DATASET_IDS] = result[FilterKeys.DATASET_IDS].concat(
        data_collection_names
      );
    }

    return result;
  }, [props]);

  return (
    <>
      <ButtonBase onClick={handleExpandFilters} disableRipple>
        <StyledFilterBubble value={filterCount}>
          <FilterListIcon />
          {filterCount}
        </StyledFilterBubble>
      </ButtonBase>
      <Popover
        open={showExpandFilters}
        anchorEl={anchorEl}
        onClose={handleCloseFilters}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px",
            width: "300px",
            maxHeight: "258px",
            overflow: "auto",
          },
        }}
        sx={{
          zIndex: 1506,
        }}
      >
        <Box
          sx={{
            p: "12px",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <StyledHeader>Applied Filters:</StyledHeader>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "6px",
            }}
          >
            {filterOptions.map((filterItem, index) => {
              if (!appliedFilterNameList?.[filterItem.key]?.length) return null;
              return (
                <Box
                  key={index}
                  sx={{
                    backgroundColor: theme.palette.grey[200],
                    borderRadius: "4px",
                    padding: "8px",
                    display: "flex",
                    gap: "6px",
                  }}
                >
                  <img src={filterItem.icon} width="14" height="14" />
                  <StyledContent>
                    {appliedFilterNameList[filterItem.key].join(", ")}
                  </StyledContent>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Popover>
    </>
  );
};
export default SearchHistoryAppliedFilters;
