import {
  Box,
  Button,
  Container,
  DialogTitle,
  Input,
  Slide,
  Tab,
  TableCell,
  Tabs,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef } from "react";
import { Colors } from "src/view/constants/colors";

export const StyledContainer = styled(Button)(() => ({
  borderRadius: 4,
  backgroundColor: "#F75151",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#ffffff",
  fontSize: 14,
  lineHeight: "17px",
  margin: 5,
  fontWeight: "700",
  padding: 15,
  height: 46,
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#F7515188",
  },
}));

export const StyledBackButton = styled(Button)(() => ({
  border: "none",
  backgroundColor: "transparent",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  color: Colors.textDark,
  fontSize: 16,
  lineHeight: "20px",
  margin: 5,
  marginLeft: -15,
  fontWeight: "700",
  padding: 15,
  height: 46,
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

export const PageWrapper = styled(Container)(() => ({
  marginTop: 127,
  paddingBottom: 100,
  width: "100%",
}));

export const StyledInputBoxTitle = styled(Input)(() => ({
  height: "100%",
  width: "calc(100% - 16px)",
  borderRadius: 4,
  fontSize: 24,
  lineHeight: "29px",
  fontWeight: "700",
  color: "rgba(20, 74, 104)",
  border: "none",
  "&::after": {
    border: "none",
  },
  "&::before": {
    border: "none",
  },
  "&:focus-visible": {
    outline: "none",
  },
  "&:hover": {
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
}));

export const StyledTextAreaTitle = styled("textarea")(() => ({
  width: "calc(100% - 16px)",
  borderRadius: 4,
  fontSize: 24,
  lineHeight: "29px",
  fontWeight: "700",
  fontFamily: "Inter",
  color: "rgba(20, 74, 104)",
  border: "none",
  resize: "none",
  overflow: "hidden",
  padding: "8px",
  "&:focus-visible": {
    outline: "none",
  },
  "&:hover": {
    border: "none",
  },
}));

export const StyledCardContainer = styled("div")(() => ({
  backgroundColor: "#FFFFFF",
  boxShadow: " 0px 5px 26px rgba(0, 0, 0, 0.08)",
  padding: 12,
  alignItems: "flex-start",
  cursor: "pointer",
}));
export const CardTitle = styled("div")(() => ({
  fontSize: 14,
  lineHeight: "20px",
  fontWeight: "400",
  color: Colors.secondary,
}));
export const CardDescription = styled("div")(() => ({
  fontSize: 18,
  lineHeight: "24px",
  fontWeight: "700",
  color: Colors.twilight,
}));

export const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #CED3D9",
  "& .MuiTabs-indicator": {
    backgroundColor: "#122945",
    height: 3,
  },
});

export const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface StyledTabProps {
  label: string;
}

export const AntTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: Colors.textDark,
  "&:hover": {
    color: "#40a9ff",
    opacity: 1,
  },
  "&.Mui-selected": {
    color: Colors.textDark,
    fontWeight: theme.typography.fontWeightBold,
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#d1eaff",
  },
}));

export const StyledDialogTitle = styled(DialogTitle)(() => ({
  fontSize: 24,
  fontWeight: "700",
  color: Colors.twilight,
  paddingBottom: 8,
}));

export const StyledButton = styled(Button)(() => ({
  color: Colors.twilight,
  borderColor: Colors.twilight,
  textTransform: "capitalize",
  fontSize: 14,
  lineHeight: "20px",
  fontWeight: "700",
  "&:hover": {
    backgroundColor: Colors.textDark100,
  },
  "&.Mui-disabled": {
    color: "#FFFFFF",
  },
}));

export const TableHeadCell = styled(TableCell)(() => ({
  textTransform: "capitalize",
  fontSize: 12,
  lineHeight: "20px",
  color: "rgba(20, 74, 104, 0.5)",
  paddingTop: 8,
  paddingBottom: 8,
  borderRight: "1px solid #DFE5E9",
}));

export const AllAdminsButton = styled(Button)(() => ({
  background: "#F0F1F3",
  borderRadius: "30px",
  width: "30px",
  height: "17px",
  padding: "2px 4px",
  display: "flex",
  justifyContent: "center",
  marginLeft: 12,
  fontSize: 14,
  minWidth: "min-content",
  fontWeight: 700,
  lineHeight: "20px",
  "&:hover": {
    backgroundColor: "#F7515188",
  },
}));

export const CardOfUnknownPurpose = (props: {
  title: string;
  icon: JSX.Element;
  label?: JSX.Element | string;
  onClick?: () => void;
}) => {
  return (
    <StyledCardContainer onClick={props.onClick}>
      <Box sx={{ display: "flex" }}>
        {props.icon}
        <CardTitle>{props.title}</CardTitle>
      </Box>
      <CardDescription>{props.label}</CardDescription>
    </StyledCardContainer>
  );
};
