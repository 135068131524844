import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "src/index";
import { fetchApi } from "src/modules/shared/api";

import queryKeys from "src/modules/api/queryKeys";
import { Paginated } from "src/types/Shared";
import { GroupShort } from "src/types/UserGroup";
import { useUser } from "src/modules/api/auth";
import { Features } from "src/types/Auth";

interface GetGroupsParams {
  searchKey?: string;
  pageParam?: number;
  enabled?: boolean;
}

export function useUserGroups() {
  const { user } = useUser();

  const getGroupsFn = async ({ searchKey, pageParam }: GetGroupsParams) => {
    try {
      const params = new URLSearchParams({
        ...(searchKey && { name: searchKey }),
        ...(pageParam && { page: pageParam.toString() }),
      });
      const res = await fetchApi("groups/?" + params, {
        method: "GET",
      });
      return await res.json();
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getGroups = (params: GetGroupsParams = { enabled: true }) =>
    useQuery<Paginated<GroupShort[]>>({
      enabled:
        !!user &&
        !!user.features.includes(Features.USER_GROUP) &&
        params.enabled,
      queryFn: ({ pageParam }) => {
        return getGroupsFn({ searchKey: params.searchKey, pageParam });
      },
      queryKey: [queryKeys.GROUPS, params],
    });

  const getGroupsInfinite = (params: GetGroupsParams) =>
    useInfiniteQuery<Paginated<GroupShort[]>>({
      enabled: !!user && !!user.features.includes(Features.USER_GROUP),
      queryFn: ({ pageParam = 1 }) => {
        return getGroupsFn({
          searchKey: params.searchKey,
          pageParam,
        });
      },
      queryKey: [queryKeys.GROUPS, params],
      getNextPageParam: (lastPage) => lastPage.next_page_number,
    });

  const deleteGroupFn = async (id: string) => {
    try {
      const res = await fetchApi("groups/" + id + "/", {
        method: "DELETE",
      });
      return await res.json();
    } catch (err) {
      return false;
    }
  };

  const deleteGroup = () =>
    useMutation({
      mutationFn: (id: string) => deleteGroupFn(id),
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.GROUPS]);
      },
    });

  return {
    getGroups,
    getGroupsInfinite,
    deleteGroup,
  };
}
