export enum Role {
  ADMIN = "Administrator",
  USER = "User",
}

export enum Features {
  DATASET = "dataset",
  BULK_SEARCH = "bulk_search",
  EXTERNAL_API = "external_api",
  SEARCH = "search",
  USER_GROUP = "user_group",
  KNOWLEDGE_WIKI = "knowledge_wiki",
  INDEXING = "indexing",
  EXTRA_KW_INFO = "extra_kw_info",
  DATA_COLLECTION = "data_collection",
  BULK_DOWNLOAD = "bulk_download",
}

export interface UserPermissions {
  role: Role;
  features: Features[];
  kw_write: string[];
  group_admins: string[];
  eula_signed?: boolean;
  new_features_seen?: boolean;
  id?: string;
}

export type User = {
  id: string;
  email: string;
  first_name?: string;
  last_name?: string;
  role: Role;
  eula_signed: boolean;
  new_features_seen: boolean;
  features: Features[];
  group_admins: string[];
  kw_write: string[];
  search_history_opted_in: boolean;
};
