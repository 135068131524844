import { Box, Typography } from "@mui/material";
import { getHighlightText } from "src/utils/get-highlight-text";
import { isStringRTL } from "src/utils";
import HighlightActionMenu from "src/view/components/HighlightActionMenu";
import theme from "src/theme";

export interface RawTextViewProps {
  data: string;
}

const RawTextView = ({ data }: RawTextViewProps) => (
  <Box>
    <Typography
      variant="body2"
      sx={{
        fontSize: "12px",
        fontWeight: "700",
        color: theme.palette.primary.light,
        textTransform: "capitalize",
      }}
    >
      Excerpt:{" "}
    </Typography>
    <Typography
      variant="body2"
      sx={{
        ml: 9,
        fontSize: "12px",
        lineHeight: "17px",
        wordBreak: "break-all",
        whiteSpace: "pre-wrap",
        color: theme.palette.primary.light,
        direction: isStringRTL(data) ? "rtl" : "ltr",
      }}
    >
      <HighlightActionMenu>{getHighlightText(data)}</HighlightActionMenu>
    </Typography>
  </Box>
);

RawTextView.displayName = "RawTextView";

export default RawTextView;
