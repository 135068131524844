import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { styled } from "@mui/material/styles";
import { Box, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Colors } from "src/view/constants/colors";

const StyledContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  borderRadius: 8,
  padding: theme.spacing(3),
  minWidth: 350,
}));

const StyledHeader = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const StyledTitle = styled(Box)(() => ({
  fontSize: 18,
  fontWeight: "700",
  color: Colors.textDark,
}));
const StyledDescription = styled(Box)(() => ({
  fontSize: 12,
  lineHeight: "1rem",
  fontWeight: "400",
  color: Colors.twilight,
  marginBottom: "26px",
  marginTop: "6px",
}));

const StyledContent = styled(Box)(({ theme }) => ({
  width: "100%",
  margin: theme.spacing(1, 0),
}));

const StyledActions = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  marginTop: theme.spacing(2),
}));

const StyledCancelButton = styled(Button)(({ theme }) => ({
  height: 40,
  borderRadius: 4,
  padding: theme.spacing(1, 4),
  fontSize: 14,
  lineHeight: "17px",
  fontWeight: "700",
  color: Colors.twilight,
  marginTop: "6px",
  marginRight: "6px",
  textTransform: "capitalize",
}));

const StyledConfirmButton = styled(Button)(({ theme }) => ({
  height: 40,
  borderRadius: 4,
  padding: theme.spacing(1, 4),
  fontSize: 14,
  lineHeight: "17px",
  fontWeight: "700",
  color: "#FFFFFF",
  marginTop: "6px",
  marginRight: "6px",
  textTransform: "capitalize",
  backgroundColor: Colors.secondary,
  "&:hover": {
    backgroundColor: Colors.secondary2,
  },
}));

export interface ConfirmDialogProps {
  title?: string;
  description?: string;
  confirmText?: string;
  cancelText?: string;
  onConfirm: () => void;
  onCancel?: () => void;
  dialogChildren?: JSX.Element;
  children?: JSX.Element;
  disabled?: boolean;
  skip?: boolean;
}

function ConfirmDialog(props: ConfirmDialogProps) {
  const submit = () => {
    if (props.disabled) {
      return;
    }
    if (props.skip) {
      props.onConfirm();
      return;
    }
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <StyledContainer>
            <StyledHeader>
              <StyledTitle>{props.title || "Confirm to submit"}</StyledTitle>
              <IconButton onClick={() => onClose()} sx={{ ml: 3 }}>
                <CloseIcon />
              </IconButton>
            </StyledHeader>
            <StyledDescription>
              {props.description || "Are you sure to do this."}
            </StyledDescription>
            <StyledContent>{props.dialogChildren}</StyledContent>
            <StyledActions>
              <StyledConfirmButton
                variant="text"
                onClick={() => {
                  props.onConfirm();
                  onClose();
                }}
              >
                {props.confirmText || "Yes"}
              </StyledConfirmButton>
              <StyledCancelButton variant="text" onClick={onClose}>
                {props.cancelText || "No"}
              </StyledCancelButton>
            </StyledActions>
          </StyledContainer>
        );
      },
    });
  };

  return (
    <Box
      onClick={submit}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "max-content",
      }}
    >
      {props.children}
    </Box>
  );
}

export default ConfirmDialog;
