import { ReactElement, useMemo } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from "@mui/material";
import { getHighlightText } from "src/utils/get-highlight-text";
import HighlightActionMenu from "src/view/components/HighlightActionMenu";
import theme from "src/theme";

interface Props {
  data: unknown[];
}

const TableView = ({ data }: Props) => {
  const rows = useMemo(() => {
    const highlightedFields: string[] = [];
    const regularFields: string[] = [];
    Object.keys(data[0]).forEach((key) => {
      if (data.some((row) => row[key].includes("<span"))) {
        highlightedFields.push(key);
      } else {
        regularFields.push(key);
      }
    });
    return [...highlightedFields, ...regularFields];
  }, [data]);

  return (
    <Table sx={{ width: "100%" }}>
      <TableHead sx={{ backgroundColor: "rgba(231, 231, 231, 0.5)" }}>
        <TableRow>
          {rows.map((field: string) => {
            const isRowEmpty = !data[0][field]?.length;
            if (isRowEmpty) return null;
            return (
              <TableCell
                sx={{
                  whiteSpace: "nowrap",
                  py: 1,
                  fontSize: "11px",
                  lineHeight: "13px",
                  color: "rgba(0, 0, 0, 0.7)",
                }}
                key={field}
              >
                {getHighlightText(field)}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.slice(0, 5).map((row: unknown, index: number) => (
          <TableRow
            key={index}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            {rows.map((field: string) => {
              const isRowEmpty = !row[field]?.length;
              if (isRowEmpty) return null;
              return (
                <TableCell
                  key={field}
                  sx={{
                    whiteSpace: "nowrap",
                    py: 2,
                    fontSize: "12px",
                    lineHeight: "15px",
                    color: "#000000",
                    maxWidth: "250px",
                  }}
                >
                  <Tooltip
                    placement={"bottom-start"}
                    title={
                      <HighlightActionMenu>
                        {
                          getHighlightText(row[field], {
                            color: theme.palette.primary.main,
                          }) as unknown as ReactElement
                        }
                      </HighlightActionMenu>
                    }
                    arrow
                    sx={{
                      mr: "20px",
                      backgroundColor: "red",
                    }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          p: 1,
                          bgcolor: "#122945",
                          "& .MuiTooltip-arrow": {
                            color: "#122945",
                          },
                        },
                      },
                    }}
                    PopperProps={{
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, -5],
                          },
                        },
                      ],
                    }}
                  >
                    <div
                      style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    >
                      {getHighlightText(row[field]) as unknown as string}
                    </div>
                  </Tooltip>
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default TableView;
