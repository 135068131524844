import { QueryGroupItem } from "src/types/BulkSearch";
import { SearchResult } from "src/types/Search";
import { SearchTerm } from "src/types/BulkSearch";
import actions from "./queryGroupActions";

export interface Props {
  loading?: boolean;
  created?: boolean;
  error?: boolean;
  queryGroups?: QueryGroupItem[];
  updated?: boolean;
  selectedSearchTerm?: SearchTerm;
  searchResult?: SearchResult | null;
  searchError?: string;
  searchResultQueryGroup?: string[];
  searchQueryGroupError?: string;
}

const initialData: Props = {
  loading: false,
  created: false,
  error: false,
  queryGroups: [],
  updated: false,
  selectedSearchTerm: null,
  searchResult: null,
  searchError: null,
  searchResultQueryGroup: null,
  searchQueryGroupError: null,
};

export default (
  state = initialData,
  {
    type,
    payload,
  }: {
    type: string;
    payload:
      | boolean
      | string
      | SearchTerm
      | SearchResult
      | string[]
      | QueryGroupItem[];
  }
) => {
  if (type === actions.LOADING) {
    return {
      ...state,
      loading: payload,
    };
  }

  if (type === actions.CREATE) {
    return {
      ...state,
      loading: false,
      created: true,
    };
  }

  if (type === actions.CREATE_ERROR) {
    return {
      ...state,
      loading: false,
      error: true,
    };
  }

  if (type === actions.CLEAR_ERROR) {
    return {
      ...state,
      loading: false,
      error: false,
      created: false,
      searchError: null,
    };
  }

  if (type === actions.FIND_ERROR) {
    return {
      ...state,
      loading: false,
      error: false,
      created: false,
      queryGroups: [],
    };
  }

  if (type === actions.GET_QUERY_GROUPS) {
    return {
      ...state,
      loading: false,
      queryGroups: payload,
    };
  }

  if (type === actions.DELETE_ONE) {
    return {
      ...state,
      loading: false,
      updated: true,
    };
  }
  if (type === actions.UPDATED) {
    return {
      ...state,
      loading: false,
      updated: true,
    };
  }

  if (type === actions.CLEAR_UPDATE_STATUS) {
    return {
      ...state,
      updated: false,
    };
  }

  if (type === actions.SELECT_SEARCH_TERM) {
    return {
      ...state,
      selectedSearchTerm: payload,
    };
  }

  if (type === actions.GET_SEARCH_RESULT) {
    return {
      ...state,
      searchResult: payload,
    };
  }

  if (type === actions.GET_SEARCH_RESULT_ERROR) {
    return {
      ...state,
      searchError: payload,
    };
  }

  if (type === actions.SEARCH_RESULT_QUERY_GROUP) {
    return {
      ...state,
      searchResultQueryGroup: payload,
    };
  }

  if (type === actions.SEARCH_QUERY_GROUP_ERROR) {
    return {
      ...state,
      searchQueryGroupError: payload,
    };
  }

  return state;
};
