import { useState } from "react";
import Dropzone from "react-dropzone";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { Colors } from "src/view/constants/colors";
import { styled } from "@mui/material/styles";
import Papa from "papaparse";
import { snackbar } from "src/view/toaster";
import { User } from "src/types/UserGroup";

const StyledButton = styled(Button)(() => ({
  color: Colors.twilight,
  borderColor: Colors.twilight,
  textTransform: "capitalize",
  fontSize: 14,
  lineHeight: "20px",
  fontWeight: "700",
  "&:hover": {
    backgroundColor: Colors.textDark100,
  },
}));

const DropZone = (props: { updateCSVdata: (data: User[]) => void }) => {
  const [importing, setImporting] = useState(false);
  const [result, setResult] = useState(null);

  const handleUploadFile = async (files: File[]) => {
    if (files.length === 0) return;
    const file = files[0];
    const postData = new FormData();
    postData.append("file", file);

    setImporting(true);
    // let res = await dispatch(actions.importCSV(postData));
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results: { data: Array<User> }) {
        const rowsArray: string[][] = [];
        const valuesArray: string[][] = [];

        // Iterating data to get column name and their values
        results.data?.map((d: User) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });

        if (!rowsArray[0]) {
          setImporting(false);
          snackbar.error(
            "The file is not formatted correctly. Please download and follow the template before upload."
          );
          return;
        }

        const teA = rowsArray[0].filter(
          (item: string) => item.toLowerCase() === "email"
        );
        if (teA.length === 0) {
          const res = {
            error: true,
          };
          setResult(res);
          setImporting(false);
        } else {
          // Parsed Data Response in array format
          const users: User[] = [];
          for (let i = 0; i < results.data.length; i++) {
            const user = results.data[i];
            const _user: User = {
              first_name: user.first_name,
              last_name: user.last_name,
              email: user.email,
            };
            users.push(_user);
          }
          setResult(users);
          props.updateCSVdata(users);
          setImporting(false);
        }
      },
    });
  };

  const handleDownloadTemplate = () => {
    window.location.href = window.location.origin + "/template.csv";
  };

  return (
    <Dropzone
      maxFiles={1}
      accept={{ "text/plain": [".csv"] }}
      onDrop={(acceptedFiles) => handleUploadFile(acceptedFiles)}
    >
      {({ getRootProps, getInputProps }) => (
        <Box
          sx={{
            width: "100%",
            paddingTop: "50px",
            paddingBottom: "50px",
            border:
              result?.error && importing === false
                ? "1px dashed #F75151"
                : "1px dashed #043D5D",
            borderRadius: "12px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor:
              result?.error && importing === false
                ? "rgba(247, 81, 81, 0.05)"
                : "rgba(4, 61, 93, 0.05)",
          }}
        >
          <div style={{ width: "100%", height: "100%" }} {...getRootProps()}>
            <input {...getInputProps()} />
            {result?.error && importing === false ? (
              <Box
                sx={{
                  margin: "auto",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={require("src/assets/images/cloud-upload-red.png")}
                  style={{ width: 39, height: 39 }}
                />
                <Box sx={{ height: 12 }}></Box>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "18px",
                    lineHeight: "20px",
                    fontWeight: "700",
                    color: Colors.secondary,
                  }}
                >
                  Error encountered with uploaded CSV
                </Typography>
                <Box sx={{ height: 30 }}></Box>
                <StyledButton
                  startIcon={
                    <img
                      src={require("src/assets/images/upload_icon_white.png")}
                      style={{ width: 18, height: 18, color: "white" }}
                    />
                  }
                  variant="outlined"
                  sx={{
                    backgroundColor: Colors.textDark100,
                    color: "#FFFFFF",
                    height: 42,
                  }}
                >
                  Upload Again
                </StyledButton>
              </Box>
            ) : (
              <Box
                sx={{
                  margin: "auto",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {importing ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <img
                    src={require("src/assets/images/cloud-upload-filled.png")}
                    style={{ width: 39, height: 39 }}
                  />
                )}
                <Box sx={{ height: 12 }}></Box>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "18px",
                    lineHeight: "20px",
                    fontWeight: "700",
                    color: Colors.twilight,
                  }}
                >
                  Select CSV file to upload
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "14px",
                    lineHeight: "21px",
                    fontWeight: "400",
                    color: Colors.textGray200,
                  }}
                >
                  or drag and drop it here
                </Typography>
                <Box sx={{ height: 30 }}></Box>
                <Box
                  sx={{
                    width: "240px",
                    height: "1px",
                    backgroundColor: Colors.bgGray3,
                  }}
                ></Box>
                <Box
                  sx={{
                    backgroundColor: "rgb(244 246 247)",
                    padding: "20px",
                    marginTop: "-33px",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "14px",
                      lineHeight: "21px",
                      fontWeight: "400",
                      color: Colors.textGray200,
                      textAlign: "center",
                    }}
                  >
                    OR
                  </Typography>
                </Box>
              </Box>
            )}
          </div>
          {result == null && (
            <StyledButton
              onClick={handleDownloadTemplate}
              startIcon={
                <img
                  src={require("src/assets/images/download_icon_white.png")}
                  style={{ width: 18, height: 18, color: "white" }}
                />
              }
              variant="outlined"
              sx={{
                backgroundColor: Colors.textDark100,
                color: "#FFFFFF",
                height: 42,
              }}
            >
              Download Template
            </StyledButton>
          )}
        </Box>
      )}
    </Dropzone>
  );
};

export default DropZone;
