interface Props {
  src: string;
  sx: React.CSSProperties;
}

const ImageIcon: React.FC<Props> = ({ src, sx }) => {
  return <img src={src} style={sx} alt="" />;
};

export default ImageIcon;
