import {
  Box,
  CircularProgress,
  Collapse,
  Switch,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Suspense } from "react";
import { KnowledgeWiki } from "src/types/KnowledgeWiki";
import { MDXConfiguredEditor } from "src/view/components/MarkdownEditor";

const StyledDetailContainer = styled(Box)(() => ({
  backgroundColor: "white",
  border: "1px solid #D9D9D9",
  borderRadius: 8,
  padding: 20,
  width: "calc(100% - 48px)",
  marginBottom: 20,
  position: "relative",
}));

const StyledBlockTitle = styled(Typography)(() => ({
  color: "#122945",
  fontWeight: "700",
  lineHeight: "25px",
  fontSize: "20px",
}));

type Props = {
  label: string;
  fieldname: keyof KnowledgeWiki;
  content: string;
  handleChangeProps: (key: string, value: boolean) => void;
  handleChangeFormData: (type: keyof KnowledgeWiki, data: unknown) => void;
  isEnabled: boolean;
};

const DictionaryDetailsMDEditor = ({
  handleChangeProps,
  handleChangeFormData,
  isEnabled,
  label,
  content,
  fieldname,
}: Props) => {
  return (
    <StyledDetailContainer>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <StyledBlockTitle>{label}</StyledBlockTitle>
        <Switch
          checked={isEnabled}
          color="success"
          onChange={(_, checked: boolean) =>
            handleChangeProps(fieldname, checked)
          }
        />
      </Box>
      <Collapse in={isEnabled}>
        <Box sx={{ height: 24 }} />

        <Suspense fallback={<CircularProgress />}>
          <MDXConfiguredEditor
            initialValue={content}
            onChange={(v: string) => handleChangeFormData(fieldname, v)}
          />
        </Suspense>
      </Collapse>
    </StyledDetailContainer>
  );
};

export default DictionaryDetailsMDEditor;
