import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { snackbar } from "src/view/toaster";
import {
  Box,
  Container,
  Grid,
  Typography,
  Input,
  CircularProgress,
  Stack,
  IconButton,
  Avatar,
  Tooltip,
  useMediaQuery,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material";
import { Colors } from "../constants/colors";
import actions from "src/modules/bulk-search/screeningList/screeningActions";
import queryGroupActions from "src/modules/bulk-search/query-group/queryGroupActions";
import selector from "src/modules/bulk-search/query-group/queryGroupSelectors";
import QueryGroupListTable from "./QueryGroupListTable";
import screeningSelectors from "src/modules/bulk-search/screeningList/screeningSelectors";
import ShareDialog from "./ShareDialog";
import { randomColor } from "src/utils";
import { QueryGroupItem, ScreeningList } from "src/types/BulkSearch";
import { Group } from "src/types/UserGroup";
import searchFilterActions from "src/modules/bulk-search/filter/searchFilterActions";
import { useUser } from "src/modules/api/auth";
import { ReactComponent as IconDuplicate } from "src/assets/icons/file_copy_line.svg";
import { usePostHog } from "posthog-js/react";
import { HogEvent } from "src/types/PosthogEvents";

const StyledContainer = styled(Button)(() => ({
  borderRadius: 4,
  backgroundColor: "#F75151",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#ffffff",
  fontSize: 14,
  lineHeight: "17px",
  margin: 5,
  fontWeight: "700",
  padding: 15,
  height: 46,
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#F7515188",
  },
}));

const StyledShareButton = styled(Button)(() => ({
  borderRadius: 4,
  backgroundColor: "transparent",
  border: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: Colors.textDark,
  fontSize: 14,
  lineHeight: "17px",
  margin: 5,
  fontWeight: "700",
  height: 46,
  padding: 15,
  textTransform: "capitalize",
}));

const StyledBackButton = styled(Button)(() => ({
  border: "none",
  backgroundColor: "transparent",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  color: Colors.textDark,
  fontSize: 16,
  lineHeight: "20px",
  margin: 5,
  marginLeft: -15,
  fontWeight: "700",
  padding: 15,
  height: 46,
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

const StyledBulkScaffolding = styled(Container)(() => ({
  marginTop: 127,
  paddingBottom: 100,
}));

const StyledGroupHeader = styled(Typography)(() => ({
  fontSize: 18,
  lineHeight: "24px",
  fontWeight: "700",
  color: Colors.textDark100,
}));

const StyledListContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "calc(100% - 127px - 76px - 95px - 33px - 20px",
  position: "relative",
}));

const StyledInputBoxTitle = styled(Input)(() => ({
  height: "100%",
  width: "100%",
  borderRadius: 4,
  fontSize: 24,
  lineHeight: "29px",
  fontWeight: "700",
  color: Colors.textGray800,
  border: "none",
  "&::after": {
    border: "none",
    borderBottomStyle: "none !important",
  },
  "&::before": {
    border: "none",
    borderBottomStyle: "none !important",
  },
  "&:focus-visible": {
    outline: "none",
    borderBottomStyle: "none !important",
  },
  "&:hover": {
    "&::after": {
      border: "none",
      borderBottomStyle: "none !important",
    },
    "&::before": {
      border: "none",
      borderBottomStyle: "none !important",
    },
  },
}));

const StyledInputBoxDescription = styled(Input)(() => ({
  height: "100%",
  width: "100%",
  borderRadius: 4,
  fontSize: 18,
  lineHeight: "24px",
  fontWeight: "bold",
  color: "rgba(20, 74, 104)",
  border: "none",
  "&::after": {
    border: "none",
    borderBottomStyle: "none !important",
  },
  "&::before": {
    border: "none",
    borderBottomStyle: "none !important",
  },
  "&:focus-visible": {
    outline: "none",
    borderBottomStyle: "none !important",
  },
}));

const StyledImage = styled("img")(() => ({
  width: "100%",
  height: 226,
  objectFit: "contain",
}));

const StyledLoadingContainer = styled("div")(() => ({
  width: "100%",
  position: "absolute",
  top: 0,
  height: "100%",
  minHeight: 200,
  backgroundColor: "#FFFFFF88",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledAvatarContainer = styled(Box)(() => ({
  width: 10,
}));

type ResponseStatus = { status: "Succeeded" | "Failed" | "In Progress" };

const QueryGroupList = () => {
  const [queryGroupList, setQueryGroupList] = useState<QueryGroupItem[]>([]);
  const { user } = useUser();
  const posthog = usePostHog();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [screeningListItem, setScreeningListItem] =
    useState<ScreeningList>(null);
  const queryGroups = useSelector(selector.selectQueryGroupList);
  const loading = useSelector(selector.selectLoading);
  const updated = useSelector(selector.selectUpdatedStatus);
  const screeningItemUpdated = useSelector(
    screeningSelectors.selectUpdatedStatus
  );
  const selectedScreeningList = useSelector(
    screeningSelectors.selectSelectedScreeningList
  );
  const [openShareModal, setOpenShareModal] = useState(false);
  const [sharedGroups, setSharedGroups] = useState([]);
  const [avatarColors, setAvatarColors] = useState([]);
  const { id } = useParams();

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    shared_users: [],
  });
  const location = useLocation();
  const [runningAllSearches, setRunningAllSearches] = useState(false);
  const [isMine, setIsMine] = useState(true);
  const [needChecking, setNeedChecking] = useState(true);
  const mobileView = useMediaQuery("(max-width:678px)");
  const [duplicating, setDuplicating] = useState(null);

  useEffect(() => {
    const { data, groupData } = location?.state || {};
    const isCreator = user?.id === data?.creator;
    if (data) {
      updateFormData(data);
      setIsMine(data.tab === 0 || data.isMine || isCreator);
      return;
    }
    if (groupData) {
      updateFormData(groupData);
      setIsMine(groupData.isMine);
      return;
    }
    if (id) {
      getScreeningList();
    } else {
      clearFormData();
    }
  }, [location, id, user]);

  const _id = screeningListItem?.id || id;
  useEffect(() => {
    if (_id) {
      dispatch(queryGroupActions.getQueryGroups(_id));
      getSharedGroups();
      window.addEventListener("UpdateShareGroups", getSharedGroups);
      return () => {
        window.removeEventListener("UpdateShareGroups", getSharedGroups);
      };
    }
  }, [_id]);

  useEffect(() => {
    if (updated && screeningListItem?.id) {
      dispatch(queryGroupActions.getQueryGroups(screeningListItem.id));
    }
  }, [updated, screeningListItem]);

  useEffect(() => {
    if (updated) {
      snackbar.success("Query Group Saved");
      dispatch(queryGroupActions.clearUpdateStatus());
    }
  }, [updated]);

  useEffect(() => {
    if (queryGroups) {
      setQueryGroupList(queryGroups);
    }
  }, [queryGroups]);

  useEffect(() => {
    if (selectedScreeningList) {
      updateFormData(selectedScreeningList);
    }
  }, [selectedScreeningList]);

  useEffect(() => {
    if (screeningItemUpdated) {
      snackbar.success("Screening List was updated successfully");
      dispatch(actions.clearUpdateStatus);
    }
  }, [screeningItemUpdated]);

  useEffect(() => {
    if (runningAllSearches && screeningListItem.id) {
      const interval = setInterval(() => {
        dispatch(
          actions.getScreeningListMonitorStatus(screeningListItem.id)
        ).then((res: ResponseStatus) => {
          if (res.status === "Succeeded") {
            setRunningAllSearches(false);
            snackbar.success(`Finished Searching ${screeningListItem.name}`);
            document.dispatchEvent(new CustomEvent("refreshQueryGroup"));
          }
          if (res.status === "Failed") {
            snackbar.error(`Failed Searching ${screeningListItem.name}`);
          }
        });
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [runningAllSearches, screeningListItem]);

  useEffect(() => {
    if (screeningListItem?.id) {
      checkSearchStatus(screeningListItem);
    }
  }, [screeningListItem]);

  useEffect(() => {
    if (duplicating) {
      const interval = setInterval(() => {
        dispatch(
          actions.getScreeningListMonitorStatus(duplicating.task_id)
        ).then(async (res: ResponseStatus) => {
          if (res.status === "Succeeded") {
            setDuplicating(null);
            snackbar.success(`Duplicated ${screeningListItem.name}`);
            const newId = duplicating.screening_list;
            const duplicatedScreeningList = await dispatch(
              actions.getScreeningList(newId)
            );
            updateFormData(duplicatedScreeningList);
          }
          if (res.status === "Failed") {
            snackbar.error(`Failed Duplicating ${screeningListItem.name}`);
          }
        });
      }, 2500);

      return () => clearInterval(interval);
    }
  }, [duplicating, screeningListItem]);

  const checkSearchStatus = (sl: ScreeningList) => {
    if (sl?.id && needChecking) {
      setNeedChecking(false);
      setTimeout(() => {
        dispatch(actions.getScreeningListMonitorStatus(sl.id)).then(
          (res: ResponseStatus) => {
            if (res.status === "In Progress") {
              setRunningAllSearches(true);
            } else {
              document.dispatchEvent(
                new CustomEvent("checkQueryGroupSearchStatus")
              );
            }
          }
        );
      }, 1000);
    }
  };

  const getScreeningList = async () => {
    const res = await dispatch(actions.getScreeningList(id));
    if (res) {
      updateFormData(res);
    }
  };

  const getSharedGroups = async () => {
    const res = await dispatch(
      actions.getSharedGroups(screeningListItem?.id || id)
    );
    if (res) {
      const colors = [];
      for (let index = 0; index < res.length; index++) {
        const color = randomColor();
        colors.push(color);
      }
      setAvatarColors(colors);
      setSharedGroups(res);
    }
  };

  const updateFormData = (_data: ScreeningList) => {
    const s_list = _data;
    const f_data = { ...formData };
    f_data.name = s_list.title ? s_list.title : s_list.name;
    f_data.description = s_list.description;
    if (s_list.sharedUsers) {
      f_data.shared_users = s_list.sharedUsers;
    }
    setFormData(f_data);
    setScreeningListItem(s_list);
  };

  const clearFormData = () => {
    const f_data = { ...formData };
    f_data.name = "";
    f_data.description = "";
    setFormData(f_data);
    setScreeningListItem(null);
    setQueryGroupList([]);
    dispatch({
      type: queryGroupActions.GET_QUERY_GROUPS,
      payload: [],
    });
  };

  const goBack = () => {
    navigate("/bulk-search", { replace: true });
  };

  const handleChangeFormData = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    if (!formData.name) {
      snackbar.error("The name is required.");
      return false;
    }

    if (selectedScreeningList) {
      const postData = {
        id: selectedScreeningList.id,
        name: formData.name,
        description: formData.description,
      };
      await dispatch(actions.update(postData));
    } else {
      const postData = {
        name: formData.name,
        description: formData.description,
      };
      const res = await dispatch(actions.create(postData));
      if (res) {
        posthog.capture(HogEvent.SCREENING_LIST_CREATED);
        snackbar.success("The Screening List was created successfully.");
        return res;
      } else {
        snackbar.error("Something Went Wrong");
        return false;
      }
    }
  };

  const onClickAdd = async () => {
    dispatch(
      searchFilterActions.setFilter({
        type: null,
        dataset_ids: [],
        doc_types: [],
        dataset_types: [],
        countries: [],
        regions: [],
        languages: [],
      })
    );
    if (screeningListItem) {
      const currentState = location?.state
        ? { ...location.state, groupData: { isMine: isMine } }
        : { data: { ...screeningListItem }, groupData: { isMine: isMine } };
      const path = location?.state ? "edit" : screeningListItem.id + "/edit";
      dispatch({
        type: actions.EDIT,
        payload: screeningListItem,
      });
      navigate(path, {
        replace: false,
        state: { ...currentState },
      });
    } else {
      const save = await handleSave();
      if (save && save.id) {
        navigate(save.id + "/edit", {
          replace: false,
          state: {
            ...location?.state,
            data: { id: save.id, isMine: true },
            groupData: { isMine: isMine },
          },
        });
      }
    }
  };

  const handleSearchAll = async () => {
    setRunningAllSearches(true);
    posthog.capture(HogEvent.SCREENING_LIST_SEARCH_RUN);
    await dispatch(actions.runScreeningListSearch(screeningListItem.id));
    snackbar.success(`Searching everything in ${screeningListItem.name}`);
  };

  const handleShare = () => {
    setOpenShareModal(true);
  };

  const handleDuplicate = async () => {
    const res = await dispatch(
      actions.duplicateScreeningList(screeningListItem.id)
    );
    if (res) {
      if (res.task_id) {
        setDuplicating(res);
        snackbar.success("Duplicating this screening list for you.");
      }
    }
  };

  return (
    <StyledBulkScaffolding sx={{ mt: mobileView ? "149px" : "104px" }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{
          paddingTop: 2,
        }}
      >
        <Grid item>
          <StyledBackButton
            startIcon={<ArrowBackIcon />}
            color="primary"
            onClick={goBack}
          >
            Back
          </StyledBackButton>
        </Grid>
        <Grid item>
          <Box sx={{ display: "flex", color: "#122945" }}>
            <StyledShareButton
              startIcon={
                <IconDuplicate
                  style={{ width: 20, height: 20, color: "inherit" }}
                />
              }
              onClick={handleDuplicate}
            >
              Duplicate
            </StyledShareButton>
            {isMine && (
              <StyledShareButton
                startIcon={
                  <img
                    src={require("src/assets/images/share.png")}
                    alt="share"
                    style={{ width: 20, height: 20 }}
                  />
                }
                onClick={handleShare}
              >
                Share
              </StyledShareButton>
            )}
            {isMine && (
              <StyledContainer
                startIcon={
                  <img
                    src={require("src/assets/images/check.png")}
                    alt="check"
                    style={{ width: 18, height: 18 }}
                  />
                }
                onClick={handleSave}
              >
                Save
              </StyledContainer>
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ pt: 2 }}
      >
        <Box sx={{ width: "calc(100% - 200px)" }}>
          <StyledInputBoxTitle
            placeholder="Enter Bulk Search Name"
            value={formData.name}
            name="name"
            onChange={handleChangeFormData}
            disabled={!isMine}
          />
          <StyledInputBoxDescription
            placeholder="Enter Description"
            value={formData.description}
            name="description"
            onChange={handleChangeFormData}
            maxRows={10}
            multiline
            disabled={!isMine}
          />
        </Box>
        <Box sx={{ display: "flex", pt: 2 }}>
          <Stack direction="row" spacing={2} sx={{ mr: 7, display: "flex" }}>
            {sharedGroups?.map((item: Group, index: number) => {
              if (!item.avatar) {
                const nameArray = item.name.split(" ");
                const name = nameArray[0]?.["0"] + nameArray[0]?.["1"];
                return (
                  <StyledAvatarContainer key={index}>
                    <Tooltip title={item.name}>
                      <Avatar
                        sx={{
                          bgcolor: avatarColors[index] || "#940930",
                          border: "1px solid white",
                          fontSize: 14,
                          lineHeight: "20px",
                          fontWeight: "700",
                          textTransform: "uppercase",
                        }}
                      >
                        {name}
                      </Avatar>
                    </Tooltip>
                  </StyledAvatarContainer>
                );
              }
              return (
                <StyledAvatarContainer key={index}>
                  <Tooltip title={item.name}>
                    <Avatar
                      alt={item.name}
                      src={item.avatar}
                      sx={{
                        border: "1px solid white",
                        fontSize: 14,
                        lineHeight: "20px",
                        fontWeight: "700",
                        textTransform: "uppercase",
                      }}
                    />
                  </Tooltip>
                </StyledAvatarContainer>
              );
            })}
          </Stack>
          {isMine && (
            <IconButton
              onClick={() => {
                const Event = new CustomEvent("openGroupListDrawer", {
                  detail: {
                    type: "group_for_screening_list",
                    data: sharedGroups,
                    id: screeningListItem.id,
                  },
                });
                window.dispatchEvent(Event);
              }}
            >
              <img
                src={require("src/assets/images/edit-fill.png")}
                alt={"edit icon"}
                style={{ width: 24, height: 24 }}
              />
            </IconButton>
          )}
        </Box>
      </Grid>
      <Box
        sx={{
          height: 1,
          pt: 3,
          mb: 4,
          width: "100%",
          borderBottom: "1px solid #000000",
          opacity: 0.1,
        }}
      />
      <Grid container alignItems="center" justifyContent="space-between">
        <StyledGroupHeader>List of Query Groups</StyledGroupHeader>
        {!!queryGroupList?.length && (
          <Box sx={{ display: "flex" }}>
            {isMine && (
              <Button
                startIcon={<AddIcon />}
                variant="outlined"
                sx={{
                  color: Colors.textDark100,
                  borderColor: Colors.textDark100,
                  height: 38,
                  textTransform: "capitalize",
                  fontWeight: "700",
                }}
                onClick={onClickAdd}
              >
                Add Query Group
              </Button>
            )}
            <Box sx={{ width: 12 }} />
            <Button
              startIcon={
                <img
                  src={require("src/assets/images/run-circle.png")}
                  className={runningAllSearches ? "rotate-image" : ""}
                  alt={"Run All Searches"}
                  style={{ width: 18, height: 18 }}
                />
              }
              sx={{
                color: "#FFFFFF",
                bgColor: Colors.textDark100,
                height: 38,
                textTransform: "capitalize",
                fontWeight: "700",
              }}
              variant="contained"
              onClick={() => handleSearchAll()}
            >
              Run All Searches
            </Button>
          </Box>
        )}
      </Grid>
      <StyledListContainer>
        {queryGroupList?.length > 0 && (
          <QueryGroupListTable
            isMine={isMine}
            hasSharedGroup={sharedGroups.length > 0}
            data={queryGroupList}
            loading={loading}
          />
        )}
        {!loading && !queryGroupList?.length && (
          <Box sx={{ maxWidth: 350 }}>
            <StyledImage
              src={require("src/assets/images/not-found.png")}
              alt="Not Found"
            ></StyledImage>
            <Typography
              sx={{
                color: Colors.textDark,
                fontSize: 18,
                lineHeight: "20px",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              No Query Groups Found
            </Typography>
            <Typography
              sx={{
                color: Colors.textGray300,
                fontSize: 14,
                lineHeight: "152.2%",
                fontWeight: "400",
                textAlign: "center",
                mt: "8px",
              }}
            >
              No query groups were found for this screening list.
            </Typography>
            <Box sx={{ height: 20 }}></Box>
            <Box
              sx={{ display: "flex", width: "100%", justifyContent: "center" }}
            >
              <Button
                onClick={onClickAdd}
                startIcon={<AddIcon />}
                sx={{
                  color: "#FFFFFF",
                  bgColor: Colors.twilight,
                  height: 46,
                  textTransform: "capitalize",
                  fontWeight: "700",
                }}
                variant="contained"
              >
                Add Query Group
              </Button>
            </Box>
          </Box>
        )}
        {loading && !queryGroupList?.length && (
          <StyledLoadingContainer sx={{}}>
            <CircularProgress />
          </StyledLoadingContainer>
        )}
      </StyledListContainer>
      <ShareDialog
        open={openShareModal}
        onClose={() => setOpenShareModal(false)}
        title={`Share '${formData.name}' with groups`}
        data={screeningListItem}
      />
    </StyledBulkScaffolding>
  );
};

export default QueryGroupList;
