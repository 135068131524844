import { Box, Container, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useMemo, useState } from "react";
import { Colors } from "src/view/constants/colors";
import { useNavigate } from "react-router-dom";
import { ScreeningList } from "src/types/BulkSearch";
import { format } from "date-fns";
import { useBulkSearch } from "src/modules/api/bulkSearch";

const StyledDataItem = styled(Grid)(() => ({
  display: "flex",
  padding: 20,
  height: 63,
  alignItems: "center",
  justifyContent: "space-between",
  "&:hover": {
    backgroundColor: "#FFFFFF",
    border: "1px solid #E8EAEC",
    boxShadow: "0px 24px 54px rgba(165, 184, 206, 0.25)",
    borderRadius: 8,
  },
}));

const StyledTitle = styled(Typography)(() => ({
  color: "#1D1D1B",
  fontSize: 16,
  lineHeight: "20px",
  fontWeight: "700",
}));

const StyledLabel = styled(Typography)(() => ({
  color: "#5A7290",
  fontSize: 12,
  lineHeight: "15px",
  fontWeight: "700",
}));

const StyledSectionWrapper = styled(Box)(() => ({
  borderRadius: 8,
  border: "1px solid #E8EAEC",
}));

const ScreeningListSection = () => {
  const { getScreeningLists } = useBulkSearch();
  const { data } = getScreeningLists();
  const navigate = useNavigate();

  const rows = useMemo(() => {
    const { created_by, shared_with_groups } = data;
    return [
      ...created_by.map((item) => ({ ...item, tab: 0 })),
      ...shared_with_groups.map((item) => ({ ...item, tab: 1 })),
    ].slice(0, 5);
  }, [data]);

  const gotoDetail = (item: ScreeningList) => {
    navigate("/bulk-search/query-group/" + item.id, {
      replace: false,
      state: { data: item },
    });
  };

  const viewAll = () => {
    navigate("/bulk-search");
  };

  return (
    <Container>
      <Grid
        item
        sx={{
          pt: 10,
          pb: 7,
          alignItems: "flex-start",
          px: { xs: 2, sm: 4 },
        }}
      >
        <Grid
          container
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Grid item>
            <Typography
              variant="caption"
              sx={{
                color: Colors.textDark100,
                fontSize: "32px",
                lineHeight: "39.23px",
                fontWeight: "700",
              }}
            >
              Screening Lists
            </Typography>
            <Box
              sx={{
                width: 56,
                height: 5,
                backgroundColor: Colors.secondary,
                mt: "14px",
                mb: "14px",
              }}
            ></Box>
          </Grid>
          <Grid item>
            <Typography
              sx={{
                color: Colors.textDark,
                opacity: 0.5,
                fontSize: "14px",
                lineHeight: "17px",
                fontWeight: "700",
                cursor: "pointer",
              }}
              onClick={viewAll}
            >
              View All
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ mt: 2 }}></Box>
        <StyledSectionWrapper>
          {rows?.map((item: ScreeningList, j: number) => {
            const isLast = rows.length - 1 === j;
            return (
              <DataItem
                key={"dataset " + j}
                isLast={isLast}
                item={item}
                onClick={() => gotoDetail(item)}
              ></DataItem>
            );
          })}
          {rows.length == 0 && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 3,
              }}
            >
              No lists found. Create one&nbsp;<a href="/bulk-search"> here</a>.
            </Box>
          )}
        </StyledSectionWrapper>
      </Grid>
    </Container>
  );
};

interface DataItemProps {
  isLast?: boolean;
  item: ScreeningList;
  onClick?: () => void;
}

const DataItem = (props: DataItemProps) => {
  const { isLast, item, onClick } = props;
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <StyledDataItem
      container
      sx={{ borderBottom: isLast ? "none" : "1px solid #E8EAEC" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Grid item>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <StyledTitle sx={{ cursor: "pointer" }} onClick={onClick}>
            {item["name"]}
          </StyledTitle>
          <Box sx={{ width: 10 }}></Box>
          <StyledLabel>
            {"• " + format(new Date(item["created"]), "MMMM do, yyyy")}
          </StyledLabel>
        </Box>
      </Grid>
      {hovered && (
        <Grid
          item
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={onClick}
        >
          {/* <StyledLabel sx={{ color: "#F75151", mr: 3 }}>
            {"• New results Available"}
          </StyledLabel> */}
          <img
            src={require("src/assets/images/arrow_right_secondary.png")}
            alt={"navigate icon"}
            style={{ width: 20, height: 20 }}
          />
          {/* <IconButton onClick={onClick}>
          </IconButton> */}
        </Grid>
      )}
    </StyledDataItem>
  );
};

export default ScreeningListSection;
