import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { snackbar } from "src/view/toaster";
import { cloneDeep, differenceWith, isEqual } from "lodash";
import {
  Box,
  CircularProgress,
  Dialog,
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import actions from "src/modules/groups/view/groupsViewActions";
import selector from "src/modules/groups/view/groupsViewSelectors";
import wikiActions from "src/modules/knowledge-wiki/view/actions";
import Datasets from "./DatasetsTab";
import Features from "./Features";
import { Group, User } from "src/types/UserGroup";
import { getGroupObjectDiff } from "src/utils/get-group-object-diff";
import { isAPIValidationErrors } from "src/utils";
import ImportUserFromExisting from "./AddUserModals/ImportUserFromExisting";
import ImportUserFromSCV from "./AddUserModals/ImportUserFromSCV";
import AddNewUserModal, {
  UserFields,
} from "src/view/users-groups/shared/AddNewUsersModal";
import { APIValidationErrors } from "src/types/Shared";
import { useToggle } from "usehooks-ts";
import { GroupUpdateType } from "src/modules/groups/view/groupsViewReducers";
import {
  PageWrapper,
  StyledBackButton,
  AntTabs,
  AntTab,
  Transition,
} from "./StyledComponents";
import GroupNameEdit from "./components/GroupNameEdit";
import GroupAdmins from "./GroupAdmins";
import BulkSearch from "./BulkSearch";
import GroupUsersTable from "./GroupUsersTable";

const EditGroupPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const { created, create_error, updated, update_error, update_type } =
    useSelector(selector.selectRaw);
  const loading = useSelector(selector.selectLoading);
  const selectedGroup = useSelector(selector.selectSelectedGroup);
  const mobileView = useMediaQuery("(max-width:678px)");
  const [isAddNewUserModalOpen, toggleAddNewUserModalOpen] = useToggle();
  const [isImportCSVModalOpen, toggleImportCSVModalOpen] = useToggle();
  const [isImportExistingModalOpen, toggleImportExistingModalOpen] =
    useToggle();
  const [formData, setFormData] = useState<Group>({
    name: "",
    description: null,
    members: [],
    sensitivity_levels: ["Nonsensitive"],
    datasets: [],
    dataset_downloads: [],
    external_apis: [],
    features: [],
    kw_read: [],
    kw_write: [],
    permissions: [],
    screening_lists: [],
    data_catalogs: [],
    group_admins: [],
  });

  const [isFormDataSynced, setIsFormDataSynced] = useState(false);
  const [groupUsers, setGroupUsers] = useState<User[] | null>(null);
  const [tempUsers, setTempUsers] = useState<User[]>([]);
  const [newUsers, setNewUsers] = useState<User[]>([]);

  const [addUserAPIValidationErrors, setAddUserAPIValidationErrors] = useState<
    APIValidationErrors<UserFields>
  >([]);
  const [savingUsers, setSavingUsers] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const { id: groupId } = useParams();

  useEffect(() => {
    if (groupId) {
      dispatch(actions.getGroupDetails(groupId));
    }
  }, [groupId]);

  useEffect(() => {
    /** auto-update group changes any time a user makes a change to a group */
    (async () => {
      if (
        !isFormDataSynced ||
        !selectedGroup ||
        isEqual(formData, selectedGroup)
      ) {
        return;
      }

      const updatedFields = getGroupObjectDiff(formData, selectedGroup);
      const addObjs: Group = {};
      const removeObjs: Group = {};
      updatedFields.forEach((field) => {
        if (field === "name") {
          return;
        }
        const add = differenceWith(
          formData[field],
          selectedGroup[field],
          isEqual
        );
        const remove = differenceWith(
          selectedGroup[field],
          formData[field],
          isEqual
        );
        if (add.length) {
          addObjs[field] = add;
        }
        if (remove.length) {
          removeObjs[field] = remove;
        }
      });

      const shouldAddObjectsToGroup = Object.keys(addObjs).length;
      const shouldRemoveObjectsToGroup = Object.keys(removeObjs).length;
      if (shouldAddObjectsToGroup || shouldRemoveObjectsToGroup) {
        const group_id = selectedGroup.group_id || groupId;
        if (shouldAddObjectsToGroup) {
          await dispatch(actions.addObjectsToGroup(addObjs, group_id));
        }
        if (shouldRemoveObjectsToGroup) {
          await dispatch(actions.removeObjectsToGroup(removeObjs, group_id));
        }
        setIsFormDataSynced(false);
        await dispatch(actions.getGroupDetails(group_id));
      }
    })();
  }, [selectedGroup, formData, isFormDataSynced]);

  useEffect(() => {
    dispatch(wikiActions.find(""));

    /** clean up the selected Group in store on unmount before selecting other groups */
    return () => dispatch(actions.selectGroup(null));
  }, []);

  useEffect(() => {
    if (created) {
      snackbar.success("Group was created successfully");
      dispatch({
        type: actions.CLEAR_UPDATE_STATUS,
      });
      goBack();
    }
    if (create_error) {
      snackbar.error(create_error);
    }
  }, [created, create_error]);

  useEffect(() => {
    if (updated) {
      dispatch({
        type: actions.CLEAR_UPDATE_STATUS,
      });
      snackbar.success(
        update_type === GroupUpdateType.CREATED_USERS
          ? "Created new users. They should receive an invite email soon."
          : "Group was updated successfully"
      );
      if (formData.group_id) {
        getGroupUsers(formData.group_id, false);
      } else if (groupId) {
        getGroupUsers(groupId, false);
      }
      setTempUsers([]);
    }
    if (update_error) {
      snackbar.error(update_error);
    }
  }, [updated, update_error, groupId, formData.group_id]);

  useEffect(() => {
    if (selectedGroup) {
      setFormData(cloneDeep({ ...selectedGroup }));
      setIsFormDataSynced(true);
      getGroupUsers(selectedGroup.group_id, true);
    } else {
      if (loadingUsers) {
        setGroupUsers([]);
      }
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (!groupUsers) {
      return;
    }
    setFormData((prev) => ({
      ...prev,
      members: groupUsers.map(({ id }) => id),
    }));
  }, [groupUsers]);

  const getGroupUsers = async (id: string, loading = false) => {
    if (!id) return;
    setLoadingUsers(loading);
    const _users = await dispatch(actions.getGroupUsers(id));
    if (Array.isArray(_users)) setGroupUsers(_users);
    setLoadingUsers(false);
  };

  const goBack = () => {
    navigate("/groups", { replace: true });
  };

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleSaveUsers = async (_users: User[], type = "new") => {
    setSavingUsers(true);
    if (type === "new") {
      if (_users?.length === 0) return;
      if (formData.group_id) {
        for (let i = 0; i < _users.length; i++) {
          _users[i].group_id = formData.group_id;
        }
      }
      const res = await dispatch(actions.saveUsers(_users));

      if (res.error) {
        if (res.detail && isAPIValidationErrors(res.detail)) {
          setAddUserAPIValidationErrors(res.detail);
        } else {
          snackbar.error("Something went wrong");
        }

        setSavingUsers(false);
      } else {
        const _formState = { ...formData };
        _formState.members = _formState.members
          ? [..._formState.members, ...res]
          : res;
        setFormData(_formState);
        toggleAddNewUserModalOpen();
        setSavingUsers(false);
        setAddUserAPIValidationErrors([]);
        if (formData.group_id) {
          setTempUsers([]);
          dispatch(actions.getGroupDetails(groupId));
        } else {
          const _formState = { ...formData };
          _formState.members.push(...res);
          setTempUsers([..._users, ...tempUsers]);
          setFormData(_formState);
        }
      }
    } else {
      const ids = [...groupUsers, ..._users]?.map((user) => user.id);
      const _formState = { ...formData };
      _formState.members = ids;
      setFormData(_formState);
      toggleImportExistingModalOpen();
      setSavingUsers(false);
    }
  };

  const handleUpdateCSVData = (data: User[]) => {
    if (data && data.length > 0) {
      setNewUsers(data);
      toggleImportCSVModalOpen();
      toggleAddNewUserModalOpen();
    }
  };

  const handleUpdateDatasets = (
    checked: boolean,
    key: string,
    value: string
  ) => {
    const _formState = { ...formData };

    const _index = _formState[key].indexOf(value);
    if (checked) {
      if (_index < 0) {
        _formState[key].push(value);
      }
    } else {
      if (_index > -1) {
        _formState[key].splice(_index, 1);
      }
    }

    setFormData(_formState);
  };

  const handleUpdateFeatures = (checked: boolean, value: string) => {
    const _formState = { ...formData };

    // value = value.toLowerCase().replace(/\ /gi, '_');
    const _index = _formState.features.indexOf(value);
    if (checked) {
      if (_index < 0) {
        _formState.features.push(value);
      }
    } else {
      if (_index > -1) {
        _formState.features.splice(_index, 1);
      }
    }

    setFormData(_formState);
  };

  return (
    <PageWrapper sx={{ mt: mobileView ? "149px" : "104px" }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ paddingTop: 2 }}
      >
        <Grid item>
          <StyledBackButton
            startIcon={<ArrowBackIcon />}
            color="primary"
            onClick={goBack}
          >
            Back
          </StyledBackButton>
        </Grid>
        {loading && (
          <Grid item>
            <CircularProgress size={20} color="inherit" />
          </Grid>
        )}
      </Grid>

      <Grid
        container
        alignItems="flex-start"
        justifyContent="space-between"
        sx={{ pt: 2, mb: "30px" }}
      >
        <Box sx={{ flex: 1 }}>
          <GroupNameEdit
            formData={formData}
            setFormData={setFormData}
            groupId={groupId}
          />
        </Box>
        <Stack direction="row" spacing={2} sx={{ mr: 0, display: "flex" }}>
          <GroupAdmins formData={formData} setFormData={setFormData} />
          <BulkSearch formData={formData} setFormData={setFormData} />
        </Stack>
      </Grid>
      <Box
        sx={{
          borderRadius: "8px",
          border: "1px solid #CED3D9",
          px: 2,
          mt: 0,
        }}
      >
        <AntTabs
          value={tabIndex}
          onChange={handleChange}
          aria-label="ant example"
        >
          <AntTab label={"Users"} />
          <AntTab label={"Datasets"} />
          <AntTab label={"Features"} />
        </AntTabs>
        {tabIndex === 0 && (
          <GroupUsersTable
            loadingUsers={loadingUsers}
            setNewUsers={setNewUsers}
            formData={formData}
            setFormData={setFormData}
            groupUsers={groupUsers}
            setGroupUsers={setGroupUsers}
            tempUsers={tempUsers}
            setTempUsers={setTempUsers}
            toggleAddNewUserModalOpen={toggleAddNewUserModalOpen}
            toggleImportCSVModalOpen={toggleImportCSVModalOpen}
            toggleImportExistingModalOpen={toggleImportExistingModalOpen}
          />
        )}
        {tabIndex === 1 && (
          <Datasets handleUpdate={handleUpdateDatasets} data={formData} />
        )}
        {tabIndex === 2 && (
          <Features
            handleUpdate={handleUpdateFeatures}
            data={formData.features}
          />
        )}
      </Box>

      <AddNewUserModal
        newUsers={newUsers}
        setNewUsers={setNewUsers}
        open={isAddNewUserModalOpen}
        handleClose={toggleAddNewUserModalOpen}
        handleSaveUsers={handleSaveUsers}
        savingUsers={savingUsers}
        apiErrors={addUserAPIValidationErrors}
        setApiErrors={setAddUserAPIValidationErrors}
      />

      <Dialog
        fullScreen={true}
        TransitionComponent={Transition}
        open={isImportExistingModalOpen}
        onClose={toggleImportExistingModalOpen}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={false}
      >
        <ImportUserFromExisting
          handleClose={toggleImportExistingModalOpen}
          setTempUsers={setTempUsers}
          handleSaveUsers={handleSaveUsers}
          tempUsers={tempUsers}
        />
      </Dialog>
      <ImportUserFromSCV
        open={isImportCSVModalOpen}
        handleClose={toggleImportCSVModalOpen}
        handleUpdateCSVData={handleUpdateCSVData}
      />
    </PageWrapper>
  );
};

export default EditGroupPage;
