import { memo } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import _ from "lodash";
import filterSelector from "src/modules/bulk-search/filter/searchFilterSelectors";
import doc_types from "src/assets/json/doc_types.json";
import dataset_types from "src/assets/json/dataset_types.json";
import languages from "src/assets/json/languages.json";
import FilterTag from "../../../search-result/components/SearchResultCard/FilterTag";
import { useFilterOptions } from "src/modules/api/filters";

const Tag = (props: { label: string; value: string }) => (
  <FilterTag
    label={props.label}
    value={props.value}
    containerStyle={{
      backgroundColor: "rgba(247, 81, 81, 0.08)",
      height: 17,
      borderRadius: 30,
      padding: "4px 8px",
    }}
    valueStyle={{ color: "#F75151" }}
  />
);

const FilterTags = () => {
  const filter = useSelector(filterSelector.selectFilter);
  const { countries: allCountries } = useFilterOptions();
  const dataset_ids_count = filter?.dataset_ids.length;
  const docs = filter?.doc_types
    .map((value) => _.find(doc_types, { value })?.["label"])
    .join(",");
  const types = filter?.dataset_types
    ?.map((value) => _.find(dataset_types, { value })?.["label"])
    .join(",");
  const countries = filter?.countries
    .map((value) => _.find(allCountries, { value })?.label)
    .join(",");
  const regions = filter?.regions.join(",");
  const language = filter?.languages
    .map((value) => _.find(languages, { value })?.label)
    .join(",");

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-start">
      {dataset_ids_count > 0 && (
        <Tag
          label="Datasets"
          value={dataset_ids_count > 20 ? "+20" : `${dataset_ids_count}`}
        />
      )}
      {docs && <Tag label="Docs" value={docs} />}
      {types && <Tag label="Type" value={types} />}
      {countries && <Tag label="Country" value={countries} />}
      {regions && <Tag label="Region" value={regions} />}
      {language && <Tag label="Language" value={language} />}
    </Box>
  );
};

export default memo(FilterTags);
