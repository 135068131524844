import { CircularProgress, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DataIndex, Hit } from "src/types/Search";
import { Colors } from "src/view/constants/colors";
import SearchResultCard from "./SearchResultCard/SearchResultCard";
import LoadingText from "src/view/components/LoadingText";
import { NoResultCard } from "./SearchResultCard";
import { isJsonString, replaceHighlightTags } from "src/utils";
import { useSearch } from "src/modules/api/search";
import SearchResultPagination from "./SearchResultPagination";
import { useContext, useEffect } from "react";
import { parsePreviewFromSearchParams } from "src/modules/api/search/SearchProvider";
import actions from "../../../modules/search/view/searchViewActions";
import { useSearchParams } from "react-router-dom";
import { usePostHog } from "posthog-js/react";
import { useDispatch } from "react-redux";
import { PreviewContext } from "src/view/search-result/components/Preview/Source/PreviewContext";
import { HogEvent } from "src/types/PosthogEvents";

const StyledSearchResultContent = styled("div")(() => ({
  height: "calc(100% - 230px)",
  overflowY: "auto",
  padding: "20px 26px 20px 27px",
}));

const StyledContainer = styled("div")(() => ({
  width: "100%",
  height: "calc(100% - 230px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
}));

const StyledNoData = styled("div")(() => ({
  width: "100%",
  height: "calc(100% - 230px)",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  color: Colors.twilight,
}));

const StyledLoadingMessage = styled(Typography)(() => ({
  fontSize: 16,
  lineHeight: "23px",
  fontWeight: "bold",
  color: Colors.textGray300,
  marginBottom: 10,
  width: 64,
}));

function parseDataIndex(_index: string) {
  return _index.split("_v", 2)[0] as DataIndex;
}

function getTypeLabelForIndex<T extends DataIndex>(hit: Hit<T>): string {
  return (
    {
      source_data: hit._source.doc_type,
      modeled_data: hit._source["schema"],
    } as const
  )[parseDataIndex(hit._index)];
}

function getFileNameForIndex<T extends DataIndex>(hit: Hit<T>): string {
  return (
    {
      source_data: hit._source.file_name,
      modeled_data: hit._source["fileName"] ? hit._source["fileName"][0] : "",
    } as const
  )[parseDataIndex(hit._index)];
}

export function prepSearchResultData<T extends DataIndex>(
  hit: Hit<T>
): Record<string, any> {
  let _data =
    parseDataIndex(hit._index) === DataIndex.modeled
      ? hit._source
      : typeof hit._source.content == "string" &&
        isJsonString(hit._source.content)
      ? JSON.parse(hit._source.content)
      : hit._source.content;

  if (
    hit.highlight?.content &&
    Array.isArray(hit.highlight.content) &&
    ["eml", "msg", "json", "jsonl", "csv", "parquet"].includes(
      hit._source.doc_type
    )
  ) {
    _data = hit._source.content;
    hit.highlight.content.forEach((el: string) => {
      const h_txt = replaceHighlightTags(el);
      const r_txt = el.replace(/<\/em>/g, "");
      _data = _data.replace(r_txt, h_txt);
    });
    _data = JSON.parse(_data);
  }

  return _data;
}

function getDatasetId<T extends DataIndex>(hit: Hit<T>) {
  return Array.isArray(hit._source.dataset_id)
    ? hit._source.dataset_id[0]
    : hit._source.dataset_id;
}

export default function SearchResultContent() {
  const { data, activePageIndex, status, isFetching } = useSearch();
  const posthog = usePostHog();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { setSelectedResult } = useContext(PreviewContext);

  // sync preview state with URL (respecting history)
  useEffect(() => {
    const parsedPreview = parsePreviewFromSearchParams(searchParams);
    if (parsedPreview) {
      const { _id, source_type, type, data } = parsedPreview;
      if (data?.[0]) setSelectedResult(data[0]);
      posthog.capture(
        source_type === 1
          ? HogEvent.SOURCE_DATA_PREVIEWED
          : HogEvent.MODEL_DATA_PREVIEWED
      );
      dispatch(actions.getDocumentPreview(_id, source_type, type));
      dispatch({
        type: actions.SET_PREVIEW_DOC,
        payload: parsedPreview,
      });
    } else {
      setSelectedResult(null);
      dispatch({
        type: actions.SET_PREVIEW_DOC,
        payload: null,
      });
      dispatch({
        type: actions.GET_PREVIEW_DOCUMENT,
        payload: null,
      });
    }
  }, [searchParams]);

  if (isFetching) {
    return (
      <StyledSearchResultContent>
        <StyledContainer>
          <StyledLoadingMessage>
            <LoadingText />
          </StyledLoadingMessage>
          <CircularProgress />
        </StyledContainer>
      </StyledSearchResultContent>
    );
  }

  if (status === "error") {
    return null;
  }

  const page = data.pages[activePageIndex];

  if (!page) {
    return null;
  }

  return (
    <StyledSearchResultContent>
      {page.total_counts > 0 ? (
        page.hits.hits.map((hit) => {
          const data = prepSearchResultData(hit);
          return (
            <SearchResultCard
              id={hit._id}
              key={hit._id}
              fileName={getFileNameForIndex(hit)}
              data={data}
              highlight={hit.highlight}
              type={getTypeLabelForIndex(hit)}
              datasetId={getDatasetId(hit)}
              source_type={
                parseDataIndex(hit._index) === DataIndex.modeled ? 0 : 1
              }
            />
          );
        })
      ) : (
        <StyledNoData>
          <NoResultCard />
        </StyledNoData>
      )}
      <SearchResultPagination />
    </StyledSearchResultContent>
  );
}
