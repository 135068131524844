import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import SearchHeader from "src/view/layout/SearchHeader";
import IndexNewDataset from "src/view/indexing/indexNewDataset/IndexNewDataset";
import DatasetList from "src/view/indexing/datasetList/IndexingPage";
import { Role } from "src/types/Auth";
import { useUser } from "src/modules/api/auth";

const Indexing = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  useEffect(() => {
    if (user && user.role !== Role.ADMIN) {
      navigate("/");
    }
  }, [user]);

  return (
    <>
      {/*TODO: move search header to top level to get rid of such files?
      AND having to add top margin on 104px on each view */}
      <SearchHeader />
      <Routes>
        <Route path="/" element={<DatasetList />} />
        <Route path="/add" element={<IndexNewDataset />} />
      </Routes>
    </>
  );
};

export default Indexing;
