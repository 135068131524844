import Service from "../service";
import Errors from "src/modules/shared/error/errors";
import { AnyAction, Dispatch } from "redux";
import { AppState } from "src/modules/store";
import { KnowledgeWiki, WikiFilter } from "src/types/KnowledgeWiki";
import { Dataset } from "src/types/Dataset";

const prefix = "KNOWLEDGE_WIKI";

const Actions: AnyAction = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,
  LOADING: `${prefix}_LOADING`,
  GET_ITEM_DETAIL: `${prefix}_GET_ITEM_DETAIL`,
  DETAIL_UPDATED: `${prefix}_DETAIL_UPDATED`,
  DETAIL_DELETED: `${prefix}_DETAIL_DELETED`,
  CLEAR_UPDATE_STATUS: `${prefix}_CLEAR_UPDATE_STATUS`,
  UPDATE_FILTER: `${prefix}_UPDATE_FILTER`,
  UPDATE_SEARCH: `${prefix}_UPDATE_SEARCH`,

  find:
    (q: string, type = "") =>
    async (dispatch: Dispatch<AnyAction>) => {
      try {
        dispatch({
          type: Actions.FIND_STARTED,
        });

        const res = await Service.find(q);
        if (res.error) {
          dispatch({
            type: Actions.FIND_ERROR,
            payload: res.detail,
          });
        } else {
          if (type == "tools") {
            dispatch({
              type: Actions.GET_TOOLS,
              payload: res,
            });
          } else {
            dispatch({
              type: Actions.FIND_SUCCESS,
              payload: res,
            });
          }
        }
        return res;
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: Actions.FIND_ERROR,
          payload: error,
        });
      }
    },
  getItemDetail: (id: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({
        type: Actions.LOADING,
        payload: true,
      });

      const res = await Service.getItemDetail(id);
      dispatch({
        type: Actions.LOADING,
        payload: false,
      });
      if (!res.error) {
        dispatch({
          type: Actions.GET_ITEM_DETAIL,
          payload: res,
        });
      }
      return res;
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: Actions.LOADING,
        payload: false,
      });
    }
  },
  getRecordCountForKnowledgeWiki:
    (id: string) => async (dispatch: Dispatch<AnyAction>) => {
      try {
        const res = await Service.getRecordCountForKnowledgeWiki(id);
        if (res.error) {
          return null;
        }
        dispatch({
          type: Actions.LOADING,
          payload: false,
        });
        return res;
      } catch (error) {
        Errors.handle(error);
      }
    },
  deleteKnowledgeWiki:
    (id: string) =>
    async (dispatch: Dispatch<AnyAction>, getState: () => AppState) => {
      try {
        dispatch({
          type: Actions.LOADING,
          payload: true,
        });

        const res = await Service.deleteKnowledgeWiki(id);

        dispatch({
          type: Actions.LOADING,
          payload: false,
        });

        if (!res?.error) {
          const data = (getState().knowledge_wiki.view as unknown)["data"];
          dispatch({
            type: Actions.FIND_SUCCESS,
            payload: data.filter((item: KnowledgeWiki) => item.id !== id),
          });
        }
        return res;
      } catch (error) {
        Errors.handle(error);
      }
    },
  updateKnowledgeWiki:
    (data: unknown) => async (dispatch: Dispatch<AnyAction>) => {
      try {
        dispatch({
          type: Actions.LOADING,
          payload: true,
        });

        const res = await Service.updateKnowledgeWiki(data);
        dispatch({
          type: Actions.LOADING,
          payload: false,
        });
        if (!res?.error) {
          dispatch({
            type: Actions.DETAIL_UPDATED,
          });
        }
        return res;
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: Actions.LOADING,
          payload: false,
        });
      }
    },
  updateDataset: (data: Dataset) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({
        type: Actions.LOADING,
        payload: true,
      });

      const res = await Service.updateDataset(data);
      if (!res?.error) {
        dispatch({
          type: Actions.DETAIL_UPDATED,
        });
      }
      return res;
    } catch (error) {
      Errors.handle(error);
    } finally {
      dispatch({
        type: Actions.LOADING,
        payload: false,
      });
    }
  },
  deleteDataset:
    (id: string) =>
    async (dispatch: Dispatch<AnyAction>, getState: () => AppState) => {
      try {
        dispatch({
          type: Actions.LOADING,
          payload: true,
        });

        const res = await Service.deleteDataset(id);
        if (!res?.error) {
          const data = (getState().knowledge_wiki.view as unknown)["data"];
          dispatch({
            type: Actions.FIND_SUCCESS,
            payload: data.filter((item: KnowledgeWiki) => item.dataset !== id),
          });
        }
        return res;
      } catch (error) {
        Errors.handle(error);
      } finally {
        dispatch({
          type: Actions.LOADING,
          payload: false,
        });
      }
    },
  updateFilter: (filter: WikiFilter) => ({
    type: Actions.UPDATE_FILTER,
    payload: filter,
  }),
  updateSearch: (search: string) => ({
    type: Actions.UPDATE_SEARCH,
    payload: search,
  }),
  type: undefined,
};

export default Actions;
