import Multiselect from "multiselect-react-dropdown";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { Colors } from "src/view/constants/colors";
import { SelectOption } from "src/types/Shared";

const StyledMultiselect = styled(Multiselect)(() => ({
  fontFamily: "Inter",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "20px",
  color: "#043D5D",
  width: "100%",
  "& .searchBox ": {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "20px",
    color: "#043D5D",
    minHeight: "35px",
    paddingLeft: 5,
  },
  "& .searchWrapper": {
    border: "none",
  },
}));

const StyledMultiSelectWrapper = styled("div")(() => ({
  width: "100%",
  background: "#FFFFFF",
  border: "1px solid rgba(65, 64, 66, 0.16)",
  borderRadius: 8,
  marginBottom: 10,
}));

const StyledLabel = styled(Typography)(() => ({
  fontSize: 12,
  lineHeight: "15px",
  fontWeight: "400",
  color: Colors.textGray400,
  marginBottom: 10,
}));

interface Props {
  placeholder?: string;
  name: string;
  label?: string;
  labelStyle?: StyleSheetList;
  inputContainerStyle?: StyleSheetList;
  hideSelectedList?: boolean;
  showCheckbox?: boolean;
  options?: Array<unknown>;
  onChange?: (value: Array<string | number>) => void;
  disabled?: boolean;
  selectedValues?: SelectOption[];
  fullWidth?: boolean;
}

const MySelect = (props: Props) => {
  const {
    placeholder = "Select",
    label = null,
    inputContainerStyle = {},
    hideSelectedList = false,
    showCheckbox = false,
    options,
    onChange,
    disabled,
    selectedValues,
  } = props;

  const handleChangeSelected = (props: SelectOption[]) => {
    const values = props.map((item: SelectOption) => item.value);
    onChange(values);
  };

  return (
    <Box>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledMultiSelectWrapper style={{ ...inputContainerStyle }}>
        <StyledMultiselect
          placeholder={placeholder}
          displayValue="label"
          hideSelectedList={hideSelectedList}
          onRemove={handleChangeSelected}
          onSelect={handleChangeSelected}
          options={options}
          showCheckbox={showCheckbox}
          selectedValues={selectedValues || []}
          style={{
            chips: {
              background: Colors.secondary,
            },

            searchBox: {},
          }}
          disable={disabled}
        />
      </StyledMultiSelectWrapper>
    </Box>
  );
};

export default MySelect;
