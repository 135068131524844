import { ChangeEvent, useRef, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { snackbar } from "src/view/toaster";
import UploadIconUrl from "src/assets/images/cloud-upload.png";
import ImportIconUrl from "src/assets/images/import.png";
import BulkSearchService from "src/modules/bulk-search/bulkSearchService";

const Wrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: 24,
  borderRadius: 4,
  border: "1px solid #CED3D9",
  padding: 12,
});

const ImportButton = styled(LoadingButton)({
  height: 36,
  backgroundColor: "#FFFFFF",
  lineHeight: 1,
  fontWeight: "700",
  padding: "0 15px",
  textTransform: "capitalize",

  "&:disabled .MuiButton-startIcon:not(.MuiLoadingButton-startIconLoadingStart)":
    {
      opacity: 0.5,
    },

  ".MuiLoadingButton-loadingIndicator": {
    left: 12,
  },
});

interface Props {
  groupId: string;
  groupName: string;
  screeningListId: string;
  onBack: () => void;
}

const ImportCSV = ({ screeningListId, groupName, onBack, groupId }: Props) => {
  const csvFileRef = useRef<HTMLInputElement>();
  const [importing, setImporting] = useState(false);

  const handleChangeInput = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    if (!file) return;

    const postData = new FormData();
    if (groupId) {
      postData.append("query_group_id", groupId);
    }
    postData.append("file", file);
    postData.append("query_group_name", groupName);
    postData.append("screening_list_id", screeningListId);

    setImporting(true);
    try {
      const response = await BulkSearchService.importCSV(postData);

      if (response.error) {
        snackbar.error(response.detail);
      } else {
        snackbar.success("Successfully imported");
        onBack();
        return;
      }
    } catch (error) {
      snackbar.error("Failed to import CSV.");
    }
    setImporting(false);
  };

  return (
    <Wrapper>
      <img src={UploadIconUrl} alt="CloudUpload" width="24" height="24" />
      <Typography fontSize={14} mr="auto">
        Import search terms for this query group via a CSV file, or export the
        search terms to a CSV file.
      </Typography>
      <ImportButton
        startIcon={
          <img
            style={{ transform: "rotate(180deg)" }}
            src={ImportIconUrl}
            alt="Import"
            width="18"
            height="18"
          />
        }
      >
        Export
      </ImportButton>
      <ImportButton
        disabled={!groupName}
        loadingPosition="start"
        startIcon={
          <img src={ImportIconUrl} alt="Import" width="18" height="18" />
        }
        onClick={() => csvFileRef.current.click()}
        loading={importing}
      >
        Import
      </ImportButton>
      <input
        ref={csvFileRef}
        accept=".csv"
        multiple={false}
        type="file"
        onChange={handleChangeInput}
        style={{ display: "none" }}
      />
    </Wrapper>
  );
};

export default ImportCSV;
