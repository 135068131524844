import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  Button,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import { Colors } from "src/view/constants/colors";
import CreateGroupDialog from "./groups/CreateGroupDialog";
import GroupsTable from "./groups/GroupsTable";
import UsersTable from "./users/UsersTable";
import SearchBox from "src/view/users-groups/SearchBox";
import { Role } from "src/types/Auth";
import { useUser } from "src/modules/api/auth";
import { AntTab, AntTabs } from "./groups/EditGroup/StyledComponents";

const Wrapper = styled(Container)({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  paddingTop: 40,
  paddingBottom: 100,
});

const Title = styled(Typography)({
  fontSize: 32,
  fontWeight: 700,
  color: Colors.textDark,
});

const Description = styled(Typography)({
  fontSize: 14,
  lineHeight: "24px",
  color: Colors.textDark,
  maxWidth: 800,
  marginBottom: "30px",
});

const AddButton = styled(Button)({
  backgroundColor: "#F75151",
  color: "#ffffff",
  fontWeight: "700",
  padding: "0 15px",
  height: 46,
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#F7515188",
  },
});

interface TabPanelProps {
  active: boolean;
  children?: React.ReactNode;
}

const TabPanel = ({ active, children }: TabPanelProps) => {
  const ref = useRef(false);
  ref.current = ref.current || active;
  return <Box hidden={!active}>{ref.current && children}</Box>;
};

const ScaffoldingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUser();
  const mobileView = useMediaQuery("(max-width:678px)");
  const [tab, setTab] = useState<"users" | "groups">(
    location.state?.tab || "groups"
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [groupsSearchInput, setGroupsSearchInput] = useState("");
  const [usersSearchInput, setUsersSearchInput] = useState("");
  const [groupsSearchKey, setGroupsSearchKey] = useState("");
  const [usersSearchKey, setUsersSearchKey] = useState("");

  const onCreateNew = () => {
    setOpenDialog(true);
  };

  const handleChangeTab = (event: React.SyntheticEvent, tabIndex: number) => {
    const newTab = tabIndex === 0 ? "groups" : "users";
    setTab(newTab);
    navigate("", { replace: true, state: { tabIndex, tab: newTab } });
  };

  if (!user)
    return (
      <Wrapper sx={{ mt: mobileView ? "149px" : "104px" }}>
        <Box textAlign="center" py="50px">
          <CircularProgress />
        </Box>
      </Wrapper>
    );

  return (
    <Wrapper sx={{ mt: mobileView ? "149px" : "104px" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Title variant="h4">Users &amp; Groups</Title>
        {user.role === Role.ADMIN && (
          <>
            <AddButton onClick={onCreateNew} startIcon={<AddIcon />}>
              Add Group
            </AddButton>

            <CreateGroupDialog
              open={openDialog}
              onClose={() => setOpenDialog(false)}
            />
          </>
        )}
      </Box>

      <Description>
        View, delete, and edit users and groups in Seamless Horizons. Add new
        users manually or in bulk, modify data and feature access, and view
        summary information as a group admin.
      </Description>

      <Box
        sx={{
          borderBottom: "1px solid #e8e8e8",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <AntTabs value={tab === "groups" ? 0 : 1} onChange={handleChangeTab}>
          <AntTab label="Groups" />
          {user?.role === Role.ADMIN && <AntTab label="Users" />}
        </AntTabs>

        {tab === "groups" ? (
          <SearchBox
            searchInput={groupsSearchInput}
            setSearchInput={setGroupsSearchInput}
            setSearchKey={setGroupsSearchKey}
            placeholder={"Search Groups"}
          />
        ) : (
          <SearchBox
            searchInput={usersSearchInput}
            setSearchInput={setUsersSearchInput}
            setSearchKey={setUsersSearchKey}
            placeholder={"Search Users"}
          />
        )}
      </Box>

      <TabPanel active={tab === "groups"}>
        <GroupsTable searchKey={groupsSearchKey} />
      </TabPanel>
      <TabPanel active={tab === "users"}>
        <UsersTable searchKey={usersSearchKey} />
      </TabPanel>
    </Wrapper>
  );
};

export default ScaffoldingPage;
