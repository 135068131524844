import React, { useState } from "react";
import { Box, Typography, Button, styled } from "@mui/material";
import { ReactComponent as IconUpload } from "src/assets/icons/upload_bracket.svg";

import OCRTaskTable from "src/view/ocr/components/OCRTaskTable";
import MyTextField from "src/view/components/MyTextField";
import SearchHeader from "src/view/layout/SearchHeader";
import SearchIconUrl from "src/assets/images/search-primary.png";
import { useOCRTasks } from "src/modules/api/ocr";
import OCRUploadModal from "src/view/ocr/components/OCRUploadModal";

const Wrapper = styled(Box)(() => ({
  // TODO: move search header to top-level route
  marginTop: 127,
  paddingBottom: 100,
  paddingLeft: "64px",
  paddingRight: "64px",
}));
const Header = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "40px",
}));
const StyledHeader = styled("h3")(() => ({
  fontSize: 32,
  fontWeight: "700",
  margin: 0,
  marginBottom: "8px",
}));
const JobSearchHeader = styled(Box)(() => ({
  fontSize: 32,
  fontWeight: "700",
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "24px",
}));

const UploadButton = styled(Button)(() => ({
  minWidth: "106px",
  height: "46px",
  padding: "14px",
  borderRadius: "4px",
  color: "#FFF",
  background: "#F75151",
  textTransform: "none",
  "&:hover": {
    background: "#F75151",
  },
}));

const OCRPage = () => {
  // const [searchKey, setSearchKey] = useState("");
  // const [searchInput, setSearchInput] = useState("");
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(() => false);
  const { getOCRTasks } = useOCRTasks();
  const { data, isLoading } = getOCRTasks({});

  // const handleSearch = () => {
  //   setSearchKey(searchInput);
  // };

  return (
    <>
      <SearchHeader />
      <Wrapper>
        <Header>
          <Box>
            <StyledHeader>Document OCR</StyledHeader>
            <Typography
              sx={{
                maxWidth: "70%",
                fontSize: "14px",
              }}
            >
              Our Optical Character Recognition (OCR) service allows you to
              seamlessly transform scanned text and images into editable,
              searchable data.
            </Typography>
          </Box>

          <UploadButton
            onClick={() => setIsUploadModalOpen(true)}
            startIcon={<IconUpload />}
          >
            Upload
          </UploadButton>
        </Header>
        <JobSearchHeader>
          <Typography
            sx={{
              fontWeight: 700,
            }}
          >
            Jobs
          </Typography>
          <MyTextField
            containerStyle={{
              display: "none",
              width: "25%",
              minWidth: "200px",
              height: "36px",
              "& div": { height: "36px", borderRadius: "4px" },
              "& input": {
                fontSize: "14px",
                height: "16px",
              },
              "& input::placeholder": {
                color: "#122945",
                opacity: 1,
                fontWeight: "400",
              },
            }}
            prefixIcon={
              <img
                src={SearchIconUrl}
                width="16"
                height="16"
                style={{ cursor: "pointer" }}
                // onClick={handleSearch}
              />
            }
            placeholder={"Search jobs"}
            // onChange={(e) => setSearchInput(e.target.value)}
            // onKeyPress={(e) => e.key == "Enter" && handleSearch()}
          />
        </JobSearchHeader>
        <OCRTaskTable tasks={data} isLoading={isLoading} />
        <OCRUploadModal
          isOpen={isUploadModalOpen}
          handleClose={() => setIsUploadModalOpen(false)}
        />
      </Wrapper>
    </>
  );
};

export default OCRPage;
