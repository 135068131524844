import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { snackbar } from "src/view/toaster";
import groupActions from "src/modules/groups/view/groupsViewActions";
import { User } from "src/types/UserGroup";
import selector from "src/modules/groups/view/groupsViewSelectors";
import { getFullName } from "src/utils";
import DeleteIconUrl from "src/assets/images/delete.png";
import SearchBox from "./SearchBox";
import UserList from "./UserList";

const Wrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: 370,
  height: "100%",
});

const Header = styled(Box)({
  display: "flex",
  alignItems: "center",
  minHeight: 64,
  borderBottom: "solid 1px #A0B9D0",
  paddingLeft: 8,
});

const Item = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingLeft: "16px",
  boxSizing: "border-box",
  color: "#122945",
  fontSize: "14px",
  background: "#F0F1F3",
  borderRadius: "6px",
  margin: "8px 15px",
  padding: "12px 16px",
});

const GrantButton = styled(Button)({
  backgroundColor: "#F75151",
  color: "#ffffff",
  fontWeight: "700",
  padding: "0 10px",
  minHeight: 38,
  margin: 14,
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#F7515188",
  },
  "&:disabled": {
    background: "#E1E4E8",
    color: "#FFFFFF",
  },
});

interface Props {
  onClose: () => void;
}

const Content = ({ onClose }: Props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [openSearchDropdown, setOpenSearchDropdown] = useState(false);
  const [groupAdmins, setGroupAdmins] = useState<User[]>([]);
  const selectedGroup = useSelector(selector.selectSelectedGroup);
  const [searchKey, setSearchKey] = useState("");
  const refSearch = useRef<HTMLDivElement>();

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (!refSearch.current.contains(e.target as Node)) {
        setOpenSearchDropdown(false);
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const searchUser = useCallback((key: string) => {
    setSearchKey(key);
    setOpenSearchDropdown(!!key);
  }, []);

  const addAdmin = useCallback((user: User) => {
    setGroupAdmins((old) => (old.indexOf(user) == -1 ? [...old, user] : old));
  }, []);

  const removeAdmin = (userId: string) => {
    setGroupAdmins((old) => old.filter(({ id }) => id !== userId));
  };

  const handleGrantAccess = async () => {
    const newIds = groupAdmins.map((user) => user.id);

    setLoading(true);
    const res = await dispatch(
      groupActions.updateGroup(
        {
          group_admins: [...selectedGroup.group_admins, ...newIds],
        },
        selectedGroup.group_id
      )
    );
    setLoading(false);

    if (!res.error) {
      snackbar.success("Successfully granted admin access to selected users.");
    }
    onClose();
  };

  return (
    <Wrapper>
      <Header>
        <IconButton size="small" color="inherit" onClick={onClose}>
          <ArrowBackIcon />
        </IconButton>
        <Typography
          variant="h6"
          fontSize="16px"
          fontWeight="700"
          color="#043D5D"
          pl={1}
          mr="auto"
        >
          Add Admin
        </Typography>
        <IconButton size="large" color="inherit" onClick={onClose}>
          <Close />
        </IconButton>
      </Header>

      <Box ref={refSearch} m="14px">
        <SearchBox onSearch={searchUser} />
        {openSearchDropdown && (
          <UserList
            key={searchKey}
            searchKey={searchKey}
            groupAdminIds={selectedGroup?.group_admins as string[]}
            selectedUsers={groupAdmins}
            onAdd={addAdmin}
          />
        )}
      </Box>

      <Box overflow="auto">
        {groupAdmins?.map((user) => {
          return (
            <Item key={user.id}>
              <Box display="flex" flexDirection="column">
                <strong>{getFullName(user) || "--"}</strong>
                <span>{user.email}</span>
              </Box>
              <IconButton size="small" onClick={() => removeAdmin(user.id)}>
                <img src={DeleteIconUrl} width="18" height="18" />
              </IconButton>
            </Item>
          );
        })}
      </Box>

      <GrantButton
        startIcon={loading && <CircularProgress size={20} color="inherit" />}
        disabled={loading || !groupAdmins.length}
        onClick={handleGrantAccess}
      >
        Grant Admin Access
      </GrantButton>
    </Wrapper>
  );
};

export default Content;
