import { styled } from "@mui/material/styles";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Colors } from "src/view/constants/colors";
import { SelectOption } from "src/types/Shared";
import { useEffect, useState } from "react";

const StyledSelect = styled(Select)(() => ({
  fontFamily: "Inter",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "20px",
  color: "#043D5D",
  width: "100%",
  "& .searchBox ": {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "20px",
    color: "#043D5D",
    minHeight: "35px",
    paddingLeft: 5,
  },
}));

const StyledMultiSelectWrapper = styled("div")(() => ({
  borderRadius: 8,
  marginBottom: 10,
  "&>div": {
    borderRadius: 8,
    height: 50,
  },
}));

const StyledLabel = styled(Typography)(() => ({
  fontSize: 12,
  lineHeight: "15px",
  fontWeight: "400",
  color: Colors.textDark100,
  marginBottom: 10,
}));
interface Props {
  label?: string;
  name?: string;
  placeholder?: string;
  value?: string | number;
  initialValue?: string | number;
  labelStyle?: React.CSSProperties;
  inputContainerStyle?: React.CSSProperties;
  options?: Array<SelectOption>;
  onChange?: (e: string) => void;
  fullWidth?: boolean;
  valueKey?: string;
}

const CustomSelect = (props: Props) => {
  const {
    placeholder = "Select",
    label = null,
    inputContainerStyle = {},
    options,
    onChange,
    initialValue,
    valueKey = "value",
  } = props;

  const [selectedOption, setSelectedOption] = useState(initialValue || "");

  const handleChangeSelected = (e: unknown) => {
    setSelectedOption(e["target"]["value"]);
    onChange(e["target"]["value"]);
  };

  useEffect(() => {
    setSelectedOption(initialValue);
  }, [initialValue]);

  return (
    <Box>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledMultiSelectWrapper style={{ ...inputContainerStyle }}>
        <FormControl
          sx={{
            width: "100%",
            "& .MuiInputLabel-shrink": { display: "none" },
            "& .MuiSelect-select": { minHeight: "1rem" },
            "& .MuiInputBase-root": {
              borderRadius: "8px !important",
              height: "48px",
            },
          }}
        >
          <InputLabel
            id="simple-select-label"
            sx={{
              color: "#122945",
              fontSize: "14px",
              lineHeight: "17px",
              fontWeight: "700",
            }}
            shrink
          >
            {placeholder}
          </InputLabel>
          <StyledSelect
            labelId="simple-select-label"
            id="simple-select"
            placeholder={placeholder}
            value={selectedOption}
            defaultValue={selectedOption}
            onChange={handleChangeSelected}
            style={{ fontSize: "12px", color: "#122945", fontWeight: "700" }}
          >
            {options?.length > 0 &&
              options.map((item: SelectOption) => {
                return (
                  <MenuItem key={item[valueKey]} value={item[valueKey]}>
                    {item.label}
                  </MenuItem>
                );
              })}
          </StyledSelect>
        </FormControl>
      </StyledMultiSelectWrapper>
    </Box>
  );
};

export default CustomSelect;
