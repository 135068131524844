import { useRef, useState, useLayoutEffect } from "react";
import LoadMoreButton from "./SearchResultCardPreview/LoadMoreButton";
import { Box, BoxProps, Skeleton, Typography, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import DataTypeIcon from "./DataTypeIcon";
import FilterTag from "./FilterTag";
import { format, isValid } from "date-fns";
import * as ftm from "@alephdata/followthemoney";
import { Schema } from "@alephdata/followthemoney";
import { titleCase } from "src/utils";
import { useFilterOptions } from "src/modules/api/filters";

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  lineHeight: "16px",
  fontWeight: "700",
  color: theme.palette.primary.light,
  cursor: "pointer",
}));

const StyledSubtitle = styled(Typography)(() => ({
  marginTop: 0.5,
  fontSize: "10px",
  color: "#536378",
}));

export interface TagBoxProps extends BoxProps {
  expand?: boolean;
}
const TagBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "expand",
})<TagBoxProps>(({ expand, theme }) => ({
  width: "calc(100% - 80px)",
  overflow: "hidden",
  maxHeight: "22px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexWrap: "wrap",
  gap: theme.spacing(1),
  transition: theme.transitions.create(["all"], {
    duration: theme.transitions.duration.standard,
  }),
  ...(expand && {
    maxHeight: "300px",
    transition: theme.transitions.create(["all"], {
      duration: theme.transitions.duration.standard,
    }),
  }),
}));

export interface SearchResultCardHeaderProps {
  loading: boolean;
  title?: string;
  subtitle?: string;
  type?: string;
  dateAdded?: string;
  dateSnapshot?: string;
  onClick?: () => void;
  cardActions?: React.ReactNode;
  tags?: { label: string; value: string; disableHighlight?: boolean }[];
  TagsComponent?: React.ReactNode;
  source_type?: number;
  schema?: Schema["name"];
}

const SearchResultCardHeader = ({
  loading,
  title,
  subtitle = "",
  type,
  dateAdded,
  onClick,
  cardActions = <div />,
  tags = [],
  source_type,
  schema,
}: SearchResultCardHeaderProps) => {
  const { countries } = useFilterOptions();
  const ref = useRef<HTMLDivElement>();
  const [isOverflow, setIsOverflow] = useState<boolean>(false);
  const [expand, setExpand] = useState<boolean>(false);
  useLayoutEffect(() => {
    const { current } = ref;

    if (current) {
      const trigger = () => {
        setExpand(false);
        const hasOverflow = current.scrollHeight > current.clientHeight;
        setIsOverflow(hasOverflow);
      };

      window.addEventListener("resize", trigger);
      trigger();
      return () => window.removeEventListener("resize", trigger);
    }
  }, [ref]);

  const toggleExpand = () => {
    setExpand(!expand);
  };

  return (
    <Box
      id="card-header"
      sx={{
        width: "100%",
        display: "flex",
        gap: 2,
      }}
    >
      <Box id="card-header-gutter" sx={{ height: 64, flex: "0 0 64px" }}>
        {loading ? (
          <Skeleton variant="rounded" width={64} height={64} />
        ) : (
          <DataTypeIcon type={type} onClick={onClick} />
        )}
      </Box>
      <Box
        id="card-header-content"
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Box id="card-title-box">
            {loading ? (
              <>
                <Skeleton variant="text" width={300} />
                <Skeleton variant="text" width={100} />
              </>
            ) : (
              <>
                {Array.isArray(title) && title.length > 1 ? (
                  <Tooltip
                    title={
                      <Typography
                        style={{
                          maxHeight: "100px",
                          overflowY: "auto",
                          fontSize: "10px",
                        }}
                      >
                        {title.join(",\n")}
                      </Typography>
                    }
                    placement={"bottom"}
                  >
                    <Box>
                      <StyledTitle onClick={onClick}>
                        {title[0] + " ..."}
                      </StyledTitle>
                    </Box>
                  </Tooltip>
                ) : (
                  <StyledTitle onClick={onClick}>{title}</StyledTitle>
                )}
                <StyledSubtitle>{subtitle}</StyledSubtitle>
              </>
            )}
          </Box>
          <Box
            id="card-action-box"
            sx={{ ml: "auto", display: "flex", gap: 1 }}
          >
            {cardActions}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 1,
          }}
        >
          <TagBox id="card-tag-box" expand={expand} ref={ref}>
            {tags.map((props, i: number) => {
              let { value } = props;
              if (
                ["jurisdiction", "nationality", "main_country"].includes(
                  props.label
                )
              ) {
                const country = countries.find(
                  (country) =>
                    country.label.toLowerCase() == value?.toLowerCase()
                );
                if (country) {
                  value = country.value;
                }
              }

              const keyValueQuery = `${props.label}:"${value}"`;
              const ftmPropertyLabel =
                ftm.defaultModel.schemata?.[schema]?.properties?.[props.label]
                  ?.label;
              const titleCaseLabel = titleCase(props.label);

              return (
                <FilterTag
                  {...props}
                  compact
                  key={`${keyValueQuery}-${i}`}
                  {...(source_type === 0 && {
                    label: ftmPropertyLabel ?? titleCaseLabel,
                    highlightKeyValueQuery: keyValueQuery,
                  })}
                />
              );
            })}
          </TagBox>
          <LoadMoreButton
            expand={expand}
            isOverflow={isOverflow}
            onClick={toggleExpand}
          />
          <Box id="card-date-box" sx={{ textAlign: "right" }}>
            {isValid(new Date(dateAdded)) && (
              <Typography
                variant="body1"
                style={{
                  whiteSpace: "nowrap",
                  fontSize: 10,
                  lineHeight: "12px",
                  color: "#7A8796",
                  fontWeight: "400",
                }}
              >
                Added on: {`${format(new Date(dateAdded), "MM/dd/yyyy")}`}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

SearchResultCardHeader.displayName = "SearchResultCardHeader";

export default SearchResultCardHeader;
