import { UserPermissions } from "src/types/Auth";

export interface User {
  admin?: boolean;
  avatar?: string;
  error?: { first_name: string; last_name: string; email: string };
  id?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  name?: string;
  role?: 1 | 0;
  permissions?: UserPermissions;
  members?: Array<User>;
  search_terms?: Array<string>;
  num_groups?: string;
  num_screening_list?: string;
  created?: string;
  group_id?: string;
  date_joined?: Date;
  user_groups?: {
    member: Array<{
      group_id: string;
      name: string;
    }>;
    admin: Array<{
      group_id: string;
      name: string;
    }>;
  };
}

export interface GroupShort {
  name: string;
  group_id: string;
  num_users: number;
  // TODO: sens levels enum
  sensitivity_levels: Array<string>;
  group_admins: {
    first_name: string;
    id: string;
    last_name: string;
  }[];
}

export enum GroupFeatures {
  DATASET = "dataset",
  BULK_SEARCH = "bulk_search",
  EXTERNAL_API = "external_api",
  SEARCH = "search",
  USER_GROUP = "user_group",
  KNOWLEDGE_WIKI = "knowledge_wiki",
  INDEXING = "indexing",
  EXTRA_KW_INFO = "extra_kw_info",
  DATA_COLLECTION = "data_collection",
  BULK_DOWNLOAD = "bulk_download",
}

export enum GroupFeatureNames {
  DATASET = "Dataset",
  BULK_SEARCH = "Bulk Search",
  EXTERNAL_API = "External API",
  SEARCH = "Search",
  USER_GROUP = "Users and Groups",
  KNOWLEDGE_WIKI = "Data Dictionary",
  INDEXING = "Indexing",
  EXTRA_KW_INFO = "Extra Data Dictionary Info",
  DATA_COLLECTION = "Data Collections",
  BULK_DOWNLOAD = "Bulk Download",
}

export interface GroupDetailed {
  data_catalogs: Array<string>;
  dataset_downloads: Array<string>;
  datasets: Array<string>;
  description: string | null;
  external_apis: Array<string>;
  features: Array<GroupFeatures>;
  group_admins: Array<string>;
  group_id: string;
  group_ptr: number;
  kw_read: Array<string>;
  kw_write: Array<string>;
  members: Array<string>;
  name: string;
  permission: UserPermissions;
  screening_lists: Array<string>;
  sensitivity_levels: Array<string>;
}

// TODO: check and remove
export interface Group {
  avatar?: string;
  id?: string;
  group_id?: string;
  name?: string;
  description?: string;
  group_admins?: string[];
  num_users?: number;
  members?: Array<User | string>;
  sensitivity_levels?: Array<string>;
  datasets?: Array<string>;
  external_apis?: Array<string>;
  features?: Array<string>;
  dataset_downloads?: Array<string>;
  kw_read?: Array<string>;
  kw_write?: Array<string>;
  screening_lists?: Array<string>;
  permissions?: Array<string>;
  dataset_type?: string;
  data_catalogs?: Array<string>;
  created?: string;
  updated?: string;
}

export enum UserFeatures {
  SEARCH_HISTORY = "search_history",
}

export enum UserFeatureNames {
  SEARCH_HISTORY = "Enable Search History",
}
