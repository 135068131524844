import { Group, User } from "src/types/UserGroup";

import { fetchApi } from "../shared/api";

export default class GroupsService {
  static async getExistingUsers({ page = "", searchKey = "" } = {}) {
    try {
      const res = await fetchApi(
        "users/?" +
          new URLSearchParams({
            ...(page && { page: page?.toString() }),
            ...(searchKey && { name_or_email: searchKey }),
          }),
        {
          method: "GET",
        }
      );
      const response = res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response["detail"],
        };
      }
    } catch (err) {
      return false;
    }
  }

  static async createGroup(data: Group) {
    try {
      const res: Response = await fetchApi("groups/", {
        method: "POST",
        body: JSON.stringify(data),
      });
      const response = res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response["detail"],
        };
      }
    } catch (err) {
      return false;
    }
  }

  static async saveUsers(data: User[]) {
    try {
      const res = await fetchApi("users/bulk/", {
        method: "POST",
        body: JSON.stringify(data),
      });
      const response = await res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response,
        };
      }
    } catch (err) {
      return false;
    }
  }

  static async resendEmail(data: User[]) {
    try {
      const res = await fetchApi("users/resend_signup_email/", {
        method: "POST",
        body: JSON.stringify(data),
      });
      const response = await res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response,
        };
      }
    } catch (err) {
      return false;
    }
  }

  static async addUsersToGroup(id: string, data: Group) {
    try {
      const res = await fetchApi("groups/" + id + "/add_objects/", {
        method: "POST",
        body: JSON.stringify(data),
      });

      const response = res;
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response["detail"],
        };
      }
    } catch (err) {
      return false;
    }
  }

  static async getGroupUsers(group_id: string) {
    try {
      const res = await fetchApi("groups/" + group_id + "/users/", {
        method: "GET",
      });
      const response = res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response["detail"],
        };
      }
    } catch (err) {
      return false;
    }
  }

  static gettingGroupDetail = false;

  static async getGroupDetails(group_id: string) {
    try {
      if (this.gettingGroupDetail) return;
      this.gettingGroupDetail = true;

      const res = await fetchApi("groups/" + group_id + "/", {
        method: "GET",
      });
      const response = res.json();
      this.gettingGroupDetail = false;
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response["detail"],
        };
      }
    } catch (err) {
      this.gettingGroupDetail = false;
      return false;
    }
  }

  static async importCSV(data: FormData) {
    try {
      const res = await fetchApi("users/csv_import/", {
        method: "POST",
        body: data,
      });
      const response = res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response["detail"],
        };
      }
    } catch (err) {
      return false;
    }
  }
  static async updateGroup(data: Group, id: string) {
    try {
      delete data.group_id;
      // delete data.name;

      const res: Response = await fetchApi("groups/" + id + "/", {
        method: "PUT",
        body: JSON.stringify(data),
      }).then((res) => res);
      if (!res) return;
      const response = res;

      if (res.status >= 200 && res.status < 300) {
        return {
          error: false,
        };
      } else {
        return {
          error: true,
          detail: response["detail"],
        };
      }
    } catch (err) {
      return false;
    }
  }
  static async addObjectsToGroup(data: Group, id: string) {
    try {
      delete data.group_id;
      delete data.name;

      const res: Response = await fetchApi("groups/" + id + "/add_objects/", {
        method: "POST",
        body: JSON.stringify(data),
      }).then((res) => res);
      if (!res) return;
      const response = res;

      if (res.status >= 200 && res.status < 300) {
        return {
          error: false,
        };
      } else {
        return {
          error: true,
          detail: response["detail"],
        };
      }
    } catch (err) {
      return false;
    }
  }
  static async removeObjectsToGroup(data: Group, id: string) {
    try {
      delete data.group_id;
      delete data.name;

      const res: Response = await fetchApi(
        "groups/" + id + "/delete_objects/",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      ).then((res) => res);
      if (!res) return;
      const response = res;

      if (res.status >= 200 && res.status < 300) {
        return {
          error: false,
        };
      } else {
        return {
          error: true,
          detail: response["detail"],
        };
      }
    } catch (err) {
      return false;
    }
  }

  static async shareScreeningListItemToGroup(
    screening_list_id: string,
    data: { group_id: string }
  ) {
    try {
      const res = await fetchApi(
        "bulk_search/screening_list/" + screening_list_id + "/add_to_group/",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );
      if (!res)
        return {
          error: false,
        };

      if (res.status >= 200 && res.status < 300) {
        return {
          error: false,
        };
      } else {
        return {
          error: true,
          detail: res,
        };
      }
    } catch (err) {
      return {
        error: false,
      };
    }
  }
  static async removeScreeningListItemFromGroup(
    group_id: string,
    data: { screening_lists: string[] }
  ) {
    try {
      const res = await fetchApi("groups/" + group_id + "/delete_objects/", {
        method: "POST",
        body: JSON.stringify(data),
      });
      if (!res)
        return {
          error: false,
        };
      const response = await res.json();

      if (res.status >= 200 && res.status < 300) {
        return {
          error: false,
        };
      } else {
        return {
          error: true,
          detail: response["detail"],
        };
      }
    } catch (err) {
      return {
        error: false,
      };
    }
  }
}
