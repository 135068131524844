import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  InputBase,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState, useEffect, useRef, ChangeEvent } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { QueryItem } from "src/types/BulkSearch";
import { DraggableProvidedDragHandleProps } from "@hello-pangea/dnd";
import useClickAway from "src/modules/shared/hooks/useClickAway";

const StyledContainer = styled("div")(() => ({
  position: "relative",
  borderRadius: 0,
  backgroundColor: "white",
  display: "flex",
  alignItems: "center",
  // justifyContent: 'center',
  width: "100%",
  paddingLeft: "10px",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: 210,
  height: 28,
  backgroundColor: "white",
  border: "1px solid #A0B9D0",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create("width"),
    width: "100%",
    fontSize: 14,
    lineHeight: "16px",
    fontWeight: "700",
  },
}));

const StyledImage = styled("img")(() => ({
  width: 15,
  height: 17,
  paddingRight: 10,
  cursor: "grab",
  "&:active": {
    cursor: "grabbing",
  },
}));

const Flex = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
}));

const Label = styled("div")(() => ({
  fontSize: 12,
  lineHeight: "17px",
  fontWeight: "400",
  color: "#536378",
  marginRight: 2,
}));

const StyledOptionButtonWrapper = styled("div")(() => ({
  position: "relative",
}));

const StyledOptionButton = styled(Button)(() => ({
  borderRadius: 60,
  backgroundColor: "white",
  boxShadow: "none",
  zIndex: 1,
  fontSize: 12,
  lineHeight: "17px",
  fontWeight: "700",
  color: "#536378",
  textTransform: "unset",
  padding: "4px 9px",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: "#ECF1F799",
    boxShadow: "none",
  },
}));

const StyledDropdown = styled("ul")(() => ({
  position: "absolute",
  width: "100%",
  background: "#FFFFFF",
  boxShadow: "0px 34px 33px rgba(0, 0, 0, 0.25)",
  marginTop: "2px",
  zIndex: 2,
  padding: 0,
}));

const FuzzyButton = (props: {
  text: number;
  handleChangeFuzziness: (value: number) => void;
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [query, setQuery] = useState("Exact");
  const menuRef = useRef(null);
  useClickAway(menuRef, () => setOpenDropdown(false));

  useEffect(() => {
    if (props.text) {
      let text = "";
      if (props.text == 0) {
        text = "Exact";
      } else {
        if (props.text == 1) text = "1 edit";
        else text = `${props.text} edits`;
      }
      setQuery(text);
    }
  }, [props]);

  const handleClose = () => {
    setOpenDropdown(false);
  };

  const handleSelectQuery = (value: number, event: unknown) => {
    setQuery(event["target"].innerText);
    props.handleChangeFuzziness(value);
    handleClose();
  };

  const handleClickButton = () => {
    if (openDropdown == false) setOpenDropdown(true);
  };

  return (
    <StyledOptionButtonWrapper>
      <StyledOptionButton
        variant="contained"
        onClick={() => handleClickButton()}
        endIcon={<ArrowDropDownIcon sx={{ color: "inherit" }} />}
      >
        {query}
      </StyledOptionButton>
      {openDropdown && (
        <StyledDropdown ref={menuRef}>
          <MenuItem onClick={(e) => handleSelectQuery(0, e)}>Exact</MenuItem>
          {[...Array(9)].map((_i: number, index: number) => {
            return (
              <MenuItem
                key={"fuzziness-menu-item-" + index}
                onClick={(e) => handleSelectQuery(index + 1, e)}
              >
                {index + 1} {index == 0 ? "edit" : "edits"}
              </MenuItem>
            );
          })}
        </StyledDropdown>
      )}
    </StyledOptionButtonWrapper>
  );
};

export default function InputBox(props: {
  query: unknown;
  index: number;
  handleChange: (query: QueryItem, index: number) => void;
  dragHandleProps?: DraggableProvidedDragHandleProps;
}) {
  const { query, index, handleChange, dragHandleProps } = props;
  const inputRef = useRef();

  const handleChangeInput = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const _query = {
      type: query["type"],
      value: e.target.value,
      fuzziness: query["fuzziness"],
    };
    handleChange(_query, index);
  };

  const handleClearInput = () => {
    const _query = {
      type: query["type"],
      value: "",
      fuzziness: 0,
    };
    handleChange(_query, index);
  };

  const deleteInputBox = () => {
    const _query: QueryItem = null;
    handleChange(_query, index);
  };

  const handleChangeFuzziness = (e: number) => {
    const _query = {
      type: query["type"],
      value: query["value"],
      fuzziness: e,
    };
    handleChange(_query, index);
  };

  return (
    <StyledContainer>
      <StyledImage
        src={require("../../../../assets/images/drag-handler.png")}
        {...dragHandleProps}
      />
      <StyledInputBase
        ref={inputRef}
        // inputRef={(ref) => ref = inputRef}
        placeholder="Search…"
        inputProps={{ "aria-label": "search" }}
        onChange={(e) => handleChangeInput(e)}
        value={query["value"]}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle visibility"
              edge="start"
              size="small"
              onClick={() => handleClearInput()}
            >
              {query["value"] ? <Close /> : null}
            </IconButton>
          </InputAdornment>
        }
      />
      {/* <StyledMoveIconsWrapper>
                <StyledArrowIcon
                    onClick={() => handleMoveQuery(-1)}
                >
                    <ArrowRightAlt 
                        sx={{
                            transform: 'rotate(-90deg)',
                            width: '22px',
                            height: '22px',
                            color: '#757575'
                        }}
                    />
                </StyledArrowIcon>
                <StyledArrowIcon
                    onClick={() => handleMoveQuery(1)}
                >
                    <ArrowRightAlt 
                        sx={{
                            transform: 'rotate(90deg)',
                            width: '22px',
                            height: '22px',
                            color: '#757575'
                        }}
                    />
                </StyledArrowIcon>
            </StyledMoveIconsWrapper> */}
      <Flex sx={{ mr: "12px", ml: "12px" }}>
        <Label>Fuzziness: </Label>
        <FuzzyButton
          text={query["fuzziness"]}
          handleChangeFuzziness={handleChangeFuzziness}
        />
      </Flex>
      <img
        src={require("src/assets/images/delete-secondary.png")}
        style={{ width: 17, height: 17, cursor: "pointer" }}
        onClick={deleteInputBox}
      />
    </StyledContainer>
  );
}
