import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "../constants/colors";
import CloseIcon from "@mui/icons-material/Close";
import { SyntheticEvent, useEffect, useState } from "react";
import actions from "src/modules/groups/view/groupsViewActions";
import { useDispatch } from "react-redux";
import { snackbar } from "src/view/toaster";
import screeningActions from "src/modules/bulk-search/screeningList/screeningActions";
import { GroupShort } from "src/types/UserGroup";
import { ScreeningList } from "src/types/BulkSearch";
import { useUserGroups } from "src/modules/api/userGroups";

const StyledDialogTitle = styled(DialogTitle)(() => ({
  fontSize: 18,
  // lineHeight: '20px',
  fontWeight: "700",
  color: Colors.twilight,
  paddingBottom: 8,
}));

const DialogSubTitle = styled(Typography)(() => ({
  color: "rgba(90, 114, 144, 0.7)",
  fontSize: 12,
  lineHeight: "20px",
  fontWeight: "700",
  paddingLeft: 24,
  paddingBottom: 16,
}));

const StyledShareButton = styled(Button)(() => ({
  borderRadius: 4,
  backgroundColor: Colors.secondary2,
  border: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  fontSize: 14,
  lineHeight: "17px",
  margin: 5,
  fontWeight: "700",
  width: 100,
  height: 40,
  padding: 15,
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: Colors.secondary,
  },
}));

interface Props {
  open: boolean;
  title?: string;
  onClose?: () => void;
  data?: ScreeningList;
}
const ShareDialog = (props: Props) => {
  const { open, title, onClose, data } = props;
  const [selected, setSelected] = useState([]);
  const { getGroups } = useUserGroups();
  const { data: groups, isLoading } = getGroups();
  const dispatch = useDispatch();
  const [sharing, setSharing] = useState(false);
  const [sharedGroups, setSharedGroups] = useState([]);

  useEffect(() => {
    if (sharedGroups) {
      const ids = sharedGroups.map((group) => group.group_id);
      setSelected(ids);
    }
  }, [sharedGroups]);

  const getSharedGroups = async () => {
    const res = await dispatch(screeningActions.getSharedGroups(data?.id));
    if (res) {
      setSharedGroups(res);
    }
  };

  useEffect(() => {
    if (data?.id && open) {
      getSharedGroups();
    }
  }, [data, open]);

  const handleShare = async () => {
    const screening_list_id = data.id;
    setSharing(true);
    let failed = false;
    for (let index = 0; index < selected.length; index++) {
      const group_id = selected[index];
      const postData = {
        group_id: group_id,
      };
      try {
        const res = await dispatch(
          actions.shareScreeningListItemToGroup(screening_list_id, postData)
        );
        if (res.error) {
          failed = true;
          const group = groups.results.filter(
            (g: GroupShort) => g.group_id === group_id
          )[0];
          if (res.detail?.status == 403) {
            snackbar.error(
              "Cannot share with one or more groups. Please contact an admin."
            );
          } else if (group) {
            snackbar.error("Failed sharing to group " + group.name);
          }
        }
      } catch (error) {
        failed = true;
        snackbar.error(error["message"] || "Something went wrong");
      }
    }
    if (failed) {
      setSharing(false);
    } else {
      setSharing(false);
      snackbar.success("Screening lists Shared!");
      setSelected([]);
      window.dispatchEvent(new CustomEvent("UpdateShareGroups"));
      setTimeout(() => {
        onClose();
      }, 2000);
    }
    // window.dispatchEvent(CloseBackdropEvent);
  };

  const handleCheckGroup = (_checked: boolean, id: string) => {
    const _edata = [...selected];
    if (_edata.includes(id)) {
      const idx = selected.indexOf(id);
      _edata.splice(idx, 1);
    } else {
      _edata.push(id);
    }
    setSelected(_edata);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth={"sm"}
      sx={{}}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          padding: "8px 0px",
        }}
      >
        <Box>
          <StyledDialogTitle id="scroll-dialog">{title}</StyledDialogTitle>
          <DialogSubTitle>
            Select one or more groups below to share this screening list.
          </DialogSubTitle>
        </Box>
        <Box
          sx={{ display: "flex", alignItems: "center", paddingRight: "24px" }}
        >
          <IconButton
            aria-label="close"
            sx={{
              // width: 35,
              marginLeft: 1,
            }}
            onClick={onClose}
          >
            <CloseIcon color="primary" />
          </IconButton>
        </Box>
      </Box>
      <DialogContent
        dividers={false}
        sx={{ minWidth: "300px", overflowY: "unset" }}
      >
        {groups && groups.results.length > 0 && (
          <FormGroup>
            {groups.results.map((group: GroupShort, index: number) => {
              return (
                <FormControlLabel
                  key={"group-item-" + index}
                  onChange={(
                    event: SyntheticEvent<Element, Event>,
                    checked: boolean
                  ) => handleCheckGroup(checked, group.group_id)}
                  control={
                    <Checkbox checked={selected.includes(group.group_id)} />
                  }
                  value={group.group_id}
                  label={group.name}
                />
              );
            })}
          </FormGroup>
        )}
        {isLoading == false && groups && groups.results.length == 0 && (
          <Typography variant="body1">
            There aren't any groups with which you can share this.
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-start", padding: 2 }}>
        <StyledShareButton
          disabled={selected.length == 0}
          onClick={handleShare}
        >
          Share
        </StyledShareButton>
      </DialogActions>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={sharing}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};

export default ShareDialog;
