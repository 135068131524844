import { Alert, Box, CircularProgress } from "@mui/material";

import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "../constants/colors";
import { useState } from "react";
import { useDispatch } from "react-redux";
import MyTextField from "../components/MyTextField";
import actions from "src/modules/auth/actions";

const StyledHeader = styled("h3")(() => ({
  fontSize: 18,
  lineHeight: "24px",
  fontWeight: "700",
  color: Colors.textDark100,
  marginRight: 18,
}));

const Label1 = styled("div")(() => ({
  fontSize: 14,
  lineHeight: "17.17px",
  fontWeight: "700",
  color: Colors.textDark100,
  marginRight: 18,
}));

const Label2 = styled("div")(() => ({
  fontSize: 14,
  lineHeight: "17.17px",
  fontWeight: "400",
  color: Colors.textDark100,
  marginRight: 18,
  marginTop: 8,
}));

const FormIcon = styled("img")(() => ({
  width: 20,
  height: 20,
  objectFit: "contain",
  cursor: "pointer",
}));

const StyledButton = styled(Button)(() => ({
  borderRadius: 4,
  backgroundColor: "#F75151",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#ffffff",
  fontSize: 14,
  lineHeight: "17px",
  fontWeight: "700",
  padding: 15,
  height: 46,
  width: 122,
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#F7515188",
  },
}));

const PasswordManagement = () => {
  const [visible, setVisible] = useState({
    old: false,
    new: false,
    confirm: false,
  });
  const [formData, setFormData] = useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [message, setMessage] = useState({
    error: "",
    warning: "",
    success: "",
  });
  const [updating, setUpdating] = useState(false);
  const dispatch = useDispatch();

  const changeVisible = (type: string) => {
    const _visi = { ...visible };
    _visi[type] = !_visi[type];
    setVisible(_visi);
  };

  const handleChangeFormData = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const _fd = { ...formData };
    _fd[e.target.name] = e.target.value;
    setFormData(_fd);
  };

  const handleUpdateUserPassword = async () => {
    const msg = { ...message };
    if (!formData.current_password) {
      msg.error = "Please enter your old password.";
      msg.warning = "";
      setMessage(msg);
      return;
    }
    if (!formData.new_password) {
      msg.warning = "Please enter your new password.";
      msg.error = "";
      setMessage(msg);
      return;
    }
    if (!formData.confirm_password) {
      msg.warning = "Please enter your confirm password.";
      msg.error = "";
      setMessage(msg);
      return;
    }
    if (formData.confirm_password != formData.new_password) {
      msg.warning = "These two passwords don't currently match.";
      msg.error = "";
      setMessage(msg);
      return;
    }
    msg.warning = "";
    msg.error = "";
    setMessage(msg);
    setUpdating(true);
    const res = await dispatch(actions.updatePasswords(formData));

    if (!res?.error) {
      setUpdating(false);
      msg.warning = "";
      msg.error = "";
      msg.success = "Your New Password was updated successfully.";
      const _fd = { ...formData };
      _fd.current_password = "";
      _fd.new_password = "";
      _fd.confirm_password = "";
      setFormData(_fd);
      setMessage(msg);
      setTimeout(() => {
        msg.success = "";
        setMessage(msg);
      }, 3000);
    } else {
      msg.error = res.detail || "Something went wrong!";
      setMessage(msg);
      setUpdating(false);
    }
  };

  return (
    <Box sx={{ width: "100%", mb: 4 }}>
      <StyledHeader>Change Password</StyledHeader>
      {message.error && <Alert severity="error">{message.error}</Alert>}
      {message.warning && <Alert severity="warning">{message.warning}</Alert>}
      {message.success && <Alert severity="success">{message.success}</Alert>}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          alignItems: "center",
          mt: 2,
        }}
      >
        <Box sx={{ flex: "1 1" }}>
          <Label1>Current Password</Label1>
          <Label2>Enter your currently used password first.</Label2>
        </Box>
        <Box sx={{ flex: "2 1" }}>
          <MyTextField
            // label="Password"
            labelStyle={{ color: "#97A6B5" }}
            name="current_password"
            value={formData.current_password}
            type={visible.old ? "text" : "password"}
            prefixIcon={
              <FormIcon src={require("src/assets/images/lock.png")} />
            }
            suffixIcon={
              <FormIcon
                src={
                  visible.old
                    ? require("src/assets/images/eye-close.png")
                    : require("src/assets/images/eye.png")
                }
                onClick={() => changeVisible("old")}
              />
            }
            containerStyle={{ maxWidth: 400, minWidth: 100 }}
            onChange={handleChangeFormData}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          alignItems: "center",
          my: 3,
        }}
      >
        <Box sx={{ flex: "1 1" }}>
          <Label1>New Password</Label1>
          <Label2>Add your new password here.</Label2>
        </Box>
        <Box sx={{ flex: "2 1" }}>
          <MyTextField
            // label="Password"
            labelStyle={{ color: "#97A6B5" }}
            name="new_password"
            value={formData.new_password}
            type={visible.new ? "text" : "password"}
            prefixIcon={
              <FormIcon src={require("src/assets/images/lock.png")} />
            }
            suffixIcon={
              <FormIcon
                src={
                  visible.new
                    ? require("src/assets/images/eye-close.png")
                    : require("src/assets/images/eye.png")
                }
                onClick={() => changeVisible("new")}
              />
            }
            containerStyle={{ maxWidth: 400, minWidth: 100 }}
            onChange={handleChangeFormData}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <Box sx={{ flex: "1 1" }}>
          <Label1>Confirm Password</Label1>
          <Label2>Let’s make sure it’s really the password you want.</Label2>
        </Box>
        <Box sx={{ flex: "2 1" }}>
          <MyTextField
            // label="Password"
            labelStyle={{ color: "#97A6B5" }}
            name="confirm_password"
            value={formData.confirm_password}
            type={visible.confirm ? "text" : "password"}
            prefixIcon={
              <FormIcon src={require("src/assets/images/lock.png")} />
            }
            suffixIcon={
              <FormIcon
                src={
                  visible.confirm
                    ? require("src/assets/images/eye-close.png")
                    : require("src/assets/images/eye.png")
                }
                onClick={() => changeVisible("confirm")}
              />
            }
            containerStyle={{ maxWidth: 400, minWidth: 100 }}
            onChange={handleChangeFormData}
          />
        </Box>
      </Box>
      <Box sx={{ height: 40 }}></Box>
      <StyledButton
        onClick={handleUpdateUserPassword}
        startIcon={
          updating ? <CircularProgress size={20} color={"inherit"} /> : null
        }
      >
        Update
      </StyledButton>
    </Box>
  );
};

export default PasswordManagement;
