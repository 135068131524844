import { useMemo, useState } from "react";
import CarouselChild from "./CarouselChild";
import { Box } from "@mui/material";

export const degToRad = (deg: number) => {
  return (Math.PI / 180) * deg;
};

export const genInitialZAndXCoordinates = (
  items: any[],
  degStep: number,
  middleIndex: number
) => {
  const rightSideElements = [];
  const leftSideElements = [];

  for (let i = middleIndex; i >= 0; i--) {
    const radians = degStep * i > 90 ? degToRad(90) : degToRad(degStep * i);
    const x = -Math.sin(radians);
    const z = Math.cos(radians);
    leftSideElements.push({ x, z });
  }

  const startIndex = middleIndex + 1;
  for (let i = startIndex; i < items.length; i++) {
    const index = i - middleIndex;
    const radians =
      degStep * index > 90 ? degToRad(90) : degToRad(degStep * index);
    const x = Math.sin(radians);
    const z = Math.cos(radians);
    rightSideElements.push({ x, z });
  }

  return leftSideElements.concat(rightSideElements);
};

export interface CustomImagesCarouselProps {
  images: string[];
}

const CustomImagesCarousel = ({ images }: CustomImagesCarouselProps) => {
  const [middleIndex, setMiddleIndex] = useState<number>(
    Math.floor(images.length / 2)
  );

  const coordinates = useMemo(() => {
    return genInitialZAndXCoordinates(images, 30, middleIndex);
  }, [images, middleIndex]);

  const onClick = (index: number) => {
    return () => {
      setMiddleIndex(index);
    };
  };

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "250px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        transformStyle: "preserve-3d",
      }}
    >
      {images.map((imageUrl, index) => (
        <CarouselChild
          key={index}
          imageUrl={imageUrl}
          coordinate={coordinates[index]}
          onClick={onClick(index)}
        />
      ))}
    </Box>
  );
};

export default CustomImagesCarousel;
