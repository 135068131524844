import { useMemo, useState } from "react";
import { snackbar } from "src/view/toaster";
import { Download } from "@mui/icons-material";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import LoadingText from "src/view/components/LoadingText";
import { Features } from "src/types/Auth";
import { useUser } from "src/modules/api/auth";
import { useSearch } from "src/modules/api/search";
import { usePostHog } from "posthog-js/react";
import { HogEvent } from "src/types/PosthogEvents";

const StyledSearchResultHeader = styled(Box)(() => ({
  height: 64,
  borderBottom: "solid 1px #A0B9D0",
  display: "flex",
  alignItems: "center",
  paddingLeft: 25,
  paddingRight: 25,
}));

const StyledText = styled(Typography)(() => ({
  fontSize: 12,
  fontWeight: 700,
  color: "#5A7290",
}));

const StyledButton = styled(Button)(() => ({
  width: 36,
  minWidth: 36,
  height: 36,
  padding: 0,
  color: "#5A7290",
  marginLeft: 15,
}));

export default function SearchResultHeader() {
  const { user } = useUser();
  const posthog = usePostHog();
  const enableDownload = user?.features?.includes(Features.BULK_DOWNLOAD);
  const [gettingDownloadLink, setGettingDownloadLink] = useState(false);
  const {
    data,
    status,
    isFetching,
    activePageIndex,
    getDownloadAllDocumentsUrl,
  } = useSearch();

  const totalResultCount = data?.pages[0]?.hits?.total?.value || 0;
  const totalResultRelation = data?.pages[0]?.hits?.total?.relation;

  const [downloadDocIds, setDownloadDocIds] = useState<string[]>([]);

  const { data: downloadUrl, status: downloadUrlStatus } =
    getDownloadAllDocumentsUrl({
      doc_ids: downloadDocIds,
    });

  if (downloadUrlStatus === "success") {
    setDownloadDocIds([]);
    const downloadWindow = window.open(downloadUrl, "_blank");
    downloadWindow.focus();
    setGettingDownloadLink(false);
  }

  if (downloadUrlStatus === "error") {
    snackbar.error("Download failed");
  }

  const handleDownloadDocAll = async () => {
    posthog.capture(HogEvent.BULK_DOWNLOAD_RUN);
    const doc_ids = data.pages[activePageIndex]?.hits.hits.map(
      ({ _id }) => _id
    );
    setDownloadDocIds(doc_ids);
  };

  const message = useMemo(() => {
    if (isFetching) {
      return <LoadingText text="Searching" />;
    }

    if (status === "error") {
      return "Error";
    }

    if (status === "success") {
      return `Found ${totalResultCount.toLocaleString()}${
        totalResultRelation === "gte" ? "+ " : " "
      }${totalResultCount == 1 ? "result" : "results"}`;
    }
  }, [status, isFetching, totalResultCount, totalResultRelation]);

  return (
    <StyledSearchResultHeader>
      <StyledText>{message}</StyledText>
      {enableDownload && (
        <StyledButton
          variant="outlined"
          sx={{ marginLeft: "auto" }}
          onClick={handleDownloadDocAll}
          disabled={!data || isFetching}
        >
          {gettingDownloadLink ? (
            <CircularProgress size={20} color="primary" />
          ) : (
            <Download />
          )}
        </StyledButton>
      )}
    </StyledSearchResultHeader>
  );
}
