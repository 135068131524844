import { AnyAction, Dispatch } from "redux";
import Service from "src/modules/shared/service";
import Errors from "src/modules/shared/error/errors";
import { SelectOption } from "src/types/Shared";

const prefix = "SHARED_";

const Actions: AnyAction = {
  UPDATE_COUNTRIES: `${prefix}_UPDATE_COUNTRIES`,
  getCountries: () => async (dispatch: Dispatch<AnyAction>) => {
    try {
      const res = await Service.getCountries();
      if (res?.error) {
        dispatch({
          type: Actions.UPDATE_COUNTRIES,
          payload: [],
        });
      } else {
        const arr: SelectOption[] = [];
        Object.keys(res).forEach((key) => {
          if (key != "status") {
            const item = {
              value: key,
              label: res[key],
            };
            arr.push(item);
          }
        });
        dispatch({
          type: Actions.UPDATE_COUNTRIES,
          payload: arr,
        });
      }
      return res;
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: Actions.UPDATE_COUNTRIES,
        payload: [],
      });
    }
  },
  type: undefined,
};

export default Actions;
