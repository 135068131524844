import { MenuItem, MenuProps, Select } from "@mui/material";
import { MATCHES } from "../type";

const menuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "right",
  },
  sx: {
    "& .MuiPaper-root": {
      boxShadow:
        "0 0 0 0 rgb(255, 255, 255), 0 0 0 1px rgba(0, 0, 0, 0.05), 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
    },
    ".MuiMenu-list": {
      padding: "4px 0",
    },
  },
};

interface Props {
  value: number;
  onChange: (value: number) => void;
}

const MatchSelect = ({ value, onChange }: Props) => {
  return (
    <Select
      value={value}
      onChange={(e) => onChange(e.target.value as number)}
      sx={{
        height: 36,
        color: "#626262",
        fontSize: 10,
        ".MuiSelect-select": {
          py: 0,
        },
        ".MuiSvgIcon-root": {
          fontSize: "2em",
        },
        fieldset: {
          border: "none",
        },
      }}
      MenuProps={menuProps}
    >
      {MATCHES.map((p, index) => (
        <MenuItem key={index} value={index}>
          {p}
        </MenuItem>
      ))}
    </Select>
  );
};

export default MatchSelect;
