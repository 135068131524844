import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import {
  StyledButton,
  StyledDialogTitle,
} from "src/view/users-groups/groups/EditGroup/StyledComponents";
import { Colors } from "src/view/constants/colors";
import { Add as AddIcon, Close as CloseIcon } from "@mui/icons-material";
import CustomTextField from "src/view/components/CustomTextField";
import { User } from "src/types/UserGroup";
import NewUsersTable from "src/view/users-groups/shared/AddNewUsersModal/NewUsersTable";
import NewUsersEmpty from "src/view/users-groups/shared/AddNewUsersModal/NewUsersEmpty";
import { email as emailRegex } from "src/utils/regex";
import { useUsers } from "src/modules/api/users";
import { APIValidationErrors } from "src/types/Shared";

const AddButton = styled(Button)(() => ({
  color: Colors.secondary,
  textTransform: "capitalize",
  fontSize: 14,
  lineHeight: "20px",
  fontWeight: "700",
  borderWidth: 1,
  height: 46,
  borderColor: Colors.secondary,
  borderRadius: 4,
  background: "transparent",
  "&:hover": {
    background: "transparent",
    borderColor: Colors.secondary,
  },
}));

const Form = styled("form")(() => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "flex-start",
  backgroundColor: Colors.bgGray5,
  padding: "25px",
  borderRadius: "8px",
}));

interface Props {
  open: boolean;
  handleClose: () => void;
  newUsers: User[];
  setNewUsers: (users: (users: User[]) => any) => void;
  handleSaveUsers: (users: User[], type?: string) => void;
  savingUsers: boolean;
  apiErrors: APIValidationErrors<UserFields>;
  setApiErrors: (
    errors: (
      errors: APIValidationErrors<UserFields>
    ) => { [K in keyof UserFields]: string[] }[]
  ) => void;
}

export interface UserFields {
  first_name?: string;
  last_name?: string;
  email: string;
}

const AddNewUserModal = ({
  open,
  handleClose,
  newUsers,
  setNewUsers,
  handleSaveUsers,
  savingUsers,
  apiErrors,
  setApiErrors,
}: Props) => {
  const {
    formState: { errors },
    control,
    handleSubmit,
    setError,
    reset,
  } = useForm<UserFields>({
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
    },
    shouldFocusError: true,
  });
  const { getUsersFn } = useUsers();

  const addUser = async (data: UserFields) => {
    const foundExistingUsers = await getUsersFn({ searchKey: data.email });

    if (foundExistingUsers?.results?.length) {
      return setError("email", {
        type: "existing",
        message: "User with this email already exists",
      });
    }

    if (newUsers.some(({ email }) => data.email === email)) {
      return setError("email", {
        type: "existing",
        message: "This email is already being added",
      });
    }

    setNewUsers((users: User[]) => [...users, data]);
    reset();
  };

  const deleteUser = (index: number) => {
    setNewUsers((users) => users.filter((_v, _index) => _index !== index));
    setApiErrors((errors: APIValidationErrors<UserFields>) =>
      errors.filter((_v, _index) => _index !== index)
    );
  };

  return (
    <Dialog open={open} onClose={handleClose} scroll={"paper"} maxWidth={false}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "8px 0px",
        }}
      >
        <Box>
          <StyledDialogTitle id="scroll-dialog">Add New User</StyledDialogTitle>
        </Box>
        <Box
          sx={{ display: "flex", alignItems: "center", paddingRight: "24px" }}
        >
          {newUsers?.length > 0 && (
            <StyledButton
              startIcon={
                savingUsers ? (
                  <CircularProgress size={20} color="inherit" />
                ) : null
              }
              variant="outlined"
              sx={{
                backgroundColor: Colors.textDark100,
                color: "#FFFFFF",
                height: 42,
                width: 94,
              }}
              onClick={() => handleSaveUsers(newUsers)}
              disabled={savingUsers}
            >
              Save
            </StyledButton>
          )}
          <IconButton
            aria-label="close"
            sx={{
              // width: 35,
              marginLeft: 1,
            }}
            onClick={handleClose}
          >
            <CloseIcon color="primary" />
          </IconButton>
        </Box>
      </Box>
      <DialogContent
        dividers={false}
        sx={{ minWidth: "60vw", overflowY: "unset" }}
      >
        <Form onSubmit={handleSubmit(addUser)}>
          <Box
            sx={{
              display: "flex",
              flex: "1 1 200px",
              mr: 1,
              alignItems: "center",
              height: "100%",
            }}
          >
            <Controller
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  tabIndex={-1}
                  border={true}
                  placeholder="Enter First Name"
                  prefixIcon={
                    <img
                      src={require("src/assets/images/user.png")}
                      style={{ width: 20, height: 20 }}
                    />
                  }
                />
              )}
              name="first_name"
            />
          </Box>
          <Box
            sx={{
              flex: "1 1 200px",
              display: "flex",
              alignItems: "center",
              mr: 1,
            }}
          >
            <Controller
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  tabIndex={-1}
                  border={true}
                  placeholder="Enter Last Name"
                  prefixIcon={
                    <img
                      src={require("src/assets/images/user.png")}
                      style={{ width: 20, height: 20 }}
                    />
                  }
                />
              )}
              name="last_name"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flex: "1 1 200px",
              mr: 1,
              flexDirection: "column",
            }}
          >
            <Controller
              control={control}
              rules={{
                required: "This field is required",
                pattern: {
                  value: emailRegex,
                  message: "Please enter a valid email",
                },
              }}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  tabIndex={-1}
                  border={true}
                  placeholder="Enter Email Address"
                  prefixIcon={
                    <img
                      src={require("src/assets/images/email.png")}
                      style={{ width: 20, height: 20 }}
                    />
                  }
                  type="email"
                  invalid={!!errors?.email}
                />
              )}
              name="email"
            />

            {!!errors?.email && (
              <Typography
                sx={{
                  color: "#F75151",
                  fontSize: "12px",
                  marginTop: "-10px",
                }}
              >
                {errors.email.message}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              flex: "0.5 0 75px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <AddButton
              variant="outlined"
              startIcon={<AddIcon color="secondary" />}
              type="submit"
            >
              Add
            </AddButton>
          </Box>
        </Form>

        {newUsers?.length > 0 ? (
          <NewUsersTable
            newUsers={newUsers}
            deleteUser={deleteUser}
            apiErrors={apiErrors}
          />
        ) : (
          <NewUsersEmpty />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AddNewUserModal;
