import { AnyAction, Dispatch } from "redux";
import Service from "src/modules/feedback/service";

const prefix = "FEEDBACK_";

const Actions: AnyAction = {
  SENDING: `${prefix}SENDING`,
  SEND_ERROR: `${prefix}SEND_ERROR`,
  SEND_SUCCESS: `${prefix}SEND_SUCCESS`,

  sendFeedback: (message: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({
        type: Actions.SENDING,
        payload: true,
      });
      const res = await Service.sendFeedback(message);
      if (res.error) {
        dispatch({
          type: Actions.SEND_ERROR,
          payload: res.detail,
        });
      } else {
        dispatch({
          type: Actions.SEND_SUCCESS,
          payload: res,
        });
      }
      return res;
    } catch (err) {
      dispatch({
        type: Actions.SENDING,
        payload: false,
      });
    }
  },
  type: undefined,
};

export default Actions;
