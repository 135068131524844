import { replaceHighlightTags } from ".";
import { CSSProperties } from "react";
import theme from "src/theme";

export const getHighlightText = (text: string, style?: CSSProperties) => {
  text = replaceHighlightTags(text);

  const parser = new DOMParser();
  const parsed = parser.parseFromString(text, "text/html");
  const nodes = parsed.body.childNodes;

  const elements = Object.keys(nodes).map((key, index) => {
    const element = nodes[key];

    if (element.nodeName === "SPAN") {
      return (
        <span
          key={`highlight-${index}`}
          style={{ backgroundColor: theme.palette.highlight.main, ...style }}
        >
          {element.textContent}
        </span>
      );
    }
    return <span key={`highlight-${index}`}>{element.textContent}</span>;
  });

  return <>{elements}</>;
};
