import React, { useState } from "react";
import { styled, Box, CircularProgress } from "@mui/material";
import {
  StyledContainer,
  StyledHeader,
  renderContent,
} from "src/view/search-result/components/Preview/Source/SourcePreviews";

const ImageContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  "& img": {
    width: "100%",
    height: "100%",
  },
}));

interface Props {
  data: unknown;
  loading: boolean;
}

const IMGPreview = ({ data, loading }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const onLoad = () => {
    setIsLoading(false);
  };

  const onError = () => {
    setIsLoading(false);
    setIsError(true);
  };

  return (
    <StyledContainer>
      <StyledHeader />
      {renderContent(
        data,
        loading,
        <Box
          sx={{
            overflow: "auto",
            width: "calc(100% - 79px)",
            height: "calc(100vh - 350px)",
            display: "block",
            ml: "43px",
            mr: "36px",
            padding: 1,
          }}
          aria-label="simple table"
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isError && <Box>Failed to load image</Box>}
            {isLoading && <CircularProgress size={20} />}
          </Box>

          {data?.[0]?.["file_url"] && (
            <ImageContainer
              sx={{ display: isError || isLoading ? "none" : "flex" }}
            >
              <img
                src={data[0]["file_url"]}
                alt="search result"
                onLoad={onLoad}
                onError={onError}
              />
            </ImageContainer>
          )}
        </Box>
      )}
    </StyledContainer>
  );
};

export default IMGPreview;
