import { Dataset } from "src/types/Dataset";
import { SearchFilter } from "src/types/SearchFilter";
import { QueryItem } from "src/types/BulkSearch";
import actions from "src/modules/search/filter/searchFilterActions";

export interface SearchFilterProps {
  loading: boolean;
  filter: SearchFilter;
  // for keeping filter state accessible to search header without applying it to url
  tempFilter: SearchFilter;
  query: QueryItem[];
}

export const defaultFilter: SearchFilter = {
  dataset_types: [],
  regions: [],
  languages: [],
  countries: [],
  dataset_ids: [],
  exclude_dataset_ids: [],
  data_collection_ids: [],
  exclude_data_collection_ids: [],
  doc_types: [],
};

export const initialData: SearchFilterProps = {
  loading: false,
  filter: defaultFilter,
  tempFilter: defaultFilter,
  query: null,
};

export default (
  state = initialData,
  {
    type,
    payload,
  }: { type: string; payload: boolean | Dataset[] | SearchFilter | QueryItem[] }
) => {
  if (type === actions.FIND_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.FIND_SUCCESS) {
    return {
      ...state,
      datasets: payload,
      loading: false,
    };
  }

  if (type === actions.FIND_ERROR) {
    return {
      ...state,
      loading: false,
    };
  }

  if (type === actions.SET_FILTER) {
    return {
      ...state,
      filter: payload,
      tempFilter: payload,
      loading: false,
    };
  }

  if (type === actions.SET_TEMP_FILTER) {
    return {
      ...state,
      tempFilter: payload,
      loading: false,
    };
  }

  if (type === actions.SET_QUERY) {
    return {
      ...state,
      query: payload,
    };
  }

  if (type === actions.CLEAR) {
    return {
      ...initialData,
    };
  }

  return state;
};
