import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Button,
  DialogContentText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DataCollection } from "src/types/DataCatalog";

export interface DeleteCollectionDialogProps {
  collection: DataCollection | null;
  handleClose: () => void;
  handleDelete: (collection: DataCollection) => void;
}
export default function DeleteCollectionDialog({
  collection,
  handleClose,
  handleDelete,
}: DeleteCollectionDialogProps) {
  return (
    <Dialog open={Boolean(collection)}>
      <DialogTitle>
        Are you sure you want to delete this collection?
      </DialogTitle>
      <IconButton
        aria-label="close"
        color="primary"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 9,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <DialogContentText>
          Once this action is taken this cannot be undone.
        </DialogContentText>
        <Box
          sx={{
            py: 1,
            px: 2,
            border: "solid",
            borderWidth: "1px",
            color: "grey.700",
            borderRadius: 1,
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
          }}
        >
          <Typography
            color="primary"
            sx={{
              fontSize: 14,
              fontFamily: "Century Gothic",
            }}
          >
            {collection?.name}
          </Typography>
          <Typography
            color="grey.700"
            sx={{
              fontSize: 12,
              fontFamily: "Century Gothic",
            }}
          >{`${collection?.datasets?.length || ""} Datasets`}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleDelete(collection)}
        >
          Delete
        </Button>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
