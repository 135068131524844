import { fetchApi } from "src/modules/shared/api";

export default class sharedService {
  static async getCountries() {
    try {
      const res = await fetchApi("countries/", {
        method: "GET",
      });
      const response = await res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response["error"],
        };
      }
    } catch (err) {
      return false;
    }
  }
}
