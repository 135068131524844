import { Close } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { snackbar } from "src/view/toaster";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import actions from "src/modules/groups/view/groupsViewActions";
import { Colors } from "../constants/colors";
import ConfirmDialog from "../components/ConfirmDialog";
import { Group } from "src/types/UserGroup";

const StyledFilterBox = styled("div")(() => ({
  height: "100%",
  width: 370,
  backgroundColor: "white",
}));

const StyledFilterHeader = styled(Box)(() => ({
  height: 64,
  width: "100%",
  borderBottom: "solid 1px #A0B9D0",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  position: "relative",
}));

const StyledFilterBody = styled(Box)(() => ({
  height: "calc(100% - 64px - 127px - 30px)",
  width: "calc(100% - 28px)",
  overflow: "auto",
  padding: 14,
  position: "relative",
}));

const GroupItemContainer = styled(Box)(() => ({
  height: "auto",
  width: "100%",
  display: "flex",
  alignItem: "center",
  justifyContent: "space-between",
  paddingTop: 15,
  paddingBottom: 15,
  cursor: "pointer",
  borderBottom: "1px solid rgba(4, 61, 93, 0.12)",
}));

const StyledTitle = styled(Typography)(() => ({
  fontSize: 14,
  lineHeight: "20px",
  color: Colors.twilight,
  fontWeight: "700",
}));

const StyledActionButtonContainer = styled("div")(() => ({
  width: "calc(100% - 50px)",
  padding: 14,
  position: "absolute",
  bottom: 0,
}));

const StyledActionButton = styled(Button)(() => ({
  width: 300,
  height: 40,
  backgroundColor: Colors.secondary,
  color: "white",
  fontSize: 14,
  lineHeight: "20px",
  fontWeight: "700",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: Colors.secondary2,
  },
}));

const CustomDrawer = (props: {
  handleClose: () => void;
  drawerData: { type: string; data: Group[]; id: string };
}) => {
  const { handleClose, drawerData } = props;
  const [type, setType] = useState(null);
  const [ids, setIds] = useState([]);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const OpenBackdropEvent = new CustomEvent("startBackdrop");
  const CloseBackdropEvent = new CustomEvent("endBackdrop");
  const UpdateShareGroups = new CustomEvent("UpdateShareGroups");
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (drawerData) {
      setType(drawerData.type);
      setData(drawerData.data);
      setIds(drawerData.data.map((i: Group) => i.group_id));
      if (drawerData.id) {
        setId(drawerData.id);
      }
    }
  }, [drawerData]);

  useEffect(() => {
    setData([]);
    getData();
  }, [ids]);

  const getData = async () => {
    const dt = [];
    setLoading(true);
    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      let item;
      if (type == "group_for_screening_list") {
        item = await dispatch(actions.getGroupDetails(id));
        if (item && !item.error) {
          dt.push(item);
        }
      }
    }
    setData(dt);
    setLoading(false);
  };

  const handleCollapseFilter = () => {
    handleClose();
  };

  const handleCheckGroup = (id: string) => {
    const _edata = [...selected];
    if (_edata.includes(id)) {
      const idx = selected.indexOf(id);
      _edata.splice(idx, 1);
    } else {
      _edata.push(id);
    }
    setSelected(_edata);
  };

  const handleRemoveGroups = async () => {
    const postData = {
      screening_lists: [id],
    };

    window.dispatchEvent(OpenBackdropEvent);
    let failed = false;
    for (let index = 0; index < selected.length; index++) {
      const group_id = selected[index];
      try {
        const res = await dispatch(
          actions.removeScreeningListItemFromGroup(group_id, postData)
        );
        if (res.error) {
          failed = true;
          const group = data.filter((g: Group) => g.group_id === group_id)[0];
          if (group) {
            snackbar.error("Failed removing access " + group.name);
          }
        } else {
          window.dispatchEvent(UpdateShareGroups);
          const _data = data.filter((g: Group) => g.group_id !== group_id);
          setSelected([]);
          setData(_data);
        }
      } catch (error: unknown) {
        failed = true;
        snackbar.error(error["message"] || "Something went wrong");
      }
    }
    if (!failed) {
      snackbar.success("Screening lists Shared!");
    }
    window.dispatchEvent(CloseBackdropEvent);
  };

  return (
    <StyledFilterBox>
      <StyledFilterHeader>
        <Box sx={{ pl: 2, alignItems: "center" }}>
          <Typography
            variant="h6"
            sx={{ fontSize: "16px", fontWeight: "700", color: "#043D5D" }}
          >
            Edit Group Access
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: "700",
              color: "#144A68",
            }}
          >
            Choose which groups can see this screening list.
          </Typography>
        </Box>
        {/*  */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            position: "absolute",
            right: 0,
            top: 0,
          }}
        >
          <IconButton
            size="large"
            aria-label="collapse of filters"
            aria-controls="toggle filter"
            aria-haspopup="true"
            color="inherit"
            onClick={() => handleCollapseFilter()}
          >
            <Close />
          </IconButton>
        </Box>
      </StyledFilterHeader>
      <StyledFilterBody>
        {type == "group_for_screening_list" &&
          loading == false &&
          data?.map((item: Group, index: number) => {
            return (
              <GroupItem
                key={"group_item_" + index}
                data={item}
                selected={selected}
                handleCheckGroup={handleCheckGroup}
              />
            );
          })}
        {loading && <CircularProgress />}
        <StyledActionButtonContainer>
          <ConfirmDialog
            title="Remove Access from Screening List"
            description="Are you sure you want to remove access from selected groups? This action cannot be undone."
            onConfirm={handleRemoveGroups}
            confirmText="Confirm"
            cancelText="Cancel"
          >
            <StyledActionButton disabled={selected.length == 0}>
              Remove Access
            </StyledActionButton>
          </ConfirmDialog>
        </StyledActionButtonContainer>
      </StyledFilterBody>
    </StyledFilterBox>
  );
};

const GroupItem = (props: {
  data: Group;
  selected: string[];
  handleCheckGroup: (id: string) => void;
}) => {
  const { data, selected, handleCheckGroup } = props;

  return (
    <GroupItemContainer>
      <FormControlLabel
        onChange={() => handleCheckGroup(data.group_id)}
        control={<Checkbox checked={selected.includes(data.group_id)} />}
        value={data.group_id}
        label={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar
              alt={data.name}
              src={data.avatar}
              sx={{
                border: "1px solid white",
                fontSize: 14,
                lineHeight: "20px",
                fontWeight: "700",
                textTransform: "uppercase",
                mr: 1,
              }}
            />
            <StyledTitle>{data.name}</StyledTitle>
          </Box>
        }
      />
    </GroupItemContainer>
  );
};

export default CustomDrawer;
