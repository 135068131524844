import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import { styled } from "@mui/material/styles";
import { Button, MenuItem } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useEffect, useState } from "react";
import { QueryItem } from "src/types/BulkSearch";

const StyledTimeline = styled(Timeline)(() => ({
  position: "absolute",
  top: "-50px",
  padding: 0,
  "& .MuiTimelineItem-root:nth-of-type(even)": {
    flexDirection: "row",
  },
  "& .MuiTimelineItem-root": {
    padding: 0,
    minHeight: 60,
  },
  "& .MuiTimelineItem-root:before": {
    padding: 0,
  },
}));

const StyledOptionButtonWrapper = styled("div")(() => ({
  position: "relative",
}));

const StyledOptionButton = styled(Button)(() => ({
  padding: 0,
  width: 55,
  height: 23,
  borderRadius: 60,
  backgroundColor: "#C8D4DB",
  zIndex: 1,
  "&:hover": {
    backgroundColor: "#C8D4D1",
  },
}));

const StyledDropdown = styled("ul")(() => ({
  position: "absolute",
  width: "100%",
  background: "#FFFFFF",
  boxShadow: "0px 34px 33px rgba(0, 0, 0, 0.25)",
  marginTop: "2px",
  zIndex: 2,
  padding: 0,
}));

const StyledTimelineItem = (props: {
  text: string;
  isGroup: boolean;
  handleChange: (value: string) => void;
}) => {
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineConnector
          sx={{ bgcolor: props.isGroup ? "#FED9A8" : "#043D5D", width: "1px" }}
        />
        <OptionButton
          text={props.text}
          isGroup={props.isGroup}
          handleChange={props.handleChange}
        />
        <TimelineConnector
          sx={{ bgcolor: props.isGroup ? "#FED9A8" : "#043D5D", width: "1px" }}
        />
      </TimelineSeparator>
    </TimelineItem>
  );
};

const OptionButton = (props: {
  text: string;
  isGroup: boolean;
  handleChange: (value: string) => void;
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [query, setQuery] = useState("AND");

  useEffect(() => {
    setQuery(props.text);
  }, [props]);

  const handleClose = () => {
    setOpenDropdown(false);
  };

  const handleSelectQuery = (value: string) => {
    setQuery(value);
    props.handleChange(value);
    handleClose();
  };

  return (
    <StyledOptionButtonWrapper>
      <StyledOptionButton
        disableRipple
        variant="contained"
        sx={{
          color: "black",
          fontWeight: "700",
          fontSize: "12px",
          bgcolor: props.isGroup ? "#FED9A8" : "#C8D4DB",
          "&:hover": {
            backgroundColor: props.isGroup ? "#FED9A8" : "#FED9c8",
          },
        }}
        onClick={() => setOpenDropdown(!openDropdown)}
        endIcon={<ArrowDropDownIcon sx={{ color: "black" }} />}
      >
        {query}
      </StyledOptionButton>
      {openDropdown && (
        <StyledDropdown>
          <MenuItem onClick={() => handleSelectQuery("AND")}>AND</MenuItem>
          <MenuItem onClick={() => handleSelectQuery("OR")}>OR</MenuItem>
        </StyledDropdown>
      )}
    </StyledOptionButtonWrapper>
  );
};

export default function CustomizedTimeline(props: {
  query: QueryItem;
  index: number;
  updateConnector: (q: QueryItem, index: number) => void;
  isGroup?: boolean;
}) {
  const { query, index, updateConnector, isGroup = false } = props;

  const handleChange = (value: string) => {
    const _query = {
      type: "operator",
      value: value,
    };
    updateConnector(_query, index);
  };

  return (
    <StyledTimeline position="alternate">
      <StyledTimelineItem
        text={query.value}
        isGroup={isGroup}
        handleChange={(text: string) => handleChange(text)}
      />
    </StyledTimeline>
  );
}
