import { ScreeningList } from "src/types/BulkSearch";

import { fetchApi } from "../shared/api";

export default class BulkSearchService {
  static async create(data: ScreeningList) {
    try {
      const res = await fetchApi("bulk_search/screening_list/", {
        method: "POST",
        body: JSON.stringify(data),
      });
      if (res.status === 500) {
        return [];
      }
      const response = await res.json();
      if (response.detail) return [];
      return response;
    } catch (err) {
      return [];
    }
  }

  static async updateScreeningList(data: ScreeningList) {
    try {
      const res = await fetchApi(
        "bulk_search/screening_list/" + data.id + "/",
        {
          method: "PATCH",
          body: JSON.stringify(data),
        }
      );
      const response = res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response["detail"],
        };
      }
    } catch (err) {
      return [];
    }
  }

  static async createQueryGroup(data: object) {
    try {
      const res = await fetchApi("bulk_search/query_group/", {
        method: "POST",
        body: JSON.stringify(data),
      });
      if (res.status == 500) {
        return false;
      }
      const response = await res.json();
      if (response.detail) return false;
      return response;
    } catch (err) {
      return false;
    }
  }

  static async updateQueryGroup(data: object, id: string) {
    try {
      const res = await fetchApi("bulk_search/query_group/" + id + "/", {
        method: "PATCH",
        body: JSON.stringify(data),
      });
      if (res.status == 500) {
        return false;
      }
      const response = await res.json();
      if (response.detail) return false;
      return response;
    } catch (err) {
      return false;
    }
  }

  static async importCSV(data: FormData) {
    try {
      const res = await fetchApi("bulk_search/query_group/csv_import/", {
        method: "POST",
        body: data,
      });
      if (res.status == 500) {
        return {
          error: true,
          detail: "Something went wrong on the server side",
        };
      }
      const response = res.json();
      if (response["detail"])
        return {
          error: true,
          detail: response["detail"],
        };
      return response;
    } catch (err) {
      return false;
    }
  }

  static async getScreeningLists() {
    try {
      const res = await fetchApi("bulk_search/screening_list/", {
        method: "GET",
      });
      if (res.status == 500) {
        return {
          error: true,
          detail: "Something went wrong on the server side",
        };
      }
      const response = res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response["detail"],
        };
      }
    } catch (err) {
      return false;
    }
  }

  static async deleteScreeningList(id: string) {
    try {
      const res = await fetchApi("bulk_search/screening_list/" + id + "/", {
        method: "DELETE",
      });
      if (res.status == 500) {
        return null;
      }
      const response = await res.json();
      if (response.detail) return null;
      return response;
    } catch (err) {
      return false;
    }
  }

  static async getScreeningList(id: string) {
    try {
      const res = await fetchApi("bulk_search/screening_list/" + id + "/", {
        method: "GET",
      });
      if (res.status == 500) {
        return null;
      }
      const response = await res.json();
      if (response.detail) return null;
      return response;
    } catch (err) {
      return false;
    }
  }

  static async getQueryGroups(s_list: string) {
    try {
      const res = await fetchApi(
        "bulk_search/screening_list/" + s_list + "/query_groups/",
        {
          method: "GET",
        }
      );
      if (res.status == 500) {
        return null;
      }
      const response = res.json();
      if (response["detail"]) return null;
      return response;
    } catch (err) {
      return false;
    }
  }

  static async getSharedGroups(s_list: string) {
    try {
      const res = await fetchApi(
        "bulk_search/screening_list/" +
          s_list +
          "/shared_groups_with_screening_list/",
        {
          method: "GET",
        }
      );
      if (res.status == 500) {
        return null;
      }
      const response = res.json();
      if (response["detail"]) return null;
      return response;
    } catch (err) {
      return false;
    }
  }

  static async deleteSearchTerm(id: string) {
    try {
      const res = await fetchApi("bulk_search/search_term/" + id + "/", {
        method: "DELETE",
      });
      const response = res.json();
      if (res.status >= 200 && res.status < 300) {
        return true;
      } else {
        if (response["detail"]) return null;
      }
      return response;
    } catch (err) {
      return false;
    }
  }

  static async deleteQueryGroup(id: string) {
    try {
      const res = await fetchApi("bulk_search/query_group/" + id + "/", {
        method: "DELETE",
      });
      if (res.status >= 200 && res.status < 300) {
        return true;
      } else {
        return null;
      }
    } catch (err) {
      return false;
    }
  }

  static async getSearchItems(
    id: string,
    search?: string,
    order?: { query?: number; total_results?: number },
    page?: number
  ) {
    try {
      let url = "bulk_search/query_group/" + id + "/search_terms/?";
      if (search) {
        url += "search=" + search;
      }
      const ordering = [];
      if (order) {
        if (search) url += "&";
        if (order.query != 0) {
          if (order.query == 1) ordering.push("query");
          else ordering.push("-query");
        }
        if (order.total_results != 0) {
          if (order.total_results == 1) ordering.push("total_results");
          else ordering.push("-total_results");
        }
        if (ordering.length > 0) {
          url += "ordering=" + ordering.join(",");
        }
      }

      if (page) {
        if (search || ordering.length > 0) url += "&";
        url += "page=" + page;
      }

      const res = await fetchApi(url, {
        method: "GET",
      });
      if (res.status == 500) {
        return null;
      }
      const response = await res.json();
      if (response.detail) return null;
      return response;
    } catch (err) {
      return false;
    }
  }

  static async performSearch(id: string, page: number) {
    try {
      const res = await fetchApi(
        "bulk_search/search_term/perform_search/" + id + "/?page=" + page,
        {
          method: "GET",
        }
      );
      const response = res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        if (res.status == 500 || response["detail"]) return null;
      }
      return response;
    } catch (err) {
      return false;
    }
  }

  static async performSearchQueryGroup(id: string) {
    try {
      const res = await fetchApi(
        "bulk_search/query_group/perform_search/" + id + "/",
        {
          method: "GET",
        }
      );
      const response = res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        if (res.status == 500 || response["detail"]) return null;
      }
      return response;
    } catch (err) {
      return false;
    }
  }

  static async performSearchScreeningList(id: string) {
    try {
      const res = await fetchApi(
        "bulk_search/screening_list/perform_search/" + id + "/",
        {
          method: "GET",
        }
      );
      const response = res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        if (res.status == 500 || response["detail"]) return null;
      }
      return response;
    } catch (err) {
      return false;
    }
  }

  static async duplicateScreeningList(id: string) {
    try {
      const res = await fetchApi(
        "bulk_search/screening_list/" + id + "/duplicate/",
        {
          method: "POST",
        }
      );
      const response = res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        if (res.status == 500 || response["detail"]) return null;
      }
      return response;
    } catch (err) {
      return false;
    }
  }

  static async getScreeningListMonitorStatus(id: string) {
    try {
      const res = await fetchApi(
        "bulk_search/screening_list/" + id + "/monitoring/",
        {
          method: "GET",
        }
      );
      const response = res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        if (res.status == 500 || response["detail"]) return null;
      }
      return response;
    } catch (err) {
      return false;
    }
  }

  static async getQueryGroupMonitorStatus(id: string) {
    try {
      const res = await fetchApi(
        "bulk_search/query_group/" + id + "/monitoring/",
        {
          method: "GET",
        }
      );
      const response = res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        if (res.status == 500 || response["detail"]) return null;
      }
      return response;
    } catch (err) {
      return false;
    }
  }
}
