import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Colors } from "../constants/colors";
import Selectors from "src/modules/auth/selectors";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { snackbar } from "src/view/toaster";
import logo_vertical from "src/assets/logo-vertical-white.svg";
import { useAuth0 } from "@auth0/auth0-react";

const RootContainer = styled(Box)(() => ({
  width: "100vw",
  height: "100vh",
  display: "flex",
}));

const Banner = styled(Box)(({ theme }) => ({
  width: "40%",
  display: "flex",
  flexDirection: "column",
  backgroundColor: Colors.twilight,
  position: "relative",
  alignItems: "center",
  justifyContent: "space-around",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const Form = styled(Box)(({ theme }) => ({
  width: "60%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    width: "calc(100% - 4rem)",
    padding: "2rem",
  },
}));

const FormContainer = styled(Box)(() => ({
  width: "70%",
  maxWidth: "493px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const BGImage = styled("img")(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "contain",
}));

const Logo = styled("img")(() => ({
  width: 280,
  height: 150,
  objectFit: "contain",
  marginBottom: "40px",
}));

const BulkSearchImage = styled("img")(() => ({
  width: 257,
  height: 189,
  objectFit: "contain",
}));

const BannerTitle = styled(Typography)(() => ({
  fontSize: 24,
  lineHeight: "29px",
  fontWeight: "700",
  color: "#FFFFFF",
}));

const FormTitle = styled(Typography)(() => ({
  fontSize: 24,
  lineHeight: "29px",
  fontWeight: "700",
  color: "#122945",
  textAlign: "center",
}));

const FormDescription = styled(Typography)(() => ({
  fontSize: 14,
  lineHeight: "20px",
  fontWeight: "400",
  color: "#536378",
  textAlign: "center",
}));

export default function LoginPage() {
  const loading = useSelector(Selectors.selectLoading);
  const location = useLocation();
  const [resetPassLinkSend, setResetPassLinkSend] = useState(null);
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (location.state && location.state["sentLink"] == true) {
      setResetPassLinkSend(true);
    }
  }, [location]);

  useEffect(() => {
    if (resetPassLinkSend == true) {
      snackbar.success("Password Reset Link Emailed");
    }
  }, [resetPassLinkSend]);

  return (
    <RootContainer>
      <Banner>
        <BGImage src={require("src/assets/images/bg-login.png")} />
        <Logo src={logo_vertical} />
        <BulkSearchImage src={require("src/assets/images/bulk-search.png")} />
        <Box sx={{ textAlign: "center", alignItems: "center" }}>
          <BannerTitle>Easy to search &amp; find accurate results</BannerTitle>
          <Box sx={{ height: 12 }}></Box>
          <Box sx={{ height: 40 }} />
        </Box>
      </Banner>
      <Form>
        <FormContainer>
          <FormTitle>Hello Again!</FormTitle>
          <Box sx={{ height: 12 }} />
          <FormDescription>
            Log in below to begin your investigation.
          </FormDescription>
          <Box sx={{ height: 24 }} />
          <Box sx={{ height: 24 }} />
          <Button
            variant="contained"
            sx={{
              width: "100%",
              height: 40,
              bgcolor: Colors.twilight,
              textTransform: "capitalize",
              fontWeight: "700",
            }}
            onClick={() => loginWithRedirect()}
            startIcon={
              loading ? (
                <CircularProgress
                  size="small"
                  style={{ color: "#FFFFFF", width: 24, height: 24 }}
                />
              ) : null
            }
          >
            Log in
          </Button>
          <Box sx={{ height: 12 }} />
        </FormContainer>
      </Form>
    </RootContainer>
  );
}
