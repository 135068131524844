import { useMemo } from "react";
import sanitizeHtml from "sanitize-html";
import { Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { isHtmlEmpty } from "src/utils";
import HighlightActionMenu from "src/view/components/HighlightActionMenu";
import { getHighlightText } from "src/utils/get-highlight-text";

const KeyText = styled(Typography)(() => ({
  width: "100px",
  minWidth: "100px",
  fontSize: 14,
  fontWeight: 700,
  color: "#536378",
}));

const Splitter = styled(Typography)(() => ({
  width: "auto",
  marginRight: "20px",
}));

const ValueText = styled(KeyText)(() => ({
  width: "auto",
  color: "#122945",
  wordBreak: "break-word",
}));

interface SelectableTextProps {
  children?: JSX.Element;
  innerHTML?: string;
  handleClosePreview: (shouldSetURLParam?: boolean) => void;
}

const SelectableText = ({
  children,
  innerHTML,
  handleClosePreview,
}: SelectableTextProps) => {
  return (
    <HighlightActionMenu onSearchInTab={handleClosePreview}>
      <ValueText
        dangerouslySetInnerHTML={innerHTML ? { __html: innerHTML } : null}
      >
        {children}
      </ValueText>
    </HighlightActionMenu>
  );
};

interface Props {
  data: any;
  handleClosePreview?: () => void;
}

export const EMLView: React.FC<Props> = ({ data, handleClosePreview }) => {
  const htmlContent = data.body?.html;
  const plainContent = data.body?.plain?.trim();
  const { subject, from, to, bodyHtml, bodyPlain, attachments } =
    useMemo(() => {
      return {
        subject: getHighlightText(data.subject),
        from: getHighlightText(data.from),
        to: getHighlightText(data.to),
        bodyHtml: !isHtmlEmpty(htmlContent) && sanitizeHtml(htmlContent),
        bodyPlain: isHtmlEmpty(htmlContent) && getHighlightText(plainContent),
        attachments: data.body?.attachments || null,
      };
    }, [data]);

  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row">
        <KeyText>Subject</KeyText>
        <Splitter>:</Splitter>
        <SelectableText handleClosePreview={handleClosePreview}>
          {subject}
        </SelectableText>
      </Stack>
      <Stack direction="row">
        <KeyText>From</KeyText>
        <Splitter>:</Splitter>
        <SelectableText handleClosePreview={handleClosePreview}>
          {from}
        </SelectableText>
      </Stack>
      <Stack direction="row">
        <KeyText>To</KeyText>
        <Splitter>:</Splitter>
        <SelectableText handleClosePreview={handleClosePreview}>
          {to}
        </SelectableText>
      </Stack>
      <Stack direction="row">
        <KeyText>Content</KeyText>
        <Splitter>:</Splitter>
        {bodyHtml && (
          <SelectableText
            innerHTML={bodyHtml}
            handleClosePreview={handleClosePreview}
          ></SelectableText>
        )}
        {bodyPlain && (
          <SelectableText handleClosePreview={handleClosePreview}>
            {bodyPlain}
          </SelectableText>
        )}
      </Stack>
      {attachments && attachments.length > 0 && (
        <Stack direction="row">
          <KeyText>Attachments</KeyText>
          <Splitter>:</Splitter>
          <SelectableText handleClosePreview={handleClosePreview}>
            {getHighlightText(attachments.join(", "))}
          </SelectableText>
        </Stack>
      )}
    </Stack>
  );
};
