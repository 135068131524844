import { Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { getHighlightText } from "src/utils/get-highlight-text";

export interface ObjectViewProps {
  data: any;
}

const KeyText = styled(Typography)(() => ({
  width: "214px",
  textOverflow: "ellipsis",
  fontSize: 12,
  fontWeight: 700,
  color: "#536378",
  marginRight: "20px",
}));

const ValueText = styled(KeyText)(() => ({
  width: "auto",
  color: "#122945",
  wordBreak: "break-word",
}));

const ObjectView = ({ data }: ObjectViewProps) => (
  <Stack direction="column" gap={1} sx={{ width: "100%" }}>
    {Object.entries(data).map(([key, value], index) => (
      <Box sx={{ display: "inline-flex" }} key={`object-view-${key}-${index}`}>
        <KeyText>{getHighlightText(key)} :</KeyText>
        <ValueText>{getHighlightText(value.toString())}</ValueText>
      </Box>
    ))}
  </Stack>
);

ObjectView.displayName = "ObjectView";

export default ObjectView;
