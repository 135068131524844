import { useAuth0 } from "@auth0/auth0-react";
import { formatInit, formatUrl } from "./utils";

export function useFetch() {
  const { getAccessTokenSilently } = useAuth0();

  const fetchApi = async (
    path: string | string[],
    method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
    options?: {
      searchParams?: string;
      initParams?: RequestInit;
      getAPIErrorMessage?: (response: any) => string;
    }
  ) => {
    const { searchParams, initParams, getAPIErrorMessage } = options;
    const token = await getAccessTokenSilently();
    const url = formatUrl(path, searchParams);
    const init = formatInit(method, token, initParams);
    const req = fetch(url, init);
    const res = await req;

    if (!res.ok) {
      let errorMsg = `${res.status} Error: ${res.statusText}`;
      if (typeof getAPIErrorMessage === "function") {
        errorMsg = getAPIErrorMessage(res);
      }
      throw new Error(errorMsg);
    }

    if (method !== "GET") {
      // ReactQuery useQuery (GET) hooks expect a Promise with data
      return req;
    }

    return res.json();
  };

  const getApi = async (
    path: string | string[],
    searchParams?: string,
    init: RequestInit = {},
    getAPIErrorMessage?: (response: any) => string
  ) => {
    return fetchApi(path, "GET", {
      ...init,
      searchParams,
      getAPIErrorMessage,
    });
  };

  const postApi = async (
    path: string | string[],
    init: RequestInit = {},
    getAPIErrorMessage?: (response: any) => string
  ) => {
    return fetchApi(path, "POST", {
      initParams: init,
      getAPIErrorMessage,
    });
  };

  const putApi = async (
    path: string | string[],
    init: RequestInit = {},
    getAPIErrorMessage?: (response: any) => string
  ) => {
    return fetchApi(path, "PUT", {
      initParams: init,
      getAPIErrorMessage,
    });
  };

  const deleteApi = async (
    path: string | string[],
    init: RequestInit = {},
    getAPIErrorMessage?: (response: any) => string
  ) => {
    return fetchApi(path, "DELETE", {
      initParams: init,
      getAPIErrorMessage,
    });
  };

  return {
    fetchApi,
    getApi,
    postApi,
    putApi,
    deleteApi,
  };
}
