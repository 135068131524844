import { createSelector } from "reselect";
import { SearchFilterProps } from "./searchFilterReducers";

const selectRaw = (state: { bulk_search: { filter: SearchFilterProps } }) =>
  state.bulk_search.filter;

const selectFilter = createSelector([selectRaw], (raw) => raw.filter);

const selectQuery = createSelector([selectRaw], (raw) => raw.query);

const selectLoading = createSelector([selectRaw], (raw) =>
  Boolean(raw.loading)
);

const searchViewSelectors = {
  selectLoading,
  selectRaw,
  selectFilter,
  selectQuery,
};

export default searchViewSelectors;
