import { useMemo } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDatasetMetadata } from "src/modules/api/datasetMetadata";
import { chain } from "lodash";

export interface RemoveDatasetsDialogProps {
  datasets: string[];
  handleClose: () => void;
  handleRemove: (datasets: string[]) => void;
}
export default function RemoveDatasetsDialog({
  datasets,
  handleClose,
  handleRemove,
}: RemoveDatasetsDialogProps) {
  const { getDatasetMetadata } = useDatasetMetadata();
  const { data: allDatasets } = getDatasetMetadata();

  const handleSubmit = () => {
    handleRemove(datasets);
    handleClose();
  };

  const { displayNames, nExtra } = useMemo(() => {
    const displayNames = chain(datasets)
      .take(5)
      .map((id) => allDatasets.find((d) => d.id === id).name)
      .value();
    const nExtra = datasets.length - displayNames.length;

    return { displayNames, nExtra };
  }, [datasets]);

  return (
    <Dialog open={Boolean(datasets.length > 0)}>
      <DialogTitle>Remove Datasets</DialogTitle>
      <IconButton
        aria-label="close"
        color="primary"
        onClick={() => handleClose()}
        sx={{
          position: "absolute",
          right: 8,
          top: 9,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <DialogContentText>
          Are you sure you want to remove the following datasets from this
          collection?
        </DialogContentText>
        <Box
          sx={{
            p: 1,
            border: "solid 1px",
            borderColor: "divider",
            borderRadius: 1,
          }}
        >
          <Typography
            variant="caption"
            color="primary.light"
            sx={{ wordBreak: "break-word" }}
          >
            {displayNames.join(",  ")} {nExtra > 0 ? ` +${nExtra}` : ""}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleSubmit()}
        >
          Remove
        </Button>
        <Button color="primary" onClick={() => handleClose()}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
