import { SearchHistory } from "src/types/Search";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import theme from "src/theme";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import SearchHistoryAppliedFilters from "src/view/search-result/components/SearchHistory/SearchHistoryAppliedFilters";
import { ReactComponent as IconDelete } from "src/assets/icons/delete.svg";
import { useSearchHistory } from "src/modules/api/searchHistory";
import { usePostHog } from "posthog-js/react";
import { HogEvent } from "src/types/PosthogEvents";

const StyledHistoryItem = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "32px",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: 4,
  marginLeft: `-8px`,
  "&:hover": {
    backgroundColor: theme.palette.grey[100],
    ".delete-search-history-btn": {
      opacity: 1,
    },
  },
}));

const StyledSearchHistoryItemTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 14,
  maxWidth: 490,
  fontWeight: 500,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
}));

const StyledText = styled(Typography)(() => ({
  fontSize: 10,
  lineHeight: "12.1px",
  fontWeight: "400",
  color: theme.palette.grey[500],
}));

const StyledDeleteButton = styled(IconButton)(() => ({
  opacity: 0,
  "& svg": {
    color: theme.palette.primary.main,
  },
}));

const StyledSearchButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
  "& svg": {
    color: theme.palette.primary.main,
    width: 16,
    height: 16,
  },
}));

interface Props {
  item: SearchHistory;
  handleSearch: (data: SearchHistory) => void;
  isAvailable: boolean;
}

const SearchHistoryItem = ({ item, handleSearch, isAvailable }: Props) => {
  const posthog = usePostHog();
  const { deleteSearchHistoryItem } = useSearchHistory();
  const { mutateAsync: deleteItem } = deleteSearchHistoryItem;

  // TODO: review availability condition, clean up conditional layout
  if (!isAvailable) {
    return (
      <StyledHistoryItem key={item.id}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flex: "1 0",
          }}
        >
          <StyledSearchHistoryItemTitle>
            {item.content}
          </StyledSearchHistoryItemTitle>
        </Box>

        <Box
          alignItems="center"
          justifyContent="center"
          sx={{ display: "flex", flex: "0 0", gap: 2 }}
        >
          <Tooltip
            placement={"bottom"}
            arrow
            title={
              "This search contained datasets that are no longer available to you."
            }
            enterDelay={250}
            PopperProps={{ style: { zIndex: 1503 } }}
          >
            <WarningAmberIcon
              style={{
                color: theme.palette.grey[500],
                width: 16,
                height: 16,
              }}
            />
          </Tooltip>
        </Box>
      </StyledHistoryItem>
    );
  }

  return (
    <StyledHistoryItem key={item.id}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "8px",
          alignItems: "center",
          flex: "1 0",
          mr: 1,
        }}
      >
        <StyledDeleteButton
          disableRipple
          className="delete-search-history-btn"
          onClick={() => {
            deleteItem(item.id);
            posthog.capture(HogEvent.SEARCH_HISTORY_ITEM_DELETED);
          }}
        >
          <IconDelete />
        </StyledDeleteButton>

        <StyledSearchHistoryItemTitle>
          {item.content}
        </StyledSearchHistoryItemTitle>
        <StyledText>
          {item.result_count} {item.result_count === 1 ? "result" : "results"}
        </StyledText>
      </Box>

      <Box
        alignItems="center"
        justifyContent="center"
        sx={{ display: "flex", flex: "0 0", gap: 1 }}
      >
        <SearchHistoryAppliedFilters item={item} />
        <StyledSearchButton
          disableRipple
          size="medium"
          aria-label="search from search history"
          aria-haspopup="true"
          onClick={() => {
            handleSearch(item);
            posthog.capture(HogEvent.SEARCH_FROM_HISTORY);
          }}
          sx={{
            color: "inherit",
            p: "4px",
          }}
        >
          <SearchIcon />
        </StyledSearchButton>
      </Box>
    </StyledHistoryItem>
  );
};

export default SearchHistoryItem;
