import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import _ from "lodash";
import { Colors } from "src/view/constants/colors";
import BulkSearchService from "src/modules/bulk-search/bulkSearchService";
import DeleteIconUrl from "src/assets/images/delete.png";
import { TermItem, QueryItem } from "../type";
import MatchSelect from "./MatchSelect";
import Query from "./Query";
import StartAddingTerms from "./StartAddingTerms";

const Table = styled(Box)({
  position: "relative",
  display: "inline-block",
  paddingRight: 28,
  paddingBottom: 28,
});

const Header = styled(Box)({
  display: "flex",
  backgroundColor: "#F6F6F6",
  border: "1px solid #DFE5E9",
});

const HeaderColumn = styled(Box)({
  position: "relative",
  display: "flex",
  alignItems: "center",
  width: 290,
  borderLeft: "1px solid #DFE5E9",
  boxSizing: "border-box",
  padding: "0 16px",
  ":first-of-type": {
    paddingLeft: 0,
  },
  ":last-child": {
    paddingRight: 0,
  },

  ".term": {
    fontSize: 12,
    fontWeight: 700,
    color: "rgba(20, 74, 104, 0.5)",
    textTransform: "uppercase",
    marginRight: "auto",
  },

  ".operator": {
    position: "absolute",
    left: 0,
    padding: "4px 8px",
    border: "1px solid rgba(4, 61, 93, 0.8)",
    backgroundColor: "#F9FAFB",
    color: Colors.twilight,
    borderRadius: 10,
    fontWeight: 700,
    fontSize: 10,
    lineHeight: 1,
    transform: "translateX(-50%)",
  },
});

const AddButton = styled(Box)({
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 1,
  borderRadius: 2,
  cursor: "pointer",
  backgroundColor: "rgba(20, 74, 104, 0.06)",
  "&:hover": {
    backgroundColor: "rgba(20, 74, 104, 0.1)",
  },
  svg: {
    color: Colors.twilight,
    fontSize: 16,
  },
});

const Row = styled(Box)({
  display: "flex",
  border: "1px solid #DFE5E9",
  borderTop: "none",
});

const DeleteImg = styled("img")({
  display: "flex",
  width: 20,
  padding: 8,
  cursor: "pointer",
  opacity: 0.6,
  objectFit: "contain",
  "&:hover": {
    opacity: 1,
  },
});

const DeleteRowButton = styled(Button)({
  width: "10px",
  minWidth: "10px",
  height: "10px",
  margin: "8px",
  marginTop: "14px",
  backgroundColor: "#e1e4e8",
  borderRadius: 4,
});

interface Props {
  groupId: string;
  searchKey: string;
  modifiedTerms: Record<string, TermItem>;
}

function TermsList(props: Props) {
  const { groupId, searchKey, modifiedTerms } = props;
  const refData = useRef({
    page: 0,
    hasNextPage: false,
    results: [],
    headerMatchs: [],
  });
  const data = refData.current;
  const [loading, setLoading] = useState(false);
  const [terms, setTerms] = useState<TermItem[]>();
  const displayedTerms = terms?.filter((term) => !term.deleted);

  useEffect(() => {
    if (groupId) {
      getSearchTerms(1);
    } else {
      setTerms([]);
    }
  }, []);

  useEffect(() => {
    terms?.forEach((term) => {
      if (!term.query && term.deleted) {
        delete modifiedTerms[term.id];
      } else {
        modifiedTerms[term.id] = term;
      }
    });
  }, [terms]);

  useEffect(() => {
    if (loading) return;
    window.addEventListener("scroll", checkNextPage);
    return () => {
      window.removeEventListener("scroll", checkNextPage);
    };
  }, [loading]);

  const checkNextPage = useCallback(() => {
    if (
      data.hasNextPage &&
      document.body.scrollHeight - window.innerHeight - window.pageYOffset < 10
    ) {
      getSearchTerms(data.page + 1);
    }
  }, []);

  const getSearchTerms = useCallback(async (page: number) => {
    setLoading(true);
    const res = await BulkSearchService.getSearchItems(
      groupId,
      searchKey,
      { query: 0, total_results: 0 },
      page
    );
    setLoading(false);
    if (!res || data.page === page) return;

    data.page = page;
    data.hasNextPage = res.count > page * 50;
    data.results = [...data.results, ...res.results];

    let terms = data.results
      ?.filter(({ id }) => !modifiedTerms[id]?.deleted)
      .map(
        ({ id, query }) =>
          modifiedTerms[id] || {
            id,
            query,
            items: query.split(" AND ").map((item: string) => {
              const [value, match = "0"] = item.split("~");
              return {
                value: value.replace(/^"(.*)"$/, "$1"),
                match: parseInt(match),
              };
            }),
          }
      );
    if (!data.hasNextPage) {
      terms = terms.concat(
        Object.values(modifiedTerms).filter(
          (term) => !term.deleted && terms.every(({ id }) => id !== term.id)
        )
      );
    }
    terms = terms.filter((term) =>
      term.items.some((item: unknown) =>
        item["value"].toLowerCase().includes(searchKey)
      )
    );

    const columnCount = Math.max(
      data.headerMatchs.length,
      ...terms.map(({ items }) => items.length)
    );

    if (data.headerMatchs.length < columnCount) {
      data.headerMatchs = Array.from({ length: columnCount }).map(
        (_, i) => data.headerMatchs[i] || 0
      );
    }

    terms.forEach((term) => {
      if (term.items.length < columnCount) {
        term.items = Array.from({ length: columnCount }).map(
          (_, i) => term.items[i] || { value: "", match: 0 }
        );
      }
    });
    setTerms(terms);
  }, []);

  const initTerms = () => {
    data.headerMatchs = [0];
    setTerms([
      {
        id: `${+new Date()}`,
        items: [{ value: "", match: 0 }],
      },
    ]);
  };

  const addColumn = () => {
    data.headerMatchs.push(0);
    terms.forEach((term) => {
      term.items.push({ value: "", match: 0 });
    });
    setTerms(terms.slice());
  };

  const addRow = () => {
    setTerms([
      ...terms,
      {
        id: `${+new Date()}`,
        items: data.headerMatchs.map((match) => ({ value: "", match })),
      },
    ]);
  };

  const deleteColumn = (index: number) => {
    if (data.headerMatchs.length === 1) {
      deleteAll();
      return;
    }
    data.headerMatchs.splice(index, 1);
    terms.forEach((term) => {
      term.items.splice(index, 1);
    });
    setTerms(terms.slice());
  };

  const deleteRow = (id: string) => {
    setTerms(
      terms.map((term) => (term.id === id ? { ...term, deleted: true } : term))
    );
    checkNextPage();
  };

  const deleteAll = () => {
    data.headerMatchs = [];
    setTerms(terms.map((term) => ({ ...term, deleted: true })));
    setTimeout(() => {
      checkNextPage();
    });
  };

  const changeHeaderMatch = (index: number, match: number) => {
    window.dispatchEvent(
      new CustomEvent("editing-query", { detail: { index } })
    );
    data.headerMatchs = data.headerMatchs.map((value, i) =>
      i === index ? match : value
    );
    setTerms((terms) =>
      terms.map((term) => ({
        ...term,
        items: term.items.map((item, i) =>
          i === index ? { ...item, match } : item
        ),
      }))
    );
  };

  const changeQuery = useCallback(
    (id: string, index: number, updatedItem: QueryItem) => {
      const { match } = updatedItem;
      if (match !== undefined) {
        data.headerMatchs = data.headerMatchs.map((value, i) =>
          i === index && match !== value ? 0 : value
        );
      }
      setTerms((terms) => {
        const term = _.find(terms, { id });
        term.items = term.items.map((item, i) =>
          i === index ? { ...item, ...updatedItem } : item
        );
        return terms.slice();
      });
    },
    []
  );

  return (
    <Box>
      {displayedTerms?.length === 0 && !data.hasNextPage && (
        <StartAddingTerms onStart={searchKey ? null : initTerms} />
      )}
      {displayedTerms?.length > 0 && (
        <Table>
          <Header>
            <DeleteRowButton onClick={deleteAll}>-</DeleteRowButton>
            {data.headerMatchs.map((value, index) => (
              <HeaderColumn key={index}>
                <DeleteImg
                  src={DeleteIconUrl}
                  alt="Delete Icon"
                  onClick={() => deleteColumn(index)}
                />
                <Box className="term">{`Term ${index + 1}`}</Box>
                <MatchSelect
                  value={value}
                  onChange={(value) => changeHeaderMatch(index, value)}
                />
                {index > 0 && <div className="operator">AND</div>}
              </HeaderColumn>
            ))}
          </Header>

          {displayedTerms.map((term) => (
            <Row key={term.id}>
              <DeleteRowButton onClick={() => deleteRow(term.id)}>
                -
              </DeleteRowButton>
              {term.items.map((item, index) => (
                <Query
                  key={index}
                  termId={term.id}
                  index={index}
                  item={item}
                  onChange={changeQuery}
                />
              ))}
            </Row>
          ))}

          <AddButton
            onClick={addColumn}
            sx={{ right: 0, top: 0, bottom: "28px" }}
          >
            <AddOutlinedIcon />
          </AddButton>

          {!loading && (
            <AddButton
              onClick={addRow}
              sx={{ left: "36px", right: "28px", bottom: 0 }}
            >
              <AddOutlinedIcon />
            </AddButton>
          )}
        </Table>
      )}
      {loading && (
        <Box ml="10px" mt="-10px">
          <CircularProgress size={20} />
        </Box>
      )}
    </Box>
  );
}

export default memo(TermsList);
