import React from "react";
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Chip,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import ExcludableCheckbox from "src/view/components/ExcludableCheckbox";
import { DataCollectionFilterOption } from "src/types/SearchFilter";
import theme from "src/theme";
import { useSearch } from "src/modules/api/search";

export interface DataCollectionFilterProps {
  options: DataCollectionFilterOption[];
}

export default function DataCollectionFilter({
  options,
}: DataCollectionFilterProps) {
  const { variables, updateFilters } = useSearch();

  const renderLabel = (option: string) => {
    return options.find(({ value }) => value === option)?.label;
  };

  const isChecked = (option: string) => {
    return variables.filters?.data_collection_ids.includes(option);
  };

  const isExcluded = (option: string) => {
    return variables.filters?.exclude_data_collection_ids.includes(option);
  };

  // eslint-disable-next-line
  const handleChange = (
    event: React.SyntheticEvent,
    value: string[],
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails
  ) => {
    const { option } = details;
    if (
      !variables.filters.data_collection_ids ||
      !variables.filters.exclude_data_collection_ids
    ) {
      return;
    }

    let payload;
    if (variables.filters.data_collection_ids.includes(option)) {
      payload = {
        filters: {
          data_collection_ids: variables.filters.data_collection_ids.filter(
            (id) => id !== option
          ),
          exclude_data_collection_ids: [
            ...variables.filters.exclude_data_collection_ids,
            option,
          ],
        },
      };
    } else if (variables.filters.exclude_data_collection_ids.includes(option)) {
      payload = {
        filters: {
          exclude_data_collection_ids:
            variables.filters.exclude_data_collection_ids.filter(
              (id) => id !== option
            ),
        },
      };
    } else {
      payload = {
        filters: {
          data_collection_ids: [
            ...variables.filters.data_collection_ids,
            option,
          ],
        },
      };
    }

    updateFilters(payload.filters);
  };

  const handleClear = () => {
    updateFilters({
      data_collection_ids: [],
      exclude_data_collection_ids: [],
    });
  };

  const value = [
    ...(variables.filters?.data_collection_ids || []),
    ...(variables.filters?.exclude_data_collection_ids || []),
  ];

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      size="small"
      options={options.map(({ value }) => value)}
      disablePortal
      value={value}
      onChange={handleChange}
      componentsProps={{
        clearIndicator: {
          onClick: () => handleClear(),
        },
      }}
      getOptionLabel={(option: string) => renderLabel(option)}
      renderOption={(props, option) => (
        <li {...props}>
          <ExcludableCheckbox
            sx={{ mr: 1 }}
            checked={isChecked(option)}
            excluded={isExcluded(option)}
          />
          <Typography sx={{ fontFamily: "Inter", fontSize: 12 }}>
            {renderLabel(option)}
          </Typography>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={value.length === 0 ? "Filter by data collection" : null}
          sx={{
            "& .MuiInputBase-input": {
              fontSize: 12,
              fontFamily: "Inter",
            },
            "& .MuiSvgIcon-root": {
              color: "primary.main",
            },
            "& .MuiInputBase-root": {
              "&.Mui-focused fieldset": {
                borderColor: "primary.main",
                borderWidth: 1,
              },
            },
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment:
              value.length === 0 ? (
                <InputAdornment position="start" sx={{ ml: 1, mr: 0.5 }}>
                  <FilterListIcon color="primary" fontSize="small" />
                </InputAdornment>
              ) : (
                params.InputProps.startAdornment
              ),
          }}
        />
      )}
      renderTags={(tagValue: readonly string[], getTagProps) => (
        <>
          <FilterListIcon
            color="primary"
            fontSize="small"
            sx={{ ml: 1, mr: 0.5 }}
          />
          {tagValue.slice(0, 3).map((option, index) => (
            <Chip
              label={renderLabel(option)}
              color={isChecked(option) ? "primary" : "default"}
              sx={{
                bgcolor: isExcluded(option) && theme.palette.grey[600],
                color: isExcluded(option) && "white",
                "& .MuiChip-deleteIcon": {
                  color: "white",
                  opacity: 0.6,
                  ":hover": {
                    color: "white",
                    opacity: 1,
                  },
                },
              }}
              size="small"
              {...getTagProps({ index })}
            />
          ))}
          <Typography variant="caption" color="primary" sx={{ ml: 0.5 }}>
            {value.length > 3 && `+${value.length - 3}`}
          </Typography>
        </>
      )}
    />
  );
}
