import React from "react";
import { useEffect } from "react";

const useClickAway = (
  ref: React.MutableRefObject<HTMLUnknownElement>,
  onClickAway: () => void
) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        event.target instanceof HTMLElement &&
        ref["current"] &&
        !ref["current"].contains(event.target)
      ) {
        onClickAway();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

export default useClickAway;
