import { Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomizedTimeline from "./Connector";
import InputBox from "./InputBox";
import StyledButton from "./StyledButton";
import AddIcon from "@mui/icons-material/Add";
import { QueryItem } from "src/types/BulkSearch";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  DraggingStyle,
  NotDraggingStyle,
} from "@hello-pangea/dnd";
import { CSSProperties } from "react";

const StyledContainer = styled("div")(() => ({
  position: "relative",
  background: "#FFFFFF",
  border: "1px solid #E6E6E6",
  boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.08)",
  borderRadius: "8px",
  padding: "20px",
}));

const TopCrossLineEle = styled("div")(() => ({
  position: "relative",
  top: "9px",
  left: 31,
  width: 40,
  height: "100%",
  borderLeft: "1.5px solid #FED9A8",
  borderTop: "1.5px solid #FED9A8",
}));

const BottomCrossLineEle = styled("div")(() => ({
  position: "relative",
  top: "-15px",
  left: 31,
  width: 40,
  height: "100%",
  borderLeft: "1.5px solid #FED9A8",
  borderBottom: "1.5px solid #FED9A8",
}));

const StyledBadge = styled("div")(() => ({
  position: "absolute",
  width: "auto",
  background: "#52A8F9",
  borderRadius: 8,
  height: 15,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  top: -8,
  left: 24,
  padding: "4px 8px",
  textTransform: "capitalize",
  color: "white",
  fontSize: 12,
  lineHeight: "15px",
  fontWeight: "700",
  textAlign: "center",
}));

const QueryGroupBox = (props: {
  query: QueryItem;
  index: number;
  handleChange: (q: QueryItem, index: number) => void;
}) => {
  const { query, index, handleChange } = props;
  const formQuery = query.form;

  const handleChangeQueryItem = (_q: QueryItem, _index: number) => {
    const _formQuery = [...formQuery];
    if (!_q) {
      if (_index === 0) {
        if (!formQuery.length) {
          _formQuery.splice(_index, 1);
        } else {
          _formQuery.splice(_index + 1, 1);
          _formQuery.splice(_index, 1);
        }
      } else {
        _formQuery.splice(_index, 1);
        _formQuery.splice(_index - 1, 1);
      }
    } else {
      _formQuery[_index] = _q;
    }
    const _query = {
      type: query.type,
      form: _formQuery,
    };
    if (_formQuery.length < 3) return;
    handleChange(_query, index);
  };

  const onAddQuery = () => {
    const operatorQuery = {
      type: "operator",
      value: "AND",
    };
    const termQuery = {
      type: "term",
      value: "",
      fuzziness: 0,
    };
    const newFormArr = [...formQuery, operatorQuery, termQuery];
    const _query = {
      type: query.type,
      form: newFormArr,
    };
    handleChange(_query, index);
  };

  const handleRemoveQueryGroup = () => {
    const _query: QueryItem = null;
    handleChange(_query, index);
  };

  // a little function to help us with reordering the result
  const reorder = (list: QueryItem[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    if (list.length <= 2) return result;
    if (endIndex % 2 == 1) {
      if (Math.abs(startIndex - endIndex) > 2 && startIndex < endIndex) {
        endIndex -= 1;
      } else {
        endIndex += 1;
      }
    }
    const [queryItem] = result.splice(startIndex, 1);
    const [operatorItem] =
      startIndex == 0
        ? result.splice(startIndex, 1)
        : result.splice(startIndex - 1, 1);

    if (startIndex > endIndex) {
      if (endIndex == 0) {
        result.splice(endIndex, 0, operatorItem);
        result.splice(endIndex, 0, queryItem);
      } else {
        result.splice(endIndex, 0, operatorItem);
        result.splice(endIndex, 0, queryItem);
      }
    } else {
      result.splice(endIndex - 1, 0, queryItem);
      result.splice(endIndex - 1, 0, operatorItem);
    }

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const _items: QueryItem[] = reorder(
      formQuery,
      result.source.index,
      result.destination.index
    );
    const _query = {
      type: query.type,
      form: _items,
    };
    handleChange(_query, index);
  };
  const getListStyle = (isDraggingOver: boolean) => ({
    padding: 0,
    width: "100%",
    marginBottom: !isDraggingOver ? 0 : "40px",
    minHeight: getMinHeight(),
  });

  const getMinHeight = () => {
    let height = 0;
    formQuery?.map((item: QueryItem) => {
      if (item.type === "term") {
        height += 30;
      }
    });
    return height;
  };

  const getItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined
  ) => {
    return {
      userSelect: "none",
      paddingBottom: isDragging ? 20 : 0,
      ...draggableStyle,
    } as CSSProperties;
  };

  return (
    <StyledContainer>
      <StyledBadge>{query["type"]}</StyledBadge>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {formQuery &&
                formQuery.map((q: QueryItem, index: number) => (
                  <Grid
                    container
                    sx={{ display: "flex", position: "relative" }}
                    key={index}
                  >
                    <Grid
                      item
                      sx={{
                        width: 90,
                      }}
                    >
                      {q.type === "operator" && (
                        <CustomizedTimeline
                          query={q}
                          index={index}
                          isGroup={true}
                          updateConnector={handleChangeQueryItem}
                        />
                      )}
                      {q.type !== "operator" &&
                        index === 0 &&
                        formQuery.length > 1 && <TopCrossLineEle />}
                      {q.type !== "operator" &&
                        index === formQuery.length - 1 &&
                        formQuery.length > 1 && <BottomCrossLineEle />}
                    </Grid>
                    <Draggable
                      key={`draggable-${index}`}
                      draggableId={`draggable-${index}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <Grid
                          item
                          xs={9}
                          sx={{
                            justifyContent: "flex-start",
                            display: "block",
                            height: "100%",
                            alignItems: "flex-start",
                          }}
                          key={index}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          {q.type === "term" && (
                            <Grid item>
                              <Grid item key={"query-builder-input-" + index}>
                                <InputBox
                                  query={q}
                                  index={index}
                                  handleChange={handleChangeQueryItem}
                                  dragHandleProps={provided.dragHandleProps}
                                />
                                <Box height={15} />
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      )}
                    </Draggable>
                  </Grid>
                ))}
            </div>
          )}
        </Droppable>
        <Grid
          container
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Grid item container xs={8}>
            <StyledButton
              text="Add Term"
              icon={<AddIcon />}
              onClick={onAddQuery}
            />
          </Grid>
          <Grid item>
            <img
              src={require("src/assets/images/delete-secondary.png")}
              style={{ width: 20, height: 20, cursor: "pointer" }}
              onClick={handleRemoveQueryGroup}
            />
          </Grid>
        </Grid>
      </DragDropContext>
    </StyledContainer>
  );
};

export default QueryGroupBox;
