import { Dataset } from "src/types/Dataset";
import actions from "./actions";

export interface Props {
  loading: boolean;
  error?: string;
  datasets?: Dataset[];
}

const initialData: Props = {
  loading: false,
  error: null,
  datasets: [],
};

export default (
  state = initialData,
  { type, payload }: { type: string; payload: boolean | string | Dataset[] }
) => {
  if (type === actions.LOADING) {
    return {
      ...state,
      loading: payload,
    };
  }
  if (type === actions.UPDATE_ERROR) {
    return {
      ...state,
      loading: false,
      error: payload,
    };
  }
  if (type === actions.GET_LIST) {
    return {
      ...state,
      loading: false,
      datasets: payload,
    };
  }
  if (type === actions.UPDATE_SUCCESS) {
    return {
      ...state,
      loading: false,
      datasets: payload,
    };
  }

  return state;
};
