import { AnyAction, Dispatch } from "redux";
import { DataCollection } from "src/types/DataCatalog";
import SearchService from "src/modules/search/searchService";
import Errors from "src/modules/shared/error/errors";
import DataCatalogService from "../service";

const prefix = "BULK_SEARCH_FILTER";

const actions: AnyAction = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  GET_SUCCESS: `${prefix}_GET_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,
  SET_FILTER: `${prefix}_SET_FILTER`,
  SET_QUERY: `${prefix}_SET_QUERY`,
  GET_DATACATALOG: `${prefix}_GET_DATACATALOG`,
  LOADING: `${prefix}_LOADING`,

  getDataset: (id: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({
        type: actions.LOADING,
      });
      const data = await SearchService.getDataset(id);
      dispatch({
        type: actions.LOADING,
      });
      return data;
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: actions.FIND_ERROR,
      });
    }
  },
  getDataCatalog: () => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({
        type: actions.FIND_STARTED,
      });

      let data = await DataCatalogService.getDataCatalog();

      if (!data) data = [];
      dispatch({
        type: actions.GET_DATACATALOG,
        payload: data,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.FIND_ERROR,
      });
    }
  },
  create: (data: DataCollection) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({
        type: actions.LOADING,
      });

      const res = await DataCatalogService.createDataCatalog(data);
      dispatch({
        type: actions.LOADING,
      });
      return res;
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.FIND_ERROR,
      });
    }
  },
  update: (data: DataCollection) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({
        type: actions.LOADING,
      });

      const res = await DataCatalogService.updateDataCatalog(data);

      dispatch({
        type: actions.LOADING,
      });
      return res;
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.FIND_ERROR,
      });
    }
  },
  delete: (id: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({
        type: actions.LOADING,
      });

      const res = await DataCatalogService.deleteDataCatalog(id);
      dispatch({
        type: actions.LOADING,
      });
      return res;
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: actions.FIND_ERROR,
      });
    }
  },

  type: undefined,
};

export default actions;
