export const Colors = {
  white: "#FFFFFF",
  black: "#131521",
  twilight: "#043D5D",
  mist: "#A0B9D0",
  mist100: "#DDE4EC",
  primary: "#043D5D",
  secondary: "#F75151",
  secondary2: "#F7515D",
  textGray: "#7B8694",
  textGray100: "#6D90A3",
  textGray200: "#5A7290",
  textGray300: "#9E9E9E",
  textGray400: "#1D1D1B",
  textGray500: "#536378",
  textGray600: "#9BA5B1",
  textGray800: "#536478",
  textGray900: "#122945",
  textDark: "#144A68",
  textDark100: "#122945",
  bgGray1: "#EDF1F5",
  bgGray2: "#EEF1F2",
  bgGray3: "#E8E8E8",
  bgGray4: "#E5EAED",
  bgGray5: "#F7F8F9",
  link1: "#0B83F2",
  success: "#27A643",
};
