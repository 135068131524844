import { AnyAction, Dispatch } from "redux";
import { ScreeningList } from "src/types/BulkSearch";
import Errors from "src/modules/shared/error/errors";
import BulkSearchService from "../bulkSearchService";

const prefix = "BULK_SEARCH_SCREENING_LIST";

const screeningActions: AnyAction = {
  LOADING: `${prefix}_LOADING`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,
  CREATE: `${prefix}_CREATE`,
  UPDATE: `${prefix}_UPDATE`,
  EDIT: `${prefix}_EDIT`,
  FIND_LIST: `${prefix}_FIND_LIST`,
  GET_SCREENING_LISTS: `${prefix}_GET_SCREENING_LISTS`,
  CLEAR_UPDATE_STATUS: `${prefix}_CLEAR_UPDATE_STATUS`,
  DELETE_ONE: `${prefix}_DELETE_ONE`,

  create: (data: ScreeningList) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({
        type: screeningActions.LOADING,
        payload: true,
      });

      const response = await BulkSearchService.create(data);

      dispatch({
        type: screeningActions.CREATE,
        payload: response,
      });
      return response;
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: screeningActions.LOADING,
        payload: false,
      });
      return false;
    }
  },
  update: (data: ScreeningList) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({
        type: screeningActions.LOADING,
        payload: true,
      });
      const response = await BulkSearchService.updateScreeningList(data);
      dispatch({
        type: screeningActions.UPDATE,
        payload: response,
      });
      return response;
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: screeningActions.LOADING,
        payload: false,
      });
      return false;
    }
  },

  getScreeningLists: () => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({
        type: screeningActions.LOADING,
        payload: true,
      });

      const response = await BulkSearchService.getScreeningLists();

      dispatch({
        type: screeningActions.GET_SCREENING_LISTS,
        payload: response,
      });
    } catch (error) {
      Errors.handle(error);
    }
  },
  clearError: () => async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: screeningActions.GET_SCREENING_LISTS,
      payload: { created_by: [], shared_with_groups: [] },
    });
  },
  clearUpdateStatus: () => async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: screeningActions.CLEAR_UPDATE_STATUS,
    });
  },
  delete: (id: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({
        type: screeningActions.LOADING,
        payload: true,
      });

      const response = await BulkSearchService.deleteScreeningList(id);

      dispatch({
        type: screeningActions.DELETE_ONE,
      });
      return response;
    } catch (error) {
      Errors.handle(error);
    }
  },
  runScreeningListSearch: (id: string) => async () => {
    try {
      const response = await BulkSearchService.performSearchScreeningList(id);
      return response;
    } catch (error) {
      Errors.handle(error);
    }
  },
  getScreeningList: (id: string) => async () => {
    try {
      const response = await BulkSearchService.getScreeningList(id);
      return response;
    } catch (error) {
      return null;
    }
  },
  getSharedGroups: (screening_list_id: string) => async () => {
    try {
      const response = await BulkSearchService.getSharedGroups(
        screening_list_id
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  duplicateScreeningList: (screening_list_id: string) => async () => {
    try {
      const response = await BulkSearchService.duplicateScreeningList(
        screening_list_id
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  getScreeningListMonitorStatus: (screening_list_id: string) => async () => {
    try {
      const response = await BulkSearchService.getScreeningListMonitorStatus(
        screening_list_id
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  type: undefined,
};

export default screeningActions;
