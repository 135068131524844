import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Footer from "../layout/Footer";
import DataDictionary from "./data-dictionary";
import DataDetail from "./detail";
import DataEdit from "./edit";
import { AuthRouteGuard } from "src/modules/api/auth";
import { Features } from "src/types/Auth";

const LandingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Routes>
        <Route
          path="data"
          element={
            <AuthRouteGuard
              feature={Features.DATASET}
              component={DataDictionary}
            />
          }
        />
        <Route path="data/:id" element={<DataDetail />} />
        <Route path="data/edit">
          <Route path=":id" element={<DataEdit />} />
        </Route>
      </Routes>
      <Footer />
    </div>
  );
};

export default LandingPage;
