import React from "react";
import { Colors } from "src/view/constants/colors";

import { Paper, IconButton, InputBase, styled } from "@mui/material";

const StyledImage = styled("img")(() => ({
  width: 14,
  height: 14,
}));

interface Props {
  filterInput: string;
  handleFilter: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const DatasetItemFilter = ({ filterInput, handleFilter }: Props) => {
  return (
    <Paper
      elevation={0}
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: 200,
        borderRadius: "4px",
        backgroundColor: "rgba(20, 74, 104, 0.08)",
      }}
    >
      <IconButton sx={{ p: "10px" }} aria-label="menu">
        <StyledImage src={require("src/assets/images/search-black.png")} />
      </IconButton>
      <InputBase
        value={filterInput}
        sx={{ ml: 0, flex: 1, color: Colors.twilight }}
        placeholder="Search Dataset"
        inputProps={{ "aria-label": "search dataset" }}
        onChange={handleFilter}
      />
    </Paper>
  );
};

export default DatasetItemFilter;
