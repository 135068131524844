import React from "react";
import { Box, IconButton, styled, SvgIcon } from "@mui/material";
import {
  Cancel as IconFailed,
  CheckCircle as IconCompleted,
  HourglassTop as IconProgress,
} from "@mui/icons-material";
import { ReactComponent as IconQueue } from "src/assets/images/time.svg";
import theme from "src/theme";
import { TaskStatusFiltersKeys } from "src/types/Dataset";
import { SxProps, Theme } from "@mui/material/styles";

const FilterIconButton = styled(IconButton)(() => ({
  padding: "4px",

  "& svg": {
    height: "16px",
    width: "16px",
  },
}));

interface Props {
  filter: TaskStatusFiltersKeys;
  setFilter: (key: TaskStatusFiltersKeys) => void;
}

const StatusFilter = ({ filter, setFilter }: Props) => {
  const handleFilterClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const currentKey = e.currentTarget.name as TaskStatusFiltersKeys;
    if (filter === currentKey) {
      return setFilter(null);
    }

    return setFilter(currentKey);
  };

  const getIconStyles = (key: TaskStatusFiltersKeys): SxProps<Theme> => {
    const activeColors = {
      [TaskStatusFiltersKeys.IN_QUEUE]: "#3B83EF",
      [TaskStatusFiltersKeys.PROCESSING]: "#3B83EF",
      [TaskStatusFiltersKeys.COMPLETED]: theme.palette.success.main,
      [TaskStatusFiltersKeys.FAILED]: theme.palette.secondary.main,
    };

    if (!filter || filter === key) {
      return {
        color: activeColors[key],
      };
    }
  };

  // TODO: refactor duplicates
  return (
    <Box
      sx={{
        maxHeight: "23px",
        display: "flex",
        backgroundColor: theme.palette.grey[200],
        borderRadius: "25px",
      }}
    >
      <FilterIconButton
        name={TaskStatusFiltersKeys.COMPLETED}
        onClick={handleFilterClick}
      >
        <IconCompleted sx={getIconStyles(TaskStatusFiltersKeys.COMPLETED)} />
      </FilterIconButton>
      <FilterIconButton
        name={TaskStatusFiltersKeys.IN_QUEUE}
        onClick={handleFilterClick}
      >
        <SvgIcon
          component={IconQueue}
          inheritViewBox
          sx={getIconStyles(TaskStatusFiltersKeys.IN_QUEUE)}
        />
      </FilterIconButton>
      <FilterIconButton
        name={TaskStatusFiltersKeys.PROCESSING}
        onClick={handleFilterClick}
      >
        <IconProgress sx={getIconStyles(TaskStatusFiltersKeys.PROCESSING)} />
      </FilterIconButton>
      <FilterIconButton
        name={TaskStatusFiltersKeys.FAILED}
        onClick={handleFilterClick}
      >
        <IconFailed sx={getIconStyles(TaskStatusFiltersKeys.FAILED)} />
      </FilterIconButton>
    </Box>
  );
};

export default StatusFilter;
