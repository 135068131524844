import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Pagination,
  Button,
  TableBody,
  Table,
  TableHead,
  TableCell,
  Switch,
  TableRow,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CropFreeOutlinedIcon from "@mui/icons-material/CropFreeOutlined";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { arrayContained } from "src/utils";
import { Group } from "src/types/UserGroup";
import { KnowledgeWiki } from "src/types/KnowledgeWiki";
import ConfirmDialog from "src/view/components/ConfirmDialog";
import DatasetItemFilter from "src/view/users-groups/groups/EditGroup/components/DatasetItemFilter";
import WikiPreview from "src/view/users-groups/groups/EditGroup/components/WikiPreview";
import VisibilityIcon from "@mui/icons-material/Visibility";
import theme from "src/theme";

const StyledDialogTitle = styled(DialogTitle)(() => ({
  fontSize: 24,
  fontWeight: "700",
  color: theme.palette.primary.light,
  paddingBottom: 8,
}));

const tableBorder = "1px solid #9BA5B1";
const tableBorderRadius = "4px";
const StyledTable = styled(Table)(() => ({
  borderSpacing: 0,
  borderCollapse: "separate",
  overflow: "hidden",
  "& td": {
    borderRight: tableBorder,
    borderBottom: tableBorder,
    "&:first-of-type": {
      borderLeft: tableBorder,
    },
  },
  "& tbody>tr:first-of-type": {
    "&>td": {
      "&:first-of-type": {
        borderTopLeftRadius: tableBorderRadius,
      },
      "&:last-child": {
        borderTopRightRadius: tableBorderRadius,
      },
      borderTop: tableBorder,
    },
  },
  "& tbody>tr:last-child": {
    "&>td": {
      "&:first-of-type": {
        borderBottomLeftRadius: tableBorderRadius,
      },
      "&:last-child": {
        borderBottomRightRadius: tableBorderRadius,
      },
    },
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: 14,
  color: theme.palette.primary.light,
  alignItems: "center",
  textAlign: "left",
  padding: 0,
  maxHeight: 100,
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

const StyledTableHeadCell = styled(TableCell)(() => ({
  fontSize: 14,
  fontWeight: 700,
  lineHeight: "20px",
  color: theme.palette.primary.light,
  alignItems: "center",
  textAlign: "left",
  borderBottom: "none",
  padding: "8px 0",
}));
const StyledImage = styled("img")(() => ({}));

const StyledButton = styled(Button)(() => ({
  color: theme.palette.primary.light,
  borderColor: theme.palette.primary.light,
  textTransform: "capitalize",
  fontSize: 14,
  lineHeight: "20px",
  fontWeight: "700",
}));

interface Props {
  title: string;
  data: Group;
  filtered: KnowledgeWiki[];
  filterInput: string;
  handleFilter: (e: React.ChangeEvent<HTMLInputElement>) => void;
  datasets: KnowledgeWiki[];
  handleUpdate: (checked: boolean, key: string, value: string) => void;
  handleChangeAll?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  allChecked?: boolean;
}

const DatasetTable: React.FC<Props> = ({
  data,
  title,
  datasets,
  filtered,
  filterInput,
  handleFilter,
  handleUpdate,
  handleChangeAll,
}) => {
  const [displayData, setDisplayData] = useState<KnowledgeWiki[]>(filtered);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [wikiPreviewId, setWikiPreviewId] = useState(null);
  const [checkedAll, setCheckAll] = useState({
    datasets: false,
    dataset_downloads: false,
    kw_read: false,
    kw_write: false,
  });
  const [sort, setSort] = useState("desc");

  useEffect(() => {
    const _all = { ...checkedAll };
    const ds = datasets.map((dataset: KnowledgeWiki) => dataset.dataset);
    const dss = data.datasets;
    if (dss?.length > 0 && arrayContained(dss, ds)) {
      _all.datasets = true;
    } else {
      _all.datasets = false;
    }
    const dds = data.dataset_downloads;
    if (dds?.length > 0 && arrayContained(dds, ds)) {
      _all.dataset_downloads = true;
    } else {
      _all.dataset_downloads = false;
    }
    const kr = data.kw_read;
    const datasets_for_kw_read = datasets
      .filter((item: KnowledgeWiki) => kr?.includes(item.id))
      .map((item: KnowledgeWiki) => item.dataset);
    if (
      datasets_for_kw_read?.length > 0 &&
      arrayContained(datasets_for_kw_read, ds)
    ) {
      _all.kw_read = true;
    } else {
      _all.kw_read = false;
    }
    const kw = data.kw_write;
    const datasets_for_kw_write = datasets
      .filter((item: KnowledgeWiki) => kw?.includes(item.id))
      .map((item: KnowledgeWiki) => item.dataset);
    if (
      datasets_for_kw_write?.length > 0 &&
      arrayContained(datasets_for_kw_write, ds)
    ) {
      _all.kw_write = true;
    } else {
      _all.kw_write = false;
    }

    setCheckAll(_all);
  }, [data]);

  const sortDisplayData = (groupData: Group, datasets: KnowledgeWiki[]) => {
    return (
      datasets
        // sort alphabetically
        .sort((a, b) =>
          sort === "desc"
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name)
        )
        // sort by any active permission
        .sort((a, b) => {
          let isDatasetActiveA = false;
          if (data.datasets.includes(a.dataset)) isDatasetActiveA = true;
          if (data.dataset_downloads.includes(a.dataset))
            isDatasetActiveA = true;
          if (data.kw_read.includes(a.id)) isDatasetActiveA = true;
          if (data.kw_write.includes(a.id)) isDatasetActiveA = true;

          let isDatasetActiveB = false;
          if (data.datasets.includes(b.dataset)) isDatasetActiveB = true;
          if (data.dataset_downloads.includes(b.dataset))
            isDatasetActiveB = true;
          if (data.kw_read.includes(b.id)) isDatasetActiveB = true;
          if (data.kw_write.includes(b.id)) isDatasetActiveB = true;

          return isDatasetActiveA > isDatasetActiveB && -1;
        })
    );
  };

  useEffect(() => {
    if (filtered) {
      setCurrentPage(1);
      const _dd = [...filtered];
      setPageCount(Math.ceil(_dd?.length / 10));
      setDisplayData(sortDisplayData(data, _dd));
    }
  }, [filtered]);

  useEffect(() => {
    if (data && displayData) {
      setDisplayData(sortDisplayData(data, displayData));
    }
  }, [data, sort]);

  const paginateData = (data: KnowledgeWiki[], page: number) => {
    const startIndex = (page - 1) * 10;
    data = data.slice(startIndex, startIndex + 10);

    return data;
  };

  const handlechangePage = (e: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  const limited = useMemo(
    () => ["Restricted", "Sensitive"].includes(title),
    [title]
  );

  const handleOpenViewAll = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement> | boolean,
    key: string,
    value: string | null = null
  ) => {
    const isChecked = typeof e === "boolean" ? e : e.target.checked;

    // update single feature for dataset
    if (value) {
      handleUpdate(isChecked, key, value);

      // update dataset and kw_read features as one
      if (key === "datasets") {
        const dataset = datasets.find(({ dataset: ds }) => ds === value);
        if (dataset) {
          handleUpdate(isChecked, "kw_read", dataset.id);
        }
      }
    } else {
      // toggle feature for all datasets
      datasets?.forEach((dataset) => {
        if (key === "datasets") {
          handleUpdate(isChecked, key, dataset.dataset);
          handleUpdate(isChecked, "kw_read", dataset.id);
        }

        if (key === "kw_read" || key === "kw_write") {
          handleUpdate(isChecked, key, dataset.id);
        } else {
          handleUpdate(isChecked, key, dataset.dataset);
        }
      });
    }
  };

  const handleSort = () => {
    if (sort === "asc") {
      setSort("desc");
    } else {
      setSort("asc");
    }
  };

  const accessDataset = (id: string) => {
    return data.datasets?.includes(id) || data.dataset_downloads?.includes(id);
  };

  const accessWiki = (id: string) => {
    return data.kw_read?.includes(id) || data.kw_write?.includes(id);
  };

  const renderTableHead = useCallback(() => {
    return (
      <TableHead>
        <TableRow
          sx={{
            borderBottom: "1px solid #0000000f",
            width: "calc(100% - 80px)",
          }}
        >
          <StyledTableHeadCell
            sx={{
              width: "240px",
              fontSize: 12,
              color: "rgba(20, 74, 104, 0.5)",
            }}
          >
            <Box
              sx={{ display: "flex", cursor: "pointer" }}
              onClick={() => handleSort()}
            >
              Datasets &nbsp;
              <StyledImage
                src={require("src/assets/images/sort.png")}
                style={{ width: 18, height: 18 }}
              />
            </Box>
          </StyledTableHeadCell>
          <StyledTableHeadCell
            sx={{
              width: "282px",
              fontSize: 12,
              color: "rgba(20, 74, 104, 0.5)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>Search</div>
              {!limited && (
                <Switch
                  color="secondary"
                  checked={checkedAll.datasets}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(e, "datasets")
                  }
                />
              )}
            </Box>
          </StyledTableHeadCell>
          <StyledTableHeadCell
            sx={{
              width: "282px",
              fontSize: 12,
              color: "rgba(20, 74, 104, 0.5)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>Download</div>
              {!limited && (
                <Switch
                  color="secondary"
                  checked={checkedAll.dataset_downloads}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(e, "dataset_downloads")
                  }
                />
              )}
            </Box>
          </StyledTableHeadCell>
          <StyledTableHeadCell
            sx={{
              width: "282px",
              fontSize: 12,
              color: "rgba(20, 74, 104, 0.5)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>Edit</div>
              {!limited && (
                <Switch
                  color="secondary"
                  checked={checkedAll.kw_write}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(e, "kw_write")
                  }
                />
              )}
            </Box>
          </StyledTableHeadCell>
        </TableRow>
      </TableHead>
    );
  }, [
    limited,
    checkedAll.dataset_downloads,
    checkedAll.datasets,
    checkedAll.kw_read,
    checkedAll.kw_write,
  ]);

  if (!datasets?.length) {
    return (
      <Box
        sx={{
          width: "100%",
          mt: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        No Results
      </Box>
    );
  }

  const table = (paginated?: boolean) => {
    let _dd = [...displayData];
    if (paginated) {
      _dd = paginateData(_dd, currentPage);
    }

    return (
      <StyledTable
        sx={{
          width: "calc(100%)",
          overflowY: "hidden",
        }}
      >
        {renderTableHead()}
        <TableBody>
          {_dd.map((item: KnowledgeWiki) => {
            return (
              <>
                <WikiPreview
                  open={wikiPreviewId === item.id}
                  wikiId={item.id}
                  title={item.name}
                  handleClose={() => setWikiPreviewId(null)}
                />

                <TableRow key={item.id}>
                  <StyledTableCell
                    sx={{
                      maxWidth: "300px",
                      padding: "12px 16px",
                      lineHeight: "17px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {item.name}

                      <IconButton
                        onClick={() => setWikiPreviewId(item.id)}
                        sx={{
                          "&:hover > svg": { color: theme.palette.grey[900] },
                        }}
                      >
                        <VisibilityIcon
                          sx={{
                            color: theme.palette.grey[400],
                          }}
                        />
                      </IconButton>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {!limited ||
                      accessDataset(item.dataset) ||
                      accessWiki(item.id) ? (
                        <Switch
                          color="secondary"
                          checked={data.datasets?.includes(item.dataset)}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChange(e, "datasets", item.dataset)
                          }
                        />
                      ) : (
                        <ConfirmDialog
                          onConfirm={() =>
                            handleChange(true, "datasets", item.dataset)
                          }
                          title={`${title} Dataset Access`}
                          description="Have you talked with the dataset owner to give an external org access to this dataset?"
                          confirmText="Yes"
                          cancelText="No"
                        >
                          <Switch
                            color="secondary"
                            checked={data.datasets?.includes(item.dataset)}
                          />
                        </ConfirmDialog>
                      )}
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {!limited || accessDataset(item.dataset) ? (
                        <Switch
                          color="secondary"
                          checked={data.dataset_downloads?.includes(
                            item.dataset
                          )}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChange(e, "dataset_downloads", item.dataset)
                          }
                        />
                      ) : (
                        <ConfirmDialog
                          onConfirm={() =>
                            handleChange(
                              true,
                              "dataset_downloads",
                              item.dataset
                            )
                          }
                          title={`${title} Dataset Access`}
                          description="Have you talked with the dataset owner to give an external org access to this dataset?"
                          confirmText="Yes"
                          cancelText="No"
                        >
                          <Switch
                            color="secondary"
                            checked={data.dataset_downloads?.includes(
                              item.dataset
                            )}
                          />
                        </ConfirmDialog>
                      )}
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {!limited ||
                      accessDataset(item.dataset) ||
                      accessWiki(item.id) ? (
                        <Switch
                          color="secondary"
                          checked={data.kw_write?.includes(item.id)}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChange(e, "kw_write", item.id)
                          }
                          disabled={!item.id}
                        />
                      ) : (
                        <ConfirmDialog
                          onConfirm={() =>
                            handleChange(true, "kw_write", item.id)
                          }
                          title={`${title} Dataset Access`}
                          description="Have you talked with the dataset owner to give an external org access to this dataset?"
                          confirmText="Yes"
                          cancelText="No"
                          disabled={!item.id}
                        >
                          <Switch
                            color="secondary"
                            checked={data.kw_write?.includes(item.id)}
                            disabled={!item.id}
                          />
                        </ConfirmDialog>
                      )}
                    </Box>
                  </StyledTableCell>
                </TableRow>
              </>
            );
          })}
        </TableBody>
      </StyledTable>
    );
  };

  return (
    <Box sx={{ width: "100%", mt: 2 }}>
      {table(true)}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 2,
        }}
      >
        <Pagination
          page={currentPage}
          count={pageCount}
          shape="rounded"
          sx={{
            "&>ul>li>button.MuiButtonBase-root": {
              color: `${theme.palette.primary.light} !important`,
              fontSize: 14,
              lineHeight: "14px",
              fontWeight: "700",
            },
          }}
          onChange={handlechangePage}
        />
        <StyledButton
          variant="outlined"
          startIcon={<CropFreeOutlinedIcon />}
          onClick={handleOpenViewAll}
        >
          View All
        </StyledButton>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={false}
        sx={{}}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "8px 0px",
          }}
        >
          <Box>
            <StyledDialogTitle id="scroll-dialog">{title}</StyledDialogTitle>
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", paddingRight: "24px" }}
          >
            <DatasetItemFilter
              filterInput={filterInput}
              handleFilter={handleFilter}
            />
            {title !== "Sensitive" && (
              <Switch
                color="secondary"
                checked={data?.sensitivity_levels?.includes(title)}
                onChange={handleChangeAll}
              />
            )}
          </Box>
        </Box>
        <DialogContent
          dividers={true}
          sx={{ minWidth: "80vw", overflowY: "unset" }}
        >
          {table()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DatasetTable;
