import { Box } from "@mui/material";

import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ConfirmDialog from "src/view/components/ConfirmDialog";
import { User } from "src/types/UserGroup";
import ResendEmailIcon from "src/assets/icons/resend_email.svg";
import DeleteIcon from "src/assets/images/delete.png";

const StyledIconButton = styled(IconButton)(() => ({
  padding: "8px",

  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },

  "& img": {
    width: "16px",
    height: "16px",
    objectFit: "contain",
  },
}));

interface Props {
  user: User;
  handleRemoveUser?: (user: User) => void;
  handleResendEmail?: (data: { user_id: string }) => void;
}

const UserListActions = ({
  user,
  handleRemoveUser,
  handleResendEmail,
}: Props) => {
  return (
    <Box sx={{ display: "flex", alignItems: "start" }}>
      <ConfirmDialog
        title={"Resend Invitation"}
        description={`Are you sure you want to resend an invitation to ${user.email}?`}
        confirmText={"Confirm"}
        cancelText={"Cancel"}
        onConfirm={() => handleResendEmail({ user_id: user.id })}
      >
        <StyledIconButton size="small">
          <img src={ResendEmailIcon} />
        </StyledIconButton>
      </ConfirmDialog>
      <ConfirmDialog
        title={"Delete User"}
        description={
          "You are about to delete this user from this group.\nAre you sure?"
        }
        confirmText={"Delete"}
        cancelText={"Cancel"}
        onConfirm={() => handleRemoveUser(user)}
      >
        <StyledIconButton size="small">
          <img src={DeleteIcon} />
        </StyledIconButton>
      </ConfirmDialog>
    </Box>
  );
};

export default UserListActions;
