import { styled } from "@mui/material/styles";
import { ReactElement, useState, useCallback } from "react";
import { Colors } from "src/view/constants/colors";
import HighlightActionMenu from "src/view/components/HighlightActionMenu";
import { Popover } from "@mui/material";
import { getHighlightText } from "src/utils/get-highlight-text";
import { IPropertyDatum } from "@alephdata/followthemoney";

const StyledTagContainer = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "compact" && prop !== "hasMatchHighlight",
})<{ compact?: boolean; hasMatchHighlight?: boolean }>(
  ({ theme, compact, hasMatchHighlight }) => ({
    height: compact ? 10 : 17,
    padding: 6,
    display: "flex",
    alignItems: "center",
    backgroundColor: hasMatchHighlight
      ? theme.palette.highlight.main
      : "#F0F1F3",
    borderRadius: 4,
    maxWidth: 300,
    cursor: "default",
    fontSize: compact ? 10 : 12,
    color: theme.palette.primary.light,
    fontWeight: "700",
    "& div": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    transition: theme.transitions.create("all", {
      delay: 150,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "&:hover": {
      maxWidth: 600,
      transition: theme.transitions.create("all", {
        duration: theme.transitions.duration.shortest,
      }),
    },
  })
);

const StyledLabel = styled("span", {
  shouldForwardProp: (prop) => prop !== "compact",
})<{ compact?: boolean }>(({ theme, compact }) => ({
  cursor: "default",
  fontSize: compact ? 10 : 12,
  color: Colors.textGray200,
  fontWeight: "700",
  marginRight: theme.spacing(1),
}));

export type FilterTagProps = {
  label: string | ReactElement | IPropertyDatum;
  icon?: React.ReactNode;
  value: string | number;
  action?: () => void;
  containerStyle?: React.CSSProperties;
  valueStyle?: React.CSSProperties;
  compact?: boolean;
  disableHighlight?: boolean;
  highlightKeyValueQuery?: string;
  hasMatchHighlight?: boolean;
};

export default function FilterTag(props: FilterTagProps) {
  const {
    label,
    icon,
    value,
    action,
    valueStyle,
    compact,
    disableHighlight = false,
    highlightKeyValueQuery,
    hasMatchHighlight,
  } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (typeof value === "string" && value.length > 90) {
        setAnchorEl(event.currentTarget);
      }
    },
    [value]
  );

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <StyledTagContainer
        style={action ? { color: Colors.link1, cursor: "pointer" } : valueStyle}
        compact={compact}
        onClick={() => (action ? action() : {})}
        hasMatchHighlight={hasMatchHighlight}
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <StyledLabel compact={compact}>
          {getHighlightText(label as string)}
        </StyledLabel>
        {icon}
        {disableHighlight ? (
          <div>{value}</div>
        ) : (
          <HighlightActionMenu keyValueQuery={highlightKeyValueQuery}>
            <div>{value}</div>
          </HighlightActionMenu>
        )}
      </StyledTagContainer>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        PaperProps={{
          sx: {
            p: 0.5,
            borderRadius: 1,
            bgcolor: hasMatchHighlight ? "#FFD266" : "#F0F1F3",
            width: "600px",
            fontSize: 10,
            fontWeight: "700",
            color: "#122945",
          },
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <StyledLabel compact={compact}>
          {getHighlightText(label as string)}
        </StyledLabel>
        {icon}
        {disableHighlight ? (
          <div>{value}</div>
        ) : (
          <HighlightActionMenu keyValueQuery={highlightKeyValueQuery}>
            <div>{value}</div>
          </HighlightActionMenu>
        )}
      </Popover>
    </>
  );
}
