import { ReactNode } from "react";
import { Box, SvgIcon, Typography } from "@mui/material";
import theme from "src/theme";
import { TaskStatusFiltersLabels } from "src/types/Dataset";
import {
  Cancel as IconFailed,
  CheckCircle as IconCompleted,
  HourglassTop as IconProgress,
} from "@mui/icons-material";
import { ReactComponent as IconQueue } from "src/assets/images/time.svg";
import { styled } from "@mui/material/styles";

const StatusWrapper = styled(Box)(() => ({
  fontSize: "14px",
  display: "flex",
  fontWeight: 700,
  alignItems: "center",
  "& svg": {
    marginRight: "4px",
    fontSize: "medium",
  },
  "& p": {
    fontWeight: "inherit",
    fontSize: "inherit",
  },
}));
interface Props {
  status: TaskStatusFiltersLabels;
}

const TaskStatus = ({ status }: Props) => {
  let icon: ReactNode;
  let content: ReactNode;

  switch (status) {
    case TaskStatusFiltersLabels.IN_QUEUE:
      icon = (
        <SvgIcon
          component={IconQueue}
          inheritViewBox
          sx={{ color: theme.palette.secondary.light }}
        />
      );
      content = <Typography sx={{ color: "#EF8479" }}>In queue</Typography>;
      break;

    case TaskStatusFiltersLabels.PROCESSING:
      icon = <IconProgress sx={{ color: "#3B83EF" }} />;
      content = <Typography color="#3B83EF">Processing</Typography>;
      break;

    case TaskStatusFiltersLabels.COMPLETED:
      icon = <IconCompleted sx={{ color: "#3BBC97" }} />;
      content = <Typography color="#3BBC97">Completed</Typography>;
      break;

    case TaskStatusFiltersLabels.FAILED:
      icon = <IconFailed sx={{ color: "#EF8479" }} />;
      content = <Typography color="#EF8479">Failed</Typography>;
      break;

    default:
      content = <Typography color="#EF8479">-</Typography>;
  }

  return (
    <StatusWrapper>
      {icon}
      {content}
    </StatusWrapper>
  );
};
export default TaskStatus;
