import SearchHeader from "../layout/SearchHeader";
import ScaffoldingPage from "./Scaffolding";

const UserProfile = () => {
  return (
    <div>
      <SearchHeader />
      <ScaffoldingPage />
    </div>
  );
};

export default UserProfile;
