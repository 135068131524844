import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import {
  ArrowOutward as ArrowIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import theme from "src/theme";

interface Props {
  open: boolean;
  handleClose: () => void;
  wikiId: string;
  title: string;
}

const WikiPreview = ({ open, wikiId, title, handleClose }: Props) => {
  const goToWiki = () =>
    window.open(`${window.location.origin}/data-dictionary/data/${wikiId}`);

  const onIframeLoad = () => {
    document
      .getElementById("wiki-preview-iframe")
      ["contentWindow"].document.getElementById("root").style.pointerEvents =
      "none";
  };

  return (
    <Dialog open={open} maxWidth={false} onClose={handleClose}>
      <Box
        sx={{
          position: "relative",
          p: 3,
          pb: "12px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: 700 }}>
          {title} - Preview
        </Typography>

        <Box
          sx={{
            gap: "10px",
          }}
        >
          <IconButton onClick={goToWiki}>
            <ArrowIcon
              fontSize="small"
              sx={{ color: theme.palette.grey[900] }}
            />
          </IconButton>

          <IconButton>
            <CloseIcon
              onClick={handleClose}
              fontSize="small"
              sx={{ color: theme.palette.grey[900] }}
            />
          </IconButton>
        </Box>
      </Box>

      <Box
        sx={{
          px: 3,
        }}
      >
        <Divider />
      </Box>

      <DialogContent
        sx={{
          p: 3,
          pt: 2,
          height: "65vh",
          width: "65vw",
        }}
      >
        <iframe
          id="wiki-preview-iframe"
          src={`/data-dictionary/data/${wikiId}`}
          title={`${title} Preview`}
          onLoad={onIframeLoad}
          style={{
            boxSizing: "border-box",
            height: "64vh",
            width: "100%",
            overflow: "hidden",
            border: "1px solid",
            borderRadius: 4,
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default WikiPreview;
