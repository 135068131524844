import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutlineSharp";
import {
  GridSlotsComponentsProps,
  GridToolbarContainer,
  useGridApiContext,
} from "@mui/x-data-grid-premium";
import AddDatasetDialog from "./AddDatasetDialog";
import { useSearch } from "src/modules/api/search";
import { emptyQueryVariables } from "src/modules/api/search/SearchProvider";
import { usePostHog } from "posthog-js/react";
import { HogEvent } from "src/types/PosthogEvents";

export default function DatasetToolbar({
  collection,
  handleUpdateCollection,
  handlePromptRemoveDatasets,
}: NonNullable<GridSlotsComponentsProps["toolbar"]>) {
  const posthog = usePostHog();
  const { handleSearch } = useSearch();
  const apiRef = useGridApiContext();
  const [openAddDatasetsDialog, setOpenAddDatasetsDialog] =
    useState<boolean>(false);

  const handleSearchCollection = () => {
    posthog.capture(HogEvent.DATA_COLLECTION_SEARCHED);
    handleSearch({
      ...emptyQueryVariables,
      filters: {
        ...emptyQueryVariables.filters,
        data_collection_ids: [collection.id],
      },
      content: "*",
    });
  };

  const handleUpdateDatasets = (datasets: string[]) => {
    handleUpdateCollection({ datasets: datasets });
  };

  const selectedRows = apiRef.current.getSelectedRows();

  const handlePromptRemoveSelectedDatasets = () => {
    handlePromptRemoveDatasets(Array.from(selectedRows.keys()) as string[]);
  };

  const datasetsLength = collection?.datasets?.length || 0;

  return (
    <GridToolbarContainer
      sx={{ mb: 1, justifyContent: "space-between", alignItems: "center" }}
    >
      <Typography variant="h6" color="primary">
        {datasetsLength} {datasetsLength === 1 ? "Dataset" : "Datasets"}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 1,
        }}
      >
        {selectedRows.size > 0 ? (
          <Button
            startIcon={<DeleteIcon />}
            variant="outlined"
            sx={{
              borderColor: "secondary.main",
              height: 38,
            }}
            onClick={() => handlePromptRemoveSelectedDatasets()}
          >
            Remove Datasets
          </Button>
        ) : (
          <>
            <Button
              startIcon={
                <img
                  src={require("src/assets/images/add_circle_secondary.png")}
                  style={{ width: 24, height: 24 }}
                />
              }
              variant="outlined"
              sx={{
                borderColor: "secondary.main",
                height: 38,
              }}
              onClick={() => setOpenAddDatasetsDialog(true)}
            >
              Add New Datasets
            </Button>
            {collection?.id && datasetsLength > 0 && (
              <Button
                startIcon={
                  <Box
                    sx={{
                      width: 24,
                      height: 24,
                      borderRadius: "50%",
                      bgcolor: "divider",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={require("src/assets/images/search-red.png")}
                      style={{ width: 16, height: 16 }}
                    />
                  </Box>
                }
                variant="outlined"
                sx={{
                  borderColor: "secondary.main",
                  height: 38,
                }}
                onClick={handleSearchCollection}
              >
                Search Collection
              </Button>
            )}
          </>
        )}
      </Box>
      <AddDatasetDialog
        open={openAddDatasetsDialog}
        collection={collection}
        handleUpdateDatasets={handleUpdateDatasets}
        handleClose={() => setOpenAddDatasetsDialog(false)}
      />
    </GridToolbarContainer>
  );
}
