import {
  Box,
  Container,
  Grid,
  Typography,
  Tabs,
  Tab,
  useMediaQuery,
} from "@mui/material";

import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import { Colors } from "../constants/colors";
import * as React from "react";
import BSTable from "./BSTable";
import { useEffect, useState } from "react";
import actions from "src/modules/bulk-search/screeningList/screeningActions";
import selector from "src/modules/bulk-search/screeningList/screeningSelectors";
import { useDispatch, useSelector } from "react-redux";
import { snackbar } from "src/view/toaster";
import { useNavigate } from "react-router-dom";
import { ScreeningList } from "src/types/BulkSearch";

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: Colors.secondary,
    height: 3,
  },
});

const AntTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontSize: 16,
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: Colors.textDark100,
  "&:hover": {
    color: "#40a9ff",
    opacity: 1,
  },
  "&.Mui-selected": {
    color: Colors.textDark100,
    fontWeight: "bold",
    "&>p>span": {
      color: Colors.secondary,
    },
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#d1eaff",
  },
}));

interface StyledTabProps {
  label: React.ReactNode;
}

const StyledContainer = styled(Button)(() => ({
  borderRadius: 4,
  backgroundColor: "#F75151",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#ffffff",
  fontSize: 14,
  lineHeight: "17px",
  margin: 5,
  fontWeight: "700",
  padding: 15,
  width: 124,
  height: 46,
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#F7515188",
  },
}));

const StyledBulkScaffolding = styled(Container)(() => ({
  marginTop: 127,
  paddingBottom: 100,
}));

const StyledHeader = styled("h3")(() => ({
  fontSize: 32,
  lineHeight: "39px",
  fontWeight: "700",
  color: Colors.textDark,
  marginRight: 18,
}));

const StyledText = styled(Typography)(() => ({
  fontSize: 14,
  lineHeight: "24px",
  fontWeight: "400",
  color: Colors.textDark,
}));

const ScaffoldingPage = () => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const [myList, setMyList] = useState<ScreeningList[]>([]);
  const [sharedWithMe, setSharedWithMe] = useState<ScreeningList[]>([]);
  const screeningList = useSelector(selector.selectScreeningList);
  const updated = useSelector(selector.selectUpdatedStatus);
  const loading = useSelector(selector.selectLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mobileView = useMediaQuery("(max-width:678px)");

  useEffect(() => {
    dispatch(actions.getScreeningLists());
  }, []);

  useEffect(() => {
    if (updated) {
      dispatch(actions.getScreeningLists());
      dispatch(actions.clearUpdateStatus());
    }
  }, [updated]);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    if (screeningList == null) {
      snackbar.error("The name is required.");
      dispatch(actions.clearError());
    } else {
      if (screeningList.created_by) {
        setMyList(screeningList.created_by);
      }
      if (screeningList.shared_with_groups) {
        setSharedWithMe(screeningList.shared_with_groups);
      }
    }
  }, [screeningList]);

  const handleClickCreate = () => {
    dispatch({
      type: actions.CREATE,
      payload: null,
    });
    navigate("query-group", { replace: true });
  };

  return (
    <StyledBulkScaffolding sx={{ mt: mobileView ? "149px" : "104px" }}>
      <Grid
        justifyContent="space-between"
        alignItems="center"
        sx={{ paddingTop: 2, display: "flex" }}
        style={{ marginLeft: 0, marginRight: 0 }}
      >
        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              <StyledHeader>Bulk Search</StyledHeader>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <StyledContainer onClick={handleClickCreate} startIcon={<AddIcon />}>
            Create
          </StyledContainer>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={8}>
          <StyledText variant="body1">
            Quickly create and search through a screening list of terms and
            share that list with organizations. These searches can be saved and
            repeatedly run or scheduled to run automatically on regular
            intervals (e.g. every day or every hour).
          </StyledText>
        </Grid>
      </Grid>
      <Box sx={{ height: 32 }} />
      <Box sx={{ bgcolor: "#fff", width: "100%", mt: { sm: 0, md: -4 } }}>
        <AntTabs
          value={tabIndex}
          onChange={handleChange}
          aria-label="ant example"
        >
          <AntTab
            label={
              <Typography
                sx={{ fontSize: 16, fontWeight: "inherit", color: "inherit" }}
              >
                My List <span style={{ color: "#CED3D9" }}>•</span>{" "}
                <span style={{ fontWeight: "bold" }}>{myList.length}</span>
              </Typography>
            }
          />
          <AntTab
            label={
              <Typography
                sx={{ fontSize: 16, fontWeight: "inherit", color: "inherit" }}
              >
                Shared with me <span style={{ color: "#CED3D9" }}>•</span>{" "}
                <span style={{ fontWeight: "bold" }}>
                  {sharedWithMe.length}
                </span>
              </Typography>
            }
          />
        </AntTabs>
        {tabIndex == 0 ? (
          <BSTable
            tabIndex={tabIndex}
            data={myList}
            loading={loading}
            isMine={true}
          ></BSTable>
        ) : (
          <BSTable
            tabIndex={tabIndex}
            data={sharedWithMe}
            loading={loading}
            isMine={false}
          ></BSTable>
        )}
      </Box>
    </StyledBulkScaffolding>
  );
};

export default ScaffoldingPage;
