import "./App.css";

import { Route, Routes } from "react-router-dom";
import { PageRoutes } from "./view/routes";
import Layout from "./view/layout/Layout";
import Sidebar from "./view/components/Sidebar";
import LaunchNotesWidget from "./view/components/LaunchNotesWidget";
import FeedbackModal from "./view/components/FeedbackModal";
import { Snackbar } from "./view/toaster";

export default function App() {
  return (
    <Layout>
      <FeedbackModal />
      <Sidebar />
      <LaunchNotesWidget />
      <Snackbar />
      <Routes>
        {PageRoutes.map((props) => (
          <Route {...props} />
        ))}
      </Routes>
    </Layout>
  );
}
