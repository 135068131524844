import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";

const StyledButton = styled(Button)(() => ({
  fontSize: "14px",
  lineHeight: "17px",
  fontWeight: "700",
  color: "#536378",
  backgroundColor: "#F0F1F3",
  textTransform: "capitalize",
  width: "max-content",
  padding: "6px 14px 6px 14px",
  borderRadius: 38,
  marginTop: 10,
  "&:hover": {
    backgroundColor: "#F0F1F355",
  },
}));

export const JSONView = (props: { data: string }) => {
  const [loadMore, setLoadMore] = useState(false);

  const handleLoadMore = () => {
    setLoadMore(!loadMore);
  };

  return (
    <Box>
      <div
        className="seho-search-result-json"
        dangerouslySetInnerHTML={{ __html: props.data }}
        style={{
          maxHeight: loadMore ? "185px" : "175px",
          overflowY: loadMore ? "auto" : "hidden",
          fontFamily: "Andale Mono",
          fontSize: 13,
        }}
      />
      <StyledButton
        onClick={handleLoadMore}
        endIcon={
          loadMore ? <RemoveCircleOutlinedIcon /> : <AddCircleOutlinedIcon />
        }
      >
        {!loadMore ? "Load More" : "Load Less"}
      </StyledButton>
    </Box>
  );
};
