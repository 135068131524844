import React, { useState, useMemo, useEffect, Fragment } from "react";
import { useIntersectionObserver } from "usehooks-ts";
import { sumBy } from "lodash";
import {
  Box,
  Container,
  DialogContent,
  styled,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Checkbox,
  InputBase,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { Colors } from "src/view/constants/colors";
import { User } from "src/types/UserGroup";
import { getFullName } from "src/utils";
import SearchHeader from "src/view/layout/SearchHeader";
import {
  StyledButton,
  TableHeadCell,
  StyledBackButton,
} from "src/view/users-groups/groups/EditGroup/StyledComponents";
import { useUsers } from "src/modules/api/users";

const TableBodyCell = styled(TableCell)(() => ({
  fontSize: 12,
  fontWeight: "700",
  lineHeight: "20px",
  color: Colors.twilight,
  paddingTop: 12,
  paddingBottom: 12,
  borderRight: "1px solid #DFE5E9",
}));

const StyledBulkScaffolding = styled(Container)(() => ({
  marginTop: 127,
  paddingBottom: 100,
}));

interface Props {
  handleClose: () => void;
  setTempUsers: (_tempUsers: User[]) => void;
  handleSaveUsers: (users: User[], type: string) => void;
  tempUsers: {
    first_name?: string;
    last_name?: string;
    email?: string;
    id?: string;
  }[];
}

const ImportUserFromExisting = ({
  handleClose,
  handleSaveUsers,
  tempUsers,
  setTempUsers,
}: Props) => {
  const [searchInputValue, setSearchInputValue] = useState("");
  const [searchKey, setSearchKey] = useState("");

  const { getUsersInfinite } = useUsers();
  const {
    data: users,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = getUsersInfinite({ searchKey });

  const { ref: endOfListRef, isIntersecting } = useIntersectionObserver();

  useEffect(() => {
    if (isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  }, [isIntersecting, hasNextPage]);

  const totalUsers = useMemo(() => {
    if (!users?.pages) return 0;
    return sumBy(users.pages, (page) => page.results.length);
  }, [users]);

  const onSearchKeyPress = (e: React.KeyboardEvent) => {
    if (e.key == "Enter") {
      setSearchKey(searchInputValue);
    }
  };

  const mobileView = useMediaQuery("(max-width:678px)");

  const handleAddExistingUser = (
    e: React.ChangeEvent<HTMLInputElement>,
    member: User
  ) => {
    const _tempUsers = [...tempUsers];
    if (e.target.checked) {
      _tempUsers.push(member);
    } else {
      const index = _tempUsers.indexOf(member);
      _tempUsers.splice(index, 1);
    }
    setTempUsers(_tempUsers);
  };

  const renderContent = () => {
    if (!isLoading && !totalUsers) {
      return (
        <Box
          sx={{
            display: "flex",
            height: 200,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: 200,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={require("src/assets/images/user.png")}
              style={{ width: 40, height: 40, color: "#BDBDBD" }}
            />
            <Box sx={{ height: 20 }}></Box>
            <Typography
              variant="body2"
              sx={{
                fontSize: "18px",
                lineHeight: "20px",
                fontWeight: "700",
                color: Colors.twilight,
              }}
            >
              No Existing Users.
            </Typography>
            <Box sx={{ height: 10 }}></Box>
            <Typography
              variant="body2"
              sx={{
                fontSize: "13px",
                lineHeight: "17px",
                fontWeight: "400",
                color: Colors.textGray100,
              }}
            >
              Add one or more users
            </Typography>
          </Box>
        </Box>
      );
    }

    return (
      <>
        {isLoading && (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={20} sx={{ marginTop: 7 }} />
          </Box>
        )}

        {!isLoading && !!totalUsers && (
          <Table
            sx={{
              bgcolor: "rgba(217, 217, 217, 0.05)",
              borderCollapse: "separate",
              overflow: "hidden",
            }}
          >
            <TableHead sx={{ bgcolor: "#F7F7F7" }}>
              <TableRow>
                <TableHeadCell sx={{ border: "none" }}>
                  <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                    <Box>NAME</Box>
                    <Box sx={{ width: 12 }}></Box>
                    <img
                      src={require("src/assets/images/sort.png")}
                      style={{ width: 18, height: 18 }}
                    />
                  </Box>
                </TableHeadCell>
                <TableHeadCell sx={{ border: "none" }}>
                  <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                    <Box>CREATED ON</Box>
                    <Box sx={{ width: 12 }}></Box>
                    <img
                      src={require("src/assets/images/sort.png")}
                      style={{ width: 18, height: 18 }}
                    />
                  </Box>
                </TableHeadCell>
                <TableHeadCell sx={{ border: "none" }}>
                  <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                    <Box>LAST UPDATED AT</Box>
                    <Box sx={{ width: 12 }}></Box>
                    <img
                      src={require("src/assets/images/sort.png")}
                      style={{ width: 18, height: 18 }}
                    />
                  </Box>
                </TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.pages?.map((usersPage) => {
                return (
                  <Fragment key={`usersPage-${usersPage.page}`}>
                    {usersPage?.results?.map((member: User, index: number) => {
                      const isLast = index === totalUsers;
                      const checked =
                        tempUsers.filter((u) => u.id == member.id)?.length > 0;

                      return (
                        <TableRow key={index}>
                          <TableBodyCell
                            sx={{
                              borderRight: "none",
                              borderBottom: isLast
                                ? "none"
                                : "1px solid #DFE5E9",
                            }}
                          >
                            <Box
                              sx={{ display: "flex", alignItems: "flex-start" }}
                            >
                              <Checkbox
                                sx={{ mt: "-10px" }}
                                checked={checked}
                                onChange={(e) =>
                                  handleAddExistingUser(e, member)
                                }
                              ></Checkbox>
                              <Box>
                                <Typography
                                  variant="h4"
                                  sx={{
                                    fontSize: "18px",
                                    lineHeight: "20px",
                                    fontWeight: "700",
                                    color: Colors.twilight,
                                  }}
                                >
                                  {getFullName(member)}
                                </Typography>
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontSize: "14px",
                                    lineHeight: "17px",
                                    fontWeight: "400",
                                    color: Colors.twilight,
                                  }}
                                >
                                  {member.email}
                                </Typography>
                              </Box>
                            </Box>
                          </TableBodyCell>
                          <TableBodyCell
                            sx={{
                              borderRight: "none",
                              borderBottom: isLast
                                ? "none"
                                : "1px solid #DFE5E9",
                            }}
                          >
                            --
                          </TableBodyCell>
                          <TableBodyCell
                            sx={{
                              borderRight: "none",
                              borderBottom: isLast
                                ? "none"
                                : "1px solid #DFE5E9",
                            }}
                          >
                            --
                          </TableBodyCell>
                        </TableRow>
                      );
                    })}
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        )}

        {!isLoading && !users?.pages?.[0]?.results?.length && (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                marginTop: 7,
              }}
            >
              No Matching Users
            </Typography>
          </Box>
        )}
      </>
    );
  };

  // TODO: refactor layout
  return (
    <>
      <SearchHeader />
      <StyledBulkScaffolding sx={{ mt: mobileView ? "149px" : "104px" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "8px 0px",
            mt: 3,
          }}
        >
          <Box>
            <StyledBackButton
              startIcon={<ArrowBackIcon />}
              color="primary"
              onClick={handleClose}
              sx={{ fontSize: 24, lineHeight: "29px", ml: 1 }}
            >
              Existing Users
            </StyledBackButton>
          </Box>
        </Box>
        <DialogContent
          dividers={false}
          sx={{ minWidth: "60vw", overflowY: "unset" }}
        >
          <Box
            sx={{
              marginTop: "20px",
              minHeight: 200,
              border: "1px solid rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px",
                borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box
                sx={{
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontWeight: "700",
                  color: Colors.twilight,
                }}
              >
                {totalUsers} Users
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <InputBase
                  sx={{
                    ml: 1,
                    flex: 1,
                    bgcolor: "#E5EAED",
                    borderRadius: "4px",
                    height: "40px",
                    paddingLeft: 1,
                    paddingRight: 1,
                  }}
                  placeholder="Search users"
                  inputProps={{ "aria-label": "search users" }}
                  onChange={(e) => setSearchInputValue(e.target.value)}
                  onKeyPress={onSearchKeyPress}
                  startAdornment={
                    <img
                      src={require("src/assets/images/search-black.png")}
                      style={{ width: 24, height: 24, marginRight: 12 }}
                    />
                  }
                />
                <Box sx={{ width: 29 }}></Box>
                <StyledButton
                  disabled={!tempUsers.length || isLoading}
                  variant="contained"
                  onClick={() => handleSaveUsers(tempUsers, "existing")}
                  sx={{
                    backgroundColor: Colors.twilight,
                    color: "#FFFFFF",
                    height: 42,
                    width: 94,
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Add Users
                </StyledButton>
              </Box>
            </Box>

            {renderContent()}

            <div ref={endOfListRef} />

            {isFetchingNextPage && (
              <Box
                sx={{
                  display: "flex",
                  padding: "30px",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
        </DialogContent>
      </StyledBulkScaffolding>
    </>
  );
};

export default ImportUserFromExisting;
