import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import QueueList from "./QueueList";
import ResultHeader from "./ResultHeader";
import { Drawer, IconButton, useMediaQuery } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Colors } from "src/view/constants/colors";
import viewSelector from "src/modules/search/view/searchViewSelectors";
import { useSelector } from "react-redux";
import PreviewContent from "src/view/search-result/components/PreviewContent";
import PreviewProvider from "src/view/search-result/components/Preview/Source/PreviewContext";
import { useNavigate } from "react-router-dom";
import { useSearch } from "src/modules/api/search";
import { formatVariablesSearchParam } from "src/modules/api/search/SearchProvider";

const drawerWidth = 370;

const StyledDrawerIconWrapper = styled("div")(() => ({
  width: "100%",
  height: "calc(100% - 76px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#F7515110",
}));

const StyledDrawer = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  position: "fixed",
  top: 181,
  left: 0,
  height: "calc(100% - 84px)",
  transition: theme.transitions.create(["width", "marginLeft"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  width: "20px",
  zIndex: 1,
  ...(open && {
    transition: theme.transitions.create(["width", "marginLeft"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: "0px",
    marginLeft: "-50px",
  }),
}));

const StyledResult = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  position: "fixed",
  top: 181,
  left: 0,
  height: "100%",
  transition: theme.transitions.create(["left", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  width: "100%",
  ...(open && {
    transition: theme.transitions.create(["left", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    left: `${drawerWidth}px`,
    width: `calc(100% - ${drawerWidth}px)`,
  }),
}));

const PageLayout = (props: { children: JSX.Element }) => {
  const navigate = useNavigate();
  const { variables } = useSearch();
  const [open, setOpen] = useState(true);
  const [previewOpen, setPreviewOpen] = useState(true);
  const previewDocInfo = useSelector(viewSelector.selectPreviewDocInfo);
  const mobileView = useMediaQuery("(max-width:678px)");

  useEffect(() => {
    if (previewDocInfo) {
      setPreviewOpen(true);
    } else {
      setPreviewOpen(false);
    }
  }, [previewDocInfo]);

  const handleClose = () => {
    setOpen(!open);
  };

  const handleClosePreview = () => {
    setPreviewOpen(false);
    // remove the preview search param, causing the result preview drawer to close
    navigate(
      `/search?q=${formatVariablesSearchParam({
        ...variables,
      })}`
    );
  };

  return (
    <PreviewProvider>
      <Drawer
        sx={{
          position: "fixed",
          top: mobileView ? 149 : 104,
          left: 0,
          height: "100%",
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <QueueList handleClose={handleClose} />
      </Drawer>
      <Drawer
        sx={{
          height: "100%",
          width: "80vw",
          flexShrink: 0,
          zIndex: 1101,
          "& .MuiDrawer-paper": {
            width: "80vw",
            boxSizing: "border-box",
          },
        }}
        variant="temporary"
        anchor="right"
        open={previewOpen}
        onClose={handleClosePreview}
      >
        <PreviewContent handleClose={handleClosePreview} />
      </Drawer>
      <StyledDrawer open={open}>
        <StyledDrawerIconWrapper>
          <IconButton
            size="small"
            aria-label="collapse of filters"
            aria-controls="toggle filter"
            aria-haspopup="true"
            color="secondary"
            onClick={() => handleClose()}
            sx={{
              border: `1px solid ${Colors.mist100}`,
              backgroundColor: "#EDF1F588",
              marginLeft: 2,
            }}
          >
            <ChevronRightIcon />
          </IconButton>
        </StyledDrawerIconWrapper>
      </StyledDrawer>
      <StyledResult open={open} sx={{ top: mobileView ? "224px" : "181px" }}>
        <ResultHeader />
        {props.children}
      </StyledResult>
    </PreviewProvider>
  );
};

export default PageLayout;
