export const formatUrl = (path: string | string[], searchParams?: string) => {
  let url = process.env.REACT_APP_BACKEND_URL;
  let segments = Array.isArray(path) ? path : path.split("/");
  segments = segments.filter((s) => !!s); // Remove empty segments created by double slashes

  // Add a mandatory trailing slash only if there are no searchParams
  url += segments.join("/");
  if (!searchParams) {
    url += "/";
  }

  // Add searchParams if necessary
  url = searchParams ? url + "?" + searchParams : url;
  return url;
};

export const formatInit = (
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
  token: string,
  init = {} as RequestInit
) => {
  return {
    method: method,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": !(init.body instanceof FormData) && "application/json",
    },
    timeout: 1000,
    ...init,
  };
};
