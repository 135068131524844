import { useState } from "react";
import { Box, styled } from "@mui/material";

import MyTextField from "src/view/components/MyTextField";
import SearchIconUrl from "src/assets/images/search-primary.png";

const JobSearchHeader = styled(Box)(() => ({
  fontSize: 32,
  fontWeight: "700",
  display: "flex",
  justifyContent: "space-between",
}));

type Props = {
  searchKey: string;
  setSearchKey: (searchKey: string) => void;
};

const JobSearchBar = ({ setSearchKey }: Props) => {
  const [searchInput, setSearchInput] = useState("");

  const handleSearch = () => {
    setSearchKey(searchInput);
  };

  return (
    <JobSearchHeader>
      <MyTextField
        containerStyle={{
          width: "25%",
          minWidth: "200px",
          height: "36px",
          "& div": { height: "36px", borderRadius: "4px" },
          "& input": {
            fontSize: "14px",
            height: "16px",
          },
          "& input::placeholder": {
            color: "#7A8796",
            opacity: 1,
            fontWeight: "400",
          },
        }}
        prefixIcon={
          <img
            src={SearchIconUrl}
            width="16"
            height="16"
            style={{ cursor: "pointer" }}
            onClick={handleSearch}
          />
        }
        placeholder={"Search"}
        onChange={(e) => setSearchInput(e.target.value)}
        onKeyPress={(e) => e.key == "Enter" && handleSearch()}
      />
    </JobSearchHeader>
  );
};

export default JobSearchBar;
