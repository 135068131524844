import { GroupDetailed, GroupShort, User } from "src/types/UserGroup";
import actions from "src/modules/groups/view/groupsViewActions";
import { Paginated } from "src/types/Shared";

export enum GroupUpdateType {
  CREATED_USERS = "created_users",
}

export interface Props {
  loading: boolean;
  usersResponse?: Paginated<User[]>;
  user_error?: string;
  created?: boolean;
  create_error?: string;
  selectedGroup?: GroupDetailed;
  selectedUser?: User;
  updated?: boolean;
  update_type?: null | GroupUpdateType;
  update_error?: string | null;
}

const initialData: Props = {
  loading: false,
  usersResponse: null,
  user_error: null,
  created: false,
  create_error: null,
  selectedGroup: null,
  selectedUser: null,
  updated: false,
  update_type: null,
  update_error: null,
};

export default (
  state = initialData,
  {
    type,
    payload,
  }: {
    type: string;
    payload:
      | boolean
      | User
      | GroupDetailed
      | Paginated<User[]>
      | Paginated<GroupShort[]>
      | null;
  }
) => {
  if (type === actions.USER_FIND_SUCCESS) {
    return {
      ...state,
      usersResponse: payload,
      loading: false,
    };
  }

  if (type === actions.USER_FIND_ERROR) {
    return {
      ...state,
      user_error: payload,
      loading: false,
    };
  }

  if (type === actions.CREATE_STARTED) {
    return {
      ...state,
      created: false,
      create_error: null,
      loading: true,
    };
  }

  if (type === actions.CREATE_FAILED) {
    return {
      ...state,
      created: false,
      create_error: payload,
      loading: false,
    };
  }

  if (type === actions.CREATE_SUCCESS) {
    return {
      ...state,
      created: true,
      create_error: null,
      loading: false,
    };
  }
  if (type === actions.UPDATE_STARTED) {
    return {
      ...state,
      updated: false,
      update_error: null,
      loading: true,
    };
  }

  if (type === actions.UPDATE_FAILED) {
    return {
      ...state,
      updated: false,
      update_error: payload,
      loading: false,
    };
  }

  if (type === actions.UPDATE_SUCCESS) {
    return {
      ...state,
      updated: true,
      update_error: null,
      loading: false,
    };
  }

  if (type === actions.CLEAR_UPDATE_STATUS) {
    return {
      ...state,
      loading: false,
      user_error: null,
      created: false,
      create_error: null,
      updated: false,
      update_error: null,
      update_type: null,
    };
  }

  if (type === actions.SELECT_USER) {
    return {
      ...state,
      selectedUser: payload,
    };
  }

  if (type === actions.SELECT_GROUP) {
    return {
      ...state,
      selectedGroup: payload,
      loading: false,
    };
  }

  if (type === actions.CREATE_USERS) {
    return {
      ...state,
      loading: false,
      update_type: GroupUpdateType.CREATED_USERS,
    };
  }

  if (type === actions.CREATE_USERS_ERROR) {
    return {
      ...state,
      loading: false,
      user_error: payload,
      created: false,
      create_error: null,
    };
  }

  return state;
};
