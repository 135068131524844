import { createSelector } from "reselect";
import { Props } from "./groupsViewReducers";

const selectRaw = (state: { groups: { view: Props } }) => state.groups.view;

const selectUsers = createSelector([selectRaw], (raw) => raw.usersResponse);

const selectLoading = createSelector([selectRaw], (raw) =>
  Boolean(raw.loading)
);

const selectSelectedGroup = createSelector(
  [selectRaw],
  (raw) => raw.selectedGroup
);

const selectSelectedUser = createSelector(
  [selectRaw],
  (raw) => raw.selectedUser
);

const GroupsViewSelectors = {
  selectLoading,
  selectUsers,
  selectRaw,
  selectSelectedGroup,
  selectSelectedUser,
};

export default GroupsViewSelectors;
