import { Backdrop, Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { snackbar } from "src/view/toaster";
import { useDispatch } from "react-redux";
import authAction from "src/modules/auth/actions";
import AgreementDialog from "./AgreementDialog";
import NewFeaturesDialog from "./NewFeaturesDialog";
import { useUser } from "src/modules/api/auth";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { User } from "src/types/Auth";
import { UserFeatures } from "src/types/UserGroup";
import useToggleSearchHistory from "src/view/profile/Settings/FeatureToggles";

const Layout = (props: { children: React.ReactNode }) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const [colorScheme, setColorScheme] = useState("light");
  const [openAgreementDialog, setOpenAgreementDialog] = useState(false);
  const [openNewFeaturesDialog, setOpenNewFeaturesDialog] = useState(false);
  const { user, logout } = useUser();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const toggleSearchHistory = useToggleSearchHistory();

  useEffect(() => {
    const pathName = location.pathname;
    if (pathName.includes("/search")) {
      return;
    }

    if (pathName === "/") {
      document.title = "Seamless Horizons (Beta)";
    } else {
      document.title = pathName
        .slice(1)
        .split("/")[0]
        .split("-")
        .map((str) => str[0].toUpperCase() + str.slice(1))
        .join(" ");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (user) {
      if (!user.eula_signed) {
        setOpenAgreementDialog(true);
      } else if (!user.new_features_seen) {
        setOpenNewFeaturesDialog(true);
      }
    }
  }, [user]);

  useEffect(() => {
    window.addEventListener("startBackdrop", handleOpenBackdrop);
    window.addEventListener("endBackdrop", handleClose);
    window.addEventListener("APIErrorOccurred", handleError);
    return () => {
      window.removeEventListener("startBackdrop", handleOpenBackdrop);
      window.removeEventListener("endBackdrop", handleClose);
      window.removeEventListener("APIErrorOccurred", handleError);
    };
  }, []);

  useEffect(() => {
    if (error != null) {
      snackbar.error(error["message"]);
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }, [error]);

  useEffect(() => {
    const updateColorScheme = () => {
      const newColorScheme = window.matchMedia("(prefers-color-scheme: dark)")
        .matches
        ? "dark"
        : "light";
      setColorScheme(newColorScheme);
    };

    updateColorScheme();
    window.addEventListener("load", updateColorScheme);
    window.addEventListener("color-scheme-change", updateColorScheme);

    return () => {
      window.removeEventListener("load", updateColorScheme);
      window.removeEventListener("color-scheme-change", updateColorScheme);
    };
  }, []);

  useEffect(() => {
    const link = document.querySelector("link[rel='icon']");
    if (link) link["href"] = `/favicon-${colorScheme}.ico`;
  }, [colorScheme]);

  const handleOpenBackdrop = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleError = (props: unknown) => {
    const detail = props["detail"];
    if (error == null) {
      setError(detail);
    }
  };

  const handleCloseAgreementDialog = () => {
    setOpenAgreementDialog(false);
    logout({ logoutParams: { returnTo: window.location.origin } });
    dispatch(authAction.logout());
  };

  const handleAgreement = async () => {
    const res = await dispatch(authAction.setAgree());
    if (res.error) {
      logout({ logoutParams: { returnTo: window.location.origin } });
      dispatch(authAction.logout());
    } else {
      setOpenAgreementDialog(false);
      queryClient.setQueryData(["user"], (oldData: User) =>
        oldData
          ? {
              ...oldData,
              eula_signed: true,
            }
          : oldData
      );
    }
  };

  const handleSeenNewFeature = async () => {
    const res = await dispatch(authAction.setSeenNewFeatures());
    if (res.error) {
      logout({ logoutParams: { returnTo: window.location.origin } });
      dispatch(authAction.logout());
    } else {
      setOpenNewFeaturesDialog(false);
      queryClient.setQueryData(["user"], (oldData: User) =>
        oldData
          ? {
              ...oldData,
              new_features_seen: true,
            }
          : oldData
      );
    }
  };

  const handleNewFeatures = async () => {
    await handleSeenNewFeature();
    navigate("/profile", { state: { tabIndex: 0 } });
  };

  const handleEnableSearchHistory = async () => {
    try {
      toggleSearchHistory.mutate(true);
      queryClient.setQueryData(["user"], (oldData: User) =>
        oldData
          ? {
              ...oldData,
              [UserFeatures.SEARCH_HISTORY]: true,
            }
          : oldData
      );
    } catch (error) {
      console.error("Error enabling search history:", error);
    }
  };

  return (
    <Box>
      {props.children}
      <Backdrop
        sx={{ color: "#fff", zIndex: 100001 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <AgreementDialog
        open={openAgreementDialog}
        onClose={handleCloseAgreementDialog}
        onAgree={handleAgreement}
        title={"EULA Agreement"}
        description={
          "Please read through our End User License Agreement (EULA). You'll have to agree to it before you can use the platform."
        }
      />
      <NewFeaturesDialog
        open={openNewFeaturesDialog}
        onClose={handleSeenNewFeature}
        onAgree={handleNewFeatures}
        onEnableFeature={handleEnableSearchHistory}
        title={"New Feature: Search History"}
      />
    </Box>
  );
};

export default Layout;
