import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import createRootReducer from "src/modules/reducers";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunkMiddleware from "redux-thunk";

const history = createBrowserHistory();

const middlewares = [thunkMiddleware, routerMiddleware(history)].filter(
  Boolean
);

export const store = createStore(
  createRootReducer(history),
  composeWithDevTools(applyMiddleware(...middlewares))
);

export type AppState = ReturnType<typeof store.getState>;
