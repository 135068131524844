import { User } from "src/types/UserGroup";
import { isArray, isPlainObject, isString } from "lodash";
import { SampleSearches } from "src/utils/constants";

export const getFullName = (user: User) =>
  [user.first_name, user.last_name].filter((s) => s).join(" ") || "Unnamed";

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const titleCase = (s: string) =>
  s.replace(/^_*(.)|_+(.)/g, (s: string, c: string, d: string) =>
    c ? c.toUpperCase() : " " + d.toUpperCase()
  );

export const isHTML = (text: string) => {
  return /<\/?[a-z][\s\S]*>/i.test(text);
};

export const randomColor = () => {
  let rc = Math.floor(Math.random() * 16581375).toString(16);
  while (rc.length > 6 || rc.length < 6) {
    rc = Math.floor(Math.random() * 16581375).toString(16);
  }
  return "#" + rc;
};

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const replaceHighlightTags = (text: string): string => {
  // highlighting is done with two closing tags like so - </em>highlight</em>
  if (!text) {
    return "";
  }
  if (Array.isArray(text)) {
    text = text.join(", ");
  }
  while (text?.includes("</em>")) {
    text = text.replace("</em>", "<span>");
    text = text.replace("</em>", "</span>");
  }

  return text;
};

export const getTotalMatches = (highlightContent: string[]) => {
  if (!highlightContent?.length) return 0;
  const regex = /<\/em>/g;
  const matches = highlightContent.join("").match(regex);
  return matches ? matches.length / 2 : 0;
};

// trims preview text around the first highlighted hit
// otherwise returns a short portion of the result from start
export const trimPreviewText = (fullText: string) => {
  let text = fullText;
  const firstHitIndex = fullText.indexOf("</em>");
  const trimLengthFull = window.innerWidth / 3;
  const trimLengthAroundHit = window.innerWidth / 6;

  if (firstHitIndex !== -1) {
    if (trimLengthAroundHit > firstHitIndex) {
      text = text.substring(
        0,
        firstHitIndex + (trimLengthFull - firstHitIndex)
      );
    } else {
      text =
        "... " +
        text.substring(
          firstHitIndex - trimLengthAroundHit,
          firstHitIndex + trimLengthAroundHit
        );
    }
  } else {
    text = text.substring(0, trimLengthFull);
  }

  if (text.length - trimLengthAroundHit < fullText.length - firstHitIndex)
    text += "...";

  return text;
};

export const formatSearchQuery = (query: string) => {
  if (!query) return;
  let formattedQuery = query.trim();
  // replace styled quotes with plain quotes for exact search
  // eslint-disable-next-line quotes
  formattedQuery = formattedQuery.replace(/(“|”)/g, `"`);
  return formattedQuery;
};

export const getFileNameFromURL = (url: string) => {
  const filename =
    url?.substring(url?.lastIndexOf("/") + 1)?.split("?")[0] || "";

  // Decode the filename as some files with special characters will be encoded
  return decodeURIComponent(filename);
};

export const isStringRTL = (string: string) =>
  /[\u0590-\u07FF\u200F\u202B\u202E\uFB1D-\uFDFD\uFE70-\uFEFC]/.test(string);

export const arrayContained = (arr1: string[], arr2: string[]) =>
  arr2.every((item) => arr1.includes(item));

export const isHtmlEmpty = (html: string) => {
  /*
  Checking for empty HTML strings that may be returned by the server.
  Examples include:
  - "<div></div>"
  - "<div><br></div>"
  - <div><p>&nbsp;</p></div>
  */
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  // Remove all comments from the document (8 is the nodeType for comments)
  Array.from(doc.querySelectorAll("*")).forEach((node) => {
    if (node.nodeType === 8) node.remove();
  });

  // Check if there's text content that's not only whitespace
  return !doc.body.textContent.trim() || doc.body.innerHTML.trim() === "&nbsp;";
};

// type guard to check if the api error is a field validation error
export const isAPIValidationErrors = (obj: any): boolean => {
  return (
    isArray(obj) &&
    obj.every((item) => {
      if (!isPlainObject(item)) {
        return false;
      }

      for (const key in item) {
        if (!isArray(item[key]) || !item[key].every(isString)) {
          return false;
        }
      }

      return true;
    })
  );
};

export const replaceQuotes = (search: string) => {
  return search
    .replace(/[\u201C\u201D\u201E\u201F\u2033\u2036"]/g, '"') // Handles various double quotes
    .replace(/[\u2018\u2019\u201A\u201B\u2032\u2035']/g, "'") // Handles various single quotes
    .replace(/[\u00AB\u00BB\u2039\u203A]/g, '"');
};

// A way to get a "random" search sample that only changes with route change
export const getSampleSearchFromLocationKey = (locationKey: string) => {
  let hash = 0;
  const maxIndex = SampleSearches.length;

  for (let i = 0; i < locationKey.length; i++) {
    hash = locationKey.charCodeAt(i) + ((hash << 5) - hash);
  }

  // fall back to Math.random approach just in case
  return (
    SampleSearches[Math.abs(hash % maxIndex)] ??
    SampleSearches[Math.floor(Math.random() * SampleSearches.length)]
  );
};
