import { AnyAction, Dispatch } from "redux";
import Service from "src/modules/dataset-sensitivity/service";
import Errors from "src/modules/shared/error/errors";

const prefix = "DATASET_SENSITIVITY_";

const Actions: AnyAction = {
  LOADING: `${prefix}LOADING`,
  GET_LIST: `${prefix}GET_LIST`,
  UPDATE_ERROR: `${prefix}UPDATE_ERROR`,
  UPDATE_SUCCESS: `${prefix}UPDATE_SUCCESS`,

  getDatasets: () => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({
        type: Actions.LOADING,
        payload: true,
      });
      const res = await Service.getAll();

      dispatch({
        type: Actions.GET_LIST,
        payload: res,
      });
    } catch (err) {
      dispatch({
        type: Actions.LOADING,
        payload: false,
      });
    }
  },
  moveItem:
    (id: string, data: { sensitivity: string }) =>
    async (dispatch: Dispatch<AnyAction>) => {
      try {
        dispatch({
          type: Actions.LOADING,
          payload: true,
        });

        const res = await Service.moveItem(id, data);
        if (res.error) {
          dispatch({
            type: Actions.UPDATE_ERROR,
            payload: res.detail,
          });
        } else {
          dispatch({
            type: Actions.UPDATE_SUCCESS,
            payload: res,
          });
        }
        return res;
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: Actions.UPDATE_ERROR,
          payload: error,
        });
      }
    },
  type: undefined,
};

export default Actions;
