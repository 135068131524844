import React from "react";
import Box from "@mui/material/Box";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import theme from "src/theme";
import { Typography } from "@mui/material";
import { ChainData } from "src/view/search-result/components/PreviewContent";

interface Props {
  data: ChainData[];
  handleLoadEntityPreview: (entity: unknown, index: number) => void;
}

const ConnectionBreadcrumbs = ({ data, handleLoadEntityPreview }: Props) => {
  const onClickRelationItem = (entity: unknown, index: number) => {
    handleLoadEntityPreview(entity, index);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: "30px",
      }}
    >
      {Array.isArray(data) &&
        data?.map((item: unknown, index: number) => {
          const islast = data.length === index + 1;
          return (
            <Box
              key={"relation-item-" + index}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                whiteSpace: "nowrap",
              }}
            >
              {item["type"] == "root" && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: 2,
                    cursor: "pointer",
                  }}
                  onClick={() => onClickRelationItem(item, index)}
                >
                  <HomeOutlinedIcon
                    style={{ fontSize: "14px", marginRight: theme.spacing(2) }}
                  />
                  <Typography
                    sx={{
                      color: islast ? "#3B83EF" : "primary",
                      fontSize: "10px",
                      fontWeight: "700",
                      textAlign: "left",
                    }}
                  >
                    {Array.isArray(item["entity"]?.title)
                      ? item["entity"]?.title[0]
                      : item["entity"]?.title}
                  </Typography>
                </Box>
              )}
              {item["type"] == "child" && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      height: 2,
                      width: 25,
                      bgcolor: "#CED3D9",
                      mx: 2,
                    }}
                  />
                  <Box
                    role="button"
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => onClickRelationItem(item, index)}
                  >
                    <Typography
                      sx={{
                        fontSize: "10px",
                        lineHeight: "15px",
                        color: "primary",
                        fontWeight: "300",
                        textAlign: "center",
                      }}
                    >
                      {item["role_in_parent"]}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alingItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: islast ? "#3B83EF" : "primary",
                          fontSize: "10px",
                          fontWeight: "700",
                          textAlign: "left",
                        }}
                      >
                        {item["entity"]["_source"]?.name?.[0] ??
                          `Unnamed ${item["entity"]["_source"].schema}`}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          );
        })}
    </Box>
  );
};

export default ConnectionBreadcrumbs;
