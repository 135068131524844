import { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import { Chip, IconButton, Switch, Box } from "@mui/material";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import { Group } from "src/types/UserGroup";
import { KnowledgeWiki } from "src/types/KnowledgeWiki";
import ConfirmDialog from "src/view/components/ConfirmDialog";
import DatasetItemFilter from "src/view/users-groups/groups/EditGroup/components/DatasetItemFilter";
import { arrayContained } from "src/utils";
import DatasetTable from "../components/DatasetTable";
import theme from "src/theme";

const StyledContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
}>(({ open }) => ({
  width: "calc(100% - 40px)",
  padding: 20,
  marginTop: 12,
  backgroundColor: "#FFFFFF",
  borderRadius: 8,
  ...(open && {
    boxShadow: "0px 4px 54px rgba(160, 185, 208, 0.3)",
  }),
}));

const StyledRow = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
}>(() => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#FFFFFF",
}));

const SensitivityGroupContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: 200,
}));

const StyledTitleContainer = styled(Box)(() => ({
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
}));

const StyledTitle = styled(Box)(() => ({
  fontSize: 18,
  lineHeight: "20px",
  fontWeight: "700",
  color: theme.palette.grey[900],
}));

interface Props {
  title: string;
  handleUpdate: (checked: boolean, key: string, value: string) => void;
  data: Group;
  datasets: KnowledgeWiki[];
}

const SensitivityGroup: React.FC<Props> = ({
  title,
  data,
  datasets,
  handleUpdate,
}) => {
  const [open, setOpen] = useState(false);
  const [checkedAll, setCheckAll] = useState(false);
  const [filterInput, setFilterInput] = useState("");
  const [filtered, setFiltered] = useState(datasets || []);

  const activeDatasetsMemo = useMemo(() => {
    let activeCount = 0;

    datasets.forEach((dataset) => {
      if (
        data.datasets.includes(dataset.dataset) ||
        data.dataset_downloads.includes(dataset.dataset) ||
        data.kw_write.includes(dataset.id) ||
        data.kw_read.includes(dataset.id)
      ) {
        return (activeCount += 1);
      }

      return activeCount;
    });

    return activeCount;
  }, [data, datasets]);

  useEffect(() => {
    let _all = true;
    const ds = datasets.map((dataset: KnowledgeWiki) => dataset.dataset);
    const dss = data.datasets;
    if (dss?.length > 0 && arrayContained(dss, ds)) {
      // _all = true;
    } else {
      _all = false;
    }
    const dds = data.dataset_downloads;
    if (dds?.length > 0 && arrayContained(dds, ds)) {
      // _all = true;
    } else {
      _all = false;
    }
    const kr = data.kw_read || [];
    const datasets_for_kw_read = datasets
      .filter((item: KnowledgeWiki) => kr.includes(item.dataset))
      .map((item: KnowledgeWiki) => item.dataset);
    if (
      datasets_for_kw_read.length > 0 &&
      arrayContained(datasets_for_kw_read, ds)
    ) {
      // _all = true;
    } else {
      _all = false;
    }

    setCheckAll(_all);
  }, [data]);

  const isChecked = useMemo(
    () => data?.sensitivity_levels?.includes(title),
    [data?.sensitivity_levels, title]
  );

  const handleToggleChange = (
    e: React.ChangeEvent<HTMLInputElement> | boolean
  ) => {
    const value = typeof e === "boolean" ? e : e.target.checked;
    handleUpdate(value, "sensitivity_levels", title);
  };

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterInput(e.target.value);
    if (e.target.value === "") {
      setFiltered(datasets);
    } else {
      const filter = datasets.filter((dataset: KnowledgeWiki) =>
        dataset.name.toLowerCase()?.includes(e.target.value.toLowerCase())
      );
      setFiltered(filter);
    }
  };

  return (
    <StyledContainer open={open}>
      <StyledRow open={open}>
        <StyledTitleContainer>
          <Box>
            <IconButton
              size="small"
              sx={{
                backgroundColor: theme.palette.grey[200],
                width: 24,
                height: 24,
              }}
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <ArrowDropDownOutlinedIcon
                  sx={{ fontSize: 24, color: theme.palette.primary.light }}
                />
              ) : (
                <ArrowRightOutlinedIcon
                  sx={{ fontSize: 24, color: theme.palette.primary.light }}
                />
              )}
            </IconButton>
          </Box>
          <Box sx={{ width: 22 }} />
          <Box>
            <StyledTitle>{title}</StyledTitle>
          </Box>
        </StyledTitleContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "18%",
            marginRight: "30px",
          }}
        >
          <Chip
            sx={{
              fontWeight: 700,
              background: "#F7515140",
              mr: 1,
            }}
            label={
              `● ${activeDatasetsMemo}` +
              " Dataset" +
              (activeDatasetsMemo === 1 ? "" : "s") +
              " Active"
            }
          />

          <Chip
            sx={{ fontWeight: 700 }}
            label={
              `● ${datasets.length}` +
              " Dataset" +
              (datasets.length === 1 ? "" : "s") +
              " Available"
            }
          />
        </Box>

        <DatasetItemFilter
          filterInput={filterInput}
          handleFilter={handleFilter}
        />
        {!["Sensitive", "Restricted"].includes(title) && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "50px",
              marginLeft: "20px",
            }}
          >
            <ConfirmDialog
              title={`Access to all ${title} Datasets`}
              description={`Do you want all ${title} datasets added to this group?`}
              onConfirm={() => handleToggleChange(!isChecked)}
              skip={isChecked}
              confirmText="Confirm"
              cancelText="Cancel"
            >
              <Switch sx={{ ml: -2 }} color="secondary" checked={isChecked} />
            </ConfirmDialog>
          </Box>
        )}
      </StyledRow>
      {open && (
        <SensitivityGroupContainer>
          <DatasetTable
            data={data}
            title={title}
            datasets={datasets}
            filtered={filtered}
            filterInput={filterInput}
            handleFilter={handleFilter}
            handleUpdate={handleUpdate}
            handleChangeAll={handleToggleChange}
            allChecked={checkedAll}
          />
        </SensitivityGroupContainer>
      )}
    </StyledContainer>
  );
};

export default SensitivityGroup;
