import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "../constants/colors";
import * as React from "react";
import { useEffect, useState } from "react";
import actions from "src/modules/dataset-sensitivity/actions";
import selector from "src/modules/dataset-sensitivity/selectors";
import { useDispatch, useSelector } from "react-redux";
import { Dataset, SensitivityValues } from "src/types/Dataset";

const StyledRootWrapper = styled(Container)(() => ({
  paddingLeft: "0px !important",
  paddingRight: "0px !important",
  marginTop: 127,
  paddingBottom: 100,
}));

const StyledHeader = styled("h3")(() => ({
  fontSize: 32,
  lineHeight: "39px",
  fontWeight: "700",
  color: Colors.textDark,
  marginRight: 18,
}));

const StyledText = styled(Typography)(() => ({
  fontSize: 14,
  lineHeight: "24px",
  fontWeight: "400",
  color: Colors.textDark,
}));

const StyledCard = styled(Box)(() => ({
  border: "1px solid rgba(0, 0, 0, 0.1)",
  borderRadius: 8,
  backgroundColor: "#FFFFFF",
  display: "table-cell",
}));

const CardHeader = styled(Box)(() => ({
  backgroundColor: "rgba(4, 61, 93, 0.04)",
  padding: "14px 20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
}));

const HeaderTitle = styled(Typography)(() => ({
  fontSize: 20,
  lineHeight: "25px",
  fontWeight: "700",
  color: Colors.twilight,
}));

const Title = styled(Typography)(() => ({
  fontSize: 14,
  lineHeight: "17px",
  fontWeight: "700",
  color: Colors.twilight,
  marginBottom: 4,
  paddingTop: 3,
}));

const Label = styled(Typography)(() => ({
  fontSize: 12,
  lineHeight: "15px",
  fontWeight: "400",
  color: Colors.twilight,
}));

const CardBody = styled(Box)(() => ({
  alignItems: "flex-start",
  justifyContent: "flex-start",
}));

const Item = styled(Box)(() => ({
  padding: "20px 20px 0px 20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  "&:last-child": {
    paddingBottom: "20px",
  },
}));

const MoveButton = styled(Button)(() => ({
  backgroundColor: Colors.twilight,
  color: "#FFFFFF",
  textTransform: "capitalize",
  fontSize: 14,
  lineHeight: "17px",
  fontWeight: "700",
  "&:hover": {
    backgroundColor: Colors.twilight,
  },
}));

const ScaffoldingPage = () => {
  const loading = useSelector(selector.selectLoading);
  const { datasets } = useSelector(selector.selectRaw);
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState({
    nonsensitive: [],
    privileged: [],
    sensitive: [],
    restricted: [],
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [menuType, setMenuType] = useState("");
  const mobileView = useMediaQuery("(max-width:678px)");

  useEffect(() => {
    dispatch(actions.getDatasets());
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
    key: string
  ) => {
    const sItems = { ...selectedItems };
    if (e.target.checked) {
      sItems[key].push(id);
    } else {
      sItems[key].splice(id, 1);
    }
    setSelectedItems(sItems);
  };

  const handleClickMoveButton = (
    e: React.MouseEvent<HTMLButtonElement>,
    type: string
  ) => {
    setMenuType(type);
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickItem = (to: string) => {
    handleMoveItems(to);
    setAnchorEl(null);
  };

  const handleMoveItems = async (to: string) => {
    const items = selectedItems[menuType];
    const data = {
      sensitivity: to,
    };
    for (let index = 0; index < items.length; index++) {
      const id = items[index];
      await dispatch(actions.moveItem(id, data));
    }
    const sItems = { ...selectedItems };
    sItems[menuType] = [];
    setSelectedItems(sItems);
    await dispatch(actions.getDatasets());
  };

  return (
    <StyledRootWrapper sx={{ mt: mobileView ? "149px" : "104px" }}>
      <Grid
        justifyContent="space-between"
        alignItems="center"
        sx={{ paddingTop: 2, display: "flex" }}
        style={{ marginLeft: 0, marginRight: 0 }}
      >
        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              <StyledHeader>Dataset Sensitivity</StyledHeader>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={8}>
          <StyledText variant="body1">
            Manage the assigned sensitivity levels for datasets within the
            application.
          </StyledText>
        </Grid>
      </Grid>
      <Box sx={{ height: 32 }} />
      <Stack>
        <Grid container spacing={2} sx={{}}>
          {loading && (
            <Grid
              item
              xs={12}
              sx={{
                top: 0,
                left: 0,
                width: "100%",
                height: "100vh",
                display: "flex",
                alignItems: "center",
                opacity: 0.5,
                justifyContent: "center",
                position: "absolute",
              }}
            >
              <CircularProgress />
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={3} sx={{ display: "table" }}>
            <StyledCard>
              <CardHeader>
                <HeaderTitle>Non Sensitive</HeaderTitle>
                {selectedItems["nonsensitive"]?.length > 0 && (
                  <MoveButton
                    startIcon={
                      <img
                        src={require("src/assets/images/move_white.png")}
                        style={{ width: 16, height: 16 }}
                      />
                    }
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                      handleClickMoveButton(e, "nonsensitive")
                    }
                  >
                    Move
                  </MoveButton>
                )}
              </CardHeader>
              <CardBody>
                {datasets?.length > 0 &&
                  datasets?.map((item: Dataset, index: number) => {
                    if (item.sensitivity == SensitivityValues.NONSENSITIVE)
                      return (
                        <Item key={"nonsensitive-item-" + index}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{ padding: 0, marginRight: "10px" }}
                                checked={selectedItems.nonsensitive.includes(
                                  item.id
                                )}
                                color="secondary"
                                name="remember me"
                                onChange={(e) =>
                                  handleChange(e, item.id, "nonsensitive")
                                }
                              />
                            }
                            sx={{ alignItems: "flex-start" }}
                            label={
                              <Box>
                                <Title>{item.name}</Title>
                                <Label>
                                  {item.groups_count}{" "}
                                  {item.groups_count === 1 ? "Group" : "Groups"}
                                </Label>
                              </Box>
                            }
                          />
                        </Item>
                      );
                  })}
              </CardBody>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={{ display: "table" }}>
            <StyledCard>
              <CardHeader>
                <HeaderTitle>Privileged</HeaderTitle>
                {selectedItems["privileged"]?.length > 0 && (
                  <MoveButton
                    startIcon={
                      <img
                        src={require("src/assets/images/move_white.png")}
                        style={{ width: 16, height: 16 }}
                      />
                    }
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                      handleClickMoveButton(e, "privileged")
                    }
                  >
                    Move
                  </MoveButton>
                )}
              </CardHeader>
              <CardBody>
                {datasets?.length > 0 &&
                  datasets?.map((item: Dataset, index: number) => {
                    if (item.sensitivity == "PRIVILEGED")
                      return (
                        <Item key={"privileged-item-" + index}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{ padding: 0, marginRight: "10px" }}
                                checked={selectedItems.privileged.includes(
                                  item.id
                                )}
                                color="secondary"
                                name="remember me"
                                onChange={(e) =>
                                  handleChange(e, item.id, "privileged")
                                }
                              />
                            }
                            sx={{ alignItems: "flex-start" }}
                            label={
                              <Box>
                                <Title>{item.name}</Title>
                                <Label>
                                  {item.groups_count}{" "}
                                  {item.groups_count === 1 ? "Group" : "Groups"}
                                </Label>
                              </Box>
                            }
                          />
                        </Item>
                      );
                  })}
              </CardBody>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={{ display: "table" }}>
            <StyledCard>
              <CardHeader>
                <HeaderTitle>Sensitive</HeaderTitle>
                {selectedItems["sensitive"]?.length > 0 && (
                  <MoveButton
                    startIcon={
                      <img
                        src={require("src/assets/images/move_white.png")}
                        style={{ width: 16, height: 16 }}
                      />
                    }
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                      handleClickMoveButton(e, "sensitive")
                    }
                  >
                    Move
                  </MoveButton>
                )}
              </CardHeader>
              <CardBody>
                {datasets?.length > 0 &&
                  datasets?.map((item: Dataset, index: number) => {
                    if (item.sensitivity == "SENSITIVE")
                      return (
                        <Item key={"sensitive-item-" + index}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{ padding: 0, marginRight: "10px" }}
                                checked={selectedItems.sensitive.includes(
                                  item.id
                                )}
                                color="secondary"
                                name="remember me"
                                onChange={(e) =>
                                  handleChange(e, item.id, "sensitive")
                                }
                              />
                            }
                            sx={{ alignItems: "flex-start" }}
                            label={
                              <Box>
                                <Title>{item.name}</Title>
                                <Label>
                                  {item.groups_count}{" "}
                                  {item.groups_count === 1 ? "Group" : "Groups"}
                                </Label>
                              </Box>
                            }
                          />
                        </Item>
                      );
                  })}
              </CardBody>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={{ display: "table" }}>
            <StyledCard>
              <CardHeader>
                <HeaderTitle>Restricted</HeaderTitle>
                {selectedItems["restricted"]?.length > 0 && (
                  <MoveButton
                    startIcon={
                      <img
                        src={require("src/assets/images/move_white.png")}
                        style={{ width: 16, height: 16 }}
                      />
                    }
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                      handleClickMoveButton(e, "restricted")
                    }
                  >
                    Move
                  </MoveButton>
                )}
              </CardHeader>
              <CardBody>
                {datasets?.length > 0 &&
                  datasets?.map((item: Dataset, index: number) => {
                    if (item.sensitivity == "RESTRICTED")
                      return (
                        <Item key={"restricted-item-" + index}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{ padding: 0, marginRight: "10px" }}
                                checked={selectedItems.restricted.includes(
                                  item.id
                                )}
                                color="secondary"
                                name="remember me"
                                onChange={(e) =>
                                  handleChange(e, item.id, "restricted")
                                }
                              />
                            }
                            sx={{ alignItems: "flex-start" }}
                            label={
                              <Box>
                                <Title>{item.name}</Title>
                                <Label>
                                  {item.groups_count}{" "}
                                  {item.groups_count === 1 ? "Group" : "Groups"}
                                </Label>
                              </Box>
                            }
                          />
                        </Item>
                      );
                  })}
              </CardBody>
            </StyledCard>
          </Grid>
        </Grid>
      </Stack>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menuType != "nonsensitive" && (
          <MenuItem onClick={() => handleClickItem("Nonsensitive")}>
            Non Sensitive
          </MenuItem>
        )}
        {menuType != "privileged" && (
          <MenuItem onClick={() => handleClickItem("Privileged")}>
            Privileged
          </MenuItem>
        )}
        {menuType != "sensitive" && (
          <MenuItem onClick={() => handleClickItem("Sensitive")}>
            Sensitive
          </MenuItem>
        )}
        {menuType != "restricted" && (
          <MenuItem onClick={() => handleClickItem("Restricted")}>
            Restricted
          </MenuItem>
        )}
      </Menu>
      <Box sx={{ height: 32 }} />
    </StyledRootWrapper>
  );
};

export default ScaffoldingPage;
