import screeningList from "src/modules/bulk-search/screeningList/screeningReducers";
import query_group from "src/modules/bulk-search/query-group/queryGroupReducers";
import filter from "src/modules/bulk-search/filter/searchFilterReducers";
import { combineReducers } from "redux";

export default combineReducers({
  screeningList,
  filter,
  query_group,
});
