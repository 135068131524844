import { Box, Switch, Tooltip } from "@mui/material";
import { InfoOutlined as InfoIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import ConfirmDialog from "src/view/components/ConfirmDialog";
import { Colors } from "src/view/constants/colors";
import { GroupFeatureNames } from "src/types/UserGroup";
import theme from "src/theme";

const features = [
  GroupFeatureNames.SEARCH,
  GroupFeatureNames.DATASET,
  GroupFeatureNames.BULK_SEARCH,
  GroupFeatureNames.KNOWLEDGE_WIKI,
  GroupFeatureNames.BULK_DOWNLOAD,
  GroupFeatureNames.DATA_COLLECTION,
  GroupFeatureNames.USER_GROUP,
];

const featureTooltips = {
  [GroupFeatureNames.SEARCH]:
    "Allow users to run searches. Most groups will need this.",
  [GroupFeatureNames.DATASET]:
    "Allow users to view datasets. Most groups will need this.",
  [GroupFeatureNames.BULK_SEARCH]:
    "Allow users to run bulk searches. Most groups will need this.",
  [GroupFeatureNames.KNOWLEDGE_WIKI]:
    "Allow users to view information about datasets and access the data dictionary. Most groups will need this.",
  [GroupFeatureNames.BULK_DOWNLOAD]:
    "Allow users to download search results in bulk.",
  [GroupFeatureNames.DATA_COLLECTION]:
    "Allow users to create custom dataset collections.",
  [GroupFeatureNames.USER_GROUP]: "Allow users to view their groups and users.",
};

const StyledBlock = styled(Box)(() => ({
  width: "50%",
  backgroundColor: "#FFFFFF",
  borderRadius: "6px",
  margin: "20px",
  padding: "16px",
  height: "max-content",
}));

interface Props {
  handleUpdate?: (checked: boolean, value: string) => void;
  data?: Array<string>;
}

const Features = (props: Props) => {
  const { handleUpdate, data } = props;

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: GroupFeatureNames | string
  ) => {
    handleUpdate(e.target.checked, value);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        minHeight: "50vh",
      }}
    >
      <StyledBlock>
        {features.slice(0, 4).map((feature) => (
          <FeatureItem
            key={feature}
            title={feature}
            tooltip={featureTooltips[feature]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              // ENG-1496
              handleChange(
                e,
                feature == GroupFeatureNames.KNOWLEDGE_WIKI
                  ? "Knowledge Wiki"
                  : feature
              )
            }
            value={data?.includes(
              feature == GroupFeatureNames.KNOWLEDGE_WIKI
                ? "Knowledge Wiki"
                : feature
            )}
          />
        ))}
      </StyledBlock>
      <StyledBlock>
        {features.slice(4).map((feature) => (
          <FeatureItem
            key={feature}
            title={feature}
            tooltip={featureTooltips[feature]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e, feature)
            }
            value={data?.includes(feature)}
          />
        ))}
      </StyledBlock>
    </Box>
  );
};

const StyledContainer = styled("div")(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: 14,
}));

const StyledTitle = styled("div")(() => ({
  fontSize: 14,
  lineHeight: "20px",
  fontWeight: "700",
  color: Colors.twilight,
}));

interface FeatureItemProps {
  title: string;
  tooltip: GroupFeatureNames;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: boolean;
}

const FeatureItem = (props: FeatureItemProps) => {
  const switchItem = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Tooltip
        placement={"top"}
        title={props.tooltip}
        arrow
        sx={{
          mr: "20px",
          backgroundColor: "red",
        }}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "#122945",
              "& .MuiTooltip-arrow": {
                color: "#122945",
              },
            },
          },
        }}
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -5],
              },
            },
          ],
        }}
      >
        <InfoIcon
          fontSize="small"
          sx={{
            color: theme.palette.grey["600"],
          }}
        />
      </Tooltip>

      <Switch
        color="secondary"
        checked={props.value}
        onChange={props.onChange}
      />
    </Box>
  );

  return (
    <StyledContainer>
      <Box>
        <StyledTitle>{props.title}</StyledTitle>
      </Box>
      {props.title === "Users and Groups" ? (
        <ConfirmDialog
          title="Users and Groups Access"
          description="Are you sure this group needs Users and Groups access?"
          onConfirm={() =>
            props.onChange({
              target: { checked: !props.value },
            } as React.ChangeEvent<HTMLInputElement>)
          }
          skip={props.value}
          confirmText="Confirm"
          cancelText="Cancel"
        >
          {switchItem}
        </ConfirmDialog>
      ) : (
        switchItem
      )}
    </StyledContainer>
  );
};

export default Features;
