import { DataCollection } from "src/types/DataCatalog";
import { Dataset } from "src/types/Dataset";
import actions from "./actions";

export interface DataCollectionsResponse {
  created_by: DataCollection[];
  shared_with_groups: DataCollection[];
}

export interface CatalogViewProps {
  loading: boolean;
  dataCatalog: DataCollectionsResponse;
}

const initialData: CatalogViewProps = {
  loading: false,
  dataCatalog: {
    created_by: [],
    shared_with_groups: [],
  },
};

export default (
  state = initialData,
  {
    type,
    payload,
  }: { type: string; payload?: boolean | Dataset[] | DataCollection[] | null }
) => {
  if (type === actions.FIND_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }
  if (type === actions.LOADING) {
    return {
      ...state,
      loading: !state.loading,
    };
  }

  if (type === actions.FIND_ERROR) {
    return {
      ...state,
      loading: false,
    };
  }

  if (type === actions.GET_DATACATALOG) {
    return {
      ...state,
      dataCatalog: payload,
      loading: false,
    };
  }

  return state;
};
