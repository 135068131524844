import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import queryKeys from "src/modules/api/queryKeys";
import QueryKeys, { mutationKeys } from "src/modules/api/queryKeys";
import {
  Dataset,
  DatasetIndexingTasksReturn,
  TaskStatusFiltersKeys,
} from "src/types/Dataset";
import { queryClient } from "src/index";
import { useUser } from "src/modules/api/auth";
import { Features } from "src/types/Auth";
import { useFetch } from "src/modules/api/fetch";
import { snackbar } from "src/view/toaster";
import { merge } from "lodash";

interface UpdateDatasetMetadataVariables {
  id: string;
  payload: Partial<Dataset>;
}

export function useDatasetMetadata() {
  const { user } = useUser();
  const { getApi, postApi, putApi, deleteApi } = useFetch();

  const getDatasetMetadata = (options?: UseQueryOptions<Dataset[]>) =>
    useQuery<Dataset[]>({
      enabled: !!user && !!user.features.includes(Features.DATASET),
      queryFn: () => getApi("dataset_metadata/"),
      queryKey: [queryKeys.DATASET_METADATA],
      initialData: () => [],
      ...options,
    });

  const getDatasetMetadataById = (id: string) =>
    useQuery({
      enabled: !!id && !!user.features.includes(Features.DATASET),
      queryFn: () => getApi(["dataset_metadata", id]),
      queryKey: [queryKeys.DATASET_METADATA_BY_ID, id],
      initialData: () => ({}),
    });

  const getUpdateValidation = (response: any): string => {
    let apiErrorMsg = "";

    response?.json().then((errorResponse: Record<string, string>) => {
      if (typeof errorResponse !== "object" || errorResponse === null) {
        return false;
      } else if (errorResponse && errorResponse["detail"]) {
        apiErrorMsg = errorResponse["detail"];
      }

      snackbar.error(apiErrorMsg || "Failed to update Dataset");
    });

    return apiErrorMsg;
  };

  const updateDatasetMetadata = (id: string) =>
    useMutation({
      mutationFn: ({ id, payload }: UpdateDatasetMetadataVariables) => {
        return putApi(
          ["dataset_metadata", id],
          {
            body: JSON.stringify(payload),
          },
          getUpdateValidation
        );
      },
      mutationKey: [mutationKeys.UPDATE_DATASET_METADATA_BY_ID],
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === queryKeys.DATASET_METADATA ||
            (query.queryKey[0] === queryKeys.DATASET_METADATA_BY_ID &&
              query.queryKey[1] === id) ||
            query.queryKey[0] === queryKeys.KNOWLEDGE_WIKI,
        });
      },
    });

  interface DeleteDatasetMetadataVariables {
    id: string;
  }

  const deleteDatasetMetadata = useMutation({
    mutationFn: ({ id }: DeleteDatasetMetadataVariables) =>
      deleteApi(["dataset_metadata", id]),
    mutationKey: [mutationKeys.UPDATE_DATASET_METADATA_BY_ID],
    onSuccess: ({ id }: DeleteDatasetMetadataVariables) => {
      queryClient.invalidateQueries({
        // TODO: update cache instead of invalidating to refetch?
        predicate: (query) =>
          query.queryKey[0] === queryKeys.DATASET_METADATA ||
          (query.queryKey[0] === queryKeys.DATASET_METADATA_BY_ID &&
            query.queryKey[1] === id) ||
          query.queryKey[0] === queryKeys.KNOWLEDGE_WIKI,
      });
    },
  });

  const getDatasetRecordCountById = (id: string) =>
    useQuery({
      enabled: !!id && !!user.features.includes(Features.DATASET),
      queryFn: () => getApi(["dataset_metadata", id, "count"]),
      queryKey: [queryKeys.DATASET_METADATA_RECORD_COUNT_BY_ID, id],
      initialData: () => ({}),
    });

  const createDataset = useMutation({
    mutationFn: (body: Partial<Dataset>) => {
      return postApi(["dataset_metadata"], { body: JSON.stringify(body) });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === QueryKeys.DATASET_METADATA,
      });
    },
  });

  interface ReindexBody {
    dataset_id: string;
  }

  const reindexDataset = () =>
    useMutation({
      mutationFn: (body: ReindexBody) => {
        return postApi(`dataset_metadata/reindex_dataset/`, {
          body: JSON.stringify(body),
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === QueryKeys.DATASET_METADATA,
        });
      },
    });

  interface ReindexTaskBody {
    task_id: string;
  }

  const reindexTask = (taskId: string) =>
    useMutation({
      mutationFn: (body: ReindexTaskBody) => {
        return postApi(`dataset_metadata/reindex/`, {
          body: JSON.stringify(body),
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === QueryKeys.DATASET_INDEXING_TASKS_BY_ID,
        });
      },
      mutationKey: [mutationKeys.RETRY_DATASET_INDEXING_TASK, taskId],
    });

  interface IndexingTasksParams {
    id: string;
    filter: TaskStatusFiltersKeys;
    page: number;
    pageSize: number;
  }

  const getDatasetIndexingTasksInfinite = ({
    id,
    filter,
    page,
    pageSize,
  }: IndexingTasksParams) =>
    useInfiniteQuery({
      enabled: !!id && !!user.features.includes(Features.DATASET),
      queryFn: (): Promise<DatasetIndexingTasksReturn> => {
        const searchParamsArray = [];
        if (filter) {
          searchParamsArray.push(`task_status=${encodeURIComponent(filter)}`);
        }
        if (page) {
          searchParamsArray.push(`page=${page}`);
        }
        searchParamsArray.push(`page_size=${pageSize}`);

        return getApi(
          ["dataset_metadata", id, "monitor_indexing"],
          searchParamsArray.join("&")
        );
      },
      queryKey: [queryKeys.DATASET_INDEXING_TASKS_BY_ID, id, filter, page],
      getNextPageParam: (lastPage: DatasetIndexingTasksReturn) =>
        lastPage.next_page_number,
      select: (newData) => {
        const cachedData = queryClient.getQueryData(
          [QueryKeys.DATASET_INDEXING_TASKS_BY_ID, id, filter, page],
          { exact: false }
        );

        if (cachedData) {
          const cachedFirstPage = cachedData?.["pages"]?.[0];
          const newFirstPage = newData?.["pages"]?.[0];

          return {
            ...merge(cachedData, newData),
            status_counts:
              cachedFirstPage.status_counts || newFirstPage.status_counts,
            total_count:
              cachedFirstPage.total_count || newFirstPage.total_count,
            // Hold on to the old values if it exists
          };
        }
        return newData;
      },
    });

  return {
    getDatasetMetadata,
    getDatasetMetadataById,
    getDatasetRecordCountById,
    updateDatasetMetadata,
    deleteDatasetMetadata,
    reindexDataset,
    reindexTask,
    createDataset,
    getDatasetIndexingTasksInfinite,
  };
}
