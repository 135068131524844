import { useDispatch, useSelector } from "react-redux";
import { snackbar } from "src/view/toaster";
import { Box, CircularProgress } from "@mui/material";

import actions from "src/modules/groups/view/groupsViewActions";
import selector from "src/modules/groups/view/groupsViewSelectors";
import { Group, User } from "src/types/UserGroup";

import UsersTableControllsWrapper from "./UsersTableControllsWrapper";

type Props = {
  loadingUsers: boolean;
  setNewUsers: React.Dispatch<React.SetStateAction<User[]>>;
  groupUsers: User[];
  setGroupUsers: React.Dispatch<React.SetStateAction<User[]>>;
  tempUsers: User[];
  setTempUsers: React.Dispatch<React.SetStateAction<User[]>>;
  formData: Group;
  setFormData: React.Dispatch<React.SetStateAction<Group>>;
  toggleAddNewUserModalOpen: () => void;
  toggleImportCSVModalOpen: () => void;
  toggleImportExistingModalOpen: () => void;
};

const GroupUsersTable = ({
  loadingUsers,
  setNewUsers,
  groupUsers,
  setGroupUsers,
  tempUsers,
  setTempUsers,
  formData,
  setFormData,
  toggleAddNewUserModalOpen,
  toggleImportCSVModalOpen,
  toggleImportExistingModalOpen,
}: Props) => {
  const dispatch = useDispatch();
  const selectedGroup = useSelector(selector.selectSelectedGroup);

  const handleRemoveUser = (user: User) => {
    if (user.id && selectedGroup?.group_id) {
      const _users = groupUsers?.filter((u: User) => u.id !== user.id);
      const _tempUsers = tempUsers?.filter((u: User) => u.id !== user.id);
      const data = { ...formData };
      data.members = [..._users, ..._tempUsers].map((u: User) => u.id);
      setGroupUsers(_users);
      setTempUsers(_tempUsers);
      setFormData(data);
    } else {
      let _users = [...tempUsers];
      _users = tempUsers.filter((u: User) => u.email != user.email);
      setTempUsers(_users);
    }
  };

  const handleResendEmail = async (data: { user_id: string }) => {
    const res = await dispatch(actions.resendEmail(data));
    if (res.error) {
      snackbar.error("Something went wrong");
    } else {
      snackbar.success("Email invitation sent.");
    }
  };

  const onOpenUserCreateModal = () => {
    setNewUsers([]);
    toggleAddNewUserModalOpen();
  };

  const onOpenImportExistingModal = async () => {
    setNewUsers([]);
    toggleImportExistingModalOpen();
  };

  if (loadingUsers || !groupUsers)
    return (
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: 200,
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <UsersTableControllsWrapper
      users={[...tempUsers, ...groupUsers]}
      openCreateUserModal={onOpenUserCreateModal}
      openImportExistingModal={onOpenImportExistingModal}
      openImportCSVModal={toggleImportCSVModalOpen}
      handleRemoveUser={handleRemoveUser}
      handleResendEmail={handleResendEmail}
    />
  );
};

export default GroupUsersTable;
