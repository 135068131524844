import { fetchApi } from "src/modules/shared/api";

export default class authService {
  static async login(data: { email: string; password: string }) {
    try {
      const res = await fetchApi("users/login/", {
        method: "POST",
        body: JSON.stringify(data),
      });
      const response = await res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response["error"],
        };
      }
    } catch (err) {
      return false;
    }
  }

  static async resetPassword(data: { email: string }) {
    try {
      const res = await fetchApi("users/reset_password/", {
        method: "POST",
        body: JSON.stringify(data),
      });
      const response = await res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response["error"],
        };
      }
    } catch (err) {
      return false;
    }
  }
  static async getPermissions() {
    try {
      const res = await fetchApi("users/permissions/", {
        method: "GET",
      });
      const response = await res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response["error"],
        };
      }
    } catch (err) {
      return false;
    }
  }
  static async setAgree() {
    try {
      const res = await fetchApi("eula/sign_eula/", {
        method: "GET",
      });
      const response = await res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response["error"],
        };
      }
    } catch (err) {
      return false;
    }
  }
  static async setSeenNewFeatures() {
    try {
      const res = await fetchApi("new_features/seen_new_features/", {
        method: "GET",
      });
      const response = await res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response["error"],
        };
      }
    } catch (err) {
      return false;
    }
  }
  static async getUserDetail(id: string) {
    try {
      const res = await fetchApi("users/" + id + "/", {
        method: "GET",
      });
      const response = await res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response["error"],
        };
      }
    } catch (err) {
      return false;
    }
  }
  static async updateUserDetail(id: string, data: unknown) {
    try {
      const res = await fetchApi("users/" + id + "/", {
        method: "PUT",
        body: JSON.stringify(data),
      });
      const response = await res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response["error"],
        };
      }
    } catch (err) {
      return false;
    }
  }
  static async updatePasswords(passwords: unknown) {
    try {
      const res = await fetchApi("users/update_password/", {
        method: "POST",
        body: JSON.stringify(passwords),
      });
      const response = await res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response["error"],
        };
      }
    } catch (err) {
      return false;
    }
  }
  static async trackUserLogin() {
    try {
      const res = await fetchApi("users/track_login/", {
        method: "POST",
      });
      const response = await res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response["error"],
        };
      }
    } catch (err) {
      return false;
    }
  }
}
