import { KnowledgeWiki } from "src/types/KnowledgeWiki";

export type KnowledgeWikiMarkDownKeys = keyof Pick<
  KnowledgeWiki,
  | "summary"
  | "credibility"
  | "coverage"
  | "search_tips_tricks"
  | "sample_records"
  | "article"
>;
export const dictionaryDetailsSections: Array<{
  key: KnowledgeWikiMarkDownKeys;
  label?: string;
}> = [
  {
    key: "summary",
    label: "Summary",
  },
  {
    key: "credibility",
    label: "Dataset Credibility",
  },
  {
    key: "coverage",
    label: "Dataset Coverage",
  },
  {
    key: "search_tips_tricks",
    label: "Search Tips & Tricks",
  },
  {
    key: "sample_records",
    label: "Sample Records",
  },
  {
    key: "article",
    label: "Dataset Parsers",
  },
];
