import { useUser } from "src/modules/api/auth";

export default function LaunchNotesWidget() {
  const { user } = useUser();

  if (!user) return null;

  return (
    <div
      id="launchnotes-widget"
      dangerouslySetInnerHTML={{
        __html: `
      <launchnotes-embed
    token="public_X3T5hfo8QLzH2fp8na9jqpue"
    project="pro_NRvP13wuwvWVi"
    toggle-selector="#whats_new_link"
    heading="Product Updates"
    heading-color="#FFF"
    subheading="The latest features, improvements, and bug fixes"
    subheading-color="rgba(255, 255, 255, 0.8)"
    primary-color="#225b7b"
    widget-placement="top-end"
    widget-offset-skidding="0"
    widget-offset-distance="0"
    show-unread=true
    anonymous-user-id="${user.id}"
    unread-placement="right-start"
    unread-offset-skidding="-4"
    unread-offset-distance="4"
    unread-background-color="#0080ff"
    unread-text-color="#ffffff"
  ></launchnotes-embed>`,
      }}
    ></div>
  );
}
