import * as React from "react";
import { FormEvent, useEffect, useMemo, useRef, useState } from "react";
import { endsWith, isEmpty, startsWith } from "lodash";
import { styled } from "@mui/material/styles";
import { Colors } from "../constants/colors";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  ListItem,
  Menu,
  MenuItem,
  Paper,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import {
  AccountCircle,
  Menu as MenuIcon,
  Save as SaveIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import logo from "src/assets/logo-white.svg";
import { ReactComponent as ImgIconSearchHistory } from "src/assets/icons/search_history.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { useClickAway } from "src/modules/shared/hooks";
import { AuthToken } from "src/modules/auth/authToken";
import { QueryItem } from "src/types/BulkSearch";
import { QueryBuilderContext } from "../landing/components/Search/SearchBox";
import SearchTabs from "./SearchHeaderTabs";
import StyledButton from "src/view/landing/components/Search/StyledButton";
import FormattedTextInput from "../landing/components/Search/FormattedTextInput";
import { useUser } from "src/modules/api/auth";
import { useSearch } from "src/modules/api/search";
import { getSampleSearchFromLocationKey, replaceQuotes } from "src/utils";
import { usePostHog } from "posthog-js/react";
import SearchHistoryPopup from "src/view/search-result/components/SearchHistory/SearchHistoryPopup";
import { SearchHistory } from "src/types/Search";
import {
  emptyQueryVariables,
  formatVariablesSearchParam,
} from "src/modules/api/search/SearchProvider";
import { useSearchHistory } from "src/modules/api/searchHistory";
import { HogEvent } from "src/types/PosthogEvents";

const LogoWrapper = styled("div")(() => ({
  height: 50,
  width: 160,
  minWidth: 160,
  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    cursor: "pointer",
  },
}));

const StyledContainer = styled(Container)(() => ({
  maxWidth: "100% !important",
  backgroundColor: "transparent",
}));

const StyledSearchBox = styled("form")(() => ({
  position: "relative",
  height: 50,
  width: "100%",
  display: "flex",
  alignItems: "center",
  background: "white",
  border: "1px solid #A0B9D0",
}));

const StyledSearchIcon = styled(SearchIcon)(() => ({
  position: "absolute",
  left: 20,
  fontSize: 24,
  color: "#5A7291",
}));

const InputWrapper = styled("div")(({ theme }) => ({
  color: "inherit",
  width: "100%",
  height: "100%",
  position: "relative",
  marginLeft: 50,
  marginRight: 80,
  [theme.breakpoints.down("sm")]: {
    width: "calc(100% - 100px)",
  },
  fontSize: "14px",
}));

const SearchInput = styled("input")(() => ({
  height: "100%",
  width: "100%",
  borderRadius: 4,
  fontSize: 14,
  color: "transparent",
  border: "none",
  background: "transparent",
  position: "absolute",
  zIndex: 1,
  textIndent: "0px",
  textShadow: "none",
  "&:focus-visible": {
    outline: "none",
  },
  "&:focus": {
    caretColor: "black",
  },
  "&::placeholder": {
    color: "#999",
    opacity: 1,
  },
  fontFamily: "inherit",
}));

const SearchIconWrapper = styled(Button)(() => ({
  position: "absolute",
  right: 6,
  height: 40,
  minWidth: 40,
  zIndex: 2,
  backgroundColor: Colors.secondary,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  borderRadius: 4,
  color: "white",
  "&:hover": {
    backgroundColor: Colors.secondary,
  },
  "&:disabled": {
    opacity: 0.5,
  },
}));

const StyledSearchDropdown = styled("div")(() => ({
  position: "absolute",
  minWidth: 500,
  left: -1,
  top: 52,
  zIndex: 10000,
}));

const QueryBuilder = styled(Paper)(({ theme }) => ({
  position: "relative",
  borderRadius: 0,
  border: "1px solid #A0B9D0",
  backgroundColor: "white",
  marginTop: -1,
  marginRight: 0,
  marginLeft: 0,
  width: "100%",
  height: "auto",
  display: "block",
  alignItems: "flex-start",
  padding: theme.spacing(2, 3, 4, 3),
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const StyledQuerySearch = styled("div")(({ theme }) => ({
  // 680px of input + 150px of searchTabs to the left
  // the following is to keep the container centered
  // while also not preventing the focusable input from sliding away with searchTabs open
  maxWidth: 830,
  [theme.breakpoints.up("md")]: {
    paddingRight: 150,
  },
  paddingLeft: "160px",
  width: "100%",
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
}));

export default function SearchHeader() {
  const { user, logout } = useUser();
  const { isFetching, variables, handleSearch, updateContent } = useSearch();
  const navigate = useNavigate();
  const location = useLocation();
  const { getSearchHistory } = useSearchHistory();
  const { refetch: refetchSearchHistory } = getSearchHistory;
  const [search, setSearch] = useState<string>(variables.content);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const mobileView = useMediaQuery("(max-width:678px)");
  const wrapperRef = useRef(null);
  const slideRef = useRef(null);
  const searchInputRef = useRef(null);
  useClickAway(wrapperRef, () => setOpenSearchDropdown(""));
  const isMenuOpen = Boolean(anchorEl);
  const [openSearchDropdown, setOpenSearchDropdown] = useState(""); // '' | default | freeText | entity
  const [queryBuilder, setQueryBuilder] = useState<QueryItem[]>([
    {
      type: "term", // operator | term | group
      value: "",
      fuzziness: 0,
    },
  ]);
  const [showSearchHistoryPopup, setShowSearchHistoryPopup] = useState(false);
  const posthog = usePostHog();

  const [tabs, setTabs] = useState({
    freeText: false,
    entity: false,
  });
  const [showTabs, setShowTabs] = useState(false);

  useEffect(() => {
    const prepSearch = (search: string) => {
      if (
        !isEmpty(search) &&
        (search.includes("AND") ||
          search.includes("OR") ||
          search.includes("~"))
      ) {
        convertQuery(search);
      }
    };

    prepSearch(variables.content);
    setSearch(variables.content);
  }, [variables]);

  useEffect(() => {
    if (tabs.freeText) {
      setOpenSearchDropdown("freeText");
    } else if (tabs.entity) {
      setOpenSearchDropdown("entity");
    } else {
      setOpenSearchDropdown("");
    }
  }, [tabs]);

  useEffect(() => {
    if (openSearchDropdown == "freeText") {
      setShowTabs(true);
    } else {
      setShowTabs(false);
    }
  }, [openSearchDropdown]);

  const handleChagneTabs = (data: unknown) => {
    if (data && typeof data == "object") {
      const _tabs = { ...tabs, ...data };
      setTabs(_tabs);
    }
  };

  const handleProfileMenuOpen = (event: unknown) => {
    setAnchorEl(event["currentTarget"]);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const gotoProfilePage = () => {
    handleMenuClose();
    navigate("/profile");
  };

  const exampleSearch = useMemo(
    () => getSampleSearchFromLocationKey(location.key),
    []
  );

  const handleChangeQueryItem = (_q: QueryItem, index: number) => {
    const _query = [...queryBuilder];
    if (!_q) {
      if (index === 0) {
        if (!queryBuilder.length) {
          _query.splice(index, 1);
        } else {
          _query.splice(index + 1, 1);
          _query.splice(index, 1);
        }
      } else {
        _query.splice(index, 1);
        _query.splice(index - 1, 1);
      }
    } else {
      _query[index] = _q;
    }
    if (_query.length < 1) return;
    setQueryBuilder(_query);
  };

  const onAddQuery = () => {
    const operatorQuery = {
      type: "operator",
      value: "AND",
    };
    const termQuery = {
      type: "term",
      value: "",
      fuzziness: 0,
    };
    let newArr = [...queryBuilder, operatorQuery, termQuery];
    if (!queryBuilder.length) {
      newArr = [...queryBuilder, termQuery];
    }
    setQueryBuilder(newArr);
  };

  const onAddEntity = () => {
    const operatorQuery = {
      type: "operator",
      value: "AND",
    };
    const entityQuery = {
      type: "entity",
      value: "",
      fuzziness: 0,
      schema: "",
      property: "",
    };
    const newArr = [...queryBuilder, operatorQuery, entityQuery];
    if (queryBuilder.length > 0) {
      setQueryBuilder(newArr);
    } else {
      setQueryBuilder([entityQuery]);
    }
  };

  const onAddGroup = () => {
    const operatorQuery = {
      type: "operator",
      value: "AND",
    };
    const groupQuery = {
      type: "group",
      form: [
        {
          type: "term",
          value: "",
          fuzziness: 0,
        },
        {
          type: "operator",
          value: "AND",
        },
        {
          type: "term",
          value: "",
          fuzziness: 0,
        },
      ],
    };
    const newArr = [...queryBuilder, operatorQuery, groupQuery];
    if (queryBuilder.length > 0) {
      setQueryBuilder(newArr);
    } else {
      setQueryBuilder([groupQuery]);
    }
  };

  const _setSearchValue = (_query: QueryItem[]) => {
    let _searchValue = "";
    _query?.map((q: QueryItem) => {
      if (q.type === "group") {
        _searchValue += " (";
        q.form.map((qq: QueryItem) => {
          if (qq.type === "operator") {
            _searchValue += ` ${qq.value} `;
          } else {
            if (qq.fuzziness != 0) {
              _searchValue += ` "${qq.value}"~${qq.fuzziness}`;
            } else _searchValue += ` "${qq.value}"`;
          }
        });
        _searchValue += ") ";
      } else {
        if (q.type === "operator") {
          _searchValue += ` ${q.value} `;
        } else if (q.type === "term") {
          if (q.fuzziness != 0) {
            _searchValue += ` "${q.value}"~${q.fuzziness}`;
          } else _searchValue += ` "${q.value}"`;
        } else if (q.type === "entity") {
          _searchValue += ` (schema:${q.schema}`;
          if (q.property) {
            _searchValue += ` AND ${q.property}:"${q.value}"`;
          }
          if (q.fuzziness != 0) {
            _searchValue += `~${q.fuzziness})`;
          } else {
            _searchValue += ")";
          }
        }
      }
    });
    if (
      _searchValue.includes("OR") == false &&
      _searchValue.includes("AND") == false &&
      _searchValue.includes(")") == false &&
      _searchValue.includes("(") == false &&
      _searchValue.includes("~") == false
    ) {
      _searchValue = _searchValue.replace(/"/gi, "");
    }
    updateContent(_searchValue.trim());
  };

  const convertQuery = (query: string) => {
    const regex = /([^()"]+)|("[^"]+"~[0-9])|(\([^()]+\))/g;
    const items = query.match(regex);
    const _queryB: QueryItem[] = [];
    let isGroup = false;
    let isEntity = false;
    items.forEach((item) => {
      let qItem: QueryItem;
      item = item.trim();
      if (item == "") return;
      if (startsWith(item, "(") && endsWith(item, ")")) {
        if (item.includes("schema:")) {
          isEntity = true;
        } else {
          isGroup = true;
        }
      }
      item = item.trim();
      if (item === "AND" || item === "OR") {
        qItem = {
          type: "operator",
          value: item.includes("AND") ? "AND" : "OR",
        };
      } else {
        if (isEntity) {
          isEntity = false;
          item = item.replace("(", "").replace(")", "");
          item = item.trim();
          const entityArr = item.split("AND");
          const schema = entityArr[0]?.replace("schema:", "").trim();
          const p_v = entityArr[1]?.trim().split(":");
          const property = p_v ? p_v[0] : "";
          let value = p_v ? p_v[1]?.trim() : "";
          value = value.replace(/"/g, "");
          let fuzziness = 0;
          if (value?.includes("~")) {
            fuzziness = parseInt(value.split("~")[1]);
            value = value.split("~")[0].replace(/"/g, "");
          }
          qItem = {
            type: "entity",
            value: value,
            fuzziness: fuzziness,
            schema: schema,
            property: property,
          };
        } else if (isGroup) {
          isGroup = false;
          item = item.replace("(", "").replace(")", "");
          item = item.trim();
          const operators = item.match(/AND|OR/g);
          const groupEntries = item.split(/AND|OR/);
          qItem = {
            type: "group",
            form: [],
          };
          groupEntries.forEach((_item, index) => {
            let _qItem;
            _item = _item.trim();
            if (_item == "") return;
            const qArr = _item.split(/["*"]/g);
            if (qArr[2]?.includes("~")) {
              _qItem = {
                type: "term",
                value: qArr[1],
                fuzziness: parseInt(qArr[2].split("~")[1]),
              };
            } else {
              _qItem = {
                type: "term",
                value: qArr[1],
                fuzziness: 0,
              };
            }
            qItem.form.push(_qItem);
            if (operators?.[index]) {
              _qItem = {
                type: "operator",
                value: operators[index],
              };
              qItem.form.push(_qItem);
            }
          });
        } else {
          item = item.replace(/"/g, "");
          if (item.includes("~")) {
            const _term = item.split("~")[0];
            qItem = {
              type: "term",
              value: _term,
              fuzziness: parseInt(item.split("~")[1]),
            };
          } else if (item != "") {
            qItem = {
              type: "term",
              value: item,
              fuzziness: 0,
            };
          }
        }
      }
      _queryB.push(qItem);
    });

    setQueryBuilder(_queryB);
  };

  const handleSaveQuery = () => {
    _setSearchValue(queryBuilder);
    setOpenSearchDropdown("");
  };

  const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    const value = event.target[0].value;
    if (!value) return;
    e.preventDefault();
  };

  const openSidebar = () => {
    document.dispatchEvent(new CustomEvent("openSideBar"));
  };

  const handleLogout = () => {
    handleMenuClose();
    AuthToken.logout();
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const handleInputCursor = () => {
    const textBox = searchInputRef.current;

    const hiddenLeft = textBox.scrollLeft;

    const formattedTextBox = document.getElementById("formatted_search_text");
    formattedTextBox.scrollLeft = hiddenLeft;
  };

  const toggleShowSearchHistory = () => {
    if (!showSearchHistoryPopup) {
      refetchSearchHistory();
    }
    setShowSearchHistoryPopup(!showSearchHistoryPopup);
    if (showSearchHistoryPopup)
      posthog.capture(HogEvent.SEARCH_HISTORY_POPUP_OPENED);
    else posthog.capture(HogEvent.SEARCH_HISTORY_POPUP_CLOSED);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <ListItem sx={{ cursor: "default" }}>{user?.email}</ListItem>
      <MenuItem onClick={gotoProfilePage}>My account</MenuItem>
      <MenuItem onClick={handleLogout}>Log out</MenuItem>
    </Menu>
  );

  const handleSearchInHistory = (data: SearchHistory) => {
    const {
      id,
      content,
      result_count,
      result_count_relation,
      timestamp,
      ...filters
    } = data;
    const newVariables = {
      content: data.content,
      filters: {
        ...emptyQueryVariables.filters,
        ...filters,
      },
    };

    const searchParams = formatVariablesSearchParam(newVariables);
    return window.open(`${window.location.origin}/search?q=${searchParams}`);
  };

  const SearchComponent = () => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = replaceQuotes(e.target.value);
      setSearch(value);
      if (openSearchDropdown !== "freeText") {
        updateContent(value);
      }
    };
    return (
      <>
        <SearchHistoryPopup
          isSearchHistoryOpen={showSearchHistoryPopup}
          onClosePopup={toggleShowSearchHistory}
          handleSearch={handleSearchInHistory}
        />

        <StyledQuerySearch ref={wrapperRef}>
          <Box
            sx={{
              width: 160,
              position: "absolute",
              left: "160px",
              top: 0,
              transform: showTabs ? "translateX(-160px)" : "none",
              transition: "transform 0.3s ease",
            }}
          >
            <SearchTabs tabs={tabs} handleChange={handleChagneTabs} />
          </Box>
          <Box ref={slideRef} />
          <StyledSearchBox
            onSubmit={onFormSubmit}
            sx={{ borderRadius: showTabs ? "0px 4px 4px 0px" : "4px" }}
          >
            <StyledSearchIcon />
            <InputWrapper>
              <FormattedTextInput
                text={search}
                containerStyle={{
                  fontSize: "14px",
                  height: "calc(100% - 26px)",
                  margin: "13px 0",
                  color: "#5A7291",
                }}
              />
              <SearchInput
                placeholder={`Begin your investigation: ${exampleSearch}`}
                ref={searchInputRef}
                value={search}
                onChange={handleChange}
                onFocus={() => {
                  if (tabs.freeText) {
                    setOpenSearchDropdown("freeText");
                  }
                  setShowTabs(true);
                }}
                onKeyDown={() => handleInputCursor()}
                onKeyUp={() => handleInputCursor()}
                onBlur={() => {
                  setTimeout(() => {
                    if (!tabs.freeText && showTabs) {
                      setShowTabs(false);
                    }
                  }, 200);
                }}
              />
            </InputWrapper>
            <SearchIconWrapper
              onClick={() => handleSearch()}
              type="submit"
              disabled={!search.length}
            >
              {isFetching ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <img
                  src={require("src/assets/images/search.png")}
                  style={{ width: 20, height: 20 }}
                  alt="Search icon"
                />
              )}
            </SearchIconWrapper>
            {openSearchDropdown == "freeText" && (
              <StyledSearchDropdown>
                <QueryBuilder>
                  <QueryBuilderContext
                    queryB={queryBuilder}
                    setQueryB={setQueryBuilder}
                    handleChangeQueryItem={handleChangeQueryItem}
                  />
                  <Grid
                    container
                    sx={{ pt: 2, justifyContent: "space-between" }}
                  >
                    <Grid item container xs={8}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <StyledButton
                          text="Add Term"
                          icon={
                            <img
                              src={require("src/assets/images/term.png")}
                              style={{ width: 16, height: 16 }}
                            />
                          }
                          onClick={onAddQuery}
                          sx={{ mr: 0, whiteSpace: "nowrap" }}
                        />
                        <StyledButton
                          text="Add Entity"
                          icon={
                            <img
                              src={require("src/assets/images/entity.png")}
                              style={{ width: 16, height: 16 }}
                            />
                          }
                          onClick={onAddEntity}
                          sx={{ mr: 0, whiteSpace: "nowrap" }}
                        />
                        <StyledButton
                          text="Add Group"
                          icon={
                            <img
                              src={require("src/assets/images/group.png")}
                              style={{ width: 16, height: 16 }}
                            />
                          }
                          onClick={onAddGroup}
                          sx={{ mr: 0, whiteSpace: "nowrap" }}
                        />
                      </Box>
                    </Grid>
                    <Grid item>
                      <StyledButton
                        text="Save"
                        sx={{
                          color: "#11A76D",
                          bgcolor: "rgba(17, 167, 109, 0.2)",
                        }}
                        icon={<SaveIcon />}
                        onClick={handleSaveQuery}
                      />
                    </Grid>
                  </Grid>
                </QueryBuilder>
              </StyledSearchDropdown>
            )}
          </StyledSearchBox>
        </StyledQuerySearch>
      </>
    );
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        background: "rgba(18, 41, 69, 1.0)",
        boxShadow: "none",
        pt: "20px",
        pb: "20px",
      }}
    >
      <StyledContainer>
        <Toolbar sx={{ justifyContent: "flex-start" }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 0 }}
            onClick={openSidebar}
          >
            <MenuIcon />
          </IconButton>
          <LogoWrapper onClick={() => navigate("/")}>
            <img src={logo} />
          </LogoWrapper>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              mx: 2,
            }}
          >
            {!mobileView && SearchComponent()}
          </Box>
          <Box sx={{ display: { xs: "none", md: "flex" }, gap: 3 }}>
            <Box
              sx={{
                width: "24px",
              }}
            >
              <IconButton
                size="large"
                edge="end"
                aria-label="search history of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={toggleShowSearchHistory}
                color="inherit"
              >
                <ImgIconSearchHistory
                  style={{
                    color: Colors.white,
                    width: 24,
                    height: 24,
                  }}
                />
              </IconButton>
            </Box>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
        </Toolbar>
        {renderMenu}
        <Box
          sx={{
            display: mobileView ? "flex" : "none",
            width: "100%",
            justifyContent: "center",
          }}
        >
          {mobileView && SearchComponent()}
        </Box>
      </StyledContainer>
    </AppBar>
  );
}
