import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import {
  styled,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { Colors } from "src/view/constants/colors";
import Dropzone from "react-dropzone";
import { useOCRTasks } from "src/modules/api/ocr";
import { useIsMutating } from "@tanstack/react-query";
import { mutationKeys } from "src/modules/api/queryKeys";
import { ReactComponent as IconPDF } from "src/assets/icons/type/source/type_pdf.svg";
import { ReactComponent as IconError } from "src/assets/icons/error_octagon.svg";

const StyledButton = styled(Button)(() => ({
  color: Colors.twilight,
  borderColor: Colors.twilight,
  textTransform: "capitalize",
  fontSize: 14,
  lineHeight: "20px",
  fontWeight: "700",
  "&:hover": {
    backgroundColor: Colors.textDark100,
  },
}));

const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    margin: "150px",
    maxWidth: "975px",
  },
}));

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

const OCRUploadModal = ({ isOpen, handleClose }: Props) => {
  const { uploadFileForOCR } = useOCRTasks();
  const {
    mutateAsync: uploadFile,
    isError: isUploadError,
    isSuccess: isUploadSuccess,
  } = uploadFileForOCR();
  const [dropzoneError, setDropzoneError] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [index, setIndex] = useState(0);

  const totalFilesUploading = useIsMutating({
    mutationKey: [mutationKeys.UPLOAD_OCR_FILE],
  });

  const handleUploadFiles = (files: File[]) => {
    setDropzoneError(null);
    setSelectedFiles(files);
  };

  interface RejectedFile {
    errors: {
      code: string;
      message: string;
    }[];
  }
  const handleFilesRejected = (rejectedFiles: RejectedFile[]) => {
    if (
      rejectedFiles.some((file) =>
        file.errors.some((fileError) => fileError.code === "too-many-files")
      )
    ) {
      setDropzoneError("Please only upload up to 5 files at a time");
    }
  };

  useEffect(() => {
    if (selectedFiles.length) {
      const timerId = setInterval(
        () => setIndex((i) => (i + 1) % selectedFiles.length),
        1000
      );
      return () => clearInterval(timerId);
    }
  }, [selectedFiles]);

  useEffect(() => {
    if (selectedFiles.length) {
      selectedFiles.forEach((file: File) => {
        uploadFile(file);
      });
    }
  }, [selectedFiles]);

  const closeAndResetState = () => {
    handleClose();
    setSelectedFiles([]);
    setDropzoneError(null);
  };

  const getFakeProgress = (selectedFiles: File[]) => {
    const currentFile = selectedFiles[index];
    const portalParent = document.getElementById("filename-container-portal");

    return (
      <>
        <Box
          sx={{
            display: "flex",
            width: "54px",
            height: "54px",
            padding: "8px",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "4px",
            background: "#E0E5EB",
          }}
        >
          <IconPDF />
        </Box>

        {!!totalFilesUploading &&
          portalParent &&
          createPortal(
            <Box
              sx={{
                marginTop: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress
                size={20}
                sx={{
                  marginRight: "16px",
                }}
              />
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                {currentFile?.name}
              </Typography>
            </Box>,
            portalParent
          )}
      </>
    );
  };

  useEffect(() => {
    if (isUploadSuccess && !totalFilesUploading) {
      handleClose();
      setSelectedFiles([]);
    }
  }, [isUploadSuccess, totalFilesUploading]);

  const isErrorState = isUploadError || dropzoneError;

  return (
    <StyledDialog
      fullWidth
      open={isOpen}
      onClose={closeAndResetState}
      disableAutoFocus
      maxWidth={false}
    >
      <DialogTitle
        sx={{
          paddingBottom: 0,
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
            }}
          >
            Upload File for OCR
          </Typography>
          <IconButton
            onClick={closeAndResetState}
            sx={{
              padding: 0,
            }}
          >
            <CloseIcon
              sx={{
                color: "#122945",
              }}
            />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          overflow: "hidden",
        }}
      >
        <Dropzone
          maxFiles={5}
          multiple
          accept={{
            "image/jpeg": [".jpg", ".jpeg", ".png"],
            "application/pdf": [".pdf"],
          }}
          onDropAccepted={(acceptedFiles) => handleUploadFiles(acceptedFiles)}
          onDropRejected={(files) => handleFilesRejected(files)}
        >
          {({ getRootProps, getInputProps }) => (
            <Box
              sx={{
                boxSizing: "border-box",
                height: "262px",

                margin: "30px 0",
                padding: "50px",
                border:
                  isErrorState && !!totalFilesUploading
                    ? "1px dashed #F75151"
                    : "1px dashed #043D5D",
                borderRadius: "12px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor:
                  isErrorState && !totalFilesUploading
                    ? "rgba(247, 81, 81, 0.05)"
                    : "rgba(4, 61, 93, 0.05)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {isErrorState ? (
                  <Box
                    sx={{
                      margin: "auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <IconError
                      style={{ width: 39, height: 39, color: "#F75151" }}
                    />
                    <Box sx={{ height: 12 }}></Box>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "18px",
                        lineHeight: "20px",
                        fontWeight: "700",
                        color: Colors.secondary,
                      }}
                    >
                      {dropzoneError
                        ? dropzoneError
                        : "Error encountered when uploading files"}
                    </Typography>
                    <Box sx={{ height: 30 }}></Box>
                    <StyledButton
                      startIcon={
                        <img
                          src={require("src/assets/images/upload_icon_white.png")}
                          style={{ width: 18, height: 18, color: "white" }}
                        />
                      }
                      variant="outlined"
                      sx={{
                        backgroundColor: Colors.textDark100,
                        color: "#FFFFFF",
                        height: 42,
                      }}
                    >
                      Upload Again
                    </StyledButton>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      margin: "auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {totalFilesUploading ? (
                      <Box>
                        <>{getFakeProgress(selectedFiles)}</>
                      </Box>
                    ) : (
                      <img
                        src={require("src/assets/images/cloud-upload-filled.png")}
                        style={{ width: 39, height: 39 }}
                      />
                    )}
                    <Box sx={{ height: 12 }}></Box>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "18px",
                        lineHeight: "20px",
                        fontWeight: "700",
                        color: Colors.twilight,
                        marginBottom: "4px",
                      }}
                    >
                      {totalFilesUploading
                        ? "Uploading..."
                        : "Click to upload files"}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "14px",
                        lineHeight: "21px",
                        fontWeight: "400",
                        color: Colors.textGray200,
                      }}
                    >
                      {totalFilesUploading
                        ? "This may take a while"
                        : "or drag and drop them here"}
                    </Typography>
                    <Box id="filename-container-portal" />
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Dropzone>

        <Typography
          sx={{
            color: "#122945",
            fontSize: "16px",
            fontWeight: 700,
            marginBottom: "12px",
          }}
        >
          Things to keep in mind
        </Typography>

        <Typography
          sx={{
            color: "#536378",
          }}
        >
          Only PDF, PNG, JPG, and JPEG files are supported, up to 5 files at a
          time.
        </Typography>
      </DialogContent>
    </StyledDialog>
  );
};
export default OCRUploadModal;
