import SearchHeader from "../layout/SearchHeader";
import SearchPageLayout from "./components/SearchPageLayout";
import SearchResultContent from "./components/SearchResultContent";
import PreviewProvider from "./components/Preview/Source/PreviewContext";

const SearchResultPage = () => {
  return (
    <PreviewProvider>
      <SearchHeader />
      <SearchPageLayout>
        <SearchResultContent />
      </SearchPageLayout>
    </PreviewProvider>
  );
};

export default SearchResultPage;
