export interface SearchFilter {
  dataset_types?: Array<string | number>;
  data_collection_ids?: string[];
  exclude_data_collection_ids?: string[];
  regions?: Array<string | number>;
  languages?: string[];
  countries?: string[];
  dataset_ids?: string[];
  exclude_dataset_ids?: string[];
  doc_types?: string[];
}

export enum FilterKeys {
  DATASET_IDS = "dataset_ids",
  DOC_TYPES = "doc_types",
  DATASET_TYPES = "dataset_types",
  COUNTRIES = "countries",
  REGIONS = "regions",
  LANGUAGES = "languages",
  // not shown as separate tab, used within dataset_ids tab
  DATA_COLLECTION_IDS = "data_collection_ids",
  EXCLUDE_DATASET_IDS = "exclude_dataset_ids",
}

export enum FilterNames {
  DATASET_IDS = "Data Source",
  DOC_TYPES = "Result Type",
  DATASET_TYPES = "Dataset Type",
  COUNTRIES = "Country",
  REGIONS = "Region",
  LANGUAGES = "Language",
}

export enum AggregationCountsKeys {
  dataset_ids = "dataset_counts",
  dataset_types = "dataset_type_counts",
  doc_types = "doc_type_counts",
  countries = "country_counts",
  regions = "region_counts",
  languages = "language_counts",
  schemas = "schema_counts",
}

export interface FilterOption {
  label: string;
  value: string;
  count?: number;
}

export interface DataCollectionFilterOption extends FilterOption {
  dataset_ids: string[];
}

export interface FilterOptions {
  isLoading: boolean;
  getFilterOptionsKey: (filterKey: FilterKeys) => string;
  datasets: FilterOption[];
  data_collections: DataCollectionFilterOption[];
  doc_types: FilterOption[];
  dataset_types: FilterOption[];
  countries: FilterOption[];
  regions: FilterOption[];
  languages: FilterOption[];
}
