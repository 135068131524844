import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import search from "src/modules/search/searchReducers";
import bulk_search from "src/modules/bulk-search/bulkSearchReducers";
import groups from "src/modules/groups/groupsReducers";
import knowledge_wiki from "src/modules/knowledge-wiki/reducers";
import auth from "src/modules/auth/reducers";
import dataset_sensitivity from "src/modules/dataset-sensitivity/reducers";
import data_catalog from "src/modules/data-catalog/reducers";
import feedback from "src/modules/feedback/reducers";
import shared from "src/modules/shared/reducers";
import { BrowserHistory } from "history";

export default (history: BrowserHistory) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    search,
    bulk_search,
    groups,
    knowledge_wiki,
    dataset_sensitivity,
    data_catalog,
    feedback,
    shared,
  });
