import React, { useCallback, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Divider, Typography } from "@mui/material";
import actions from "src/modules/search/view/searchViewActions";
import { useDispatch, useSelector } from "react-redux";
import dataSelector from "src/modules/search/view/searchViewSelectors";
import CloseIcon from "@mui/icons-material/Close";
import { PreviewDocProps } from "src/modules/search/view/searchViewReducers";
import { Source } from "src/types/Search";
import * as ftm from "@alephdata/followthemoney";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import SourcePreviews from "./Preview/Source/SourcePreviews";
import PDFPreview from "src/view/search-result/components/Preview/Source/Type/PDFPreview";
import theme from "src/theme";
import EntityDescription from "src/view/search-result/components/Preview/Modeled/EntityDescription";
import PreviewHeader from "src/view/search-result/components/Preview/PreviewHeader";
import ConnectionsTable from "src/view/search-result/components/Preview/Modeled/ConnectionsTable";
import ConnectionBreadcrumbs from "src/view/search-result/components/Preview/Modeled/ConnectionBreadcrumbs";
import {
  formatVariablesSearchParam,
  formatPreviewSearchParam,
} from "src/modules/api/search/SearchProvider";
import { useNavigate } from "react-router-dom";
import { useSearch } from "src/modules/api/search";
import { sumBy, values } from "lodash";

const Container = styled("div")(() => ({
  height: "100%",
  width: "100%",
  backgroundColor: "#FFFFFF",
  position: "relative",
  "& > .MuiTableCell-root": {
    paddingLeft: 5,
    paddingRight: 5,
    "&:first-of-type": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

const StyledIconButton = styled("div")(({ theme }) => ({
  position: "fixed",
  left: "calc(15vw - 20px)",
  top: 20,
  backgroundColor: "#FFFFFF",
  padding: theme.spacing(1),
  borderRadius: theme.spacing(3),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  zIndex: 1101,
}));

const ConnectionsHeading = styled(Typography)(() => ({
  color: theme.palette.secondary.main,
  fontSize: "12px",
  lineHeight: "15px",
  fontWeight: "700",
  paddingTop: 14,
  paddingBottom: 14,
  textTransform: "capitalize",
  width: "100%",
}));

export const hiddenKeys = ["dataset_id", "ingest_timestamp", "file_name"];

export interface ChainData {
  type: string;
  entity: PreviewDocProps;
  role_in_parent?: string;
}
export interface ViewedEntity {
  [key: string]: string | number | Array<string | number>;
}

const PreviewContent = (props: { handleClose: () => void }) => {
  const { handleClose } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { variables } = useSearch();
  const data = useSelector(dataSelector.selectPreviewDocumentList);
  const previewDocInfo = useSelector(dataSelector.selectPreviewDocInfo);
  const [tabIndex, setTabIndex] = useState(0);
  const [tabData, setTabData] = useState(null);
  const [modeledData, setModeledData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [chainData, setChainData] = useState<ChainData[]>([
    {
      type: "root",
      entity: previewDocInfo,
    },
  ]);
  const [intervalTabs, setIntervalTabs] = useState([]);
  const [gettingEntity, setGettingEntity] = useState(true);
  const [scrollToEntityId, setScrollToEntityId] = useState<string>("");

  const isModeledDocument =
    data?.["entity"]?.["_source"]?.["schema"] === "Document";
  const viewedEntity = data?.["entity"]?.["_source"] ?? data?.["entity"] ?? {};

  const totalConnections = useMemo(
    () => sumBy(values(modeledData), (array) => array.length),
    [modeledData]
  );

  useEffect(() => {
    if (data?.["intervals"]) {
      if (previewDocInfo?.source_type === 0) {
        if (Object.keys(data)?.length == 0) return;

        const intervals = Object.keys(data["intervals"])
          .sort((a, b) => {
            if (a === "Documents") return 1;
            if (b === "Documents") return -1;
            return 0;
          })
          .reduce((acc, key) => {
            acc[key] = data["intervals"][key];
            return acc;
          }, {});

        const init = intervals[Object.keys(intervals)[0]];
        const intervalKeys = Object.keys(intervals);

        setIntervalTabs(intervalKeys);
        setModeledData(data["intervals"]);
        setTabData(init);
      }
    }
    if (data != null) {
      setLoading(false);
      setGettingEntity(false);
    }
  }, [data]);

  const handleSetChainData = useCallback((value: ChainData[]) => {
    setChainData(value);
    setTabIndex(0);
  }, []);

  const handleChangeTab = (tab: string) => {
    const tabs = [...intervalTabs];
    const currentTabIndex = tabs.indexOf(tab);
    tabs.splice(currentTabIndex, 1);
    tabs.splice(0, 0, tab);
    setIntervalTabs(tabs);
  };

  const getTitle = (source: Source) => {
    const captions = ftm.defaultModel.schemata[source.schema]["caption"];
    let name = "";
    for (let i = 0; i < captions.length; i++) {
      const caption = captions[i];
      if (source[caption]) {
        name = source[caption];
        break;
      }
    }
    if (name) return name;
    return [`Unnamed ${source.schema}`];
  };

  const getEntityPreview = (entity: PreviewDocProps) => {
    setGettingEntity(true);
    setScrollToEntityId(entity["_id"]);
    const title = getTitle(entity["_source"]);
    const previewMetadata: any = {
      title: title,
      type: entity["_source"].schema,
      source_type: 0,
      dataset: previewDocInfo?.dataset,
      updatedOn: entity["_source"].last_updated,
      data: entity["_source"],
      wikiItem: previewDocInfo?.wikiItem,
      _id: entity["_id"],
    };

    let _chainData = [...chainData];
    const role = Object.keys(modeledData)[tabIndex];
    const item = {
      type: "child",
      role_in_parent: role,
      entity: entity,
    };
    // check existance
    const existingValue = _chainData.filter(
      (_item: ChainData) => _item["entity"]["_id"] === entity["_id"]
    );
    if (existingValue.length > 0) {
      const index = _chainData.indexOf(existingValue[0]);
      _chainData = _chainData.slice(0, index + 1);
    } else {
      _chainData.push(item);
    }
    handleSetChainData(_chainData);

    // trigger data fetching with search params
    navigate(
      `/search?q=${formatVariablesSearchParam({
        ...variables,
      })}&preview=${formatPreviewSearchParam(previewMetadata)}`
    );
  };

  const ModeledPreview = () => {
    return (
      <Container
        sx={{
          overflow: isModeledDocument ? "unset" : "auto",
          height: isModeledDocument ? "auto" : "100%",
        }}
      >
        <Box sx={{ width: "100%", px: 2, pt: 2, boxSizing: "border-box" }}>
          <ConnectionBreadcrumbs
            data={chainData}
            handleLoadEntityPreview={handleLoadEntityPreview}
          />

          <Box sx={{ display: "flex", gap: 2 }}>
            <Box
              sx={{
                flex: "1 1 55%",
                overflow: "hidden",
              }}
            >
              {tabData?.length ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt="share icon"
                      src={require("src/assets/images/share_icon.png")}
                      style={{
                        width: 14,
                        height: 14,
                        marginRight: 10,
                      }}
                    />

                    <ConnectionsHeading>
                      {totalConnections} Connection
                      {totalConnections !== 1 ? "s" : ""}
                    </ConnectionsHeading>
                  </Box>
                </Box>
              ) : (
                <Box sx={{ height: "45px" }} />
              )}
              <ConnectionsTable
                modeledData={modeledData}
                tabData={tabData}
                getEntityPreview={getEntityPreview}
                handleChangeTab={handleChangeTab}
                intervalTabs={intervalTabs}
                gettingEntity={gettingEntity}
                handleClosePreview={handleClose}
                scrollToEntityId={scrollToEntityId}
              />
            </Box>

            <Box
              sx={{
                flex: "1 1 45%",
                overflow: "auto",
              }}
            >
              <EntityDescription
                viewedEntity={viewedEntity}
                handleClosePreview={handleClose}
              />
            </Box>
          </Box>
        </Box>
      </Container>
    );
  };

  const handleLoadEntityPreview = (entity: unknown, index: number) => {
    let _chainData = [...chainData];
    _chainData = _chainData.slice(0, index + 1);
    setGettingEntity(true);
    handleSetChainData(_chainData);
    if (entity["type"] === "child") {
      const data = entity["entity"];
      dispatch(actions.getDocumentPreview(data._id, 0));
      dispatch({
        type: actions.SET_PREVIEW_DOC,
        payload: {
          title: data["_source"].name[0],
          type: data["_source"].schema,
          source_type: 0,
          dataset: data["_source"].dataset_id,
          updatedOn: data["_source"].last_updated,
          data: data["_source"],
        },
      });
    }
    if (entity["type"] === "root") {
      dispatch(actions.getDocumentPreview(entity["entity"]["_id"], 0));
      dispatch({
        type: actions.SET_PREVIEW_DOC,
        payload: entity["entity"],
      });
    }
  };

  return (
    <Container>
      <StyledIconButton onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </StyledIconButton>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
          }}
        >
          <PreviewHeader
            handleClosePreview={handleClose}
            modeledData={modeledData}
          />

          <Divider
            sx={{
              color: theme.palette.grey[400],
            }}
          />

          {previewDocInfo?.source_type == 1 && (
            <SourcePreviews
              data={data}
              loading={loading}
              previewDocInfo={previewDocInfo}
              handleClosePreview={handleClose}
            />
          )}
          {previewDocInfo?.source_type == 0 && (
            <Box>
              <ModeledPreview />
              {isModeledDocument && (
                <PDFPreview
                  data={data}
                  loading={loading}
                  handleClosePreview={handleClose}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default PreviewContent;
