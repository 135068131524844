import style from "./LoadingText.module.css";

interface Props {
  text?: string;
}

const LoadingText: React.FC<Props> = ({ text = "Loading" }) => {
  return <p className={style.loading}>{text}</p>;
};

export default LoadingText;
