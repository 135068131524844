import { Drawer, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import SearchHeader from "../layout/SearchHeader";
import ScaffoldingPage from "./Scaffolding";
import QueryGroupEdit from "./QueryGroupEdit";
import QueryGroupList from "./QueryGroupList";
import Filters from "./Filters";
import QueryGroupRun from "./QueryGroupRun";
import CustomDrawer from "./CustomDrawer";
import QueryGroupView from "./QueryGroupView";

const BulkSearchPage = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openGroupListDrawer, setOpenGroupListDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState(null);
  const [editable, setEditable] = useState(false);
  const [groupid, setGroupId] = useState("");
  const mobileView = useMediaQuery("(max-width:678px)");

  useEffect(() => {
    window.addEventListener("openGroupListDrawer", handleOpenGroupListDrawer);
    window.addEventListener("closeGroupListDrawer", handleCloseGroupListDrawer);
    window.addEventListener("OpenDatasetFilterDrawer", OpenDatasetFilterDrawer);
    return () => {
      window.removeEventListener(
        "openGroupListDrawer",
        handleOpenGroupListDrawer
      );
      window.removeEventListener(
        "closeGroupListDrawer",
        handleCloseGroupListDrawer
      );
      window.removeEventListener(
        "OpenDatasetFilterDrawer",
        OpenDatasetFilterDrawer
      );
    };
  }, []);

  const OpenDatasetFilterDrawer = (event: unknown) => {
    if (event["detail"]) {
      setEditable(event["detail"].isMine);
      setGroupId(event["detail"].groupid);
    }
    handleDrawerOpen();
  };
  const handleDrawerOpen = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleOpenGroupListDrawer = (e: unknown) => {
    setDrawerData(e["detail"]);
    setOpenGroupListDrawer(true);
  };

  const handleCloseGroupListDrawer = () => {
    setOpenGroupListDrawer(false);
  };

  return (
    <div>
      <SearchHeader />
      <Drawer
        open={openDrawer}
        anchor="right"
        onClose={handleDrawerOpen}
        sx={{
          position: "fixed",
          top: mobileView ? 149 : 104,
          right: 0,
          height: "calc(100% - 127px)",
          "& .MuiDrawer-paper": {
            position: "fixed",
            top: mobileView ? 149 : 104,
            right: 0,
            borderLeft: "solid 1px #A0B9D0",
            boxShadow: "-20px 4px 80px rgba(0, 0, 0, 0.15)",
          },
          zIndex: 1,
        }}
        transitionDuration={{ enter: 400, exit: 400 }}
      >
        <Filters
          handleClose={handleDrawerOpen}
          editable={editable}
          groupid={groupid}
        />
      </Drawer>
      <Drawer
        open={openGroupListDrawer}
        anchor="right"
        onClose={handleCloseGroupListDrawer}
        sx={{
          position: "fixed",
          top: mobileView ? 149 : 104,
          right: 0,
          height: "calc(100% - 127px)",
          "& .MuiDrawer-paper": {
            position: "fixed",
            top: mobileView ? 149 : 104,
            right: 0,
            borderLeft: "solid 1px #A0B9D0",
            boxShadow: "-20px 4px 80px rgba(0, 0, 0, 0.15)",
          },
          zIndex: 1,
        }}
        transitionDuration={{ enter: 400, exit: 400 }}
      >
        <CustomDrawer
          handleClose={handleCloseGroupListDrawer}
          drawerData={drawerData}
        />
      </Drawer>
      <Routes>
        <Route path="/" element={<ScaffoldingPage />} />
        <Route path="query-group" element={<QueryGroupList />} />
        <Route path="query-group/:id" element={<QueryGroupList />} />
        <Route path="query-group/:id/view" element={<QueryGroupView />} />
        <Route path="query-group/:id/edit" element={<QueryGroupEdit />} />
        <Route path="query-group/:id/run" element={<QueryGroupRun />} />
      </Routes>
    </div>
  );
};

export default BulkSearchPage;
