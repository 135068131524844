import { Colors } from "src/view/constants/colors";
import { User } from "src/types/UserGroup";

import {
  Box,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { APIValidationErrors } from "src/types/Shared";
import { UserFields } from "src/view/users-groups/shared/AddNewUsersModal/index";
import React from "react";

const TableHeadCell = styled(TableCell)(() => ({
  textTransform: "capitalize",
  fontSize: 12,
  lineHeight: "20px",
  color: "rgba(20, 74, 104, 0.5)",
  paddingTop: 8,
  paddingBottom: 8,
  borderRight: "1px solid #DFE5E9",
}));

const TableBodyCell = styled(TableCell)(() => ({
  fontSize: 12,
  fontWeight: "700",
  lineHeight: "20px",
  color: Colors.twilight,
  paddingTop: 12,
  paddingBottom: 12,
  borderRight: "1px solid #DFE5E9",
}));

interface Props {
  newUsers: User[];
  deleteUser: (index: number) => void;
  apiErrors: APIValidationErrors<UserFields>;
}

const NewUsersTable = ({ newUsers, deleteUser, apiErrors }: Props) => {
  return (
    <Box sx={{ marginTop: "20px", minHeight: 200 }}>
      <Table
        sx={{
          border: "1px solid #DFE5E9",
        }}
      >
        <TableHead sx={{ bgcolor: "#F7F7F7" }}>
          <TableRow>
            <TableHeadCell>FIRST NAME</TableHeadCell>
            <TableHeadCell>LAST NAME</TableHeadCell>
            <TableHeadCell>EMAIL ADDRESS</TableHeadCell>
            <TableHeadCell sx={{ width: "50px" }}>ACTION</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {newUsers.map((user: User, index: number) => (
            <TableRow key={index}>
              <TableBodyCell
                sx={{
                  bgcolor: apiErrors?.[index]?.first_name
                    ? "rgba(4, 61, 93, 0.05)"
                    : "white",
                }}
              >
                {user.first_name}

                <Typography
                  sx={{
                    color: "#F75151",
                    fontSize: "12px",
                  }}
                >
                  {apiErrors?.[index]?.first_name &&
                    apiErrors[index].first_name}
                </Typography>
              </TableBodyCell>
              <TableBodyCell
                sx={{
                  bgcolor: apiErrors?.[index]?.last_name
                    ? "rgba(4, 61, 93, 0.05)"
                    : "white",
                }}
              >
                {user.last_name}

                <Typography
                  sx={{
                    color: "#F75151",
                    fontSize: "12px",
                  }}
                >
                  {apiErrors?.[index]?.last_name && apiErrors[index].last_name}
                </Typography>
              </TableBodyCell>
              <TableBodyCell
                sx={{
                  bgcolor: apiErrors?.[index]?.email
                    ? "rgba(247, 81, 81, 0.05)"
                    : "white",
                }}
              >
                {user.email}

                <Typography
                  sx={{
                    color: "#F75151",
                    fontSize: "12px",
                  }}
                >
                  {apiErrors?.[index]?.email && apiErrors[index].email}
                </Typography>
              </TableBodyCell>
              <TableBodyCell sx={{ alignItems: "center" }}>
                <IconButton
                  aria-label="delete"
                  onClick={() => deleteUser(index)}
                >
                  <img
                    src={require("src/assets/images/delete.png")}
                    style={{ width: 16, height: 16 }}
                  />
                </IconButton>
              </TableBodyCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default NewUsersTable;
