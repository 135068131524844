import { useState } from "react";
import { styled } from "@mui/material/styles";
import Filters from "./Filters";
import SearchResultHeader from "./SearchResultHeader";
import { Drawer, IconButton, useMediaQuery } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Colors } from "src/view/constants/colors";
import viewSelector from "src/modules/search/view/searchViewSelectors";
import { useDispatch, useSelector } from "react-redux";
import PreviewContent from "./PreviewContent";
import actions from "src/modules/search/view/searchViewActions";
import { useSearchParams } from "react-router-dom";

const drawerWidth = 370;

const StyledDrawerIconWrapper = styled("div")(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#F7515110",
}));

const StyledDrawer = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  position: "fixed",
  left: 0,
  height: "calc(100% - 84px)",
  transition: theme.transitions.create(["width", "marginLeft"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  width: "20px",
  zIndex: 1,
  ...(open && {
    transition: theme.transitions.create(["width", "marginLeft"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: "0px",
    marginLeft: "-50px",
  }),
}));

const StyledSearchResult = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  position: "fixed",
  top: 104,
  left: 0,
  height: "100%",
  transition: theme.transitions.create(["left", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  width: "100%",
  ...(open && {
    transition: theme.transitions.create(["left", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    left: `${drawerWidth}px`,
    width: `calc(100% - ${drawerWidth}px)`,
  }),
}));

const SearchPageLayout = (props: { children: React.ReactElement }) => {
  const dispatch = useDispatch();
  const previewDocInfo = useSelector(viewSelector.selectPreviewDocInfo);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filtersOpen, setFiltersOpen] = useState(true);
  const mobileView = useMediaQuery("(max-width:678px)");

  const isPreviewOpen = !!previewDocInfo;

  const handleCloseFilters = () => {
    setFiltersOpen(!filtersOpen);
  };

  const handleClosePreview = (shouldSetParams = true) => {
    dispatch({
      type: actions.SET_PREVIEW_DOC,
      payload: null,
    });
    dispatch({
      type: actions.GET_PREVIEW_DOCUMENT,
      payload: null,
    });

    // removes the preview search param
    // causing the result preview drawer to close
    if (shouldSetParams) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete("preview");
      setSearchParams(newSearchParams);
    }
  };

  return (
    <div>
      <Drawer
        variant="persistent"
        anchor="left"
        open={filtersOpen}
        PaperProps={{
          sx: {
            top: mobileView ? 149 : 104,
            bottom: 0,
            height: "auto",
            zIndex: 1501,
          },
        }}
      >
        <Filters handleClose={handleCloseFilters} />
      </Drawer>
      <Drawer
        sx={{
          zIndex: 1501,
        }}
        PaperProps={{
          sx: {
            width: "85vw",
            boxSizing: "border-box",
            overflowY: "auto",
          },
        }}
        variant="temporary"
        anchor="right"
        open={isPreviewOpen}
        onClose={handleClosePreview}
      >
        <PreviewContent handleClose={handleClosePreview} />
      </Drawer>
      <StyledDrawer
        open={filtersOpen}
        sx={{ top: mobileView ? "149px" : "104px" }}
      >
        <StyledDrawerIconWrapper>
          <IconButton
            size="small"
            aria-label="collapse of filters"
            aria-controls="toggle filter"
            aria-haspopup="true"
            color="secondary"
            onClick={() => handleCloseFilters()}
            sx={{
              border: `1px solid ${Colors.mist100}`,
              backgroundColor: "#EDF1F588",
              marginLeft: 2,
            }}
          >
            <ChevronRightIcon />
          </IconButton>
        </StyledDrawerIconWrapper>
      </StyledDrawer>
      <StyledSearchResult
        open={filtersOpen}
        sx={{ top: mobileView ? "149px" : "104px" }}
      >
        <SearchResultHeader />
        {props.children}
      </StyledSearchResult>
    </div>
  );
};

export default SearchPageLayout;
