import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "src/view/constants/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const StyledButton = styled(Button)(({ theme }) => ({
  display: "flex",
  margin: theme.spacing(0, "auto"),
  alignItems: "center",
  fontSize: "10px",
  fontWeight: "700",
  borderRadius: 38,
  width: 90,
  whiteSpace: "nowrap",
  padding: "4px 10px 4px 10px",
  textTransform: "capitalize",
  color: Colors.textGray100,
  borderWidth: 0,
  "&:hover": {
    borderWidth: 0,
    color: Colors.textGray200,
    backgroundColor: Colors.bgGray1,
  },
}));

export interface LoadMoreButtonProps extends ButtonProps {
  expand: boolean;
  isOverflow: boolean;
}

const LoadMoreButton = ({
  expand,
  isOverflow,
  ...props
}: LoadMoreButtonProps) => {
  return isOverflow ? (
    <StyledButton
      variant="outlined"
      sx={{
        "& .MuiButton-endIcon": { ml: 0 },
      }}
      endIcon={expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      {...props}
    >
      {expand ? "See Less" : "See More"}
    </StyledButton>
  ) : null;
};

LoadMoreButton.displayName = "LoadMoreButton";

export default LoadMoreButton;
