import { Box, Typography } from "@mui/material";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import { Colors } from "src/view/constants/colors";

const StyledImage = styled("img")(() => ({
  width: "100%",
  height: 226,
  objectFit: "contain",
}));

interface Props {
  openModal: () => void;
  openImportCSVModal: () => void;
}

const EmptyUserList = (props: Props) => {
  const { openModal, openImportCSVModal } = props;
  return (
    <Box sx={{ maxWidth: 350, padding: "70px" }}>
      <StyledImage
        src={require("src/assets/images/empty.png")}
        alt="Not Found"
        style={{ height: 90 }}
      ></StyledImage>
      <Box sx={{ height: 20 }}></Box>

      <Typography
        sx={{
          color: Colors.textDark,
          fontSize: 18,
          lineHeight: "20px",
          fontWeight: "700",
          textAlign: "center",
        }}
      >
        Empty User List
      </Typography>
      <Box sx={{ height: 20 }}></Box>
      <Typography
        sx={{
          color: Colors.textGray300,
          fontSize: 14,
          lineHeight: "152.2%",
          fontWeight: "400",
          textAlign: "center",
          mt: "8px",
        }}
      >
        No users have been added yet. Click below to add users.
      </Typography>
      <Box sx={{ height: 20 }}></Box>
      <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <Button
          startIcon={<AddIcon />}
          sx={{
            color: "#FFFFFF",
            bgColor: Colors.twilight,
            height: 46,
            textTransform: "capitalize",
            fontWeight: "700",
            boxShadow: "unset",
          }}
          variant="contained"
          onClick={openModal}
        >
          Add Users
        </Button>
      </Box>
      <Box sx={{ height: 40 }}></Box>
      <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <Button
          startIcon={
            <img
              src={require("src/assets/images/import_csv.png")}
              style={{ width: 20, height: 20 }}
            />
          }
          sx={{
            color: Colors.twilight,
            backgroundColor: "rgba(4, 61, 93, 0.08)",
            borderRadius: "4px",
            height: 44,
            width: "100%",
            textTransform: "capitalize",
            fontWeight: "700",
            boxShadow: "unset",
            "&:hover": {
              backgroundColor: "rgba(4, 61, 93, 0.1)",
              boxShadow: "unset",
            },
          }}
          variant="contained"
          onClick={openImportCSVModal}
        >
          Import from CSV
        </Button>
      </Box>
    </Box>
  );
};

export default EmptyUserList;
