import { Routes, Route } from "react-router-dom";
import SearchHeader from "../layout/SearchHeader";
import EditGroupPage from "./groups/EditGroup";
import AddUsers from "./users/AddUsers";
import ScaffoldingPage from "./Scaffolding";

const GroupsPage = () => {
  return (
    <div>
      <SearchHeader />
      <Routes>
        <Route path="/" element={<ScaffoldingPage />} />
        <Route path="edit/:id" element={<EditGroupPage />} />
        <Route path="add-users" element={<AddUsers />} />
      </Routes>
    </div>
  );
};

export default GroupsPage;
