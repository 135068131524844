import { memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { styled } from "@mui/material/styles";
import { snackbar } from "src/view/toaster";
import ConfirmDialog from "src/view/components/ConfirmDialog";
import { KnowledgeWiki } from "src/types/KnowledgeWiki";
import { format } from "date-fns";
import { useDatasetMetadata } from "src/modules/api/datasetMetadata";
import theme from "src/theme";
import { usePostHog } from "posthog-js/react";
import { HogEvent } from "src/types/PosthogEvents";

const StyledDataItem = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  height: "32px",
  alignItems: "center",
  justifyContent: "space-between",
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 14,
  fontWeight: 500,
}));

const StyledLabel = styled(Typography)(() => ({
  color: "#5A7290",
  fontSize: 12,
  lineHeight: "15px",
}));

const Bullet = styled(Box)(({ theme }) => ({
  display: "block",
  color: theme.palette.grey[800],
  lineHeight: "15px",
  margin: "0 10px",
  fontSize: "16px",
}));

const ControlButton = styled(IconButton)(() => ({
  opacity: 0.5,
  "&:hover": {
    opacity: 1,
  },
}));

interface DataItemProps {
  item: KnowledgeWiki;
  isAdmin?: boolean;
  isDraft?: boolean;
}
const DataItem: React.FC<DataItemProps> = ({ item, isAdmin, isDraft }) => {
  const navigate = useNavigate();
  const posthog = usePostHog();
  const location = useLocation();
  const { deleteDatasetMetadata } = useDatasetMetadata();

  const handleDelete = async () => {
    deleteDatasetMetadata.mutate(
      { id: item.dataset },
      {
        onSuccess: () => {
          snackbar.success("Dataset has been deleted");
          navigate("/data-dictionary/data", { replace: true });
        },

        onError: () => {
          snackbar.error("Failed to delete Dataset");
        },
      }
    );
  };

  const gotoDetail = () => {
    posthog.capture(HogEvent.DATA_DICTIONARY_ITEM_VIEWED);
    navigate({
      pathname: `/data-dictionary/data/${item.id}`,
      search: location.search,
    });
  };

  if (isDraft && !isAdmin) {
    return null;
  }

  return (
    <StyledDataItem>
      <Box sx={{ display: "flex", alignItems: "center", flex: "1 0" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flex: "0 0 20px",
            gap: theme.spacing(1),
          }}
        >
          {isDraft && <PendingActionsIcon fontSize={"small"} />}
          {!!item.credibility && (
            <img
              src={require("src/assets/images/note.png")}
              alt={"note"}
              style={{ width: 20, height: 20 }}
            />
          )}
          {!!item.external_source && (
            <img
              src={require("src/assets/images/link.png")}
              alt={"link"}
              style={{ width: 20, height: 20 }}
            />
          )}
        </Box>
        <StyledTitle sx={{ cursor: "pointer", ml: 1 }} onClick={gotoDetail}>
          {item.name}
        </StyledTitle>
        <Bullet>•</Bullet>
        <StyledLabel>
          {`Added on ${format(new Date(item.created), "MMMM do, yyyy")}`}
        </StyledLabel>
      </Box>

      <Box sx={{ display: "flex", flex: "0 0" }}>
        {isAdmin && (
          <ConfirmDialog
            onConfirm={handleDelete}
            title={`Delete ${item.name}`}
            description="Do you want to delete it? This action cannot be undone."
            confirmText="Confirm"
            cancelText="Cancel"
          >
            <ControlButton>
              <img
                src={require("src/assets/images/delete.png")}
                alt={"delete icon"}
                style={{ width: 20, height: 20 }}
              />
            </ControlButton>
          </ConfirmDialog>
        )}
      </Box>
    </StyledDataItem>
  );
};

export default memo(DataItem);
