import React from "react";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
import { Features } from "src/types/Auth";
import { LoadingPage } from "src/view/routes";
import useUser from "./useUser";

export type AuthRouteGuardProps = {
  feature?: Features;
  component: React.ComponentType;
};

export const AuthRouteGuard = ({ feature, component }: AuthRouteGuardProps) => {
  const { user } = useUser();
  const { isLoading, isAuthenticated, user: auth0User } = useAuth0();

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <LoadingPage />,
  });

  if (!feature) {
    return <Component />;
  }

  if (isLoading || (auth0User && !user)) {
    return <LoadingPage />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return user.features.includes(feature) ? <Component /> : <Navigate to="/" />;
};
