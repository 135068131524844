export enum HogEvent {
  // Search events
  SEARCH_PERFORMED = "search_performed",
  SCREENING_LIST_SEARCH_RUN = "screening_list_search_run",
  QUERY_GROUP_SEARCH_RUN = "query_group_search_run",
  DATA_COLLECTION_SEARCHED = "data_collection_searched",

  // Other data events
  DATA_COLLECTION_CREATED = "data_collection_created",
  DATA_COLLECTION_OPENED = "data_collection_opened",
  DATA_DICTIONARY_ITEM_VIEWED = "data_dictionary_item_viewed",
  DATA_DICTIONARY_LIST_VIEWED = "data_dictionary_list_viewed",
  SOURCE_DATA_PREVIEWED = "source_data_previewed",
  MODEL_DATA_PREVIEWED = "model_data_previewed",
  SOURCE_DATA_DOWNLOADED = "source_data_downloaded",
  MODEL_DATA_DOWNLOADED = "model_data_downloaded",

  // History events
  SEARCH_HISTORY_POPUP_OPENED = "search_history_popup_opened",
  SEARCH_HISTORY_POPUP_CLOSED = "search_history_popup_closed",
  SEARCH_HISTORY_ITEM_DELETED = "search_history_item_deleted",
  SEARCH_FROM_HISTORY = "search_from_history",
  CLEAR_SEARCH_HISTORY = "clear_search_history",

  // Feature toggle events
  FEATURE_DISABLED = "feature_disabled",
  FEATURE_ENABLED = "feature_enabled",

  // Other events
  SCREENING_LIST_CREATED = "screening_list_created",
  OCR_FILE_UPLOADED = "ocr_file_uploaded",
  BULK_DOWNLOAD_RUN = "bulk_download_run",
}
