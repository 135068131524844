export enum OCRTaskStatus {
  QUEUED = "In Task Queue",
  PROGRESS = "In Progress",
  SUCCEED = "Succeeded",
  FAILED = "Failed",
}

export interface OCRTask {
  id: string;
  file_key: string;
  status: OCRTaskStatus;
  created: Date;
}
export interface OCRTaskResult {
  url: string;
}
