import React, { useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { PreviewDocListProps } from "src/modules/search/view/searchViewReducers";
import {
  StyledContainer,
  StyledHeader,
  AntTabs,
  AntTab,
} from "src/view/search-result/components/Preview/Source/SourcePreviews";
import HighlightActionMenu from "src/view/components/HighlightActionMenu";

interface Props {
  data: PreviewDocListProps | unknown[];
  loading: boolean;
  handleClosePreview: (shouldSetURLParam?: boolean) => void;
}

const HtmlPreview = ({ data, loading, handleClosePreview }: Props) => {
  const [htmlTab, setHTMLTab] = useState(0);
  const handleChangeHTMLTab = (
    _event: React.SyntheticEvent,
    newValue: number
  ) => {
    setHTMLTab(newValue);
  };

  const content = data ? data[0]?.content : "";
  const raw_content = data ? data[0]?.raw_content : "";

  return (
    <StyledContainer sx={{ position: "sticky", top: 0 }}>
      <StyledHeader>
        <Box sx={{ display: "flex" }}>
          <AntTabs
            value={htmlTab}
            onChange={handleChangeHTMLTab}
            aria-label="ant example"
            sx={{
              backgroundColor: "transparent",
            }}
          >
            <AntTab label={"PREVIEW"} />
            <AntTab label={"RAW TEXT"} />
          </AntTabs>
        </Box>
      </StyledHeader>
      <Box
        sx={{
          width: "calc(100% - 79px)",
          height: "calc(100vh - 350px)",
          overflowX: "hidden",
          display: "block",
          ml: "43px",
          mr: "36px",
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: { xs: "auto", sm: "40vh" },
            }}
          >
            <CircularProgress disableShrink />
          </Box>
        ) : (
          <Box
            sx={{
              width: "calc(100% - 70px)",
              display: "block",
              ml: "43px",
              mr: "36px",
              padding: 1,
              height: "calc(100vh - 350px)",
            }}
            aria-label="simple table"
          >
            <HighlightActionMenu onSearchInTab={handleClosePreview}>
              <>
                {htmlTab == 0 && (
                  <div
                    dangerouslySetInnerHTML={{ __html: raw_content as string }}
                  ></div>
                )}
                {htmlTab == 1 && (
                  <div dangerouslySetInnerHTML={{ __html: content }}></div>
                )}
              </>
            </HighlightActionMenu>
          </Box>
        )}
      </Box>
    </StyledContainer>
  );
};

export default HtmlPreview;
