import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export interface CardPreviewBoxProps extends BoxProps {
  expand?: boolean;
}

const CardPreviewBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "expand",
})<CardPreviewBoxProps>(({ expand, theme }) => ({
  display: "none",
  width: "100%",
  overflowX: "hidden",
  marginBottom: "12px",
  transition: theme.transitions.create(["all"], {
    duration: theme.transitions.duration.standard,
  }),
  ...(expand && {
    display: "block",
    maxHeight: "230px",
    transition: theme.transitions.create(["all"], {
      duration: theme.transitions.duration.standard,
    }),
  }),
}));

export default CardPreviewBox;
