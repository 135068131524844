import { Controller, Control } from "react-hook-form";

import MyTextField from "src/view/components/MyTextField";

import { IndexDatasetFormFields } from "./IndexNewDataset";

type Props = {
  control: Control<IndexDatasetFormFields, any>;
  label: string;
  name: keyof Pick<
    IndexDatasetFormFields,
    "bucket_name" | "name" | "bucket_prefix"
  >;
  placeholder: string;
  required?: boolean;
};

const FormTextField = ({
  control,
  label,
  name,
  placeholder,
  required = true,
}: Props) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field }) => (
        <MyTextField
          {...field}
          fontSize={14}
          fontWeight={400}
          placeholder={placeholder}
          type="text"
          label={label}
          helperText=""
          fullWidth
          containerStyle={{ "& > div": { backgroundColor: "#F9F9FA" } }}
        />
      )}
    />
  );
};

export default FormTextField;
