import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { snackbar } from "src/view/toaster";
import actions from "src/modules/groups/view/groupsViewActions";
import selector from "src/modules/groups/view/groupsViewSelectors";
import { Group } from "src/types/UserGroup";

import { StyledTextAreaTitle } from "../StyledComponents";

type Props = {
  formData: Group;
  setFormData: React.Dispatch<React.SetStateAction<Group>>;
  groupId: string;
};
const GroupNameEdit = ({ formData, setFormData, groupId }: Props) => {
  const dispatch = useDispatch();
  const selectedGroup = useSelector(selector.selectSelectedGroup);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleGroupNameChange = async () => {
    const newName = formData.name;

    if (newName === selectedGroup.name) {
      return;
    }

    if (!newName) {
      snackbar.error("The name is required.");
      return;
    }

    await dispatch(
      actions.updateGroup({ name: newName }, selectedGroup.group_id || groupId)
    );
    snackbar.success("Group name was updated successfully");
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [formData.name]);

  return (
    <StyledTextAreaTitle
      ref={textareaRef}
      placeholder="Enter Your Group's name"
      value={formData.name}
      name="name"
      onChange={(e) =>
        setFormData((prev) => ({
          ...prev,
          name: e.target.value,
        }))
      }
      onBlur={handleGroupNameChange}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleGroupNameChange();
        }
      }}
    />
  );
};

export default GroupNameEdit;
