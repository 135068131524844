import React, { useState } from "react";
import { useFilterOptions } from "src/modules/api/filters";
import { hasFlag } from "country-flag-icons";
import HighlightActionMenu from "src/view/components/HighlightActionMenu";
import { Button, Divider, Typography } from "@mui/material";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { styled } from "@mui/material/styles";
import theme from "src/theme";
import Box from "@mui/material/Box";
import { format, isValid } from "date-fns";
import { titleCase } from "src/utils";
import {
  hiddenKeys,
  ViewedEntity,
} from "src/view/search-result/components/PreviewContent";
import { Source } from "src/types/Search";
import * as ftm from "@alephdata/followthemoney";

const entityTableOrder = [
  "name",
  "firstName",
  "secondName",
  "lastName",
  "alias",
  "nationality",
  "country",
  "mainCountry",
  "jurisdiction",
  "phone",
  "birthDate",
  "date",
  "schema",
  "description",
  "organization",
  "address",
];

const TableHeader = styled(Typography)(() => ({
  color: theme.palette.primary.main,
  fontSize: "12px",
  lineHeight: "15px",
  fontWeight: "700",
  paddingTop: 14,
  paddingBottom: 14,
  textTransform: "capitalize",
  width: "100%",
}));

const SectionWrapper = styled(Box)(() => ({
  display: "table-row",
  flexWrap: "nowrap",
  alignItems: "flex-start",
  justifyContent: "space-between",
  border: "1px solid red",
}));

const SectionHeader = styled(Typography)(({ theme }) => ({
  fontSize: "10px",
  display: "table-cell",
  color: theme.palette.midnight.main,
  marginTop: 20,
  marginBottom: theme.spacing(1),
  borderRight: "1px solid #CED3D9",
  borderBottom: "1px solid #CED3D9",
  padding: "6px 12px",
}));

const SectionHeaderNumber = styled("span")(({ theme }) => ({
  fontSize: "10px",
  fontWeight: "700",
  color: theme.palette.midnight.main,
}));

const SectionContainer = styled("div")(() => ({
  display: "table-cell",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  borderBottom: "1px solid #CED3D9",
}));

const SectionItemText = styled("div")(() => ({
  cursor: "pointer",
  boxSizing: "border-box",
  fontSize: "10px",
  fontWeight: "700",
  color: theme.palette.midnight.main,
  padding: "6px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  wordBreak: "break-all",
  "& div::first-letter": {
    textTransform: "capitalize",
  },
}));

const ShowMoreButton = styled(Button)(() => ({
  display: "flex",
  alignItems: "center",
  fontSize: "10px",
  lineHeight: "12px",
  fontWeight: "700",
  borderRadius: 20,
  color: theme.palette.secondary.main,
  borderWidth: 0,
  whiteSpace: "nowrap",
  backgroundColor: theme.palette.grey[200],
  height: 20,
  margin: 6,
  marginTop: 0,
  "&:hover": {
    borderWidth: 0,
  },
}));

const FlagIcon = styled("img")(() => ({
  width: 20,
  height: 20,
  objectFit: "contain",
  marginRight: 5,
}));

const Section = (props: {
  propertyKey: string;
  propertyLabel: string;
  data: Array<number | string>;
  handleClosePreview: (shouldSetURLParam?: boolean) => void;
}) => {
  const { propertyKey, propertyLabel, data, handleClosePreview } = props;
  const [showMore, setShowMore] = useState(false);
  const { countries } = useFilterOptions();

  const displayData = data?.slice(0, showMore ? data?.length : 1) || [];

  const handleLoadMore = () => {
    setShowMore(!showMore);
  };

  return (
    <SectionWrapper>
      <SectionHeader>
        {propertyLabel}{" "}
        {data?.length > 1 && (
          <SectionHeaderNumber>• {data?.length}</SectionHeaderNumber>
        )}
      </SectionHeader>
      <SectionContainer>
        {displayData?.map((row: string | number, index: number) => {
          const is_last = index == displayData?.length - 1;
          let label = typeof row == "number" ? row.toString() : row;
          const _row = typeof row == "number" ? row.toString() : row;
          let icon = null;
          let keyValueQuery = `${propertyKey}:"${label}"`;
          if (
            propertyLabel == "Country" ||
            propertyLabel == "Main Country" ||
            propertyLabel == "Jurisdiction" ||
            (propertyLabel == "Nationality" && _row.length == 2)
          ) {
            const country = countries.find(
              (country) =>
                country.value.toLowerCase() == _row.toLowerCase() ??
                _row?.[0]?.toLowerCase()
            );

            if (country) {
              label = country.label;
              keyValueQuery = `${propertyKey}:"${country.value}"`;
              if (hasFlag(_row.toUpperCase())) {
                icon = (
                  <FlagIcon
                    alt={label}
                    src={
                      "https://purecatamphetamine.github.io/country-flag-icons/3x2/" +
                      _row.toUpperCase() +
                      ".svg"
                    }
                  />
                );
              }
            }
          }
          return (
            <>
              <SectionItemText key={index}>
                {icon}
                <HighlightActionMenu
                  onSearchInTab={handleClosePreview}
                  keyValueQuery={keyValueQuery}
                >
                  <>{label}</>
                </HighlightActionMenu>
              </SectionItemText>
              {!is_last && <Divider sx={{ mx: "6px" }} />}
            </>
          );
        })}
        {data?.length > 1 && (
          <ShowMoreButton
            variant="outlined"
            endIcon={
              showMore ? (
                <RemoveCircleOutlinedIcon style={{ fontSize: "12px" }} />
              ) : (
                <AddCircleOutlinedIcon style={{ fontSize: "12px" }} />
              )
            }
            onClick={handleLoadMore}
          >
            {showMore ? "Show Less" : "Show More"}
          </ShowMoreButton>
        )}
      </SectionContainer>
    </SectionWrapper>
  );
};

interface Props {
  viewedEntity: ViewedEntity | Source;
  handleClosePreview: (shouldSetURLParam?: boolean) => void;
}

const EntityDescription = ({ viewedEntity, handleClosePreview }: Props) => {
  const sortEntries = (a: string[], b: string[]) => {
    // reorder by presence in s order array - unknown items come last
    const indexA = entityTableOrder.indexOf(a[0]);
    const indexB = entityTableOrder.indexOf(b[0]);
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }
    if (indexA !== -1) {
      return -1;
    }
    if (indexB !== -1) {
      return 1;
    }
    return 0;
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <img
          alt="primary menu"
          src={require("src/assets/images/menu-primary.png")}
          style={{ width: 14, height: 14, marginRight: 10 }}
        />
        <TableHeader>Description</TableHeader>
      </Box>

      <Box
        sx={{
          border: "1px solid rgba(0, 0, 0, 0.1)",
          borderRadius: 1,
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            boxSizing: "border-box",
            display: "table",
            width: "100%",
          }}
        >
          {viewedEntity &&
            Object.entries(viewedEntity)
              .sort((a, b) => sortEntries(a, b))
              .map((item: unknown, index) => {
                let key = item[0];
                if (!hiddenKeys.includes(key)) {
                  let value = item[1];
                  const ftmPropertyLabel =
                    ftm.defaultModel.schemata?.[viewedEntity.schema as string]
                      ?.properties?.[key]?.label;
                  key = titleCase(key.toString());
                  if (
                    typeof value == "string" &&
                    key !== "Percentage" &&
                    isValid(value)
                  ) {
                    value = format(new Date(value), "MM/dd/yyyy");
                  }
                  let _data: Array<number | string>;
                  if (typeof value == "number" || typeof value == "string") {
                    _data = [value];
                  } else {
                    _data = value;
                  }
                  return (
                    <Section
                      key={key + " - " + index}
                      propertyKey={item[0]}
                      propertyLabel={ftmPropertyLabel ?? key}
                      data={_data}
                      handleClosePreview={handleClosePreview}
                    />
                  );
                }
              })}
        </Box>
      </Box>
    </>
  );
};

export default EntityDescription;
