import { isJsonString, replaceHighlightTags } from "src/utils";
import { SearchFilter } from "src/types/SearchFilter";
import { defaultFilter } from "src/modules/search/filter/searchFilterReducers";
import { isEqual } from "lodash";

export const prepSearchResultData = (item: any) => {
  let _data = item["_index"].includes("modeled_data")
    ? item["_source"]
    : typeof item["data"] == "string" && isJsonString(item["data"])
    ? JSON.parse(item["data"])
    : item["data"];

  if (
    item["highlight"] &&
    item["highlight"]["content"] &&
    Array.isArray(item["highlight"]["content"]) &&
    (item["_source"]["doc_type"] == "eml" ||
      item["_source"]["doc_type"] == "msg" ||
      item["_source"]["doc_type"] == "json" ||
      item["_source"]["doc_type"] == "jsonl" ||
      item["_source"]["doc_type"] == "csv" ||
      item["_source"]["doc_type"] == "parquet")
  ) {
    _data = item["_source"]["content"];
    item["highlight"]["content"].forEach((el: string) => {
      const h_txt = replaceHighlightTags(el);
      const r_txt = el.replace(/<\/em>/g, "");
      _data = _data.replace(r_txt, h_txt);
    });
    _data = JSON.parse(_data);
  }

  return _data;
};

export const encodeFiltersToURIComponent = (
  search: string,
  filter: SearchFilter
) => {
  let query = search.trim();
  query = encodeURIComponent(query);

  if (filter) {
    if (filter.dataset_ids.length) {
      query += "&dataset_ids=" + filter.dataset_ids;
    }
    if (filter.exclude_dataset_ids.length) {
      query += "&exclude_dataset_ids=" + filter.exclude_dataset_ids;
    }
    if (filter.data_collection_ids.length) {
      query += "&data_collection_ids=" + filter.data_collection_ids;
    }
    if (filter.exclude_data_collection_ids.length) {
      query +=
        "&exclude_data_collection_ids=" + filter.exclude_data_collection_ids;
    }
    if (filter.doc_types.length) {
      query += "&doc_types=" + filter.doc_types;
    }
    if (filter.dataset_types.length) {
      query += "&dataset_types=" + filter.dataset_types;
    }
    if (filter.countries.length) {
      query += "&countries=" + filter.countries;
    }
    if (filter.regions.length) {
      query += "&regions=" + filter.regions;
    }
    if (filter.languages.length) {
      query += "&languages=" + filter.languages;
    }
  }

  return query;
};

export const syncURLSearchParamsWithFilter = (
  params: URLSearchParams,
  filter: SearchFilter
) => {
  if (filter?.dataset_ids.length) {
    params.set("dataset_ids", filter.dataset_ids.toString());
  } else params.delete("dataset_ids");
  if (filter?.exclude_dataset_ids.length) {
    params.set("exclude_dataset_ids", filter.exclude_dataset_ids.toString());
  } else params.delete("exclude_dataset_ids");
  if (filter?.data_collection_ids.length) {
    params.set("data_collection_ids", filter.data_collection_ids.toString());
  } else params.delete("data_collection_ids");
  if (filter?.exclude_data_collection_ids.length) {
    params.set(
      "exclude_data_collection_ids",
      filter.exclude_data_collection_ids.toString()
    );
  } else params.delete("exclude_data_collection_ids");
  if (filter.doc_types.length > 0) {
    params.set("doc_types", filter.doc_types.toString());
  } else params.delete("doc_types");
  if (filter?.dataset_types.length)
    params.set("dataset_types", filter.dataset_types.toString());
  else params.delete("dataset_types");
  if (filter?.countries.length) {
    params.set("countries", filter.countries.toString());
  } else params.delete("countries");
  if (filter?.regions.length) params.set("regions", filter.regions.toString());
  else params.delete("regions");
  if (filter?.languages.length) {
    params.set("languages", filter.languages.toString());
  } else params.delete("languages");

  return params;
};

export const compareActiveFiltersWithURLParams = (
  filter: SearchFilter,
  params: URLSearchParams
) => {
  const paramsEntries = Object.fromEntries(params);

  const filterObj = { ...defaultFilter };
  for (const key in paramsEntries) {
    // omit query to get pure filters without search
    if (key !== "query") {
      filterObj[key] = paramsEntries[key].split(",");
    }
  }

  return isEqual(filter, filterObj);
};

export const getFilterCount = (filter: SearchFilter) => {
  if (!filter) return;
  return (
    filter.countries.length +
    filter.regions.length +
    filter.languages.length +
    filter.doc_types.length +
    filter.dataset_ids.length +
    filter.dataset_types.length +
    filter.exclude_dataset_ids.length +
    filter.data_collection_ids.length +
    filter.exclude_data_collection_ids.length
  );
};
