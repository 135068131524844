import { Button, ButtonGroup, Container, Grid, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { Colors } from "src/view/constants/colors";

const CustomButtonGroup = styled(ButtonGroup)(() => ({
  borderRadius: 0,
}));

const StyledButton = styled(Button)(() => ({
  // backgroundColor: 'rgba(20, 74, 104, 0.2)',
  borderRadius: 0,
}));

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 43,
  height: 20,
  padding: 3,
  marginRight: 5,
  "& .MuiSwitch-switchBase": {
    margin: 0,
    padding: 0,
    transform: "translateX(0px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(20px)",
      backgroundColor: "white",
      "& .MuiSwitch-thumb": {
        backgroundColor: Colors.secondary,
        width: 16,
        height: 16,
        border: "2px solid white",
      },
      "&+.MuiSwitch-track": {
        opacity: "1 !important",
        backgroundColor: "rgba(255, 255, 255, 0.5) !important",
        borderRadius: 20 / 2,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#FFFFFF",
    width: 19,
    height: 19,
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#CED3D9 !important",
    borderRadius: 20 / 2,
  },
  [theme.breakpoints.down("sm")]: {
    width: 27,
    height: 14,
    padding: 2,
    marginRight: 2,
    "& .MuiSwitch-switchBase": {
      transform: "translateX(0px)",
      "&.Mui-checked": {
        transform: "translateX(12px)",
        "& .MuiSwitch-thumb": {
          width: 10,
          height: 10,
        },
      },
    },
    "& .MuiSwitch-thumb": {
      width: 12,
      height: 12,
    },
  },
}));

export interface Tabs {
  entity: boolean;
  freeText: boolean;
}

export default function SearchTabs(props: {
  tabs: Tabs;
  handleChange: (tabs: Tabs) => void;
}) {
  const { tabs, handleChange } = props;
  const [tab, setTab] = useState("");

  const handleChangeSwitch = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const _tabs = { ...tabs };
    if (e.target.checked) {
      setTab(key);
      if (key == "free-text-search") {
        _tabs.freeText = true;
      }
      if (key == "entity-search") {
        _tabs.entity = true;
      }
      handleChange(_tabs);
    } else {
      setTab("");
      if (key == "free-text-search") {
        _tabs.freeText = false;
      }
      if (key == "entity-search") {
        _tabs.entity = false;
      }
      handleChange(_tabs);
    }
  };

  return (
    <Container sx={{ pr: 1, pl: { xs: 0, sm: 3 } }}>
      <Grid item sx={{ alignItems: "flex-start" }}>
        <CustomButtonGroup
          color="primary"
          aria-label="medium secondary button group"
        >
          <StyledButton
            key="free-text-search"
            value="free-text-search"
            sx={{
              padding: { xs: "4px", sm: "8px", md: "8px" },
              textTransform: "capitalize",
              fontWeight: "700",
              backgroundColor: Colors.textDark100,
              color: "white",
              "&:hover": {
                backgroundColor: Colors.textDark100,
              },
              borderRadius: "4px 4px 0px 0px",
            }}
          >
            <MaterialUISwitch
              checked={tab === "free-text-search"}
              onChange={(event) =>
                handleChangeSwitch(event, "free-text-search")
              }
            />
            Query Builder
          </StyledButton>
          {/* <StyledButton key="entity-search" value="entity-search" sx={{
                        backgroundColor: tab === 'entity-search' ? Colors.twilight : 'primary', color: tab === 'entity-search' ? 'white' : 'primary', '&:hover': {
                            backgroundColor: tab === 'entity-search' ? Colors.twilight : 'primary'
                        }
                    }}>
                        <MaterialUISwitch checked={tab === 'entity-search'} onChange={(event) => handleChangeSwitch(event, 'entity-search')} />
                        Entity Search
                    </StyledButton> */}
        </CustomButtonGroup>
      </Grid>
    </Container>
  );
}
