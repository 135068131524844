import { useCallback, useMemo, useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SearchIcon from "@mui/icons-material/Search";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  AggregationCountsKeys,
  DataCollectionFilterOption,
  FilterKeys,
  FilterOption,
} from "src/types/SearchFilter";
import { SearchResult } from "src/types/Search";
import { Features } from "src/types/Auth";
import { flatMap, chain } from "lodash";
import { Role } from "src/types/Auth";
import { useUser } from "src/modules/api/auth";
import { useSearch } from "src/modules/api/search";
import { useFilterOptions } from "src/modules/api/filters";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import { InfiniteData } from "@tanstack/react-query";

import FilterTabs from "./FilterTabs";
import DataCollectionFilter from "./DataCollectionFilter";
import { FilterOptionRow } from "./FilterOptionRow";
import theme from "src/theme";
import { useSearchParams } from "react-router-dom";
import { parseVariablesFromSearchParams } from "src/modules/api/search/SearchProvider";

const StyledFilterBox = styled("div")(() => ({
  width: 370,
  height: "100%",
  borderRight: "solid 1px #A0B9D0",
  display: "flex",
  flexDirection: "column",
}));

const StyledFilterHeader = styled(Box)(() => ({
  height: 64,
  borderBottom: "solid 1px #A0B9D0",
  display: "flex",
  alignItems: "center",
}));

const StyledFilterBody = styled(Box)(() => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
}));

const StyledButton = styled(Button)(() => ({
  color: "#ffffff",
  fontWeight: "700",
  fontSize: 10,
  lineHeight: "12px",
  background: "#122945",
  borderRadius: 4,
  textTransform: "capitalize",
  marginLeft: "auto",
  marginRight: 16,
  "&:hover": {
    background: "#122945e9",
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    fontSize: 14,
    fontFamily: "Inter",
  },
  "& .MuiSvgIcon-root": {
    color: theme.palette.primary.main,
  },
  "& .MuiInputBase-root": {
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
    },
  },
}));

const ListWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.75),
  padding: theme.spacing(1),
  boxSizing: "border-box",
}));

interface FiltersProps {
  disableCounts?: boolean;
  handleClose: () => void;
}

export default function Filters({
  handleClose,
  disableCounts = false,
}: FiltersProps) {
  const { user } = useUser();

  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    unappliedFilters,
    variables,
    data,
    handleSearch,
    resetFilters,
    updateFilters,
  } = useSearch();
  const { isLoading, getFilterOptionsKey, ...filterOptions } =
    useFilterOptions();

  const [activeFilterKey, setActiveFilterKey] = useState<FilterKeys>(
    FilterKeys.DATASET_IDS
  );
  const [optionFilter, setOptionFilter] = useState<string>("");

  const enableDataCollectionFilter = useMemo(() => {
    if (!user) {
      return false;
    }

    if (activeFilterKey !== FilterKeys.DATASET_IDS) {
      return false;
    }

    if (user.role === Role.ADMIN) {
      return true;
    }

    if (user.features.includes(Features.DATA_COLLECTION)) {
      return true;
    }
  }, [user, activeFilterKey]);

  const isSelected = useCallback(
    (o: FilterOption) => {
      const activeFilters =
        parseVariablesFromSearchParams(searchParams).filters;
      const selectedDataCollectionIds = flatMap([
        activeFilters.data_collection_ids,
        activeFilters.exclude_data_collection_ids,
      ]);
      const implicitSelectedDatasetIds = flatMap(
        filterOptions.data_collections.filter(({ value }) =>
          selectedDataCollectionIds.includes(value)
        ),
        ({ dataset_ids }) => dataset_ids
      );

      const selectedValues = [
        ...flatMap(activeFilters),
        ...implicitSelectedDatasetIds,
      ];

      return selectedValues.includes(o.value);
    },
    [searchParams, filterOptions]
  );

  const itemData = useMemo(() => {
    const aggregations = getAggregations(data, activeFilterKey);

    const options = chain(filterOptions[getFilterOptionsKey(activeFilterKey)])
      .filter(
        (o: FilterOption) =>
          o.label.toLowerCase().includes(optionFilter.toLowerCase()) ||
          !optionFilter
      )
      .map((o: FilterOption) => ({
        ...o,
        count:
          (!disableCounts &&
            aggregations.find(({ key }) => key === o.value || key === o.label)
              ?.doc_count) ||
          0,
        isSelected: isSelected(o),
      }))
      .orderBy(["isSelected", "count", "label"], ["desc", "desc", "asc"])
      .value();

    const excludable = activeFilterKey === FilterKeys.DATASET_IDS;
    let areAnyChecked = variables.filters[activeFilterKey].length > 0;

    const implicitDatasetIds = flatMap(
      filterOptions.data_collections.filter(
        ({ value }: DataCollectionFilterOption) =>
          variables.filters.data_collection_ids.includes(value)
      ),
      ({ dataset_ids }) => dataset_ids
    );

    const implicitExcludeDatasetIds = flatMap(
      filterOptions.data_collections.filter(
        ({ value }: DataCollectionFilterOption) =>
          variables.filters.exclude_data_collection_ids.includes(value)
      ),
      ({ dataset_ids }) => dataset_ids
    );

    if (excludable) {
      areAnyChecked = areAnyChecked || implicitDatasetIds.length > 0;
    }

    return {
      options,
      filters: variables.filters,
      filterKey: activeFilterKey,
      updateFilters,
      excludable,
      areAnyChecked,
      implicitDatasetIds,
      implicitExcludeDatasetIds,
    };
  }, [
    filterOptions,
    activeFilterKey,
    optionFilter,
    variables,
    updateFilters,
    data,
    disableCounts,
  ]);

  const handleActiveFilterKey = (key: FilterKeys) => {
    setActiveFilterKey(key);
    setOptionFilter("");
  };

  return (
    <StyledFilterBox sx={{ top: "104px" }}>
      <StyledFilterHeader>
        <IconButton size="large" color="inherit" onClick={handleClose}>
          <ChevronLeftIcon />
        </IconButton>
        <Button
          onClick={() => resetFilters()}
          disableRipple
          sx={{
            fontWeight: 400,
            color: theme.palette.grey[500],
            textDecoration: "underline",
          }}
        >
          Reset filters
        </Button>
        {unappliedFilters && (
          <StyledButton onClick={() => handleSearch()}>Apply</StyledButton>
        )}
      </StyledFilterHeader>
      <StyledFilterBody>
        <FilterTabs
          selectedTab={activeFilterKey}
          setSelectedTab={handleActiveFilterKey}
          filters={variables.filters}
        />
        <ListWrapper>
          <StyledTextField
            size="small"
            placeholder="Search..."
            value={optionFilter}
            onChange={(e) => setOptionFilter(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="primary" fontSize="small" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" sx={{ mr: -0.25 }}>
                  <SortByAlphaIcon color="primary" fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
          {enableDataCollectionFilter && (
            <DataCollectionFilter options={filterOptions.data_collections} />
          )}
          <Box sx={{ flex: 1 }}>
            <AutoSizer>
              {({ height, width }) => {
                return isLoading ? (
                  <Box
                    sx={{
                      height,
                      width,
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      alignItems: "center",
                      justifyContent: "center",
                      color: theme.palette.grey[400],
                    }}
                  >
                    <CircularProgress color="inherit" size={28} />
                    <Typography variant="caption">
                      Fetching datasets...
                    </Typography>
                  </Box>
                ) : (
                  <FixedSizeList
                    width={width}
                    height={height}
                    itemSize={45}
                    itemData={itemData}
                    itemCount={itemData.options.length}
                    className="filters-list"
                  >
                    {FilterOptionRow}
                  </FixedSizeList>
                );
              }}
            </AutoSizer>
          </Box>
        </ListWrapper>
      </StyledFilterBody>
    </StyledFilterBox>
  );
}

function getAggregations(
  data: InfiniteData<SearchResult>,
  activeFilterKey: FilterKeys
) {
  let aggregations: { key?: string; doc_count?: number }[] = [];
  if (activeFilterKey === FilterKeys.DOC_TYPES) {
    const modeled_aggregations =
      data?.pages[0]?.aggregations["schema_counts"]?.buckets || [];
    const source_aggregations =
      data?.pages[0]?.aggregations["doc_type_counts"]?.buckets || [];
    aggregations = [...modeled_aggregations, ...source_aggregations];
  } else {
    const aggregationKey = AggregationCountsKeys[activeFilterKey];
    aggregations = data?.pages[0]?.aggregations[aggregationKey]?.buckets || [];
  }
  return aggregations;
}
