import { isEqual } from "lodash";
import { Group } from "src/types/UserGroup";

export const getGroupObjectDiff = (
  obj1: Group,
  obj2: Group,
  compareRef = false
) => {
  const group1 = { ...obj1 };
  const group2 = { ...obj2 };

  // do not compare group_admins field since it is updated separately
  delete group1.group_admins;
  delete group2.group_admins;

  return Object.keys(group1).reduce((result, key) => {
    if (isEqual(group1[key as keyof Group], group2[key as keyof Group])) {
      const resultKeyIndex = result.indexOf(key);
      if (
        compareRef &&
        group1[key as keyof Group] !== obj2[key as keyof Group]
      ) {
        result[resultKeyIndex] = `${key} (ref)`;
      } else {
        result.splice(resultKeyIndex, 1);
      }
    }
    return result;
  }, Object.keys(group2));
};
