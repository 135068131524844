import { Dataset } from "src/types/Dataset";
import actions from "./actions";

export interface Props {
  loading: boolean;
  error?: string;
  message: string;
}

const initialData: Props = {
  loading: false,
  error: null,
  message: "",
};

export default (
  state = initialData,
  { type, payload }: { type: string; payload: boolean | string | Dataset[] }
) => {
  if (type === actions.SENDING) {
    return {
      ...state,
      loading: payload,
    };
  }
  if (type === actions.SEND_ERROR) {
    return {
      ...state,
      loading: false,
      error: payload,
    };
  }
  if (type === actions.SEND_SUCCESS) {
    return {
      ...state,
      loading: false,
      datasets: payload,
    };
  }

  return state;
};
