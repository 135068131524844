import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import DatasetItem from "src/view/indexing/datasetList/components/DatasetItem";
import { Dataset } from "src/types/Dataset";

const StyledTable = styled(Table)(() => ({
  // to adjust table columns width change the width of the TableCell in StyledTableHead
  tableLayout: "fixed",
  borderCollapse: "separate",
  borderSpacing: "0",
}));

const StyledTableHead = styled(TableHead)(() => ({
  "& th": {
    color: "#9BA5B1",
    fontSize: "12px",
    fontWeight: "700",
    padding: "10px 0 10px 12px",
  },
  "& th.biggerText": { fontSize: "14px" },
}));

interface Props {
  datasets: Dataset[];
  isLoading: boolean;
}

const DatasetTable = ({ datasets, isLoading }: Props) => {
  return (
    <>
      <StyledTable>
        <StyledTableHead>
          <TableRow>
            <TableCell className="iconCell" width={"45px"} />
            <TableCell className="biggerText">Title</TableCell>
            <TableCell>Known Record Types</TableCell>
            <TableCell>Sensitivity</TableCell>
            <TableCell className="iconCell" width={"80px"} />
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {!isLoading &&
            datasets?.map((dataset) => (
              <DatasetItem key={dataset.id} dataset={dataset} />
            ))}
        </TableBody>
      </StyledTable>

      {!isLoading && !datasets?.length && (
        <Box
          sx={{
            marginTop: "30px",
            textAlign: "center",
            width: "100%",
          }}
        >
          <Typography>No Datasets found</Typography>
        </Box>
      )}
    </>
  );
};

export default DatasetTable;
