import { fetchApi } from "src/modules/shared/api";

export default class Service {
  static async sendFeedback(message: string) {
    try {
      const data = {
        message: message,
      };

      const res = await fetchApi("intercom/submit_feedback/", {
        method: "POST",
        body: JSON.stringify(data),
      });
      if (res.status >= 200 && res.status < 300) {
        return {
          error: false,
        };
      } else {
        return {
          error: true,
        };
      }
    } catch (err) {
      return {
        error: true,
        detail: err,
      };
    }
  }
}
