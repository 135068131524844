import { KnowledgeWiki, WikiFilter } from "src/types/KnowledgeWiki";
import actions from "./actions";

export interface Props {
  loading: boolean;
  data: KnowledgeWiki[];
  itemDetail: KnowledgeWiki;
  detailUpdated: boolean;
  filter?: WikiFilter;
  search: string;
}

const initialData: Props = {
  loading: false,
  data: [],
  itemDetail: null,
  detailUpdated: false,
  filter: {
    countries: [],
    dataset_type: [],
    languages: [],
    regions: [],
  },
  search: "",
};

export default (
  state = initialData,
  {
    type,
    payload,
  }: {
    type: string;
    payload: boolean | KnowledgeWiki | KnowledgeWiki[] | WikiFilter;
  }
) => {
  if (type === actions.LOADING) {
    return {
      ...state,
      loading: payload,
    };
  }
  if (type === actions.FIND_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }
  if (type === actions.FIND_ERROR) {
    return {
      ...state,
      loading: false,
    };
  }
  if (type === actions.FIND_SUCCESS) {
    return {
      ...state,
      loading: false,
      data: payload,
    };
  }
  if (type === actions.GET_ITEM_DETAIL) {
    return {
      ...state,
      itemDetail: payload,
    };
  }
  if (type === actions.DETAIL_UPDATED) {
    return {
      ...state,
      detailUpdated: true,
    };
  }
  if (type === actions.CLEAR_UPDATE_STATUS) {
    return {
      ...state,
      loading: false,
      detailUpdated: false,
    };
  }
  if (type === actions.UPDATE_FILTER) {
    return {
      ...state,
      filter: payload,
    };
  }
  if (type === actions.UPDATE_SEARCH) {
    return {
      ...state,
      search: payload,
    };
  }

  return state;
};
