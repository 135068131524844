import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import groupActions from "src/modules/groups/view/groupsViewActions";
import selector from "src/modules/groups/view/groupsViewSelectors";
import { getFullName } from "src/utils";
import AdminIconUrl from "src/assets/images/group-admin.png";
import DeleteIconUrl from "src/assets/images/delete.png";
import ConfirmDialog from "src/view/components/ConfirmDialog";
import AddGroupAdminDrawer from "../AddGroupAdminDrawer";
import { useUsers } from "src/modules/api/users";

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: 370,
  height: "100%",
});

const Header = styled(Box)({
  display: "flex",
  alignItems: "center",
  minHeight: 64,
  borderBottom: "solid 1px #A0B9D0",
  paddingLeft: 8,
});

const AddButton = styled(Button)({
  backgroundColor: "#F75151",
  color: "#ffffff",
  lineHeight: "17px",
  fontWeight: "700",
  padding: "0 16px",
  height: 38,
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#F7515188",
  },
});

const Item = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingLeft: "16px",
  boxSizing: "border-box",
  color: "#122945",
  fontSize: "14px",
  background: "#F0F1F3",
  borderRadius: "6px",
  margin: "8px 15px",
  padding: "12px 16px",
});

interface Props {
  onClose: () => void;
}

const Content = ({ onClose }: Props) => {
  const dispatch = useDispatch();
  const selectedGroup = useSelector(selector.selectSelectedGroup);

  const { getUsersByIds } = useUsers();
  const { data: admins } = getUsersByIds(selectedGroup?.group_admins);
  const [openAddGroupAdmin, setOpenAddGroupAdmin] = useState(false);

  const removeAdmin = (userId: string) => {
    dispatch(
      groupActions.updateGroup(
        {
          group_admins: selectedGroup.group_admins.filter(
            (adminId) => adminId !== userId
          ),
        },
        selectedGroup.group_id
      )
    );
  };

  const closeAddGroupAdmin = useCallback(() => setOpenAddGroupAdmin(false), []);

  return (
    <Wrapper>
      <Header>
        <img src={AdminIconUrl} width="20" height="20" />
        <Typography
          variant="h6"
          fontSize="16px"
          fontWeight="700"
          color="#043D5D"
          pl={1}
          mr="auto"
        >
          Group Admins
        </Typography>
        <AddButton
          startIcon={<AddIcon />}
          onClick={() => setOpenAddGroupAdmin(true)}
        >
          Add
        </AddButton>
        <IconButton size="large" color="inherit" onClick={onClose}>
          <Close />
        </IconButton>
      </Header>

      <Box flex="1" overflow="auto">
        {selectedGroup?.group_admins?.map((groupAdminId) => {
          const admin = admins?.find((user) => groupAdminId === user.id);
          if (!admin) return;
          return (
            <Item key={admin.id}>
              <Box display="flex" flexDirection="column">
                <strong>{getFullName(admin) || "--"}</strong>
                <span>{admin.email}</span>
              </Box>
              <ConfirmDialog
                title="Remove Admin Access"
                description="Are you sure you want to remove admin access form this user? This action cannot be undone."
                onConfirm={() => removeAdmin(admin.id)}
                confirmText="Confirm"
                cancelText="Cancel"
              >
                <IconButton size="small">
                  <img src={DeleteIconUrl} width="18" height="18" />
                </IconButton>
              </ConfirmDialog>
            </Item>
          );
        })}
      </Box>

      <AddGroupAdminDrawer
        open={openAddGroupAdmin}
        onClose={closeAddGroupAdmin}
      />
    </Wrapper>
  );
};

export default Content;
