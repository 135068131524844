import { InputBase } from "@mui/material";

import { User } from "src/types/UserGroup";
import { getFullName } from "src/utils";

interface Props {
  users: User[];
  setUserList: React.Dispatch<React.SetStateAction<User[]>>;
}

const UserSearchInput = ({ users, setUserList }: Props) => {
  const handleFilterUsers = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const keyword = e.target.value;
    const filtered = users.filter((user: User) =>
      getFullName(user).includes(keyword)
    );
    setUserList(filtered);
  };

  return (
    <InputBase
      sx={{
        mr: "14px",
        borderRadius: "4px",
        border: "1px solid #CED3D9",
        height: "36px",
        fontSize: 12,
        paddingLeft: 1.5,
        paddingRight: 1.5,
      }}
      placeholder="Search users"
      inputProps={{ "aria-label": "search users" }}
      onChange={handleFilterUsers}
      startAdornment={
        <img
          src={require("src/assets/images/search-black.png")}
          style={{ width: 16, height: 16, marginRight: 12 }}
        />
      }
    />
  );
};

export default UserSearchInput;
