import { fetchApi } from "src/modules/shared/api";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { Paginated } from "src/types/Shared";
import { User } from "src/types/UserGroup";
import queryKeys from "src/modules/api/queryKeys";
import { queryClient } from "src/index";

interface GetUsersParams {
  searchKey?: string;
  pageParam?: number;
}

export function useUsers() {
  const getUsersFn = async ({ searchKey, pageParam }: GetUsersParams) => {
    try {
      const res = await fetchApi(
        "users/?" +
          new URLSearchParams({
            ...(pageParam && { page: pageParam?.toString() }),
            ...(searchKey && { name_or_email: searchKey }),
          }),
        {
          method: "GET",
        }
      );
      const response = res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response["detail"],
        };
      }
    } catch (err) {
      return false;
    }
  };

  const getUsers = (params: GetUsersParams = {}) =>
    useQuery<Paginated<User[]>>({
      queryFn: ({ pageParam }): Promise<Paginated<User[]>> => {
        return getUsersFn({
          searchKey: params.searchKey,
          pageParam,
        });
      },
      queryKey: [queryKeys.USERS, params],
    });

  const getUsersInfinite = (params: GetUsersParams) =>
    useInfiniteQuery<Paginated<User[]>>({
      queryFn: ({ pageParam = 1 }) => {
        return getUsersFn({
          searchKey: params.searchKey,
          pageParam,
        });
      },
      queryKey: [queryKeys.USERS, params],
      getNextPageParam: (lastPage) => lastPage.next_page_number,
    });

  const getUsersByIdsFn = async (ids: string[]) => {
    try {
      const res = await fetchApi("users/bulk_get/", {
        method: "POST",
        body: JSON.stringify({
          user_ids: ids,
        }),
      });

      if (res.ok) {
        return await res.json();
      } else {
        return [];
      }
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getUsersByIds = (ids: string[]) =>
    useQuery<User[]>({
      queryFn: () => getUsersByIdsFn(ids),
      queryKey: [queryKeys.USERS_BY_IDS, ids],
      initialData: () => [],
      enabled: !!ids?.length,
    });

  const deleteUserFn = async (id: string) => {
    try {
      const res = await fetchApi(`users/${id}/`, {
        method: "DELETE",
      });
      if (!res) return;
      const response = await res.json();

      if (res.status >= 200 && res.status < 300) {
        return {
          error: false,
        };
      } else {
        return {
          error: true,
          detail: response["detail"],
        };
      }
    } catch (err) {
      return false;
    }
  };

  const deleteUser = () =>
    useMutation({
      mutationFn: (id: string) => deleteUserFn(id),
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.USERS]);
      },
    });

  return {
    getUsers,
    getUsersFn,
    getUsersByIds,
    getUsersInfinite,
    deleteUser,
  };
}
