import { Routes, Route } from "react-router-dom";
import SearchHeader from "../layout/SearchHeader";
import DataCollectionPage from "./DataCollectionPage";
import { Box, Container, Tab, Tabs, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlineSharp";
import EditIcon from "@mui/icons-material/EditOutlined";
import { ReactComponent as DuplicateIcon } from "src/assets/icons/file_copy_line.svg";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import AutoSizer from "react-virtualized-auto-sizer";
import React, { useEffect, useState } from "react";
import { snackbar } from "src/view/toaster";
import { useLocation, useNavigate } from "react-router-dom";
import { DataCollection } from "src/types/DataCatalog";
import DataGrid from "../components/DataGrid";
import {
  GridColDef,
  GridValueGetterParams,
  GridValueFormatterParams,
  GridActionsCellItem,
  GridRowParams,
} from "@mui/x-data-grid-premium";
import { format } from "date-fns";
import DeleteCollectionDialog from "./DeleteCollectionDialog";
import { useDataCollections } from "src/modules/api/dataCollections";
import theme from "src/theme";
import { useSearch } from "src/modules/api/search";
import { emptyQueryVariables } from "src/modules/api/search/SearchProvider";
import { usePostHog } from "posthog-js/react";
import { HogEvent } from "src/types/PosthogEvents";

const NoRowsOverlayComponent = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: 400,
      width: "100%",
    }}
  >
    <img
      height={200}
      width={200}
      src={require("src/assets/images/not-found.png")}
      alt="Not Found"
    />
    No Collections
  </Box>
);

const StyledHeaderBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
  padding: theme.spacing(4, 0, 1, 0),
}));

const StyledHeaderRow = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const StyledButton = styled(Button)(() => ({
  borderRadius: 4,
  backgroundColor: "#F75151",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#ffffff",
  fontSize: 14,
  lineHeight: "17px",
  fontWeight: "700",
  padding: 15,
  width: 128,
  height: 40,
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#F7515188",
  },
}));

const StyledSearchBox = styled("div")(() => ({
  position: "relative",
  height: 40,
  width: "100%",
  maxWidth: 300,
  display: "flex",
  alignItems: "center",
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    marginTop: 149,
  },
  marginTop: 104,
}));

const StyledSearchIcon = styled(SearchIcon)(() => ({
  position: "absolute",
  left: 12,
  fontSize: 16,
  color: "#043D5D",
}));

const SearchInput = styled("input")(() => ({
  height: "100%",
  width: "100%",
  borderRadius: 4,
  paddingLeft: 34,
  paddingRight: 40,
  fontFamily: "Inter",
  fontSize: 12,
  border: "1px solid rgba(65, 64, 66, 0.16)",
  "&:focus-visible": {
    outline: "none",
  },
}));

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.secondary.main,
    height: 3,
  },
});

interface StyledTabProps {
  label?: string | React.ReactNode;
}
const AntTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  paddingLeft: "0",
  paddingRight: "20px",
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  marginRight: theme.spacing(1),
  "& .tab-label-bullet": {
    color: theme.palette.grey[400],
  },
}));

const DataCollectionsIndex = () => {
  const { getDataCollections, deleteDataCollection, duplicateDataCollection } =
    useDataCollections();
  const { data, isLoading } = getDataCollections();
  const [rows, setRows] = useState<DataCollection[]>([]);
  const [filter, setFilter] = useState<string>("");
  const [deleteCollection, setDeleteCollection] =
    useState<DataCollection | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const passedTabIndex = location.state?.tabIndex;
  const [tabIndex, setTabIndex] = useState<number>(passedTabIndex ?? 0);
  const posthog = usePostHog();
  const { handleSearch } = useSearch();

  const handleCreate = () => {
    navigate("collection/create");
  };

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  useEffect(() => {
    const relevantData =
      tabIndex === 0 ? data.created_by : data.shared_with_groups;

    const filteredData = relevantData.filter(
      (item: DataCollection) =>
        !filter || item.name.toLowerCase().includes(filter.toLowerCase())
    );

    setRows(filteredData);
  }, [data, filter, tabIndex]);

  const handleSearchCollection = (collection: DataCollection) => {
    posthog.capture(HogEvent.DATA_COLLECTION_SEARCHED);
    handleSearch({
      ...emptyQueryVariables,
      filters: {
        ...emptyQueryVariables.filters,
        data_collection_ids: [collection.id],
      },
      content: "*",
    });
  };

  const handleOpen = (collection: DataCollection) => {
    posthog.capture("data_collection_opened");
    navigate(`collection/${collection.id}`, {
      state: { fromTabIndex: tabIndex },
    });
  };

  const handlePromptDelete = (collection: DataCollection) => {
    setDeleteCollection(collection);
  };

  const handleDelete = (collection: DataCollection) => {
    setDeleteCollection(null);
    deleteDataCollection.mutate(collection.id, {
      onSuccess: () => {
        setRows((prev) => prev.filter(({ id }) => id !== collection.id));
        snackbar.success("Data collection deleted.");
      },
      onError: () => {
        snackbar.error("Error deleting data collection.");
      },
    });
  };

  const handleDuplicate = (collection: DataCollection) => {
    duplicateDataCollection.mutate(collection);
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const generateColumns = (tabIndex: number) => {
    const baseColumns: GridColDef[] = [
      {
        field: "name",
        type: "string",
        headerName: "Title",
        flex: 1,
      },
      {
        field: "updated",
        headerName: "Last Updated",
        type: "dateTime",
        width: 175,
        valueGetter: (params: GridValueGetterParams) => new Date(params.value),
        valueFormatter: (params: GridValueFormatterParams) => {
          if (!params.value) {
            return "";
          }
          return format(params.value, "MMMM dd, yyyy");
        },
      },
      {
        field: "datasetCount",
        headerName: "Number of Datasets",
        type: "number",
        width: 175,
        valueGetter: (params: GridValueGetterParams) =>
          params.row.datasets.length,
      },
    ];

    const actionColumn: GridColDef = {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 250,
      getActions: ({ row }: GridRowParams<DataCollection>) => {
        const actions = [
          <GridActionsCellItem
            label="Search"
            icon={<SearchIcon color="primary" />}
            onClick={() => handleSearchCollection(row)}
          />,
          <GridActionsCellItem
            label="Duplicate"
            icon={
              <DuplicateIcon
                style={{
                  width: "20px",
                  height: "20px",
                  color: theme.palette.primary.main,
                }}
              />
            }
            onClick={() => handleDuplicate(row)}
          />,
        ];

        // Add Edit and Delete actions only if user created collections
        if (tabIndex === 0) {
          actions.push(
            <GridActionsCellItem
              label="Edit"
              icon={<EditIcon color="primary" />}
              onClick={() => handleOpen(row)}
            />,
            <GridActionsCellItem
              label="Delete"
              icon={<DeleteIcon color="primary" />}
              onClick={() => handlePromptDelete(row)}
            />
          );
        }

        return actions;
      },
    };

    return [...baseColumns, actionColumn];
  };

  const [columns, setColumns] = useState<GridColDef[]>(
    generateColumns(tabIndex)
  );

  useEffect(() => {
    setColumns(generateColumns(tabIndex));
  }, [tabIndex]);

  const renderTabLabel = (
    label: string,
    selected: boolean,
    tabIndex: number
  ) => {
    const totalOwnItems = data.created_by.length;
    const totalSharedItems = data.shared_with_groups.length;
    const totalItems = tabIndex === 0 ? totalOwnItems : totalSharedItems;

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          color: theme.palette.primary.main,
          "&:hover": {
            color: "#40a9ff",
            opacity: 1,
          },
          ...(selected && { fontWeight: 700, color: "red" }),
        }}
      >
        <Typography sx={{ fontWeight: "inherit" }}>{label}</Typography>
        <span className="tab-label-bullet">•</span>
        <span
          style={{ color: selected ? theme.palette.secondary.main : "inherit" }}
        >
          {totalItems}
        </span>
      </Box>
    );
  };

  return (
    <StyledContainer>
      <StyledHeaderBox>
        <StyledHeaderRow>
          <Typography variant="h2" color="primary">
            Data Collections
          </Typography>
          <StyledButton onClick={handleCreate} startIcon={<AddIcon />}>
            Create
          </StyledButton>
        </StyledHeaderRow>
        <StyledHeaderRow>
          <Typography variant="h5" color="primary">
            {`${
              data.created_by.length + data.shared_with_groups.length
            } Collection${
              data.created_by.length + data.shared_with_groups.length > 1
                ? "s"
                : ""
            }`}
          </Typography>
          <StyledSearchBox>
            <StyledSearchIcon />
            <SearchInput
              placeholder="Search collections"
              onChange={handleFilter}
            />
          </StyledSearchBox>
        </StyledHeaderRow>
      </StyledHeaderBox>

      <AntTabs value={tabIndex} onChange={handleTabChange}>
        <AntTab label={renderTabLabel("My List", tabIndex === 0, 0)} />
        <AntTab label={renderTabLabel("Shared with me", tabIndex === 1, 1)} />
      </AntTabs>

      <AutoSizer>
        {({ height, width }) => (
          <Box sx={{ height: height, minHeight: 600, width: width }}>
            <DataGrid
              rows={rows}
              columns={columns}
              loading={isLoading}
              onRowClick={({ row }) => handleOpen(row)}
              disableColumnMenu
              hideFooter
              slots={{
                noRowsOverlay: NoRowsOverlayComponent,
              }}
            />
          </Box>
        )}
      </AutoSizer>
      <DeleteCollectionDialog
        collection={deleteCollection}
        handleClose={() => setDeleteCollection(null)}
        handleDelete={handleDelete}
      />
    </StyledContainer>
  );
};

export default function DataCollections() {
  return (
    <>
      <SearchHeader />
      <Routes>
        <Route path="/" element={<DataCollectionsIndex />} />
        <Route path="collection/:id" element={<DataCollectionPage />} />
      </Routes>
    </>
  );
}
