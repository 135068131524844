import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useFetch } from "src/modules/api/fetch";
import { useUser } from "src/modules/api/auth";
import { User } from "src/types/Auth";
import { snackbar } from "src/view/toaster";

const useToggleSearchHistory = () => {
  const { user } = useUser();
  const { postApi } = useFetch();
  const queryClient = useQueryClient();
  const [, setSearchHistoryState] = useState<boolean>(
    user?.search_history_opted_in ?? false
  );

  return useMutation({
    mutationFn: (search_history_opted_in: boolean) => {
      return postApi(["users", "search_history_toggle"], {
        body: JSON.stringify({
          search_history_opted_in: search_history_opted_in,
        }),
      });
    },
    // Tracks new state to make sure the right snackbar is rendered
    onMutate: (newState) => {
      setSearchHistoryState(newState);
    },
    onSuccess: async (_, newState) => {
      queryClient.setQueryData(["user"], (oldData: User | undefined) =>
        oldData
          ? {
              ...oldData,
              search_history_opted_in: newState,
            }
          : oldData
      );

      if (newState) {
        snackbar.success(
          "Feature enabled. Your recent searches will be saved."
        );
      } else {
        snackbar.success(
          "Feature disabled. Your recent searches have been deleted."
        );
      }
    },
    onError: (error, newState) => {
      // Revert the state if the mutation fails
      setSearchHistoryState(!newState);
      snackbar.error(
        "Failed to update search history settings. Please try again."
      );
    },
  });
};

export default useToggleSearchHistory;
