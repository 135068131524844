export const nonFilterableEntities = [
  "Directorship",
  "EconomicActivity",
  "Employment",
  "Family",
  "Interval",
  "Membership",
  "Ownership",
  "Payment",
];
