import { AnyAction, Dispatch } from "redux";
import { Group, User } from "src/types/UserGroup";
import GroupsService from "src/modules/groups/groupsService";
import Errors from "src/modules/shared/error/errors";

const prefix = "GROUPS_VIEW";

const GroupsViewActions: AnyAction = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  GROUP_FIND_SUCCESS: `${prefix}_GROUP_FIND_SUCCESS`,
  USER_FIND_SUCCESS: `${prefix}_USER_FIND_SUCCESS`,
  GROUP_FIND_ERROR: `${prefix}_GROUP_FIND_ERROR`,
  USER_FIND_ERROR: `${prefix}_USER_FIND_ERROR`,
  GET_GROUPS: `${prefix}_GET_GROUPS`,
  GET_USERS: `${prefix}_GET_USERS`,
  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_END: `${prefix}_CREATE_END`,
  CREATE_FAILED: `${prefix}_CREATE_FAILED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CLEAR_UPDATE_STATUS: `${prefix}_CLEAR_UPDATE_STATUS`,
  CREATE_USERS: `${prefix}_CREATE_USERS`,
  CREATE_USERS_ERROR: `${prefix}_CREATE_USERS_ERROR`,
  SELECT_GROUP: `${prefix}_SELECT_GROUP`,
  SELECT_USER: `${prefix}_SELECT_USER`,
  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_FAILED: `${prefix}_UPDATE_FAILED`,
  SEND_EMAIL_ERROR: `${prefix}_SEND_EMAIL_ERROR`,
  SEND_EMAIL: `${prefix}_SEND_EMAIL`,

  getExistingUsers:
    ({ page = "1", searchKey = "" } = {}) =>
    async (dispatch: Dispatch<AnyAction>) => {
      try {
        dispatch({
          type: GroupsViewActions.FIND_STARTED,
        });

        const res = await GroupsService.getExistingUsers({ page, searchKey });
        if (res.error) {
          dispatch({
            type: GroupsViewActions.USER_FIND_ERROR,
            payload: res.detail,
          });
        } else {
          dispatch({
            type: GroupsViewActions.USER_FIND_SUCCESS,
            payload: res,
          });
        }
        return res;
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: GroupsViewActions.USER_FIND_ERROR,
          payload: error,
        });
      }
    },
  createGroup:
    (data: Group, loading = true) =>
    async (dispatch: Dispatch<AnyAction>) => {
      try {
        if (loading)
          dispatch({
            type: GroupsViewActions.CREATE_STARTED,
          });

        const res = await GroupsService.createGroup(data);
        if (loading == false) return res;
        if (res.error) {
          dispatch({
            type: GroupsViewActions.CREATE_FAILED,
            payload: res.detail,
          });
        } else {
          dispatch({
            type: GroupsViewActions.CREATE_SUCCESS,
            payload: res,
          });
        }
        return res;
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: GroupsViewActions.CREATE_FAILED,
          payload: error,
        });
      }
    },
  saveUsers: (data: User[]) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      const res = await GroupsService.saveUsers(data);
      if (res.error) {
        dispatch({
          type: GroupsViewActions.CREATE_USERS_ERROR,
          payload: res,
        });
      } else {
        dispatch({
          type: GroupsViewActions.CREATE_USERS,
          payload: res,
        });
      }
      return res;
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: GroupsViewActions.CREATE_USERS_ERROR,
        payload: error,
      });
    }
  },
  resendEmail: (data: User[]) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      const res = await GroupsService.resendEmail(data);
      if (res.error) {
        dispatch({
          type: GroupsViewActions.SEND_EMAIL_ERROR,
          payload: res,
        });
      } else {
        dispatch({
          type: GroupsViewActions.SEND_EMAIL,
          payload: res,
        });
      }
      return res;
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: GroupsViewActions.SEND_EMAIL_ERROR,
        payload: error,
      });
    }
  },
  addUsersToGroup:
    (id: string, data: Group) => async (dispatch: Dispatch<AnyAction>) => {
      try {
        dispatch({
          type: GroupsViewActions.CREATE_STARTED,
        });

        const res = await GroupsService.addUsersToGroup(id, data);

        if (res["error"]) {
          dispatch({
            type: GroupsViewActions.CREATE_FAILED,
            payload: res["detail"],
          });
        } else {
          dispatch({
            type: GroupsViewActions.CLEAR_UPDATE_STATUS,
          });
        }
        return res;
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: GroupsViewActions.CREATE_FAILED,
          payload: error,
        });
      }
    },
  updateGroup:
    (data: Group, id: string) => async (dispatch: Dispatch<AnyAction>) => {
      try {
        dispatch({
          type: GroupsViewActions.UPDATE_STARTED,
        });

        const res = await GroupsService.updateGroup(data, id);
        if (res["error"]) {
          dispatch({
            type: GroupsViewActions.UPDATE_FAILED,
            payload: res["detail"],
          });
        }
        dispatch(GroupsViewActions.getGroupDetails(id));
        return res;
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: GroupsViewActions.UPDATE_FAILED,
          payload: error,
        });
      }
    },
  addObjectsToGroup:
    (data: Group, id: string) => async (dispatch: Dispatch<AnyAction>) => {
      try {
        dispatch({
          type: GroupsViewActions.UPDATE_STARTED,
        });

        const res = await GroupsService.addObjectsToGroup(data, id);
        if (res["error"]) {
          dispatch({
            type: GroupsViewActions.UPDATE_FAILED,
            payload: res["detail"],
          });
        } else {
          dispatch({
            type: GroupsViewActions.UPDATE_SUCCESS,
          });
        }
        return res;
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: GroupsViewActions.UPDATE_FAILED,
          payload: error,
        });
      }
    },
  removeObjectsToGroup:
    (data: Group, id: string) => async (dispatch: Dispatch<AnyAction>) => {
      try {
        dispatch({
          type: GroupsViewActions.UPDATE_STARTED,
        });

        const res = await GroupsService.removeObjectsToGroup(data, id);
        if (res["error"]) {
          dispatch({
            type: GroupsViewActions.UPDATE_FAILED,
            payload: res["detail"],
          });
        } else {
          dispatch({
            type: GroupsViewActions.UPDATE_SUCCESS,
          });
        }
        return res;
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: GroupsViewActions.UPDATE_FAILED,
          payload: error,
        });
      }
    },
  importCSV: (data: FormData) => async () => {
    try {
      const res = await GroupsService.importCSV(data);
      return res;
    } catch (error) {
      Errors.handle(error);
      return error;
    }
  },
  getGroupUsers: (group_id: string) => async () => {
    try {
      const res = await GroupsService.getGroupUsers(group_id);
      return res;
    } catch (error) {
      Errors.handle(error);
    }
  },
  getGroupDetails:
    (group_id: string) => async (dispatch: Dispatch<AnyAction>) => {
      try {
        const res = await GroupsService.getGroupDetails(group_id);
        dispatch({
          type: GroupsViewActions.SELECT_GROUP,
          payload: res,
        });
        return res;
      } catch (error) {
        Errors.handle(error);
      }
    },

  selectGroup: (data: Group) => async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: GroupsViewActions.SELECT_GROUP,
      payload: data,
    });
  },
  selectUser: (data: User) => async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: GroupsViewActions.SELECT_USER,
      payload: data,
    });
  },
  shareScreeningListItemToGroup:
    (screening_list_id: string, data: { group_id: string }) => async () => {
      try {
        const res = await GroupsService.shareScreeningListItemToGroup(
          screening_list_id,
          data
        );
        return res;
      } catch (error) {
        console.log(error);

        // Errors.handle(error);
      }
    },

  removeScreeningListItemFromGroup:
    (group_id: string, data: { screening_lists: string[] }) => async () => {
      try {
        const res = await GroupsService.removeScreeningListItemFromGroup(
          group_id,
          data
        );
        return res;
      } catch (error) {
        console.log(error);

        // Errors.handle(error);
      }
    },
  type: undefined,
};

export default GroupsViewActions;
