import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Colors } from "src/view/constants/colors";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import BulkSearchService from "src/modules/bulk-search/bulkSearchService";
import ConfirmDialog from "src/view/components/ConfirmDialog";
import actions from "src/modules/bulk-search/query-group/queryGroupActions";
import { useDispatch } from "react-redux";
import { snackbar } from "src/view/toaster";
import filterActions from "src/modules/bulk-search/filter/searchFilterActions";
import { QueryGroupItem } from "src/types/BulkSearch";
import { SearchFilter } from "src/types/SearchFilter";
import VisibilityIcon from "@mui/icons-material/Visibility";
import regions from "src/assets/json/regions.json";
import { format } from "date-fns";
import { Dataset } from "src/types/Dataset";
import { usePostHog } from "posthog-js/react";
import { HogEvent } from "src/types/PosthogEvents";

const StyledContainer = styled(Box, {
  shouldForwardProp: (prop) => prop != "open",
})<{
  open?: boolean;
}>(({ open }) => ({
  width: "max-content",
  minWidth: "calc(100% - 40px)",
  padding: 20,
  marginTop: 12,
  backgroundColor: "#FFFFFF",
  ...(open && {
    boxShadow: "0px 4px 54px rgba(160, 185, 208, 0.3)",
    borderRadius: 8,
  }),
  borderRadius: 4,
  "&:hover": {
    backgroundColor: "#F0F1F3",
    "& button": {
      display: "flex",
    },
  },
}));

const StyledRow = styled(Box, { shouldForwardProp: (prop) => prop != "open" })<{
  open?: boolean;
}>(() => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
}));

const StyledTitleContainer = styled(Box)(() => ({
  width: "35%",
  maxWidth: "35%",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
}));

const StyledTitle = styled(Box)(() => ({
  fontSize: 18,
  lineHeight: "20px",
  fontWeight: "700",
  color: Colors.textDark,
  marginBottom: "6px",
  cursor: "pointer",
  textDecoration: "underline",
}));

const StyledLabel = styled(Box)(() => ({
  fontSize: 12,
  lineHeight: "18px",
  fontWeight: "700",
  color: "rgba(90, 114, 144, 0.7)",
}));

const StyledText1 = styled(Typography)(() => ({
  fontSize: 14,
  lineHeight: "20px",
  fontWeight: "700",
  color: "#5A7290",
  width: "max-content",
}));

const StyledIcon = styled("img")(() => ({
  width: 36,
  height: 20,
  objectFit: "contain",
}));

interface Props {
  id?: string;
  title?: string;
  createdAt?: string;
  sharedUsers?: Array<string>;
  lastResult?: string;
  dataset_filters?: Array<string>;
  hasSharedGroup?: boolean;
  data?: QueryGroupItem;
  isMine?: boolean;
}

const TRow = (props: Props) => {
  const { data, isMine } = props;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [searchTerms, setSearchTerms] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const [runningSearches, setRunningSearches] = useState(false);
  const posthog = usePostHog();

  useEffect(() => {
    document.addEventListener("refreshQueryGroup", getData);
    document.addEventListener("checkQueryGroupSearchStatus", checkSearchStatus);
    return () => {
      document.removeEventListener(
        "checkQueryGroupSearchStatus",
        checkSearchStatus
      );
    };
  }, []);

  useEffect(() => {
    if (runningSearches && props.id) {
      const interval = setInterval(() => {
        dispatch(actions.getQueryGroupMonitorStatus(props.id)).then(
          (res: unknown) => {
            if (res["status"] == "Succeeded") {
              setRunningSearches(false);
              snackbar.success(`Finished Searching ${data.name}`);
              getData();
            }
            if (res["status"] == "Failed") {
              snackbar.error(`Failed Searching ${data.name}`);
            }
          }
        );
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [runningSearches]);

  useEffect(() => {
    if (location?.state?.["groupData"]?.id === props.id) {
      setOpen(true);
    }
  }, [location]);

  const checkSearchStatus = () => {
    if (props.id) {
      dispatch(actions.getQueryGroupMonitorStatus(props.id)).then(
        (res: unknown) => {
          if (res["status"] == "In Progress") {
            setRunningSearches(true);
          }
        }
      );
    }
  };

  const getData = async () => {
    const res = await BulkSearchService.getSearchItems(props.id);
    if (res) {
      setSearchTerms(res);
    }
    return res;
  };

  const handleClickEdit = () => {
    const filter: SearchFilter = {
      dataset_ids:
        data.dataset_filters.map((item: Partial<Dataset>) => item.id) || [],
      doc_types: data.doc_types || [],
      dataset_types: data.dataset_types || [],
      countries: data.countries || [],
      regions:
        regions
          .filter((region) => data?.regions.includes(region.value))
          .map(({ label }) => label) || [],
      languages: data.languages || [],
    };
    dispatch(filterActions.setFilter(filter));
    navigate("edit", {
      replace: false,
      state: { ...location?.state, groupData: props },
    });
  };

  const handleCancel = () => {
    // do nothing
  };

  const handleDelete = () => {
    dispatch(actions.delete(props.id));
  };

  const handleSearchAll = async () => {
    setRunningSearches(true);
    posthog.capture(HogEvent.QUERY_GROUP_SEARCH_RUN);
    let terms = searchTerms;
    if (terms == null && props.id) {
      terms = await getData();
    }
    await dispatch(actions.runQueryGroupSearch(props.id));
    snackbar.success(`Running search for terms in ${data.name}`);
    // setRunningSearches(false);
    // navigate('run', { replace: false, state: {data: terms, groupData: props, showFullList: showList, selected: index} });
  };

  const handleOpenFilterDrawer = () => {
    const filter: SearchFilter = {
      dataset_ids:
        data.dataset_filters.map((item: Partial<Dataset>) => item.id) || [],
      doc_types: data.docs || [],
      dataset_types: data.dataset_types || [],
      countries: data.countries || [],
      regions:
        regions
          .filter((region) => data?.regions.includes(region.value))
          .map(({ label }) => label) || [],
      languages: data.languages || [],
    };
    dispatch(filterActions.setFilter(filter));
    window.dispatchEvent(
      new CustomEvent("OpenDatasetFilterDrawer", {
        detail: { isMine: isMine, groupid: data.id },
      })
    );
  };

  const handleOpenView = () => {
    navigate("view", {
      replace: false,
      state: { ...location?.state, groupData: props },
    });
  };

  const DatasetFilter = () => {
    let filterCount = 0;
    if (data?.dataset_filters?.length)
      filterCount += data?.dataset_filters?.length;
    if (data?.docs?.length) filterCount += data?.docs?.length;
    if (data?.dataset_types?.length) filterCount += data?.dataset_types?.length;
    if (data?.countries?.length) filterCount += data?.countries?.length;
    if (data?.regions?.length) filterCount += data?.regions?.length;
    if (data?.languages?.length) filterCount += data?.languages?.length;

    return (
      <div style={{ width: "15%", overflow: "hidden", minWidth: "165px" }}>
        <StyledText1
          sx={{
            color: Colors.twilight,
            backgroundColor: "rgba(18, 41, 69, 0.08)",
            alignItems: "center",
            padding: "5px",
            borderRadius: "16px",
            cursor: "pointer",
          }}
          onClick={handleOpenFilterDrawer}
        >
          • {filterCount}{" "}
          {filterCount === 1 || filterCount == 0 ? "Filter" : "Filters"} Applied
          {isMine && (
            <StyledIcon
              src={require("src/assets/images/edit-fill.png")}
              alt="edit icon"
              style={{ width: 14, height: 14, marginLeft: 10 }}
            />
          )}
        </StyledText1>
      </div>
    );
  };

  return (
    <StyledContainer open={open}>
      <StyledRow open={open}>
        <StyledTitleContainer sx={{ minWidth: "165px" }}>
          <IconButton
            size="small"
            sx={{
              backgroundColor: "#CED3D9",
              width: 24,
              height: 24,
              marginRight: "22px",
              borderRadius: "4px",
              display: "none",
            }}
            onClick={handleOpenView}
          >
            <VisibilityIcon sx={{ fontSize: 16, color: Colors.twilight }} />
          </IconButton>
          <Box>
            <StyledTitle onClick={handleOpenView}>{data.name}</StyledTitle>
            <Box sx={{ height: 6 }} />
            <StyledLabel>
              {format(new Date(data.created), "MMM dd, yyyy")}
            </StyledLabel>
          </Box>
        </StyledTitleContainer>
        <LastResult lastResult={data.last_run} />
        <LatestResult />
        <DatasetFilter />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
            width: "12%",
            overflow: "hidden",
            minWidth: "100px",
          }}
        >
          <IconButton
            size="small"
            onClick={() => handleSearchAll()}
            sx={{ width: 24, height: 24 }}
          >
            <StyledIcon
              src={require("src/assets/images/run-circle-black.png")}
              className={runningSearches ? "rotate-image" : ""}
              alt="Run search"
            />
          </IconButton>
          {props.isMine && (
            <IconButton
              size="small"
              onClick={handleClickEdit}
              sx={{ width: 24, height: 24 }}
            >
              <StyledIcon
                src={require("src/assets/images/edit.png")}
                alt="edit icon"
              />
            </IconButton>
          )}
          {props.isMine && (
            <ConfirmDialog
              title="Delete Query Group"
              description="Are you sure you want to delete this query group? This action cannot be undone."
              onConfirm={handleDelete}
              onCancel={handleCancel}
              confirmText="Confirm"
              cancelText="Cancel"
            >
              <IconButton size="small" sx={{ width: 24, height: 24 }}>
                <StyledIcon
                  src={require("src/assets/images/delete.png")}
                  alt="delete icon"
                />
              </IconButton>
            </ConfirmDialog>
          )}
        </Box>
      </StyledRow>
    </StyledContainer>
  );
};

const LastResult = (props: { lastResult?: string | null }) => {
  const { lastResult } = props;
  lastResult && console.log(lastResult);

  return (
    <div style={{ width: "20%", overflow: "hidden", minWidth: "90px" }}>
      {/* {
                props.lastResult == null ? (
                    ''
                ) : (
                    <StyledText1>{moment(props.lastResult).format('MM/DD/YYYY')}</StyledText1>
                )
            } */}
    </div>
  );
};

const LatestResult = () => {
  return (
    <div style={{ width: "15%", overflow: "hidden", minWidth: "125px" }}>
      {/* {
                props.total_result == null ? (
                    <StyledText2>-</StyledText2>
                ) : (
                    <StyledText1 sx={{ color: Colors.textGray200, backgroundColor: 'transparent' }}>{props.total_result}{props.results_change ? <span style={{ color: Colors.secondary, backgroundColor: 'transparent' }}>+{props.results_change}</span> : ''}</StyledText1>
                )
            } */}
    </div>
  );
};

export default TRow;
