import { createSelector } from "reselect";
import { Props } from "./screeningReducers";

const selectRaw = (state: { bulk_search: { screeningList: Props } }) =>
  state.bulk_search.screeningList;

const selectLoading = createSelector([selectRaw], (raw) => raw.loading);

const selectSelectedScreeningList = createSelector(
  [selectRaw],
  (raw) => raw.selectedScreeningList
);

const selectScreeningList = createSelector(
  [selectRaw],
  (raw) => raw.screeningList
);

const selectUpdatedStatus = createSelector([selectRaw], (raw) => raw.updated);

const screeningSelectors = {
  selectRaw,
  selectLoading,
  selectSelectedScreeningList,
  selectScreeningList,
  selectUpdatedStatus,
};

export default screeningSelectors;
