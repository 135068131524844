import { fetchApi } from "../shared/api";

export default class Service {
  static async getAll() {
    try {
      const res = await fetchApi("dataset_metadata/groups_count", {
        method: "GET",
      });
      const response = await res.json();
      return response;
    } catch (err) {
      return false;
    }
  }

  static async moveItem(id: string, data: { sensitivity: string }) {
    try {
      const res = await fetchApi("dataset_metadata/" + id + "/", {
        method: "PUT",
        body: JSON.stringify(data),
      });
      const response = await res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        return {
          error: true,
          detail: response.error,
        };
      }
    } catch (err) {
      return false;
    }
  }
}
