import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  FetchNextPageOptions,
  FetchPreviousPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
  useInfiniteQuery,
  useQuery,
  UseQueryResult,
} from "@tanstack/react-query";
import { usePostHog } from "posthog-js/react";
import { isEqual, omitBy } from "lodash";
import {
  compressToEncodedURIComponent,
  decompressFromEncodedURIComponent,
} from "lz-string";

import { QueryFilters, QueryVariables } from "src/types/QueryVariables";
import {
  DataIndex,
  GetDocumentPreviewParams,
  GetDownloadAllDocumentsURLParams,
  GetDownloadDocumentURLParams,
  SearchResult,
} from "src/types/Search";
import { queryClient } from "src/index";
import { PreviewDocProps } from "src/modules/search/view/searchViewReducers";
import { HogEvent } from "src/types/PosthogEvents";

import { useFetch } from "../fetch";

export interface SearchContextValue {
  variables: QueryVariables;
  unappliedFilters: boolean;
  updateFilters: (filters: Partial<QueryFilters>) => void;
  resetFilters: () => void;
  updateContent: (content: string) => void;
  updateVariables: (variables: QueryVariables) => void;
  resetVariables: () => void;
  handleSearch: (directPayload?: QueryVariables) => void;
  data: InfiniteData<SearchResult>;
  isFetching: boolean;
  status: unknown;
  error: unknown;
  fetchNextPage: (
    options?: FetchNextPageOptions
  ) => Promise<InfiniteQueryObserverResult<SearchResult, unknown>>;
  fetchPreviousPage: (
    options?: FetchPreviousPageOptions
  ) => Promise<InfiniteQueryObserverResult<SearchResult, unknown>>;
  hasNextPage: boolean;
  activePageIndex: number;
  setActivePageIndex: React.Dispatch<React.SetStateAction<number>>;
  getDownloadDocumentUrl: (
    params: GetDownloadDocumentURLParams
  ) => UseQueryResult<string, unknown>;
  getDownloadAllDocumentsUrl: (
    params: GetDownloadAllDocumentsURLParams
  ) => UseQueryResult<string, unknown>;
  getDocumentPreview: (params: GetDocumentPreviewParams) => unknown;
  previewData: any | null;
  setPreviewData: React.Dispatch<React.SetStateAction<any | null>>;
}

export const SearchContext = createContext<SearchContextValue>(null);

export const emptyQueryVariables: QueryVariables = {
  content: "",
  filters: {
    dataset_ids: [],
    exclude_dataset_ids: [],
    data_collection_ids: [],
    exclude_data_collection_ids: [],
    doc_types: [],
    dataset_types: [],
    countries: [],
    regions: [],
    languages: [],
  },
};

const placeholderSearchResultData: InfiniteData<SearchResult> = {
  pages: [],
  pageParams: [],
};

const SearchProvider = ({ children }: { children: React.ReactNode }) => {
  const posthog = usePostHog();
  const location = useLocation();
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [searchParams] = useSearchParams();
  const [variables, setVariables] = useState<QueryVariables>(
    parseVariablesFromSearchParams(searchParams)
  );

  const [activePageIndex, setActivePageIndex] = useState<number>(0);
  const [previewData, setPreviewData] = useState(null);
  const [enableSearch, setEnableSearch] = useState(false);

  const unappliedFilters: boolean = useMemo(() => {
    return !isEqual(
      parseVariablesFromSearchParams(searchParams).filters,
      variables.filters
    );
  }, [searchParams, variables]);

  const { postApi, getApi } = useFetch();
  const memoAbortController: AbortController = useMemo(
    () => new AbortController(),
    [variables]
  );

  const searchFn = useCallback(
    async ({ pageParam = 1 }) => {
      const { content, filters } = parseVariablesFromSearchParams(searchParams);
      const body = {
        content,
        page: pageParam,
        ...omitBy(filters, (f) => f.length === 0),
      };

      const res = await postApi("search", {
        body: JSON.stringify(body),
        signal: memoAbortController.signal,
      });
      return await res.json();
    },
    [searchParams]
  );

  const {
    status,
    data,
    error,
    isFetching,
    isFetched,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
  } = useInfiniteQuery<SearchResult>({
    enabled: enableSearch,
    queryFn: searchFn,
    queryKey: ["search", searchParams.get("q")],
    getNextPageParam: (lastPage) => lastPage.next_page_number,
    placeholderData: placeholderSearchResultData,
  });

  useEffect(() => {
    // clear store when leaving search page
    if (!location.pathname.includes("/search")) {
      setEnableSearch(false);
      resetVariables();
      abortRequest();
    } else {
      const parsedVariables = parseVariablesFromSearchParams(searchParams);

      document.title = `Search for '${
        parsedVariables.content.length > 50
          ? parsedVariables.content.slice(0, 50) + "..."
          : parsedVariables.content
      }'`;

      if (!isEqual(variables, parsedVariables)) {
        setVariables(parsedVariables);
      }

      if (!isFetched) {
        setEnableSearch(true);
      }
    }
  }, [location.pathname, searchParams]);

  const updateFilters = (filters: Partial<QueryFilters>) => {
    setVariables((v) => ({
      ...v,
      filters: {
        ...v.filters,
        ...filters,
      },
    }));
  };

  const resetFilters = () => {
    setVariables((v) => ({ ...v, filters: emptyQueryVariables.filters }));
  };

  const updateContent = (content: string) => {
    setVariables((v) => ({
      ...v,
      content,
    }));
  };

  const updateVariables = (variables: QueryVariables) => {
    setVariables((v) => ({
      ...v,
      filters: { ...v.filters, ...variables.filters },
      content: variables.content ?? v.content,
    }));
  };

  const resetVariables = () => {
    setVariables(emptyQueryVariables);
  };

  const handleSearch = useCallback(
    (directPayload: QueryVariables) => {
      posthog.capture(HogEvent.SEARCH_PERFORMED);
      if (
        !directPayload ||
        directPayload.content ||
        Object.values(directPayload.filters).some((filter) => filter.length)
      ) {
        setEnableSearch(true);
      }

      if (directPayload) {
        setVariables(directPayload);
      }

      if (activePageIndex > 0) {
        setActivePageIndex(0);
      }

      navigate(
        `/search?q=${formatVariablesSearchParam(directPayload || variables)}`
      );
    },
    [variables, location.pathname, searchParams, activePageIndex]
  );

  const getDownloadDocumentUrlFn = async ({
    id,
    dataIndex = DataIndex.source,
    entireFile = false,
  }: GetDownloadDocumentURLParams) => {
    const docType = dataIndex === DataIndex.source ? "source" : "modeled";
    const path = ["document", "download", docType, id];
    // Construct the query parameters separately
    const searchParams = entireFile ? `entire_file=true` : "";
    const { url } = await getApi(path, searchParams);
    return url;
  };

  const getDownloadDocumentUrl = (
    params: GetDownloadDocumentURLParams | null
  ) =>
    useQuery({
      enabled: !!params,
      queryFn: () => getDownloadDocumentUrlFn(params),
      queryKey: ["getDownloadDocumentUrl", params?.id],
    });

  const getDownloadAllDocumentsUrlFn = async (
    params: GetDownloadAllDocumentsURLParams
  ) => {
    const body = JSON.stringify(params);
    const path = ["document", "bulk_download"];
    const res = await postApi(path, { body });
    const { url } = await res.json();
    return url;
  };

  const getDownloadAllDocumentsUrl = (
    params: GetDownloadAllDocumentsURLParams
  ) =>
    useQuery({
      enabled: params.doc_ids.length > 0,
      queryFn: () => getDownloadAllDocumentsUrlFn(params),
      queryKey: ["getDowloadAllDocumentsUrl", params],
    });

  const getDocumentPreview = async ({
    id,
    dataIndex = DataIndex.source,
    range = 5,
  }: GetDocumentPreviewParams) => {
    let path = [];
    if (dataIndex === DataIndex.source) {
      path = ["document", "range", id, range.toString()];
    } else {
      const q = `?id=${id}`;
      path = ["modeled_path", "preview", q];
    }
    const data = await getApi(path);
    return data;
  };

  const abortRequest = () => {
    memoAbortController.abort("Leaving view");
    queryClient.cancelQueries({
      predicate: (query) => query.queryKey[0] === "search",
    });
    resetVariables();
  };

  return (
    <SearchContext.Provider
      value={{
        variables,
        unappliedFilters,
        updateFilters,
        resetFilters,
        updateContent,
        updateVariables,
        resetVariables,
        handleSearch,
        status,
        data,
        error,
        isFetching,
        fetchNextPage,
        fetchPreviousPage,
        hasNextPage,
        activePageIndex,
        setActivePageIndex,
        getDownloadDocumentUrl,
        getDownloadAllDocumentsUrl,
        getDocumentPreview,
        previewData,
        setPreviewData,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

SearchProvider.displayName = "SearchProvider";

export default SearchProvider;

export function parseVariablesFromSearchParams(searchParams: URLSearchParams) {
  const queryString = searchParams.get("q");
  if (queryString) {
    try {
      return JSON.parse(
        decompressFromEncodedURIComponent(queryString)
      ) as QueryVariables;
    } catch {
      return emptyQueryVariables;
    }
  }
  return emptyQueryVariables;
}
export function parsePreviewFromSearchParams(searchParams: URLSearchParams) {
  const queryString = searchParams.get("preview");
  if (queryString) {
    try {
      return JSON.parse(decompressFromEncodedURIComponent(queryString)) as any;
    } catch {
      return null;
    }
  }
  return null;
}

export function formatVariablesSearchParam(variables: QueryVariables) {
  return compressToEncodedURIComponent(JSON.stringify(variables));
}

export function formatPreviewSearchParam(previewMetadata: PreviewDocProps) {
  return previewMetadata
    ? compressToEncodedURIComponent(JSON.stringify(previewMetadata))
    : null;
}

SearchProvider.displayName = "SearchProvider";
