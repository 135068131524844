import { Box } from "@mui/material";
import { PreviewDocListProps } from "src/modules/search/view/searchViewReducers";
import {
  StyledContainer,
  renderContent,
} from "src/view/search-result/components/Preview/Source/SourcePreviews";
import { EMLView } from "../../../SearchResultCard/SearchResultCardPreview/EMLView";

interface Props {
  data: PreviewDocListProps | unknown[];
  loading: boolean;
  handleClosePreview: (shouldSetURLParam?: boolean) => void;
}

const EMLPreview = ({ data, loading, handleClosePreview }: Props) => {
  return (
    <StyledContainer>
      {renderContent(
        data,
        loading,
        <Box
          sx={{
            width: "calc(100% - 70px)",
            height: "calc(100vh - 350px)",
            overflowY: "auto",
            display: "block",
            ml: "43px",
            mr: "36px",
            padding: 1,
          }}
          aria-label="simple table"
        >
          <EMLView
            data={typeof data === "string" ? JSON.parse(data) : data}
            handleClosePreview={handleClosePreview}
          />
        </Box>
      )}
    </StyledContainer>
  );
};

export default EMLPreview;
