import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { QueryGroupItem } from "src/types/BulkSearch";
import { Colors } from "src/view/constants/colors";
import TRow from "./Row";

const StyledHeader = styled("div")(() => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
}));

const StyledTHD = styled("div")(({ theme, ...props }) => ({
  display: "flex",
  justifyContent: "flex-start",
  padding: theme.spacing(1, 2, 1, 1),
  fontSize: 12,
  lineHeight: "14px",
  fontWeight: "700",
  color: Colors.textGray600,
  ...props,
}));

const StyledLoadingContainer = styled("div")(() => ({
  width: "calc(100% - 80px)",
  position: "absolute",
  top: 0,
  height: "100%",
  // minHeight: 200,
  backgroundColor: "#FFFFFF88",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledText = styled("div")(() => ({
  fontSize: 12,
  lineHeight: "20px",
  fontWeight: "700",
  color: Colors.textDark,
}));

interface Props {
  data?: QueryGroupItem[];
  loading?: boolean;
  hasSharedGroup?: boolean;
  isMine?: boolean;
}

const QueryGroupListTable = (props: Props) => {
  return (
    <Box
      sx={{
        marginTop: 4,
        position: "relative",
        width: "100%",
        overflowX: "auto",
        ml: "-35px",
        mr: "-23px",
        px: "40px",
        pb: "40px",
      }}
    >
      <StyledHeader>
        <StyledTHD style={{ width: "35%", minWidth: "165px" }}>Title</StyledTHD>
        <StyledTHD style={{ width: "20%", minWidth: "65px" }}>
          Last Result
        </StyledTHD>
        <StyledTHD style={{ width: "15%", minWidth: "100px" }}>
          Latest result
        </StyledTHD>
        <StyledTHD style={{ width: "15%", minWidth: "165px" }}>
          Dataset Filter
        </StyledTHD>
        <StyledTHD
          style={{
            width: "12%",
            justifyContent: "flex-end",
            minWidth: "100px",
          }}
        >
          Action
        </StyledTHD>
      </StyledHeader>
      <Box sx={{ width: "100%" }}>
        {props.data?.map((item: QueryGroupItem, index: number) => {
          return (
            <TRow
              key={index}
              id={item.id}
              title={item.name}
              createdAt={item.created}
              lastResult={item.last_run}
              data={item}
              hasSharedGroup={props.hasSharedGroup}
              isMine={props.isMine}
            ></TRow>
          );
        })}
      </Box>
      {props.loading && (
        <StyledLoadingContainer sx={{}}>
          <CircularProgress />
        </StyledLoadingContainer>
      )}
      {props.loading == false && props.data?.length == 0 && (
        <StyledLoadingContainer>
          <StyledText>No results</StyledText>
        </StyledLoadingContainer>
      )}
    </Box>
  );
};

export default QueryGroupListTable;
