import DataDetail from "./components/detail";
import { styled } from "@mui/material/styles";
import SearchHeader from "src/view/layout/SearchHeader";

const RootContainer = styled("div")(() => ({
  position: "relative",
}));

const Detail = () => {
  return (
    <RootContainer>
      <SearchHeader />
      <DataDetail />
    </RootContainer>
  );
};

export default Detail;
