import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import UserContext, { UserContextInterface } from "./UserContext";
import { bootUserGuiding } from "src/utils/userguiding";
import { useFetch } from "src/modules/api/fetch";
import { useQuery } from "@tanstack/react-query";
import { usePostHog } from "posthog-js/react";
export interface UserProviderProps {
  context?: React.Context<UserContextInterface | null>;
  children?: React.ReactNode;
}

const UserProvider = ({
  context: Context = UserContext,
  children,
}: UserProviderProps) => {
  const { getApi } = useFetch();
  const posthog = usePostHog();

  const {
    logout: auth0Logout,
    getAccessTokenSilently,
    isAuthenticated,
  } = useAuth0();

  // remove this after refactoring fetchApi so that tokens aren't stored in localStorage
  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        localStorage.setItem("jwt", token);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [getAccessTokenSilently]);

  const getUserFn = async () => {
    const data = await getApi(["users", "permissions"]);
    return data;
  };

  const { data: user } = useQuery({
    enabled: isAuthenticated,
    queryFn: getUserFn,
    queryKey: ["user"],
  });

  useEffect(() => {
    if (user) {
      posthog.identify(user.email);
    }

    if (user && !window["userGuiding"]) {
      bootUserGuiding();
      window["userGuiding"].identify(user.email);
    }
  }, [user]);

  const logout = () => {
    localStorage.removeItem("jwt"); // remove this after refactoring fetchApi
    posthog.reset();
    auth0Logout({
      logoutParams: {
        returnTo: process.env.REACT_APP_AUTH0_LOGIN_URL,
      },
    });
  };

  return (
    <Context.Provider
      value={{
        user,
        logout,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default UserProvider;
