import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UserProvider, Auth0ProviderWithNavigate } from "./modules/api/auth";
import { ThemeProvider } from "@mui/material";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import {
  QueryClient,
  QueryClientProvider,
  QueryCache,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { store } from "./modules/store";
import theme from "./theme";
import { LicenseInfo } from "@mui/x-license-pro";
import { FilterOptionsProvider } from "./modules/api/filters";
import { SearchProvider } from "./modules/api/search";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE);

Sentry.init({
  dsn: "https://bad9d42a03784c0eb195ccef23b8dec7@o295592.ingest.sentry.io/4504475855421440",
  integrations: [new BrowserTracing({ tracePropagationTargets: ["*"] })],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // Might need to adjust this value in production
  tracesSampleRate: 0,
});

posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST || "app.posthog.com",
  autocapture: false, // disable autocapture
  sanitize_properties: function (properties) {
    if (properties["$current_url"]) {
      properties["$current_url"] = properties["$current_url"].split("?q=")[0]; //remove lz-string compressed search parameters
    }
    return properties;
  },
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      console.error("queryCache error", error);
    },
  }),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <BrowserRouter>
        <Auth0ProviderWithNavigate>
          <QueryClientProvider client={queryClient}>
            <UserProvider>
              <FilterOptionsProvider>
                <SearchProvider>
                  <Provider store={store}>
                    <ThemeProvider theme={theme}>
                      <App />
                    </ThemeProvider>
                    <ReactQueryDevtools initialIsOpen={false} />
                  </Provider>
                </SearchProvider>
              </FilterOptionsProvider>
            </UserProvider>
          </QueryClientProvider>
        </Auth0ProviderWithNavigate>
      </BrowserRouter>
    </PostHogProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
