import React from "react";
import { KnowledgeWiki } from "src/types/KnowledgeWiki";
import { Box, Divider, Typography } from "@mui/material";
import theme from "src/theme";
import { styled } from "@mui/material/styles";

const StyledDetailContainer = styled(Box)(({ theme }) => ({
  boxSizing: "border-box",
  backgroundColor: "white",
  border: "1px solid",
  borderColor: theme.palette.grey[400],
  borderRadius: "0px 4px 4px 4px",
  padding: "12px",
  width: "100%",
}));

const StyledDetailInnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  boxSizing: "border-box",
  border: "1px solid",
  borderColor: theme.palette.grey[400],
  borderRadius: "4px",
  padding: "6px",
  width: "100%",
  gap: "4px",
}));

const StyledDetailSection = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "6px",
  marginTop: "-3px",
  boxSizing: "border-box",
  height: "fit-content",
  "& p": {
    color: theme.palette.primary.main,
    fontSize: "12px",
    fontWeight: 600,
    width: "100%",
  },
}));

const StyledPropertyTag = styled(Box)(({ theme }) => ({
  color: theme.palette.grey[800],
  backgroundColor: theme.palette.grey[200],
  fontWeight: "500",
  lineHeight: "10px",
  fontSize: "10px",
  padding: "6px",
  width: "fit-content",
  borderRadius: "4px",
}));

interface Props {
  dataset: KnowledgeWiki;
}

const PropertyTags = ({ dataset }: Props) => {
  if (!dataset) return null;

  return (
    <StyledDetailContainer
      sx={{
        mb: 7,
      }}
    >
      <StyledDetailInnerContainer>
        {!!dataset?.dataset_properties?.length && (
          <>
            <StyledDetailSection>
              <Typography>Found in this dataset:</Typography>
              {dataset.dataset_properties.map(
                (item: string | number, index: number) => {
                  return (
                    <StyledPropertyTag key={`properties-${index}`}>
                      {item}
                    </StyledPropertyTag>
                  );
                }
              )}
            </StyledDetailSection>
            <Divider
              orientation="vertical"
              color={theme.palette.grey[400]}
              flexItem
              sx={{ mx: "12px" }}
            />
          </>
        )}

        <StyledDetailSection>
          <Typography>Type</Typography>
          <StyledPropertyTag>{dataset.dataset_type}</StyledPropertyTag>
        </StyledDetailSection>
        <Divider
          orientation="vertical"
          color={theme.palette.grey[400]}
          flexItem
          sx={{ mx: "12px" }}
        />

        <StyledDetailSection>
          <Typography>Regions</Typography>
          {dataset?.regions?.map((item: string | number, index: number) => {
            return (
              <StyledPropertyTag key={`regions-${index}`}>
                {item}
              </StyledPropertyTag>
            );
          })}
        </StyledDetailSection>
        <Divider
          orientation="vertical"
          color={theme.palette.grey[400]}
          flexItem
          sx={{ mx: "12px" }}
        />

        <StyledDetailSection>
          <Typography>Languages</Typography>
          {dataset?.languages?.map((item: string | number, index: number) => {
            return (
              <StyledPropertyTag key={`languages-${index}`}>
                {item}
              </StyledPropertyTag>
            );
          })}
        </StyledDetailSection>
        <Divider
          orientation="vertical"
          color={theme.palette.grey[400]}
          flexItem
          sx={{ mx: "12px" }}
        />

        <StyledDetailSection>
          <Typography>Sensitivity</Typography>
          <StyledPropertyTag>{dataset.sensitivity}</StyledPropertyTag>
        </StyledDetailSection>
      </StyledDetailInnerContainer>
    </StyledDetailContainer>
  );
};

export default PropertyTags;
