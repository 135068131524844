import { useState } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  styled,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { ReactComponent as IconDelete } from "src/assets/icons/delete.svg";
import { ReactComponent as IconReindex } from "src/assets/icons/retry.svg";
import { Dataset } from "src/types/Dataset";
import ConfirmDialog from "src/view/components/ConfirmDialog";
import { useDatasetMetadata } from "src/modules/api/datasetMetadata";
import { snackbar } from "src/view/toaster";
import Tasks from "./Tasks";
import DropdownArrow from "./DropdownArrow";

const StyledIconButton = styled(IconButton)(() => ({
  "&:hover": {
    backgroundColor: "transparent",
  },
  "& > *": {
    fontSize: "medium",
    color: "#122945",
    backgroundColor: "transparent",
  },
  margin: "0 8px",
  padding: 0,
}));

const DatasetRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== "isOpen",
})<{ isOpen?: boolean }>(({ isOpen, theme }) => ({
  boxShadow: isOpen //
    ? "0px 10px 54px 0px rgba(160, 185, 208, 0.30)"
    : "none",
  "&:hover": {
    backgroundColor: isOpen //
      ? "#FFFFFF"
      : theme.palette.grey[200],
  },

  "& > td": {
    backgroundColor: "transparent",
    color: theme.palette.grey[800],
    padding: "12px 4px",
    border: "unset",

    "&:first-of-type": {
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
      paddingLeft: "18px",
    },
    "&:last-child": {
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      paddingRight: "18px",
    },
  },
}));

const TaskDetailRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== "isOpen",
})<{ isOpen?: boolean }>(({ isOpen }) => ({
  backgroundColor: "#FFFFFF",
  boxShadow: isOpen //
    ? "0px 3px 20px 0px rgba(160, 185, 208, 0.30)"
    : "none",

  "& > td": {
    // this boxshadow kills boxshadow that falls to the top before the table
    boxShadow: isOpen //
      ? "0px -16px 0 white"
      : "none",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    paddingBottom: 0,
    paddingTop: 0,
  },
}));

interface Props {
  dataset: Dataset;
}

const DatasetItem = ({ dataset }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { deleteDatasetMetadata, reindexDataset } = useDatasetMetadata();
  const { mutate: reindex, isLoading: isReindexLoading } = reindexDataset();

  const handleDeleteDataset = () => {
    deleteDatasetMetadata.mutate(
      { id: dataset.id },
      {
        onSuccess: () => {
          snackbar.success("Dataset successfully deleted");
        },
        onError: () => {
          snackbar.error("Failed to delete dataset");
        },
      }
    );
  };

  const handleReindexDataset = () => {
    reindex(
      { dataset_id: dataset.id },
      {
        onSuccess: () => {
          snackbar.success("Reindex request successfully created");
          setIsOpen(false);
        },
        onError: () => {
          snackbar.error("Failed to create reindex request");
        },
      }
    );
  };

  return (
    <>
      <DatasetRow isOpen={isOpen} key={dataset.id}>
        <TableCell>
          <DropdownArrow isOpen={isOpen} setIsOpen={setIsOpen} />
        </TableCell>
        <TableCell>
          <Typography sx={{ fontSize: "16px", color: "#122945 !important" }}>
            {dataset.name}
          </Typography>
        </TableCell>
        <TableCell>
          {dataset.doc_types.join(", ").toUpperCase() || "---"}
        </TableCell>
        <TableCell>{dataset.sensitivity}</TableCell>
        <TableCell sx={{ overflow: "visible !important" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {isReindexLoading && (
              <Box sx={{ display: "inline-flex", padding: "0 16px 0 0" }}>
                <CircularProgress size={12} sx={{ padding: 0 }} />
              </Box>
            )}

            <ConfirmDialog
              title="Reindex Request"
              description={`Are you sure you want to reindex "${dataset.name}"?`}
              onConfirm={handleReindexDataset}
              confirmText="Confirm"
              cancelText="Cancel"
            >
              {!isReindexLoading && (
                <StyledIconButton disableRipple>
                  <IconReindex />
                </StyledIconButton>
              )}
            </ConfirmDialog>

            <ConfirmDialog
              title="Delete Dataset"
              description={`Are you sure you want to delete "${dataset.name}"?`}
              onConfirm={handleDeleteDataset}
              confirmText="Confirm"
              cancelText="Cancel"
            >
              <StyledIconButton disableRipple>
                <IconDelete />
              </StyledIconButton>
            </ConfirmDialog>
          </Box>
        </TableCell>
      </DatasetRow>

      <TaskDetailRow isOpen={isOpen}>
        <TableCell colSpan={5}>
          {isOpen && <Tasks dataset={dataset} close={() => setIsOpen(false)} />}
        </TableCell>
      </TaskDetailRow>
    </>
  );
};

export default DatasetItem;
