export class SearchResultModel {
  data?: unknown;
  dataset_id?: string;
  highlight: unknown;
  constructor(_data: unknown) {
    Object.assign(this, _data);
    try {
      this.highlight = _data["highlight"];
      if (_data["highlight"]["content"]) {
        let source = _data["_source"].content;
        const list = _data["highlight"].content;
        for (let i = 0; i < list.length; i++) {
          const item = list[i];
          const replace = item.replace(/<\/em>/gi, "");
          source = source.replace(replace, item);
        }
        this.data = JSON.parse(source);
      } else {
        this.data = JSON.parse(_data["_source"].content);
      }
    } catch (e) {
      if (_data["highlight"] && _data["highlight"]["content"]) {
        let source = _data["_source"].content;
        const list = _data["highlight"].content;
        for (let i = 0; i < list.length; i++) {
          const item = list[i];
          const replace = item.replace(/<\/em>/gi, "");
          source = source.replace(replace, item);
        }
        this.data = source;
      } else {
        this.data = _data["_source"].content;
      }
    }
    this.dataset_id = _data["_source"].dataset_id;
  }
}
