import { fetchApi } from "../shared/api";
import { stringify } from "querystring";

export default class SearchService {
  static async find(query: object) {
    try {
      const response = await fetchApi("search/", {
        method: "POST",
        body: JSON.stringify(query),
      });
      if (response.status == 500) {
        return [];
      }
      const res = await response.json();
      if (res.detail) return [];
      return res;
    } catch (err) {
      console.log(err);
      return [];
    }
  }
  static async getDataset(id: string) {
    try {
      const response = await fetchApi("data_collection/" + id + "/", {
        method: "GET",
      });
      if (response.status == 500) {
        return [];
      }
      const res = await response.json();
      if (res.detail) return [];
      return res;
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  static async getDownloadDocLink(id: string, type = 1, entireFile = false) {
    try {
      let endpoint;
      const params = { ...(entireFile && { entire_file: true }) };

      // TODO: refactor magic number
      if (type === 1) {
        endpoint = `document/download/source/${id}?` + stringify(params);
      } else {
        endpoint = `document/download/modeled/${id}/`;
      }

      const response = await fetchApi(endpoint, {
        method: "GET",
      }).then((res) => res.json());

      return response;
    } catch (e) {
      return null;
    }
  }

  static async getDownloadDocAllLink(data: { doc_ids: string[] }) {
    try {
      const res = await fetchApi("document/bulk_download/", {
        method: "POST",
        body: JSON.stringify(data),
      });
      if (res.status == 500) {
        return [];
      }
      const response = await res.json();
      return response;
    } catch (e) {
      return null;
    }
  }

  static async getDocumentPreview(doc_id: string, type = 1, range = 5) {
    try {
      let endpoint = "document/range/" + doc_id + "/" + range + "/";
      if (type === 0) {
        endpoint = "modeled_data/preview/?id=" + doc_id;
      }
      const res = await fetchApi(endpoint, {
        method: "GET",
      });
      const response = res.json();
      if (res.status >= 200 && res.status < 300) {
        return response;
      } else {
        if (response["detail"] || res.status == 500) return null;
      }
      return response;
    } catch (err) {
      return false;
    }
  }
}
