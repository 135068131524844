import { Box, Button, Drawer, InputBase, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "src/view/constants/colors";
import {
  useRef,
  useState,
  useEffect,
  CSSProperties,
  FormEvent,
  useCallback,
  useMemo,
} from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import InputBox from "./InputBox";
import StyledButton from "./StyledButton";
import SaveIcon from "@mui/icons-material/Save";
import CustomizedTimeline from "./Connector";
import QueryGroupBox from "./QueryGroupBox";
import selector from "src/modules/search/filter/searchFilterSelectors";
import { useSelector } from "react-redux";
import FilterListIcon from "@mui/icons-material/FilterList";
import Filters from "src/view/search-result/components/Filters";
import { Tabs } from "./SearchTabs";
import { QueryItem } from "src/types/BulkSearch";
import { Cancel } from "@mui/icons-material";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  DraggingStyle,
  NotDraggingStyle,
} from "@hello-pangea/dnd";
import FormattedTextInput from "./FormattedTextInput";
import EntityBox from "./EntityBox";
import { useClickAway } from "src/modules/shared/hooks";
import { getFilterCount } from "src/modules/shared/search";
import { useSearch } from "src/modules/api/search";
import { getSampleSearchFromLocationKey, replaceQuotes } from "src/utils";
import { useLocation } from "react-router-dom";

const drawerWidth = 370;
const StyledContainer = styled("div")(() => ({
  borderRadius: 0,
  width: "100%",
}));

const Search = styled("form")(({ theme }) => ({
  position: "relative",
  borderRadius: "0px 4px 4px 4px",
  border: "1px solid #A0B9D0",
  backgroundColor: "white",
  marginTop: -1,
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  height: 94,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
  [theme.breakpoints.down("sm")]: {
    marginRight: 0,
    marginLeft: 0,
    height: 70,
  },
}));

const QueryBuilder = styled(Paper)(({ theme }) => ({
  position: "relative",
  borderRadius: "0 0 4px 4px",
  border: "1px solid #A0B9D0",
  backgroundColor: "white",
  marginTop: -1,
  marginRight: 0,
  marginLeft: 0,
  width: "100%",
  height: "auto",
  display: "block",
  alignItems: "flex-start",
  padding: theme.spacing(2, 3, 2, 3),
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const ActionContainer = styled("div")(() => ({
  height: "calc(100% - 28px)",
  margin: 14,
  // width: 140,a
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const SearchIconWrapper = styled(Button)(() => ({
  height: "100%",
  width: 66,
  backgroundColor: Colors.secondary,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  borderRadius: 4,
  "&:hover": {
    backgroundColor: Colors.secondary,
  },
  "&:focus-visible": {
    backgroundColor: Colors.secondary,
  },
  "&:disabled": {
    opacity: 0.5,
  },
}));

const FilterButtonBox = styled(Box)(() => ({
  height: 40,
  width: "max-content",
  backgroundColor: "#F0F1F3",
  border: "1px solid #9BA5B1",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  borderRadius: 80,
  color: Colors.textDark100,
  fontSize: 16,
  lineHeight: "20px",
  fontWeight: "700",
}));

const FilterButton = styled(Button)(() => ({
  height: 40,
  textTransform: "none",
  cursor: "pointer",
  color: Colors.textDark100,
  fontSize: 16,
  fontWeight: 700,
  "&:hover": {
    backgroundColor: "unset",
  },
}));

const InputWrapper = styled("div")(({ theme }) => ({
  color: "inherit",
  width: "calc(100% - 154px)",
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    width: "calc(100% - 100px)",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    color: "transparent",
    writingMode: "horizontal-tb !important",
    textRendering: "auto",
    letterSpacing: "normal",
    wordSpacing: "normal",
    textTransform: "none",
    textIndent: "0px",
    textShadow: "none",
    textAlign: "start",
    appearance: "auto",
    whiteSpace: "nowrap",
    columnCount: "initial",
    "&:focus-visible": {
      outlineOffset: 0,
    },
    "&:focus": {
      caretColor: "black",
    },
    "&::placeholder": {
      color: "#999",
      opacity: 1,
    },
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiInputBase-input": {
      padding: theme.spacing(0, 0, 0, 0),
      paddingLeft: `calc(1em + ${theme.spacing(2)})`,
    },
  },
}));

const StyledSearchDropdown = styled("div")(() => ({
  position: "absolute",
  minWidth: 500,
  marginLeft: "24px",
  zIndex: 1,
}));

const VerticalLineEle = styled("div")(() => ({
  position: "relative",
  top: "-15px",
  width: "31px",
  height: "100%",
  borderRight: "1.5px solid #043D5D",
}));

const CrossLineEle = styled("div")(() => ({
  position: "relative",
  top: "-15px",
  left: 31,
  width: 40,
  height: "100%",
  borderLeft: "1.5px solid #043D5D",
  borderBottom: "1.5px solid #043D5D",
}));

const StyledFilterBubble = styled("div")(() => ({
  height: 24,
  background: "#F75151",
  borderRadius: 34,
  display: "flex",
  alignItems: "center",
  fontSize: 13,
  fontWeight: 700,
  color: "#FFFFFF",
  padding: "0 4px 0 8px",
  marginLeft: 10,
  marginRight: 10,
}));
const StyledImage = styled("img")(() => ({
  width: 15,
  height: 17,
  paddingRight: 10,
  cursor: "grab",
  "&:active": {
    cursor: "grabbing",
  },
}));

export default function SearchBox({ tabs }: { tabs: Tabs }) {
  const { updateContent, handleSearch, resetFilters } = useSearch();
  const location = useLocation();
  const wrapperRef = useRef(null);
  const [onSearch, setOnSearch] = useState(false);
  const inputRef = useRef(null);
  const [searchValue, setSearchValue] = useState("");
  const [openSearchDropdown, setOpenSearchDropdown] = useState("freeText"); // '' | default | freeText | entity
  const [queryB, setQueryB] = useState<QueryItem[]>([
    {
      type: "term", // operator | term | group
      value: "",
      fuzziness: 0,
    },
  ]);
  useClickAway(wrapperRef, () => setOpenSearchDropdown(""));

  const exampleSearch = useMemo(
    () => getSampleSearchFromLocationKey(location.key),
    []
  );
  const [openFilter, setOpenFilter] = useState(false);
  const sm = useMediaQuery("(max-width:600px)");
  const [appliedCount, setAppliedCount] = useState(0);
  const filter = useSelector(selector.selectFilter);

  useEffect(() => {
    if (filter) {
      const filterCount = getFilterCount(filter);
      setAppliedCount(filterCount);
    } else {
      setAppliedCount(0);
    }
  }, [filter]);

  useEffect(() => {
    if (tabs.freeText) {
      setOpenSearchDropdown("freeText");
    } else if (tabs.entity) {
      setOpenSearchDropdown("entity");
    } else {
      setOpenSearchDropdown("");
    }
  }, [tabs]);

  const handleBlurInput = () => {
    if (searchValue === "") {
      setOnSearch(false);
    }
  };

  const handleChangeQueryItem = (_q: QueryItem, index: number) => {
    const _query = [...queryB];
    if (!_q) {
      if (index === 0) {
        if (!queryB.length) {
          _query.splice(index, 1);
        } else {
          _query.splice(index + 1, 1);
          _query.splice(index, 1);
        }
      } else {
        _query.splice(index, 1);
        _query.splice(index - 1, 1);
      }
    } else {
      _query[index] = _q;
    }
    if (_query.length < 1) return;
    setQueryB(_query);
  };

  const onAddQuery = () => {
    const operatorQuery = {
      type: "operator",
      value: "AND",
    };
    const termQuery = {
      type: "term",
      value: "",
      fuzziness: 0,
    };
    let newArr = [...queryB, operatorQuery, termQuery];
    if (!queryB.length) {
      newArr = [...queryB, termQuery];
    }
    setQueryB(newArr);
  };

  const onAddGroup = () => {
    const operatorQuery = {
      type: "operator",
      value: "AND",
    };
    const groupQuery = {
      type: "group",
      form: [
        {
          type: "term",
          value: "",
          fuzziness: 0,
        },
        {
          type: "operator",
          value: "OR",
        },
        {
          type: "term",
          value: "",
          fuzziness: 0,
        },
      ],
    };
    const newArr = [...queryB, operatorQuery, groupQuery];
    if (queryB.length > 0) {
      if (queryB.length == 1 && queryB[0].value == "") {
        setQueryB([groupQuery]);
      } else {
        setQueryB(newArr);
      }
    } else {
      setQueryB([groupQuery]);
    }
  };

  const onAddEntity = () => {
    const operatorQuery = {
      type: "operator",
      value: "AND",
    };
    const entityQuery = {
      type: "entity",
      value: "",
      fuzziness: 0,
      schema: "",
      property: "",
    };
    const newArr = [...queryB, operatorQuery, entityQuery];
    if (queryB.length > 0) {
      if (queryB.length == 1 && queryB[0].value == "") {
        setQueryB([entityQuery]);
      } else {
        setQueryB(newArr);
      }
    } else {
      setQueryB([entityQuery]);
    }
  };

  const handleSaveQuery = () => {
    let _searchValue = "";
    queryB.map((q: QueryItem) => {
      if (q.type === "group") {
        _searchValue += " (";
        q.form.map((qq: QueryItem) => {
          if (qq.type === "operator") {
            _searchValue += ` ${qq.value} `;
          } else {
            if (qq.fuzziness != 0) {
              _searchValue += `"${qq.value}"~${qq.fuzziness}`;
            } else _searchValue += `"${qq.value}"`;
          }
        });
        _searchValue += ")";
      } else {
        if (q.type === "operator") {
          _searchValue += ` ${q.value}`;
        } else if (q.type === "term") {
          if (q.fuzziness != 0) {
            _searchValue += ` "${q.value}"~${q.fuzziness}`;
          } else _searchValue += ` "${q.value}"`;
        } else if (q.type === "entity") {
          _searchValue += ` (schema:${q.schema}`;
          if (q.property) {
            _searchValue += ` AND ${q.property}:"${q.value}"`;
          }
          if (q.fuzziness != 0) {
            _searchValue += `~${q.fuzziness})`;
          } else {
            _searchValue += ")";
          }
        }
      }
    });
    if (
      _searchValue.includes("OR") == false &&
      _searchValue.includes("AND") == false &&
      _searchValue.includes(")") == false &&
      _searchValue.includes("(") == false &&
      _searchValue.includes("~") == false
    ) {
      _searchValue = _searchValue.replace(/"/gi, "");
    }
    setOnSearch(true);
    updateContent(_searchValue);
    setSearchValue(_searchValue);
    setOpenSearchDropdown("");
  };

  const handleFocusMainSearchBox = () => {
    if (tabs.freeText) {
      setOpenSearchDropdown("freeText");
    } else if (tabs.entity) {
      setOpenSearchDropdown("entity");
    } else {
      setOpenSearchDropdown("");
    }
  };

  const handleClickSearchBox = () => {
    if (openSearchDropdown) {
      setOpenSearchDropdown("");
    } else {
      handleFocusMainSearchBox();
    }
  };

  const onFormSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      if (!e.target[0].value) return;
      e.preventDefault();
      handleSearch();
    },
    [searchValue]
  );

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleRemoveAllFilters = () => {
    resetFilters();
  };

  const handleInputCursor = () => {
    const textBox = document.getElementById("main-search-input");

    const hiddenLeft = textBox.scrollLeft;

    const formattedTextBox = document.getElementById("formatted_search_text");
    formattedTextBox.scrollLeft = hiddenLeft;
  };

  return (
    <StyledContainer ref={wrapperRef}>
      <Drawer
        sx={{
          height: "100%",
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
          "& .MuiModal-backdrop": {
            backgroundColor: "transparent",
          },
        }}
        variant="temporary"
        onClose={handleCloseFilter}
        anchor="right"
        open={openFilter}
      >
        <Filters disableCounts={true} handleClose={handleCloseFilter} />
      </Drawer>
      <Search
        className={`${onSearch ? "search" : ""}`}
        onSubmit={onFormSubmit}
        sx={{
          borderRadius:
            openSearchDropdown === "freeText"
              ? "0 4px 4px 0"
              : "0px 4px 4px 4px",
        }}
      >
        <InputWrapper>
          <FormattedTextInput text={searchValue} />
          <StyledInputBase
            id="main-search-input"
            inputRef={inputRef}
            // inputRef={(input) => {
            //     // input?.focus();
            // }}
            placeholder={`Begin your investigation: ${exampleSearch}`}
            inputProps={{ "aria-label": "search" }}
            onBlur={() => handleBlurInput()}
            value={searchValue}
            onChange={(e) => {
              if (openSearchDropdown === "freeText") return;
              const searchValue = replaceQuotes(e.target.value);
              setSearchValue(searchValue);
              updateContent(searchValue);
            }}
            onKeyDown={() => handleInputCursor()}
            onKeyUp={() => handleInputCursor()}
            // onFocus={() => handleFocusMainSearchBox()}
            onClick={() => handleClickSearchBox()}
          />
        </InputWrapper>
        <ActionContainer>
          {sm ? (
            <FilterListIcon onClick={handleOpenFilter} />
          ) : (
            <FilterButtonBox>
              <FilterButton
                disableRipple
                endIcon={<FilterListIcon onClick={handleOpenFilter} />}
                onClick={handleOpenFilter}
              >
                Filter Datasets
              </FilterButton>
              {appliedCount > 0 ? (
                <StyledFilterBubble>
                  {appliedCount}
                  <Cancel
                    sx={{
                      width: 16,
                      height: 16,
                      marginLeft: "4px",
                      cursor: "pointer",
                    }}
                    onClick={handleRemoveAllFilters}
                  />
                </StyledFilterBubble>
              ) : null}
            </FilterButtonBox>
          )}
          <Box sx={{ width: 2, height: 17, mx: 2, bgcolor: "#E1E4E8" }}></Box>
          {sm ? (
            <img
              src={require("src/assets/images/search-red.png")}
              style={{ width: 30, height: 30 }}
              alt="Search icon"
            />
          ) : (
            <SearchIconWrapper type="submit" disabled={!searchValue}>
              <img
                src={require("src/assets/images/search.png")}
                style={{ width: 24, height: 24 }}
                alt="Search icon"
              />
            </SearchIconWrapper>
          )}
        </ActionContainer>
      </Search>

      {openSearchDropdown === "freeText" && (
        <StyledSearchDropdown>
          <QueryBuilder>
            <QueryBuilderContext
              queryB={queryB}
              setQueryB={setQueryB}
              handleChangeQueryItem={handleChangeQueryItem}
            />
            <Grid container sx={{ pt: 2, justifyContent: "space-between" }}>
              <Grid item>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <StyledButton
                    text="Add Term"
                    icon={
                      <img
                        src={require("src/assets/images/term.png")}
                        style={{ width: 16, height: 16 }}
                      />
                    }
                    onClick={onAddQuery}
                    sx={{ mr: 0 }}
                  />
                  <StyledButton
                    text="Add Entity"
                    icon={
                      <img
                        src={require("src/assets/images/entity.png")}
                        style={{ width: 16, height: 16 }}
                      />
                    }
                    onClick={onAddEntity}
                    sx={{ mr: 0 }}
                  />
                  <StyledButton
                    text="Add Group"
                    icon={
                      <img
                        src={require("src/assets/images/group.png")}
                        style={{ width: 16, height: 16 }}
                      />
                    }
                    onClick={onAddGroup}
                    sx={{ mr: 0 }}
                  />
                </Box>
              </Grid>
              <Grid item>
                <StyledButton
                  text="Save"
                  sx={{ color: "#11A76D", bgcolor: "rgba(17, 167, 109, 0.2)" }}
                  icon={<SaveIcon />}
                  onClick={handleSaveQuery}
                />
              </Grid>
            </Grid>
          </QueryBuilder>
        </StyledSearchDropdown>
      )}
    </StyledContainer>
  );
}

export const QueryBuilderContext = (props: {
  queryB: QueryItem[];
  setQueryB: (value: React.SetStateAction<QueryItem[]>) => void;
  handleChangeQueryItem: (_q: QueryItem, index: number) => void;
}) => {
  const { queryB, setQueryB, handleChangeQueryItem } = props;
  // a little function to help us with reordering the result
  const reorder = (list: QueryItem[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    if (list.length <= 2) return result;
    if (endIndex % 2 == 1) {
      if (Math.abs(startIndex - endIndex) > 2 && startIndex < endIndex) {
        endIndex -= 1;
      } else {
        endIndex += 1;
      }
    }
    const [queryItem] = result.splice(startIndex, 1);
    const [operatorItem] =
      startIndex == 0
        ? result.splice(startIndex, 1)
        : result.splice(startIndex - 1, 1);

    if (startIndex > endIndex) {
      if (endIndex == 0) {
        result.splice(endIndex, 0, operatorItem);
        result.splice(endIndex, 0, queryItem);
      } else {
        result.splice(endIndex, 0, operatorItem);
        result.splice(endIndex, 0, queryItem);
      }
    } else {
      result.splice(endIndex - 1, 0, queryItem);
      result.splice(endIndex - 1, 0, operatorItem);
    }

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const _items: QueryItem[] = reorder(
      queryB,
      result.source.index,
      result.destination.index
    );
    setQueryB(_items);
  };
  const getListStyle = (isDraggingOver: boolean) => ({
    padding: 0,
    width: "100%",
    marginBottom: !isDraggingOver ? 0 : "40px",
    minHeight: getMinHeight(),
  });

  const getMinHeight = () => {
    let height = 0;
    queryB?.map((item: QueryItem) => {
      if (item.type === "term") {
        height += 30;
      } else if (item.type === "group") {
        item.form?.map((item: QueryItem) => {
          if (item.type === "term") {
            height += 30;
          }
        });
        height += 140;
      }
    });
    return height;
  };

  const getItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined
  ) => {
    return {
      userSelect: "none",
      paddingBottom: isDragging ? 20 : 0,
      ...draggableStyle,
    } as CSSProperties;
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {queryB?.map((q: QueryItem, index: number) =>
              index === 0 ? (
                <Grid container sx={{ display: "flex" }} key={index}>
                  <Grid
                    item
                    sx={{
                      width: queryB.length > 1 ? 80 : 0,
                    }}
                  >
                    {queryB.length > 1 ? <VerticalLineEle /> : null}
                  </Grid>
                  <Draggable
                    key={`draggable-${index}`}
                    draggableId={`draggable-${index}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <Grid
                        item
                        id={`draggable-${index}`}
                        sx={{
                          justifyContent: "flex-start",
                          display: "block",
                          width: "calc(100% - 100px)",
                          height: "100%",
                          alignItems: "flex-start",
                        }}
                        key={index}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {q.type === "term" && (
                          <Grid item>
                            <Grid item key={"query-builder-input-" + index}>
                              <InputBox
                                query={q}
                                index={index}
                                handleChange={handleChangeQueryItem}
                                dragHandleProps={provided.dragHandleProps}
                              />
                              <Box height={15} />
                            </Grid>
                          </Grid>
                        )}
                        {q.type === "group" && (
                          <Grid item>
                            <Grid item key={"query-builder-input-" + index}>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 1,
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <StyledImage
                                  src={require("../../../../assets/images/drag-handler.png")}
                                  {...provided.dragHandleProps}
                                />
                                <QueryGroupBox
                                  query={q}
                                  index={index}
                                  handleChange={handleChangeQueryItem}
                                />
                              </Box>
                              <Box height={15} />
                            </Grid>
                          </Grid>
                        )}
                        {q.type === "entity" && (
                          <Grid item>
                            <Grid item key={"query-builder-input-" + index}>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 1,
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <StyledImage
                                  src={require("../../../../assets/images/drag-handler.png")}
                                  {...provided.dragHandleProps}
                                />
                                <EntityBox
                                  query={q}
                                  index={index}
                                  handleChange={handleChangeQueryItem}
                                />
                              </Box>
                              <Box height={15} />
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </Draggable>
                </Grid>
              ) : (
                <Grid
                  container
                  sx={{ display: "flex", position: "relative" }}
                  key={index}
                >
                  <Grid
                    item
                    sx={{
                      width: 80,
                    }}
                  >
                    {q.type === "operator" && (
                      <CustomizedTimeline
                        query={q}
                        index={index}
                        updateConnector={handleChangeQueryItem}
                      />
                    )}
                    {q.type !== "operator" && (
                      <>
                        {index === queryB.length - 1 ? (
                          <CrossLineEle />
                        ) : (
                          <VerticalLineEle />
                        )}
                      </>
                    )}
                  </Grid>
                  <Draggable
                    key={`draggable-${index}`}
                    draggableId={`draggable-${index}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <Grid
                        item
                        sx={{
                          justifyContent: "flex-start",
                          display: "block",
                          width: "calc(100% - 100px)",
                          height: "100%",
                          alignItems: "flex-start",
                        }}
                        id={`draggable-${index}`}
                        key={index}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {q.type === "term" && (
                          <Grid item>
                            <Grid item key={"query-builder-input-" + index}>
                              <InputBox
                                query={q}
                                index={index}
                                handleChange={handleChangeQueryItem}
                                dragHandleProps={provided.dragHandleProps}
                              />
                              <Box height={15} />
                            </Grid>
                          </Grid>
                        )}
                        {q.type === "group" && (
                          <Grid item>
                            <Grid item key={"query-builder-input-" + index}>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 1,
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <StyledImage
                                  src={require("../../../../assets/images/drag-handler.png")}
                                  {...provided.dragHandleProps}
                                />
                                <QueryGroupBox
                                  query={q}
                                  index={index}
                                  handleChange={handleChangeQueryItem}
                                />
                              </Box>
                              <Box height={15} />
                            </Grid>
                          </Grid>
                        )}
                        {q.type === "entity" && (
                          <Grid item>
                            <Grid item key={"query-builder-input-" + index}>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 1,
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <StyledImage
                                  src={require("../../../../assets/images/drag-handler.png")}
                                  {...provided.dragHandleProps}
                                />
                                <EntityBox
                                  query={q}
                                  index={index}
                                  handleChange={handleChangeQueryItem}
                                />
                              </Box>
                              <Box height={15} />
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </Draggable>
                </Grid>
              )
            )}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
