import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SelectOption } from "src/types/Shared";
import { Colors } from "src/view/constants/colors";

const StyledTextFieldWrapper = styled("div")(() => ({
  position: "relative",
  width: "100%",
  background: "#FFFFFF",
  border: "1px solid rgba(65, 64, 66, 0.16)",
  borderRadius: 8,
  marginBottom: 10,
  height: 48,
}));

const StyledLabel = styled(Typography)(() => ({
  fontSize: 12,
  lineHeight: "15px",
  fontWeight: "400",
  color: Colors.textGray400,
  marginBottom: 10,
}));

const StyledDatePicker = styled(DatePicker)(() => ({
  width: "calc(100% - 30px)",
  height: 46,
  border: "none",
  marginLeft: 10,
  marginRight: 10,
  marginBottom: 5,
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: 12,
  "&:focus-visible": {
    outline: "none",
  },
}));

interface Props {
  type?: string;
  label?: string;
  name?: string;
  value?: string;
  date?: string;
  placeholder?: string;
  onChange?: (value: Date) => void;
  helperText?: string;
  options?: Array<SelectOption>;
  fullWidth?: boolean;
}
const CustomDatePicker = (props: Props) => {
  const { label, name, placeholder, onChange } = props;
  const { date } = props;
  let date_parts = null;
  if (date) {
    date_parts = date.split("-").map(Number);
  }
  if (date_parts?.[1] > 0) {
    date_parts[1] = date_parts[1] - 1;
  }

  return (
    <Box>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledTextFieldWrapper>
        <StyledDatePicker
          name={name}
          selectsRange={false}
          value={date || null}
          selected={
            date_parts
              ? new Date(date_parts[0], date_parts[1], date_parts[2])
              : null
          }
          maxDate={new Date(Date.now())}
          onChange={(update: Date) => {
            onChange(update);
          }}
          showYearDropdown
          yearDropdownItemNumber={80}
          scrollableYearDropdown
          placeholderText={placeholder}
          popperPlacement="right-start"
        />
        <img
          src={require("src/assets/images/calendar.png")}
          style={{
            position: "absolute",
            right: "13px",
            top: "15px",
            width: "20px",
            height: "20px",
          }}
        />
      </StyledTextFieldWrapper>
    </Box>
  );
};

export default CustomDatePicker;
