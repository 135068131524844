import { useQuery } from "@tanstack/react-query";
import { useFetch } from "../fetch";
import { useUser } from "../auth";
import { ScreeningList } from "src/types/BulkSearch";

export type ScreeningListsResponse = {
  created_by: ScreeningList[];
  shared_with_groups: ScreeningList[];
};

export function useBulkSearch() {
  const { user } = useUser();
  const { getApi } = useFetch();

  const getScreeningListsFn = async () => {
    const data = await getApi(["bulk_search", "screening_list"]);
    return data;
  };

  const getScreeningLists = () =>
    useQuery<ScreeningListsResponse>({
      enabled: !!user,
      queryFn: getScreeningListsFn,
      queryKey: ["screeningLists"],
      initialData: { created_by: [], shared_with_groups: [] },
    });

  return {
    getScreeningLists,
  };
}
