import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "src/view/constants/colors";
import { ReactComponent as TransparentHuman } from "src/assets/icons/transparent-human.svg";

const StyledTitle = styled(Typography)(() => ({
  color: Colors.textDark,
  fontSize: 18,
  lineHeight: "20px",
  fontWeight: "700",
  textAlign: "center",
}));

const StyledDescrption = styled(Typography)(() => ({
  color: Colors.textGray300,
  fontSize: 14,
  lineHeight: "152.2%",
  fontWeight: "400",
  textAlign: "center",
  mt: "8px",
}));

const Wrapper = styled(Box)(() => ({
  padding: "70px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "8px",
}));

const EmptyUserList = () => {
  return (
    <Wrapper>
      <TransparentHuman />

      <StyledTitle>Empty User List</StyledTitle>

      <StyledDescrption>
        There are currently no users in this group. Click “Add Users” to start.
      </StyledDescrption>
    </Wrapper>
  );
};

export default EmptyUserList;
