import { ArrowDropUp } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import theme from "src/theme";

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DropdownArrow = ({ isOpen, setIsOpen }: Props) => {
  return (
    <IconButton
      sx={{
        padding: 0,
        color: theme.palette.grey[900],
        backgroundColor: isOpen ? theme.palette.grey[300] : "transparent",
      }}
      aria-label="expand row"
      size="small"
      onClick={() => setIsOpen((isOpen) => !isOpen)}
    >
      <ArrowDropUp
        sx={{
          transform: isOpen ? "rotate(180deg)" : "rotate(90deg)",
          bgcolor: "#E6E8F0",
          borderRadius: "50%",
        }}
      />
    </IconButton>
  );
};

export default DropdownArrow;
