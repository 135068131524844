import { User } from "src/types/UserGroup";
import actions from "src/modules/auth/actions";
import { UserPermissions } from "src/types/Auth";

export interface AuthProps {
  loading: boolean;
  error: string | null;
  token: string | null;
  resetPassError: string | null;
  user?: User | null;
  permissions?: UserPermissions | null;
}

const initialData: AuthProps = {
  loading: false,
  error: null,
  token: null,
  user: null,
  resetPassError: null,
  permissions: null,
};

export default (
  state = initialData,
  {
    type,
    payload,
  }: {
    type: string;
    payload?: string | null | boolean | User | UserPermissions;
  }
) => {
  if (type === actions.LOADING) {
    return {
      ...state,
      loading: payload,
    };
  }
  if (type === actions.LOGIN_ERROR) {
    return {
      ...state,
      loading: false,
      error: payload,
    };
  }
  if (type === actions.LOGIN_SUCCESS) {
    return {
      ...state,
      loading: false,
      token: payload,
    };
  }
  if (type === actions.RESET_PASS_ERROR) {
    return {
      ...state,
      loading: false,
      resetPassError: payload,
    };
  }
  if (type === actions.RESET_PASS_SUCCESS) {
    return {
      ...state,
      loading: false,
    };
  }
  if (type === actions.PERMISSIONS) {
    return {
      ...state,
      permissions: payload,
      loading: false,
    };
  }
  if (type === actions.USER_PROFILE) {
    return {
      ...state,
      user: payload,
      loading: false,
    };
  }

  return state;
};
