import { Fragment, useEffect } from "react";
import { useIntersectionObserver } from "usehooks-ts";
import {
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { format } from "date-fns";
import ConfirmDialog from "src/view/components/ConfirmDialog";
import { Colors } from "src/view/constants/colors";
import { User } from "src/types/UserGroup";
import DeleteIconUrl from "src/assets/images/delete.png";
import { getFullName } from "src/utils";
import { useUsers } from "src/modules/api/users";
import UserGroupMemberColumn from "src/view/users-groups/users/UsersTable/UserGroupMemberColumn";

const HeadCell = styled(TableCell)({
  whiteSpace: "nowrap",
  fontSize: "12px",
  paddingTop: "34px",
  paddingBottom: "10px",
  fontWeight: "700",
  color: "#9BA5B1",
  borderBottom: "none",
});

const Cell = styled(TableCell)({
  whiteSpace: "nowrap",
  paddingTop: 16,
  paddingBottom: 16,
  fontSize: "16px",
  lineHeight: "20px",
  color: "#122945",
});

interface Props {
  searchKey: string;
}

const UsersTable = ({ searchKey }: Props) => {
  const { getUsersInfinite, deleteUser } = useUsers();
  const {
    data: users,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = getUsersInfinite({ searchKey });
  const { mutateAsync: deleteUserAsync } = deleteUser();

  const { ref: endOfListRef, isIntersecting } = useIntersectionObserver();

  useEffect(() => {
    if (isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  }, [isIntersecting, hasNextPage]);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <HeadCell>Email</HeadCell>
            <HeadCell>Name</HeadCell>
            <HeadCell>Member Of</HeadCell>
            <HeadCell>Created On</HeadCell>
            <HeadCell>Actions</HeadCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {users?.pages.map((usersPage) => {
            return (
              <Fragment key={`usersPage-${usersPage.page}`}>
                {usersPage.results.map((row: User, index: number) => (
                  <TableRow
                    key={"table row " + index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <Cell width="36%">{row.email}</Cell>
                    <Cell width="18%">{getFullName(row) || "--"}</Cell>
                    <Cell width="26%">
                      <UserGroupMemberColumn user_groups={row.user_groups} />
                    </Cell>
                    <Cell width="13%">
                      {row.date_joined
                        ? format(new Date(row.date_joined), "MMM dd, yyyy")
                        : ""}
                    </Cell>

                    <Cell width="5%">
                      <Box display="flex" alignItems="center">
                        <ConfirmDialog
                          title="Delete User"
                          description="Are you sure you want to delete this user? This action cannot be undone."
                          confirmText="Confirm"
                          cancelText="Cancel"
                          onConfirm={() => deleteUserAsync(row.id)}
                        >
                          <IconButton size="small" sx={{ height: "100%" }}>
                            <img src={DeleteIconUrl} width="20" height="20" />
                          </IconButton>
                        </ConfirmDialog>
                      </Box>
                    </Cell>
                  </TableRow>
                ))}
              </Fragment>
            );
          })}
        </TableBody>
      </Table>

      {isLoading && (
        <Box textAlign="center" my={2}>
          <CircularProgress />
        </Box>
      )}

      <div ref={endOfListRef} />

      {isFetchingNextPage && (
        <Box
          sx={{
            display: "flex",
            padding: "30px",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {users?.pages?.[0]?.results?.length == 0 && (
        <Box textAlign="center" my={2}>
          <Typography fontSize="12px" fontWeight="700" color={Colors.textDark}>
            No results
          </Typography>
        </Box>
      )}
    </>
  );
};

export default UsersTable;
