import React from "react";
import { PreviewDocListProps } from "src/modules/search/view/searchViewReducers";
import { Box } from "@mui/material";
import {
  StyledContainer,
  StyledHeader,
  renderContent,
} from "src/view/search-result/components/Preview/Source/SourcePreviews";
import HighlightActionMenu from "src/view/components/HighlightActionMenu";

interface Props {
  data: PreviewDocListProps | unknown[];
  loading: boolean;
  handleClosePreview: (shouldSetURLParam?: boolean) => void;
}

const TXTPreview = ({ data, loading, handleClosePreview }: Props) => {
  return (
    <StyledContainer>
      <StyledHeader></StyledHeader>
      {renderContent(
        data,
        loading,
        <Box
          sx={{
            width: "calc(100% - 79px)",
            height: "calc(100vh - 350px)",
            display: "block",
            ml: "43px",
            mr: "36px",
            padding: 1,
          }}
          aria-label="simple table"
        >
          {Array.isArray(data) &&
            data.map((row: unknown, index: number) => (
              <Box
                key={index}
                style={{
                  overflow: "auto",
                  fontFamily: "monospace",
                  fontSize: "12px",
                  lineHeight: "15px",
                  color: "#000000",
                  width: "100%",
                  height: "100%",
                  whiteSpace: "pre-wrap",
                }}
              >
                <HighlightActionMenu onSearchInTab={handleClosePreview}>
                  {row["content"]}
                </HighlightActionMenu>
              </Box>
            ))}
        </Box>
      )}
    </StyledContainer>
  );
};

export default TXTPreview;
