import { memo } from "react";
import { Grid } from "@mui/material";
import CustomMultiselect from "src/view/components/CustomMultiselect";
import dataset_types from "src/assets/json/dataset_types.json";
import regions from "src/assets/json/regions.json";
import languages from "src/assets/json/languages.json";
import { WikiFilter } from "src/types/KnowledgeWiki";
import { useFilterOptions } from "src/modules/api/filters";

interface Props {
  filter: WikiFilter;
  onChange: (key: string, data: (string | number)[]) => void;
}

const Filters = ({ filter, onChange }: Props) => {
  const { countries } = useFilterOptions();

  return countries.length > 0 ? (
    <Grid container spacing={4}>
      <Grid item sm={3} xs={6}>
        <CustomMultiselect
          placeholder="Select type"
          onChange={(data) => onChange("dataset_type", data)}
          value={filter.dataset_type.map((v) => {
            const vv = typeof v === "string" ? parseInt(v) : v;
            return dataset_types.find(({ value }) => value === vv);
          })}
          options={dataset_types}
          selectionLimit={1}
        />
      </Grid>
      <Grid item sm={3} xs={6}>
        <CustomMultiselect
          placeholder="Select countries"
          onChange={(data) => onChange("countries", data)}
          value={filter.countries.map((v) =>
            countries.find((item) => item.value === v)
          )}
          options={countries}
        />
      </Grid>
      <Grid item sm={3} xs={6}>
        <CustomMultiselect
          placeholder="Select regions"
          onChange={(data) => onChange("regions", data)}
          value={filter.regions.map((l) =>
            regions.find(({ label }) => label === l)
          )}
          valueKey="label"
          options={regions}
        />
      </Grid>
      <Grid item sm={3} xs={6}>
        <CustomMultiselect
          placeholder="Select languages"
          onChange={(data) => onChange("languages", data)}
          value={filter.languages.map((l) =>
            languages.find(({ label }) => label === l)
          )}
          valueKey="label"
          options={languages}
        />
      </Grid>
    </Grid>
  ) : null;
};

export default memo(Filters);
