import { useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";

const InputContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  margin: theme.spacing(1, 1, 1, 0),
  marginLeft: `calc(1em + ${theme.spacing(4)} - 1px)`,
  paddingLeft: 1,
  transition: theme.transitions.create("width"),
  height: "1.4375em",
  inset: "0px",
  userSelect: "none",
  whiteSpace: "pre",
  wordBreak: "break-word",
  lineHeight: "25px",
  fontSize: "inherit",
  cursor: "text",
  overflowY: "hidden",
  overflowX: "auto",
  "&::-webkit-scrollbar": {
    height: 0,
  },
  [theme.breakpoints.down("sm")]: {
    margin: theme.spacing(0, 0, 0, 0),
    marginLeft: `calc(1em + ${theme.spacing(2)})`,
  },
}));

const FormattedTextInput = (props: {
  text: string;
  containerStyle?: React.CSSProperties;
}) => {
  const { text } = props;
  const inputRef = useRef(null);

  useEffect(() => {
    getFragments(text);
  }, [text]);

  const getFragments = (text: string) => {
    const regex = /([a-zA-Z_]+):("[^"]*"|[^)\s"]+)/g;
    const pairs = text.match(regex);
    let fragments = "";
    if (pairs?.length == 0) fragments = text;
    else {
      for (let i = 0; i < pairs?.length; i++) {
        const pair = pairs[i];
        const prev = text?.split(pair)[0];
        if (prev != "") {
          fragments += prev;
        }
        const key = pair.split(":")[0];
        const value = pair.split(":")[1];
        fragments += `<span class="key-value-pair"><span class="pair-key">${key}</span>:<span class="pair-value">${value}</span></span>`;
        text = text?.split(pair)[1];
      }
    }

    if (text != "") {
      fragments += text;
    }

    inputRef.current.innerHTML = fragments;
  };

  return (
    <InputContainer
      id={"formatted_search_text"}
      ref={inputRef}
      tabIndex={0}
      style={props.containerStyle || {}}
    ></InputContainer>
  );
};

export default FormattedTextInput;
