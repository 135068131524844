import { Button, Container, styled } from "@mui/material";
import SearchBox from "./SearchBox";
import SearchTabs from "./SearchTabs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const BrowseAllData = styled("div")(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  marginTop: 30,
}));

const StyledButton = styled(Button)(() => ({
  backgroundColor: "#CADEF0",
  color: "#0B83F2",
  fontSize: 14,
  fontWeight: "700",
  textTransform: "none",
  height: 36,
  borderRadius: 25,
  paddingLeft: 20,
  paddingRight: 20,
  border: "1px solid #0B83F2",
}));

export default function Search() {
  const navigate = useNavigate();

  const [tabs, setTabs] = useState({
    freeText: false,
    entity: false,
  });

  const handleChangeTabs = (data: unknown) => {
    if (data && typeof data == "object") {
      const _tabs = { ...tabs, ...data };
      setTabs(_tabs);
    }
  };

  const gotoWikiPage = () => {
    navigate("/data-dictionary/data");
  };

  return (
    <Container
      sx={{
        pt: { xs: "16px", sm: "20px", md: "40px" },
        pb: { xs: "100px", sm: "140px", md: "160px" },
      }}
    >
      <SearchTabs tabs={tabs} handleChange={handleChangeTabs} />
      <SearchBox tabs={tabs} />
      <BrowseAllData>
        <StyledButton
          onClick={() => gotoWikiPage()}
          startIcon={
            <img
              src={require("src/assets/images/book_primary.png")}
              style={{ width: 24, height: 24 }}
            />
          }
        >
          Browse all datasets in the Data Dictionary
        </StyledButton>
      </BrowseAllData>
    </Container>
  );
}
