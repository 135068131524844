import { styled } from "@mui/material/styles";
import DataList from "./components/DataList";
import SearchHeader from "src/view/layout/SearchHeader";

const RootContainer = styled("div")(() => ({
  position: "relative",
}));

const DataDictionary = () => {
  return (
    <RootContainer>
      <SearchHeader />
      <DataList />
    </RootContainer>
  );
};

export default DataDictionary;
