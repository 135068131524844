import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  InputBase,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState, useEffect, useRef, ChangeEvent } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { QueryItem } from "src/types/BulkSearch";
import { DraggableProvidedDragHandleProps } from "@hello-pangea/dnd";
import * as ftm from "@alephdata/followthemoney";
import { Model, Schema } from "@alephdata/followthemoney";
import { useClickAway } from "src/modules/shared/hooks";

const StyledContainer = styled("div")(() => ({
  position: "relative",
  backgroundColor: "white",
  display: "flex",
  alignItems: "center",
  width: "max-content",
  paddingLeft: "10px",
  background: "#FFFFFF",
  border: "1px solid #E6E6E6",
  boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.08)",
  borderRadius: "8px",
  padding: "20px",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: 210,
  height: 28,
  backgroundColor: "white",
  border: "1px solid #A0B9D0",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create("width"),
    width: "100%",
    fontSize: 14,
    lineHeight: "16px",
    fontWeight: "700",
  },
}));

const Flex = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
}));

const Label = styled("div")(() => ({
  fontSize: 12,
  lineHeight: "17px",
  fontWeight: "400",
  color: "#536378",
  marginRight: 2,
}));

const StyledOptionButtonWrapper = styled("div")(() => ({
  position: "relative",
}));

const StyledOptionButton = styled(Button)(() => ({
  borderRadius: 60,
  backgroundColor: "white",
  boxShadow: "none",
  zIndex: 1,
  fontSize: 12,
  lineHeight: "17px",
  fontWeight: "700",
  color: "#536378",
  textTransform: "unset",
  padding: "4px 9px",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: "#ECF1F799",
    boxShadow: "none",
  },
}));

const StyledDropdownButton = styled(Button)(() => ({
  borderRadius: 0,
  backgroundColor: "white",
  boxShadow: "none",
  border: "1px solid #9BA5B1",
  zIndex: 1,
  width: "auto",
  minWidth: 100,
  height: 29,
  fontSize: 14,
  lineHeight: "17px",
  fontWeight: "700",
  color: "#536378",
  textTransform: "unset",
  padding: "6px 12px",
  whiteSpace: "nowrap",
  display: "flex !important",
  justifyContent: "space-between !important",
  "&:hover": {
    backgroundColor: "#ECF1F799",
    boxShadow: "none",
  },
}));

const StyledDropdown = styled("ul")(() => ({
  position: "absolute",
  width: "auto",
  background: "#FFFFFF",
  boxShadow: "0px 34px 33px rgba(0, 0, 0, 0.25)",
  marginTop: "2px",
  zIndex: 3,
  padding: 0,
  maxHeight: 400,
  overflowY: "auto",
}));

const StyledBadge = styled("div")(() => ({
  position: "absolute",
  width: "auto",
  background: "#52A8F9",
  borderRadius: 8,
  height: 15,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  top: -8,
  left: 24,
  padding: "4px 8px",
  textTransform: "capitalize",
  color: "white",
  fontSize: 12,
  lineHeight: "15px",
  fontWeight: "700",
  textAlign: "center",
}));

const FuzzyButton = (props: {
  text: number;
  handleChangeFuzziness: (value: number) => void;
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [query, setQuery] = useState("Exact");
  const menuRef = useRef(null);
  useClickAway(menuRef, () => setOpenDropdown(false));

  useEffect(() => {
    if (props.text) {
      let text = "";
      if (props.text == 0) {
        text = "Exact";
      } else {
        if (props.text == 1) text = "1 edit";
        else text = `${props.text} edits`;
      }
      setQuery(text);
    }
  }, [props]);

  const handleClose = () => {
    setOpenDropdown(false);
  };

  const handleSelectQuery = (value: number, event: unknown) => {
    setQuery(event["target"].innerText);
    props.handleChangeFuzziness(value);
    handleClose();
  };

  const handleClickButton = () => {
    if (openDropdown == false) setOpenDropdown(true);
  };

  return (
    <StyledOptionButtonWrapper>
      <StyledOptionButton
        variant="contained"
        onClick={() => handleClickButton()}
        endIcon={<ArrowDropDownIcon sx={{ color: "inherit" }} />}
      >
        {query}
      </StyledOptionButton>
      {openDropdown && (
        <StyledDropdown ref={menuRef}>
          <MenuItem onClick={(e) => handleSelectQuery(0, e)}>Exact</MenuItem>
          {[...Array(9)].map((_i: number, index: number) => {
            return (
              <MenuItem
                key={"fuzziness-menu-item-" + index}
                onClick={(e) => handleSelectQuery(index + 1, e)}
              >
                {index + 1} {index == 0 ? "edit" : "edits"}
              </MenuItem>
            );
          })}
        </StyledDropdown>
      )}
    </StyledOptionButtonWrapper>
  );
};
const SchemaPropertyButton = (props: {
  type: string;
  value: string;
  options: string[];
  handleChange: (value: string) => void;
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [value, setValue] = useState(
    props.type == "schema" ? "Select Type" : "Select Property"
  );
  const menuRef = useRef(null);
  useClickAway(menuRef, () => setOpenDropdown(false));

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
    if (props.type == "property" && props.value == "") {
      setValue("Select Property");
    }
  }, [props]);

  const handleClose = () => {
    setOpenDropdown(false);
  };

  const handleSelectQuery = (value: string, event: unknown) => {
    setValue(event["target"].innerText);
    props.handleChange(value);
    handleClose();
  };

  const handleClickButton = () => {
    if (openDropdown == false) setOpenDropdown(true);
  };

  return (
    <StyledOptionButtonWrapper>
      <StyledDropdownButton
        variant="contained"
        onClick={() => handleClickButton()}
        endIcon={<ArrowDropDownIcon sx={{ color: "inherit" }} />}
      >
        {value}
      </StyledDropdownButton>
      {openDropdown && (
        <StyledDropdown ref={menuRef}>
          {props.options.map((item: string, index: number) => {
            return (
              <MenuItem
                key={"fuzziness-menu-item-" + index}
                onClick={(e) => handleSelectQuery(item, e)}
              >
                {item}
              </MenuItem>
            );
          })}
        </StyledDropdown>
      )}
    </StyledOptionButtonWrapper>
  );
};

export default function EntityBox(props: {
  query: QueryItem;
  index: number;
  handleChange: (query: QueryItem, index: number) => void;
  dragHandleProps?: DraggableProvidedDragHandleProps;
}) {
  const { query, index, handleChange } = props;
  const inputRef = useRef();
  const schemas = Object.keys(ftm.defaultModel.schemata);
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    if (query.schema && properties.length == 0) {
      const model = new Model(ftm.defaultModel);
      const schema = new Schema(
        model,
        query.schema,
        ftm.defaultModel.schemata[query.schema]
      );
      const _properties = schema.getProperties();
      const pArr = Array.from(_properties.keys());
      setProperties(pArr);
    }
  }, [query]);

  const handleChangeInput = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const _query = {
      type: query["type"],
      value: e.target.value,
      fuzziness: query["fuzziness"],
      schema: query.schema,
      property: query.property,
    };
    handleChange(_query, index);
  };

  const handleClearInput = () => {
    const _query = {
      type: query["type"],
      value: "",
      fuzziness: 0,
      schema: query.schema,
      property: query.property,
    };
    handleChange(_query, index);
  };

  const deleteInputBox = () => {
    const _query: QueryItem = null;
    handleChange(_query, index);
  };

  const handleChangeFuzziness = (e: number) => {
    const _query = {
      type: query["type"],
      value: query["value"],
      fuzziness: e,
      schema: query.schema,
      property: query.property,
    };
    handleChange(_query, index);
  };

  const handleChangeSchema = (schema: string) => {
    const _query = {
      type: query["type"],
      value: "",
      fuzziness: query.fuzziness,
      schema: schema,
      property: "",
    };
    setProperties([]);
    handleChange(_query, index);
  };

  const handleChangeProperty = (property: string) => {
    const _query = {
      type: query["type"],
      value: "",
      fuzziness: query.fuzziness,
      schema: query.schema,
      property: property,
    };
    handleChange(_query, index);
  };

  return (
    <StyledContainer>
      <StyledBadge>{query["type"]}</StyledBadge>
      <Box sx={{ mr: 2 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            my: 1,
          }}
          gap={1}
        >
          <SchemaPropertyButton
            type={"schema"}
            value={query.schema}
            options={schemas}
            handleChange={handleChangeSchema}
          />
          {query.schema && properties.length > 0 && (
            <SchemaPropertyButton
              type={"property"}
              value={query.property}
              options={properties}
              handleChange={handleChangeProperty}
            />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
          gap={1}
        >
          {query.property && (
            <StyledInputBase
              ref={inputRef}
              // inputRef={(ref) => ref = inputRef}
              placeholder="Add a parameter"
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => handleChangeInput(e)}
              value={query["value"]}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle visibility"
                    edge="start"
                    size="small"
                    onClick={() => handleClearInput()}
                  >
                    {query["value"] ? <Close /> : null}
                  </IconButton>
                </InputAdornment>
              }
            />
          )}
          <Flex sx={{ mr: "12px", ml: "12px" }}>
            <Label>Fuzziness: </Label>
            <FuzzyButton
              text={query["fuzziness"]}
              handleChangeFuzziness={handleChangeFuzziness}
            />
          </Flex>
        </Box>
      </Box>
      <img
        src={require("src/assets/images/delete-secondary.png")}
        style={{ width: 17, height: 17, cursor: "pointer" }}
        onClick={deleteInputBox}
      />
    </StyledContainer>
  );
}
