import React from "react";
import { Box, Typography } from "@mui/material";
import { Colors } from "src/view/constants/colors";

const NewUsersEmpty = () => {
  return (
    <Box
      sx={{
        display: "flex",
        height: 200,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 200,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={require("src/assets/images/user.png")}
          style={{ width: 40, height: 40, color: "#BDBDBD" }}
        />
        <Box sx={{ height: 20 }}></Box>
        <Typography
          variant="body2"
          sx={{
            fontSize: "18px",
            lineHeight: "20px",
            fontWeight: "700",
            color: Colors.twilight,
          }}
        >
          No Users added yet.
        </Typography>
        <Box sx={{ height: 10 }}></Box>
        <Typography
          variant="body2"
          sx={{
            fontSize: "13px",
            lineHeight: "17px",
            fontWeight: "400",
            color: Colors.textGray100,
          }}
        >
          Add one or more users
        </Typography>
      </Box>
    </Box>
  );
};

export default NewUsersEmpty;
