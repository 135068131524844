import { styled } from "@mui/material/styles";
import { Container, Grid, Typography } from "@mui/material";
import { Colors } from "src/view/constants/colors";
import Search from "./Search";

const StyledBanner = styled("div")(() => ({
  position: "relative",
  backgroundImage: `url(${"/images/landing-bg.png"})`,
  backgroundSize: "contain",
  backgroundPosition: "50vw -20px",
  backgroundRepeat: "no-repeat",
  backgroundColor: "gradient(255,255,255,0",
}));

const RootContainer = styled("div")(() => ({
  position: "relative",
  background: "linear-gradient(transparent, #DDE4EC)",
}));

const LandingBanner = () => {
  return (
    <RootContainer>
      <StyledBanner>
        <Container>
          <Grid
            container
            sx={{
              pt: { xs: "100px", sm: "140px", md: "160px" },
              pb: 1,
              alignItems: "flex-start",
              paddingRight: { xs: "16px", sm: "20px", md: "48px" },
              paddingLeft: { xs: "16px", sm: "20px", md: "48px" },
            }}
          >
            <Grid item>
              <Typography
                variant="caption"
                sx={{
                  color: Colors.black,
                  fontSize: { xs: "24px", sm: "32px", md: "42px" },
                  lineHeight: { xs: "29px", sm: "39px", md: "51.5px" },
                  fontWeight: "700",
                }}
              >
                C4ADS Analyst Dashboard
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="inherit"
                sx={{
                  color: Colors.textDark,
                  fontWeight: "400",
                  lineHeight: { xs: "16px", sm: "20px", md: "26px" },
                  fontSize: { xs: "12px", sm: "15px", md: "18px" },
                }}
              >
                A premier{" "}
                <span style={{ color: Colors.secondary, fontWeight: "700" }}>
                  search and discovery
                </span>{" "}
                platform for C4ADS' repository of{" "}
                <span style={{ color: Colors.secondary, fontWeight: "700" }}>
                  investigative data.
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Search />
        </Container>
      </StyledBanner>
    </RootContainer>
  );
};

export default LandingBanner;
