import { CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Colors } from "src/view/constants/colors";
import { SearchResultModel } from "src/view/models/SearchResultModel";
import selector from "src/modules/bulk-search/query-group/queryGroupSelectors";
import actions from "src/modules/bulk-search/query-group/queryGroupActions";
import viewActions from "src/modules/search/view/searchViewActions";
import { SearchResultCard } from "src/view/search-result/components/SearchResultCard";
import { SearchResult } from "src/types/Search";
import LoadingText from "src/view/components/LoadingText";
import { prepSearchResultData } from "src/modules/shared/search";

const StyledResultContent = styled("div")(() => ({
  height: "calc(100% - 286px)",
  overflowY: "auto",
  padding: "20px 26px 40px 27px",
}));

const StyledContainer = styled("div")(() => ({
  width: "100%",
  height: "calc(100% - 300px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledNoData = styled("div")(() => ({
  width: "100%",
  height: "calc(100% - 300px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: Colors.twilight,
}));

const StyledPageLoadingContainer = styled("div")(() => ({
  width: "100%",
  height: 20,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
}));

const StyledLoadingMessage = styled("div")(() => ({
  fontSize: 16,
  lineHeight: "23px",
  fontWeight: "bold",
  color: Colors.textGray300,
  marginBottom: 10,
  width: 64,
}));

const ResultContent = () => {
  const selectedSearchTerm = useSelector(selector.selectSelectedSearchTerm);
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const listInnerRef = useRef();
  const [searchData, setSearchData] = useState(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (selectedSearchTerm) {
      run(selectedSearchTerm.id, page);
    }
  }, [selectedSearchTerm, page]);

  useEffect(() => {
    handleSchemeData(searchData);
  }, [searchData]);

  useEffect(() => {
    return () => {
      dispatch(viewActions.clear);
    };
  }, []);

  useEffect(() => {
    if (loading) {
      setResult([]);
    }
  }, [loading]);

  const handleSchemeData = (data: SearchResult) => {
    if (data == null) return;
    const source_data = data?.hits?.hits || [];
    const _result = page > 1 ? [...result] : [];
    for (let i = 0; i < source_data.length; i++) {
      const item = new SearchResultModel(source_data[i]);
      _result.push(item);
    }
    setResult(_result);
    setLoading(false);
  };

  const run = async (id: string, page: number) => {
    setLoading(true);
    const res = await dispatch(actions.runSearch(id, page));
    if (res) {
      setSearchData(res);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        if (searchData.next_page_number) {
          const nextPage = searchData.next_page_number;
          if (nextPage) {
            setPage(nextPage);
          }
        }
      }
    }
  };

  return (
    <StyledResultContent ref={listInnerRef} onScroll={onScroll}>
      {loading && result.length == 0 ? (
        <StyledContainer sx={{ gap: 2 }}>
          <StyledLoadingMessage>
            <LoadingText />
          </StyledLoadingMessage>
          <CircularProgress size={20} />
        </StyledContainer>
      ) : result.length > 0 ? (
        result.map((item: unknown, i: number) => {
          const data = prepSearchResultData(item);

          return (
            <div key={i}>
              <SearchResultCard
                id={item["_id"]}
                fileName={item["_source"]["file_name"]}
                key={`${i} - result card`}
                data={data}
                highlight={item["highlight"]}
                type={
                  item["_source"]["schema"] ||
                  item["_source"]["doc_type"] ||
                  "doc"
                }
                datasetId={item["dataset_id"]}
                source_type={item["_index"].includes("modeled_data") ? 0 : 1}
              />
            </div>
          );
        })
      ) : (
        <StyledNoData></StyledNoData>
      )}
      {loading && result.length > 0 && (
        <StyledPageLoadingContainer>
          <CircularProgress color="info" size={20} disableShrink />
        </StyledPageLoadingContainer>
      )}
    </StyledResultContent>
  );
};

export default ResultContent;
