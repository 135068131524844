import React from "react";
import {
  Box,
  CircularProgress,
  Tab,
  Tabs,
  TabProps,
  styled,
} from "@mui/material";
import { PreviewDocProps } from "src/modules/search/view/searchViewReducers";
import {
  StyledContainer,
  Title,
} from "src/view/search-result/components/Preview/Source/SourcePreviews";
import { useDocument } from "src/hooks/useDocument";
import * as xlsx from "@sheet/core";
import { Colors } from "src/view/constants/colors";
import HighlightActionMenu from "src/view/components/HighlightActionMenu";

const PreviewTabs = styled(Tabs)(() => ({
  borderTop: "solid",
  borderTopWidth: 1,
  borderTopColor: Colors.mist,
  "& .MuiTabs-indicator": {
    backgroundColor: Colors.primary,
  },
}));

const PreviewTab = styled((props: TabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: "rgba(0, 0, 0, 0.85)",
  fontFamily: "Inter",
  "&:hover": {
    color: Colors.primary,
    opacity: 1,
  },
  "&.Mui-selected": {
    color: Colors.primary,
    fontWeight: 700,
  },
}));

const PreviewFrame = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "calc(100% - 100px)",
  height: "95%",
  backgroundColor: Colors.bgGray1,
  border: "solid",
  borderRadius: theme.spacing(1),
  borderWidth: 1,
  borderColor: Colors.mist,
}));

const PreviewHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0.5, 1),
}));

const PreviewContent = styled(Box)(() => ({
  backgroundColor: "white",
  height: "calc(100% - 76px)",
  overflow: "auto",
}));

const PreviewFooter = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(0.5),
}));

interface XLSPreviewProps {
  data?: { id: string; content: Record<string, any>[] }[];
  loading: boolean;
  previewDocInfo: PreviewDocProps;
  handleClosePreview: (shouldSetURLParam?: boolean) => void;
}

type Sheet = {
  rowCount: number;
  _html: string;
};

type Book = Record<string, Sheet>;

const XLSPreview: React.FC<XLSPreviewProps> = ({
  loading: loadingData,
  data,
  previewDocInfo,
  handleClosePreview,
}) => {
  const { documentURL, isLoading: loadingXlsx } = useDocument(previewDocInfo);

  const [activeSheet, setActiveSheet] = React.useState<number>(0);
  const [book, setBook] = React.useState<Book>({});
  const [loading, setLoading] = React.useState<boolean>(
    loadingData || loadingXlsx
  );

  // eslint-disable-next-line
  const handleActiveSheet = (event: React.SyntheticEvent, index: number) => {
    setActiveSheet(index);
  };

  React.useEffect(() => {
    (async () => {
      if (documentURL) {
        const f = await fetch(documentURL);
        const ab = await f.arrayBuffer();
        const wb = xlsx.read(ab, { sheetRows: 50 });

        const bookData: Book = {};

        wb.SheetNames.forEach((name: string) => {
          const sheet = { rowCount: 0, _html: "" };
          sheet["rowCount"] = wb.Sheets[name]["!rows"]?.length || 0;
          sheet["_html"] = xlsx.utils
            .sheet_to_html(wb.Sheets[name])
            .replaceAll("border-color:black", `border-color:${Colors.mist}`)
            .replaceAll("border-bottom-color: transparent", "")
            .replaceAll("border-right-color: transparent", "");
          bookData[name] = sheet;
        });
        setBook(bookData);
        setLoading(false);
      }
    })();
  }, [data, documentURL, previewDocInfo]);

  return (
    <StyledContainer
      sx={{
        height: "100%",
        px: 4,
        pt: 4,
        overflow: "hidden",
      }}
    >
      <PreviewFrame>
        <PreviewHeader>
          <Title>{previewDocInfo?.dataset?.["fileName"]}</Title>
        </PreviewHeader>
        <PreviewContent>
          {loading && (
            <Box
              sx={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress disableShrink />
            </Box>
          )}
          {Object.keys(book).length > 0 && (
            <HighlightActionMenu onSearchInTab={handleClosePreview}>
              <div
                dangerouslySetInnerHTML={{
                  __html: Object.values(book)[activeSheet]._html,
                }}
              />
            </HighlightActionMenu>
          )}
        </PreviewContent>
        <PreviewFooter>
          <PreviewTabs value={activeSheet} onChange={handleActiveSheet}>
            {Object.keys(book).map((sheetName, i) => (
              <PreviewTab key={i} value={i} label={sheetName} />
            ))}
          </PreviewTabs>
        </PreviewFooter>
      </PreviewFrame>
    </StyledContainer>
  );
};

export default XLSPreview;
