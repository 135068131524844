import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { validateEmail } from "src/utils";
import MyTextField from "../components/MyTextField";
import { Colors } from "../constants/colors";
import Actions from "src/modules/auth/actions";
import Selectors from "src/modules/auth/selectors";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const RootContainer = styled(Box)(() => ({
  width: "100vw",
  height: "100vh",
  display: "flex",
}));

const Banner = styled(Box)(({ theme }) => ({
  width: "40%",
  display: "flex",
  flexDirection: "column",
  backgroundColor: Colors.twilight,
  position: "relative",
  alignItems: "center",
  justifyContent: "space-around",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const Form = styled(Box)(({ theme }) => ({
  width: "60%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    width: "calc(100% - 4rem)",
    padding: "2rem",
  },
}));

const FormContaienr = styled(Box)(() => ({
  width: "70%",
  maxWidth: "493px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const BGImage = styled("img")(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "contain",
}));

const Logo = styled("img")(() => ({
  width: 280,
  height: 40,
  objectFit: "contain",
  marginBottom: "40px",
}));

const BulkSearchImage = styled("img")(() => ({
  width: 257,
  height: 189,
  objectFit: "contain",
}));

const BannerTitle = styled(Typography)(() => ({
  fontSize: 24,
  lineHeight: "29px",
  fontWeight: "700",
  color: "#FFFFFF",
}));

const FormTitle = styled(Typography)(() => ({
  fontSize: 24,
  lineHeight: "29px",
  fontWeight: "700",
  color: "#122945",
  textAlign: "center",
}));

const FormDescription = styled(Typography)(() => ({
  fontSize: 14,
  lineHeight: "20px",
  fontWeight: "400",
  color: "#536378",
  textAlign: "center",
  width: "100%",
  maxWidth: 382,
}));

const FormIcon = styled("img")(() => ({
  width: 20,
  height: 20,
  objectFit: "contain",
  cursor: "pointer",
}));

const ErrorText = styled(Typography)(() => ({
  fontSize: 14,
  lineHeight: "20px",
  fontWeight: "400",
  color: Colors.secondary,
  textAlign: "center",
}));

export default function ForgotPassword() {
  const [user, setUser] = useState({
    email: "",
  });
  const [error, setError] = useState("");
  const loading = useSelector(Selectors.selectLoading);
  const dispatch = useDispatch();
  const resetPassError = useSelector(Selectors.selectResetPassError);
  const navigate = useNavigate();

  useEffect(() => {
    setError(resetPassError);
  }, [resetPassError]);

  const updateUser = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const _user = { ...user };
    _user[e.target.name] = e.target.value;
    setUser(_user);
  };

  const handleSendLink = async () => {
    if (user.email == "") return;
    if (!validateEmail(user.email)) {
      setError("Email is not valid format.");
      return;
    }
    setError("");
    const res = await dispatch(Actions.resetPassword(user));
    if (!res.error) {
      navigate("/login", { state: { sentLink: true } });
    }
  };

  return (
    <RootContainer>
      <Banner>
        <BGImage src={require("src/assets/images/bg-login.png")} />
        <Logo src={require("src/assets/images/logo-login.png")} />
        <BulkSearchImage src={require("src/assets/images/bulk-search.png")} />
        <Box sx={{ textAlign: "center", alignItems: "center" }}>
          <BannerTitle>Easy to search &amp; find accurate results</BannerTitle>
        </Box>
      </Banner>
      <Form>
        <FormContaienr>
          <FormTitle>Forgot Password</FormTitle>
          <Box sx={{ height: 12 }} />
          <FormDescription>
            Enter your email below and we’ll send you a link to reset your
            password.
          </FormDescription>
          <Box sx={{ height: 24 }} />
          {error && <ErrorText>{error}</ErrorText>}
          <MyTextField
            label="Email"
            labelStyle={{ color: "#97A6B5" }}
            name="email"
            type="email"
            prefixIcon={
              <FormIcon src={require("src/assets/images/envelope.png")} />
            }
            value={user.email}
            onChange={updateUser}
          />
          <Box sx={{ height: 24 }} />
          <Button
            variant="contained"
            sx={{
              width: "100%",
              maxWidth: 382,
              height: 40,
              bgcolor: Colors.twilight,
              textTransform: "capitalize",
              fontWeight: "700",
            }}
            onClick={handleSendLink}
            startIcon={
              loading ? (
                <CircularProgress
                  size="small"
                  style={{ color: "#FFFFFF", width: 24, height: 24 }}
                />
              ) : null
            }
          >
            Send Password Reset Link
          </Button>
        </FormContaienr>
      </Form>
    </RootContainer>
  );
}
