import { User } from "src/types/UserGroup";
import actions from "src/modules/shared/actions";
import { SelectOption } from "src/types/Shared";

export interface SharedProps {
  countries: {
    label: string;
    value: string;
  }[];
}

const initialData: SharedProps = {
  countries: [],
};

export default (
  state = initialData,
  {
    type,
    payload,
  }: { type: string; payload?: string | null | boolean | User | SelectOption[] }
) => {
  if (type === actions.UPDATE_COUNTRIES) {
    return {
      ...state,
      countries: payload,
    };
  }

  return state;
};
