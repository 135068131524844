import { AnyAction, Dispatch } from "redux";
import { SearchFilter } from "src/types/SearchFilter";

const prefix = "BULK_SEARCH_FILTER";

const searchFilterActions: AnyAction = {
  SET_FILTER: `${prefix}_SET_FILTER`,
  SET_QUERY: `${prefix}_SET_QUERY`,
  setFilter: (data: SearchFilter) => async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: searchFilterActions.SET_FILTER,
      payload: data,
    });
  },
  setQuery: (data: unknown) => async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: searchFilterActions.SET_QUERY,
      payload: data,
    });
  },
  type: undefined,
};

export default searchFilterActions;
