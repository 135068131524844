import { ChangeEvent, useMemo, useState } from "react";
import { Dataset, TaskStatusFiltersKeys } from "src/types/Dataset";
import {
  Box,
  CircularProgress,
  Pagination,
  PaginationItem,
  Typography,
} from "@mui/material";

import { useDatasetMetadata } from "src/modules/api/datasetMetadata";
import { styled } from "@mui/material/styles";
import theme from "src/theme";

import {
  SkipPreviousRounded as IconSkipPrevious,
  SkipNextRounded as IconSkipNext,
} from "@mui/icons-material";

import TasksTable from "./TasksTable";

interface Props {
  dataset: Dataset;
  close: () => void;
}

const MetadataSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
  fontSize: "14px",
  fontWeight: 400,
  marginTop: "-16px",
}));

const ProgressWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  color: theme.palette.grey[600],
  fontSize: "16px",
  fontWeight: 700,
  marginRight: "12px",
}));

const Tasks = ({ dataset }: Props) => {
  const [filter, setFilter] = useState<TaskStatusFiltersKeys | null>(null);
  const [page, setPage] = useState<number>(() => 1);

  const { getDatasetIndexingTasksInfinite, getDatasetRecordCountById } =
    useDatasetMetadata();

  const pageSize = 10;
  const { data: tasks, isFetching: isFetchingTasks } =
    getDatasetIndexingTasksInfinite({
      id: dataset.id,
      filter,
      page,
      pageSize,
    });

  const { data: recordCounts, isFetching: isFetchingRecordCounts } =
    getDatasetRecordCountById(dataset.id);

  const paginationData = useMemo(() => {
    if (!tasks?.["total_count"]) {
      return { page: 1, count: 1 };
    }

    return {
      page,
      count: Math.ceil(tasks["total_count"] / pageSize),
    };
  }, [tasks]);

  const progress = useMemo(() => {
    const statusCounts = tasks?.["status_counts"];
    const totalCount = tasks?.["total_count"];
    const completedCount = statusCounts?.[TaskStatusFiltersKeys.COMPLETED];

    if (!totalCount || !statusCounts || !completedCount) {
      return { percentComplete: 0, isError: true };
    }

    return {
      percentComplete: (completedCount / tasks["total_count"]) * 100,
      isError: Object.keys(statusCounts).some(
        (key) => key === TaskStatusFiltersKeys.FAILED
      ),
    };
  }, [tasks]);

  const handleChangePage = (_event: ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  const handleSetFilter = (filter: TaskStatusFiltersKeys) => {
    setPage(1);
    setFilter(filter);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: 3,
          m: "6px 0 6px 30px",
          pb: "16px",
          borderBottom: "1px solid #D4D8DE",
        }}
      >
        <MetadataSubtitle>bucket_name: {dataset.bucket_name}</MetadataSubtitle>
        <MetadataSubtitle>
          bucket_prefix: {dataset.bucket_prefix}
        </MetadataSubtitle>
        <MetadataSubtitle>id: {dataset.id}</MetadataSubtitle>
      </Box>
      <ProgressWrapper>
        <Box sx={{ mr: "68px" }}>
          <Typography
            sx={{ mr: "12px", display: "inline-block", fontWeight: "inherit" }}
          >
            Record Count
          </Typography>

          <>
            {isFetchingRecordCounts ? (
              <CircularProgress size={12} color="inherit" />
            ) : (
              <Typography
                sx={{
                  display: "inline-block",
                  fontWeight: "inherit",
                }}
              >
                {recordCounts?.count?.toLocaleString() ?? "---"}
              </Typography>
            )}
          </>
        </Box>

        <Box>
          <Typography
            sx={{ mr: "12px", display: "inline-block", fontWeight: "inherit" }}
          >
            Progress
          </Typography>

          <>
            {isFetchingRecordCounts || isFetchingTasks ? (
              <CircularProgress size={12} color="inherit" />
            ) : (
              <Typography
                sx={{
                  display: "inline-block",
                  fontWeight: "inherit",
                  color: progress.isError
                    ? theme.palette.secondary.main
                    : "inherit",
                }}
              >
                {progress.percentComplete}%
              </Typography>
            )}
          </>
        </Box>
      </ProgressWrapper>

      <TasksTable
        dataset={dataset}
        tasks={tasks}
        filter={filter}
        handleSetFilter={handleSetFilter}
        isFetchingTasks={isFetchingTasks}
        isFetchingRecordCounts={isFetchingRecordCounts}
      />

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: 3,
          marginBottom: 3,
        }}
      >
        <Pagination
          {...paginationData}
          shape="rounded"
          showFirstButton
          showLastButton
          renderItem={(item) => (
            <PaginationItem
              slots={{ first: IconSkipPrevious, last: IconSkipNext }}
              {...item}
            />
          )}
          sx={{
            "& button.MuiButtonBase-root": {
              color: `${theme.palette.primary.light} !important`,
              fontSize: 14,
              lineHeight: "14px",
            },
          }}
          onChange={handleChangePage}
        />
      </Box>
    </>
  );
};

export default Tasks;
