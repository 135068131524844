import React from "react";
import LandingPage from "./landing";
import SearchResultPage from "./search-result";
import ForgotPassword from "./auth/forgot-password";
import LoginPage from "./auth/login";
import BulkSearchPage from "./bulk-search";
import DataCollection from "./data-collection";
import DatasetSensitivityPage from "./dataset-sensitivity";
import UsersGroupsPage from "./users-groups";
import KnowledgeWikiPage from "./knowledge-wiki";
import UserProfilePage from "./profile";
import OCRPage from "./ocr/OCRPage";
import CallbackPage from "./callback";
import { AuthRouteGuard } from "src/modules/api/auth";
import { Features } from "src/types/Auth";
import Indexing from "src/view/indexing";

export { default as LoadingPage } from "./loading";

export type PageRouteProps = {
  key: string;
  path: string;
  element: React.ReactNode;
};

export const PageRoutes: PageRouteProps[] = [
  {
    key: "login",
    path: "/login",
    element: <LoginPage />,
  },
  {
    key: "reset-password",
    path: "/reset-password",
    element: <ForgotPassword />,
  },
  {
    key: "callback",
    path: "/callback",
    element: <CallbackPage />,
  },
  {
    key: "landing-page",
    path: "/",
    element: <AuthRouteGuard component={LandingPage} />,
  },
  {
    key: "search",
    path: "/search",
    element: (
      <AuthRouteGuard feature={Features.SEARCH} component={SearchResultPage} />
    ),
  },
  {
    key: "bulk-search",
    path: "/bulk-search/*",
    element: (
      <AuthRouteGuard
        feature={Features.BULK_SEARCH}
        component={BulkSearchPage}
      />
    ),
  },
  {
    key: "groups",
    path: "/groups/*",
    element: (
      <AuthRouteGuard
        feature={Features.USER_GROUP}
        component={UsersGroupsPage}
      />
    ),
  },
  {
    key: "data-dictionary",
    path: "/data-dictionary/*",
    element: (
      <AuthRouteGuard
        feature={Features.KNOWLEDGE_WIKI}
        component={KnowledgeWikiPage}
      />
    ),
  },
  {
    key: "dataset-sensitivity",
    path: "/dataset-sensitivity/*",
    element: <AuthRouteGuard component={DatasetSensitivityPage} />,
  },
  {
    key: "data-collections",
    path: "/data-collections/*",
    element: <AuthRouteGuard component={DataCollection} />,
  },
  {
    key: "profile",
    path: "/profile",
    element: <AuthRouteGuard component={UserProfilePage} />,
  },
  {
    key: "ocr",
    path: "/ocr",
    element: <AuthRouteGuard component={OCRPage} />,
  },
  {
    key: "indexing",
    path: "/indexing/*",
    element: <AuthRouteGuard component={Indexing} />,
  },
];
