import { Button } from "@mui/material";
import { styled, SxProps, Theme } from "@mui/material/styles";
import { ReactElement } from "react";

const StyledContainer = styled(Button)(() => ({
  borderRadius: 4,
  backgroundColor: "white",
  display: "flex",
  height: 28,
  alignItems: "center",
  justifyContent: "center",
  color: "#52A8F9",
  fontSize: 12,
  lineHeight: "15px",
  margin: 5,
  fontWeight: "700",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "rgba(11, 131, 242, 0.1)",
  },
}));

export default function StyledButton(props: {
  icon?: ReactElement;
  sx?: SxProps<Theme>;
  text?: string;
  onClick?: () => void;
}) {
  return (
    <StyledContainer
      startIcon={props.icon}
      onClick={props.onClick}
      sx={props.sx || {}}
    >
      {props.text}
    </StyledContainer>
  );
}
