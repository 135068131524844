import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { chain, groupBy, intersection } from "lodash";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
// import AddIcon from "@mui/icons-material/Add";
import { SelectOption } from "src/types/Shared";
import LoadingText from "src/view/components/LoadingText";
import Filters from "./Filters";
import DataItem from "./DataItem";
import dataset_types from "src/assets/json/dataset_types.json";
import regions from "src/assets/json/regions.json";
import languages from "src/assets/json/languages.json";
import SearchBox from "src/view/knowledge-wiki/data-dictionary/components/SearchBox";
import { KnowledgeWiki, WikiFilter } from "src/types/KnowledgeWiki";
import { useKnowledgeWiki } from "src/modules/api/knowledgeWiki";
import { Role } from "src/types/Auth";
import { useUser } from "src/modules/api/auth";
import { useFilterOptions } from "src/modules/api/filters";
import theme from "src/theme";
import { usePostHog } from "posthog-js/react";
import { HogEvent } from "src/types/PosthogEvents";

const StyledBanner = styled("div")(() => ({
  position: "relative",
  // header offset (yeah, I know)
  marginTop: 104,
  height: "calc(390px - 104px)",
  backgroundImage: `url(${"/images/landing-wiki.png"})`,
  backgroundSize: "50vw",
  backgroundPosition: "right 0px",
  backgroundRepeat: "no-repeat",
}));

const StyledBackground = styled("div")(() => ({
  background:
    "linear-gradient(305.5deg, rgba(18, 42, 71, 0.972136) 29.19%, #225B7B 70.81%)",
}));

const StyledSectionLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 16,
  marginBottom: "18px",
  fontWeight: 600,
}));

const StyledSectionWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(1),
  gap: theme.spacing(1),
  borderRadius: 8,
  border: `1px solid`,
  borderColor: theme.palette.grey[400],
  marginBottom: theme.spacing(3),
}));

const parseVariablesFromSearchParams = (
  searchParams: URLSearchParams
): WikiFilter => {
  const keys = ["countries", "dataset_type", "languages", "regions"];
  const filter = {};
  keys.forEach((key) => {
    filter[key] = searchParams.getAll(key);
  });
  return filter;
};

const DataList = () => {
  const posthog = usePostHog();
  const { countries } = useFilterOptions();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useUser();
  const { getKnowledgeWikis } = useKnowledgeWiki();
  const { data, isFetching } = getKnowledgeWikis();
  const isAdmin = useMemo(() => user?.role === Role.ADMIN, [user]);

  useEffect(() => {
    posthog.capture(HogEvent.DATA_DICTIONARY_LIST_VIEWED);
  }, []);

  const [searchInput, setSearchInput] = useState<string>("");
  const [search, setSearch] = useState<string>("");

  const filteredData: Record<string, KnowledgeWiki[]> = useMemo(() => {
    if (!data.length || !countries.length) return {};

    const filter: WikiFilter = parseVariablesFromSearchParams(searchParams);

    //this filter uses the value as the key, not the label
    const filterDatasetTypes = filter.dataset_type.map(
      (d) =>
        dataset_types.find(
          ({ value }: SelectOption) => value.toString() === d.toString()
        )?.label
    );

    const values = chain(data)
      .filter(
        (d) =>
          filterDatasetTypes.length === 0 ||
          filterDatasetTypes.includes(d.dataset_type)
      )
      .filter(
        (d) =>
          filter.countries.length === 0 ||
          intersection(d.countries, filter.countries).length > 0
      )
      .filter(
        (d) =>
          filter.regions.length === 0 ||
          intersection(d.regions, filter.regions).length > 0
      )
      .filter(
        (d) =>
          filter.languages.length === 0 ||
          intersection(d.languages, filter.languages).length > 0
      )
      .filter((d) => d.name?.toLowerCase().includes(search.toLowerCase()))
      .filter((d) => isAdmin || !d.draft)
      .map((d) => {
        const group = d.draft
          ? "Draft"
          : d.countries.length > 0
          ? countries.find(({ value }) => value === d.countries[0])?.label
          : "Global";
        return { ...d, ["group"]: group };
      })
      .value();

    return groupBy(values, "group");
  }, [
    data,
    search,
    countries,
    dataset_types,
    regions,
    languages,
    isAdmin,
    searchParams,
  ]);

  const handleSearchChange = (value: string) => {
    setSearchInput(value);
  };

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const formBody: any[] = [];
    const formData = new FormData(e.currentTarget as HTMLFormElement);
    formData.forEach((value, property: string) => (formBody[property] = value));
    setSearch(formBody["search"]);
  };

  const onChange = (key: string, data: (string | number)[]) => {
    searchParams.delete(key);
    data.forEach((value) => searchParams.append(key, value.toString()));
    setSearchParams(searchParams);
  };

  return (
    <>
      <StyledBackground>
        <StyledBanner>
          <Container
            maxWidth={false}
            sx={{
              maxWidth: "1040px",
              px: "54px",
            }}
          >
            <Box
              sx={{
                mb: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ alignItems: "flex-start", mt: "48px" }}>
                <Grid item>
                  <Typography
                    variant="caption"
                    sx={{
                      color: "#FFFFFF",
                      fontSize: "36px",
                      fontWeight: 700,
                    }}
                  >
                    Data Dictionary
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="inherit"
                    sx={{
                      color: "#FFFFFF",
                      lineHeight: "26px",
                    }}
                  >
                    Explore coverage and sourcing for our repository of{" "}
                    <Typography
                      sx={{
                        display: "inline",
                        color: theme.palette.secondary.main,
                        lineHeight: "26px",
                      }}
                    >
                      high-impact
                    </Typography>{" "}
                    investigative data.
                  </Typography>
                </Grid>
              </Box>
            </Box>

            <SearchBox
              value={searchInput}
              onChange={handleSearchChange}
              onSubmit={handleSearchSubmit}
            />
            <Filters
              filter={parseVariablesFromSearchParams(searchParams)}
              onChange={onChange}
            />
          </Container>
        </StyledBanner>
      </StyledBackground>

      <Container
        sx={{
          my: "40px",
        }}
      >
        {isFetching && <LoadingText />}
        {/* TODO: create = index dataset?*/}
        {/*{isAdmin && <StyledButton startIcon={<AddIcon />}>Create</StyledButton>}*/}
      </Container>

      <Container>
        {Object.entries(filteredData)
          ?.sort()
          .map(([group, items], index) => (
            <Box key={group + index} sx={{ gap: 3 }}>
              <StyledSectionLabel>{group}</StyledSectionLabel>
              <StyledSectionWrapper>
                {items.map((item: KnowledgeWiki, index, array) => (
                  <>
                    <DataItem
                      key={item.id}
                      item={item}
                      isAdmin={isAdmin}
                      isDraft={item.draft}
                    />
                    {index !== array.length - 1 && <Divider />}
                  </>
                ))}
              </StyledSectionWrapper>
            </Box>
          ))}
      </Container>
    </>
  );
};

export default DataList;
