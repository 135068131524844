import DropZone from "./DropZone";
import { Colors } from "src/view/constants/colors";
import { User } from "src/types/UserGroup";

import {
  Box,
  Dialog,
  DialogContent,
  Typography,
  IconButton,
} from "@mui/material";

import { Close as CloseIcon } from "@mui/icons-material";

import { StyledDialogTitle } from "src/view/users-groups/groups/EditGroup/StyledComponents";

interface Props {
  open: boolean;
  handleClose: () => void;
  handleUpdateCSVData: (data: User[]) => void;
}

const ImportUserFromSCV = ({
  open,
  handleClose,
  handleUpdateCSVData,
}: Props) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth={false}
      sx={{}}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "8px 0px",
        }}
      >
        <Box>
          <StyledDialogTitle id="scroll-dialog">
            Upload User CSV File
          </StyledDialogTitle>
        </Box>
        <Box
          sx={{ display: "flex", alignItems: "center", paddingRight: "24px" }}
        >
          <IconButton
            aria-label="close"
            sx={{
              // width: 35,
              marginLeft: 1,
            }}
            onClick={handleClose}
          >
            <CloseIcon color="primary" />
          </IconButton>
        </Box>
      </Box>
      <DialogContent
        dividers={false}
        sx={{ minWidth: "60vw", overflowY: "unset" }}
      >
        {
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                minHeight: 200,
                width: "100%",
                alignItems: "flex-start",
                justifyContent: "center",
              }}
            >
              <DropZone updateCSVdata={handleUpdateCSVData} />
              <Box sx={{ height: "50px" }}></Box>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "18px",
                  lineHeight: "20px",
                  fontWeight: "700",
                  color: Colors.twilight,
                }}
              >
                Things to keep in mind
              </Typography>
              <Box sx={{ height: 10 }}></Box>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "13px",
                  lineHeight: "17px",
                  fontWeight: "400",
                  color: Colors.textGray100,
                }}
              >
                1. Please follow the format as per the sample file. Only .csv
                files are supported.
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "13px",
                  lineHeight: "17px",
                  fontWeight: "400",
                  color: Colors.textGray100,
                }}
              >
                2. All columns except 'email' are optional.
              </Typography>
            </Box>
          </Box>
        }
      </DialogContent>
    </Dialog>
  );
};

export default ImportUserFromSCV;
