import { memo } from "react";
import { Box, BoxProps, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Cancel } from "@mui/icons-material";
import { FilterKeys, FilterNames, SearchFilter } from "src/types/SearchFilter";
import { Colors } from "src/view/constants/colors";

import {
  ImgIconCountry,
  ImgIconDataset,
  ImgIconDocType,
  ImgIconLanguage,
  ImgIconRegion,
  ImgIconResultType,
} from "src/assets/icons";

interface FilterBubbleProps extends BoxProps {
  variant?: "inclusion" | "exclusion";
  value?: number;
}

const StyledFilterBubble = styled(Box, {
  shouldForwardProp: (prop) => !["variant", "value"].includes(prop as string),
})<FilterBubbleProps>(({ variant, value, theme }) => ({
  minWidth: 14,
  height: 14,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: 11,
  fontWeight: 700,
  lineHeight: 1,
  color: "white",
  backgroundColor: theme.palette.grey[700],
  borderRadius: value && Math.abs(value) < 10 ? "50%" : theme.spacing(2),
  padding:
    value && Math.abs(value) < 10 ? theme.spacing(0.5) : theme.spacing(0.5, 1),
  ...(variant === "inclusion" && {
    backgroundColor: theme.palette.primary.main,
  }),
  ...(variant === "exclusion" && {
    backgroundColor: theme.palette.grey[600],
  }),
}));

const FilterName = styled(Typography)({
  color: "#122945",
  fontWeight: 700,
  fontSize: 12,
  lineHeight: 1,
});

interface filterItem {
  name: FilterNames;
  icon: string;
  key: FilterKeys;
}

const filters: filterItem[] = [
  {
    name: FilterNames.DATASET_IDS,
    icon: ImgIconDataset,
    key: FilterKeys.DATASET_IDS,
  },
  {
    name: FilterNames.DOC_TYPES,
    icon: ImgIconResultType,
    key: FilterKeys.DOC_TYPES,
  },
  {
    name: FilterNames.DATASET_TYPES,
    icon: ImgIconDocType,
    key: FilterKeys.DATASET_TYPES,
  },
  {
    name: FilterNames.COUNTRIES,
    icon: ImgIconCountry,
    key: FilterKeys.COUNTRIES,
  },
  {
    name: FilterNames.REGIONS,
    icon: ImgIconRegion,
    key: FilterKeys.REGIONS,
  },
  {
    name: FilterNames.LANGUAGES,
    icon: ImgIconLanguage,
    key: FilterKeys.LANGUAGES,
  },
];

interface Props {
  selectedTab: FilterKeys;
  setSelectedTab: (key: FilterKeys) => void;
  filter: SearchFilter;
  setFilter: (filter: SearchFilter) => void;
  handleRemoveFiltersCustom?: (key: FilterKeys) => void;
}

const FilterTabs = ({
  selectedTab,
  filter,
  setFilter,
  setSelectedTab,
  handleRemoveFiltersCustom,
}: Props) => {
  const handleRemoveFilters = (key: FilterKeys) => {
    // TODO: figure out dataset_type filter quirk
    if (typeof handleRemoveFiltersCustom === "function") {
      return handleRemoveFiltersCustom(key);
    }
    setFilter({ ...filter, [key]: [] });
  };

  return (
    <Grid container sx={{ p: "14px 6px", borderBottom: "1px solid #CED3D9" }}>
      {filters.map(({ key, icon, name }) => {
        const isSelected = key === selectedTab;

        return (
          <Grid key={key} item xs={4} p="6px">
            <Box
              sx={{
                border:
                  "1px solid " +
                  (isSelected ? Colors.textDark100 : "transparent"),
                borderRadius: "6px",
                background: "#F0F1F3",
                padding: "10px",
                cursor: "pointer",
              }}
              onClick={() => setSelectedTab(key)}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={1}
                height={20}
              >
                <img src={icon} width="16" height="16" />
                {key === FilterKeys.DATASET_IDS ? (
                  <Box sx={{ display: "flex" }}>
                    {filter?.dataset_ids?.length > 0 && (
                      <StyledFilterBubble
                        variant="inclusion"
                        value={filter.dataset_ids.length}
                      >
                        {filter.dataset_ids.length}
                      </StyledFilterBubble>
                    )}
                    {filter?.exclude_dataset_ids?.length > 0 && (
                      <StyledFilterBubble
                        sx={{ ml: -0.5 }}
                        variant="exclusion"
                        value={filter.exclude_dataset_ids.length}
                      >
                        {filter.exclude_dataset_ids.length}
                      </StyledFilterBubble>
                    )}
                  </Box>
                ) : (
                  filter?.[key]?.length > 0 && (
                    <StyledFilterBubble
                      variant="inclusion"
                      value={filter?.[key]?.length}
                    >
                      {filter[key].length}
                      <Cancel
                        sx={{
                          width: 12,
                          height: 12,
                          cursor: "pointer",
                        }}
                        onClick={() => handleRemoveFilters(key)}
                      />
                    </StyledFilterBubble>
                  )
                )}
              </Box>
              <FilterName sx={{ color: isSelected ? "#122945" : "#536378" }}>
                {name}
              </FilterName>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default memo(FilterTabs);
