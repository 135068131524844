import { AnyAction, Dispatch } from "redux";
import Errors from "src/modules/shared/error/errors";
import BulkSearchService from "../bulkSearchService";

const prefix = "BULK_SEARCH_QUERY_GROUP";

const queryGroupActions: AnyAction = {
  LOADING: `${prefix}_LOADING`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,
  CREATE: `${prefix}_CREATE`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,
  CLEAR_ERROR: `${prefix}_CLEAR_ERROR`,
  GET_QUERY_GROUPS: `${prefix}GET_QUERY_GROUPS`,
  DELETE_ONE: `${prefix}_DELETE_ONE`,
  CLEAR_UPDATE_STATUS: `${prefix}_CLEAR_UPDATE_STATUS`,
  SELECT_SEARCH_TERM: `${prefix}_SELECT_SEARCH_TERM`,
  GET_SEARCH_RESULT: `${prefix}_GET_SEARCH_RESULT`,
  GET_SEARCH_RESULT_ERROR: `${prefix}_GET_SEARCH_RESULT_ERROR`,
  SEARCH_RESULT_QUERY_GROUP: `${prefix}_SEARCH_RESULT_QUERY_GROUP`,
  SEARCH_QUERY_GROUP_ERROR: `${prefix}_SEARCH_QUERY_GROUP_ERROR`,
  UPDATED: `${prefix}_UPDATED`,

  create: (data: object) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({
        type: queryGroupActions.LOADING,
        payload: true,
      });

      const response = await BulkSearchService.createQueryGroup(data);

      if (response == false) {
        dispatch({
          type: queryGroupActions.CREATE_ERROR,
        });
      } else {
        dispatch({
          type: queryGroupActions.CREATE,
          payload: response,
        });
      }
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: queryGroupActions.CREATE_ERROR,
        payload: false,
      });
    }
  },
  clearError: () => async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: queryGroupActions.CLEAR_ERROR,
    });
  },
  getQueryGroups:
    (screening_list_id: string) => async (dispatch: Dispatch<AnyAction>) => {
      try {
        dispatch({
          type: queryGroupActions.LOADING,
          payload: true,
        });

        const response = await BulkSearchService.getQueryGroups(
          screening_list_id
        );

        if (response == false) {
          dispatch({
            type: queryGroupActions.FIND_ERROR,
          });
        } else {
          dispatch({
            type: queryGroupActions.GET_QUERY_GROUPS,
            payload: response,
          });
        }
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: queryGroupActions.FIND_ERROR,
        });
      }
    },

  update:
    (data: object, id: string) => async (dispatch: Dispatch<AnyAction>) => {
      try {
        dispatch({
          type: queryGroupActions.LOADING,
          payload: true,
        });

        const response = await BulkSearchService.updateQueryGroup(data, id);

        dispatch({
          type: queryGroupActions.UPDATED,
          payload: response,
        });
      } catch (error) {
        Errors.handle(error);
      }
    },
  clearUpdateStatus: () => async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: queryGroupActions.CLEAR_UPDATE_STATUS,
    });
  },
  delete: (id: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({
        type: queryGroupActions.LOADING,
        payload: true,
      });

      await BulkSearchService.deleteQueryGroup(id);

      dispatch({
        type: queryGroupActions.DELETE_ONE,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: queryGroupActions.CREATE_ERROR,
        payload: false,
      });
    }
  },
  deleteSearchTerm: (id: string) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({
        type: queryGroupActions.LOADING,
        payload: true,
      });

      const response = await BulkSearchService.deleteSearchTerm(id);

      if (response !== false) {
        dispatch({
          type: queryGroupActions.DELETE_ONE,
        });
      }
      return response;
    } catch (error) {
      Errors.handle(error);
    }
  },
  runSearch:
    (id: string, page: number) => async (dispatch: Dispatch<AnyAction>) => {
      try {
        dispatch({
          type: queryGroupActions.LOADING,
          payload: true,
        });
        const response = await BulkSearchService.performSearch(id, page);
        if (response.error) {
          dispatch({
            type: queryGroupActions.GET_SEARCH_RESULT_ERROR,
            payload: response.detail,
          });
        } else {
          dispatch({
            type: queryGroupActions.GET_SEARCH_RESULT,
            payload: response,
          });
        }
        dispatch({
          type: queryGroupActions.LOADING,
          payload: false,
        });
        return response;
      } catch (error) {
        Errors.handle(error);
      }
    },
  runQueryGroupSearch:
    (id: string) => async (dispatch: Dispatch<AnyAction>) => {
      try {
        const response = await BulkSearchService.performSearchQueryGroup(id);
        if (response.error) {
          dispatch({
            type: queryGroupActions.SEARCH_QUERY_GROUP_ERROR,
            payload: response.detail,
          });
        } else {
          dispatch({
            type: queryGroupActions.SEARCH_RESULT_QUERY_GROUP,
            payload: response,
          });
        }
        return response;
      } catch (error) {
        Errors.handle(error);
      }
    },
  getQueryGroupMonitorStatus:
    (id: string) => async (dispatch: Dispatch<AnyAction>) => {
      try {
        const response = await BulkSearchService.getQueryGroupMonitorStatus(id);
        if (response.error) {
          dispatch({
            type: queryGroupActions.SEARCH_QUERY_GROUP_ERROR,
            payload: response.detail,
          });
        } else {
          dispatch({
            type: queryGroupActions.SEARCH_RESULT_QUERY_GROUP,
            payload: response,
          });
        }
        return response;
      } catch (error) {
        Errors.handle(error);
      }
    },
  type: undefined,
};

export default queryGroupActions;
