import { useMemo } from "react";
import { Table, TableBody, TableRow, TableCell, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { getHighlightText } from "src/utils/get-highlight-text";

const Cell = styled(TableCell)({
  borderBottom: "none",
  paddingTop: 2,
  paddingBottom: 2,
});

const Cell1 = styled(Cell)({
  fontWeight: 700,
  fontSize: 12,
  color: "#536378",
  width: "1%",
  whiteSpace: "nowrap",
  textTransform: "capitalize",
});

const Cell2 = styled(Cell)({
  fontSize: 14,
  color: "#122945",
  paddingLeft: 0,
});

interface Props {
  data: unknown[];
}

const ListView = ({ data }: Props) => {
  const fields = useMemo(() => {
    const highlightedFields: string[] = [];
    const regularFields: string[] = [];
    if (data?.[0]) {
      Object.keys(data[0]).forEach((key) => {
        if (data.some((row) => row[key].includes("</span"))) {
          highlightedFields.push(key);
        } else {
          regularFields.push(key);
        }
      });
    }
    return [...highlightedFields, ...regularFields];
  }, [data]);

  const label = (txt: string) => {
    const _label = txt.replace(/_/gi, " ").toLowerCase();
    return _label;
  };

  return (
    <Box
      sx={{
        maxHeight: 180,
        overflow: "auto",
        borderTop: "1px solid #CED3D9",
      }}
    >
      <Table>
        <TableBody>
          {fields.map((field: string) => {
            const content = data[0][field];
            const isFieldEmpty = !content.length;
            return (
              <TableRow key={field}>
                {!isFieldEmpty && <Cell1>{label(field)}:</Cell1>}
                <Cell2>
                  {content && !isFieldEmpty && (
                    <div
                      style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    >
                      {content.includes("<span")
                        ? getHighlightText(content)
                        : content}
                    </div>
                  )}
                </Cell2>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

export default ListView;
