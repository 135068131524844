import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTableBody = styled(Box)(() => ({
  width: "100%",
  border: "1px solid #DFE5E9",
  borderRadius: 8,
  padding: 0,
}));

function TableBody(props: { children: JSX.Element | JSX.Element[] }) {
  return <StyledTableBody>{props.children}</StyledTableBody>;
}

export default TableBody;
