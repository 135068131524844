import { Box, Checkbox, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchFilter } from "src/types/SearchFilter";
import selector from "src/modules/bulk-search/filter/searchFilterSelectors";
import actions from "src/modules/bulk-search/filter/searchFilterActions";
import SearchIcon from "@mui/icons-material/Search";
import ImageIcon from "src/view/components/ImageIcon";
import { ImgIconSortByAZ } from "src/assets/icons";
import { useFilterOptions } from "src/modules/api/filters";

const StyledFilterCollapseForm = styled(Box)(() => ({
  width: "100%",
  height: "calc(100% - 210px)",
}));

const StyledFilterContainer = styled(Box)(() => ({
  padding: 10,
  height: "calc(100% - 20px)",
}));

const StyledInlineFormControl = styled(Box)(() => ({
  display: "flex",
  alignItems: "flex-start",
  padding: "10px 0",
  paddingLeft: "5px",
  borderBottom: "1px solid rgba(65, 64, 66, 0.16)",
  "&:last-child": {
    borderBottom: "none",
  },
  position: "relative",
}));

const StyledSearchBox = styled("div")(() => ({
  position: "relative",
  height: 40,
  width: "100%",
  maxWidth: 680,
  display: "flex",
  alignItems: "center",
  marginLeft: "auto",
  marginRight: "auto",
}));

const StyledSearchIcon = styled(SearchIcon)(() => ({
  position: "absolute",
  left: 12,
  fontSize: 16,
  color: "#043D5D",
}));

const SearchInput = styled("input")(() => ({
  height: "100%",
  width: "100%",
  borderRadius: 4,
  paddingLeft: 34,
  paddingRight: 40,
  fontSize: 12,
  color: "#414042",
  border: "1px solid rgba(65, 64, 66, 0.16)",
  "&:focus-visible": {
    outline: "none",
  },
}));
const StyledImageButton = styled("div")(() => ({
  position: "absolute",
  right: 12,
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
}));
interface ListItem {
  label: string;
  value?: string;
  doc_count?: number;
}

const CountryFilter = (props: { editable?: boolean }) => {
  const { editable } = props;
  const { countries } = useFilterOptions();
  const filter = useSelector(selector.selectFilter);
  const dispatch = useDispatch();
  const [formData, setFormData] = React.useState<SearchFilter>({
    countries: [],
    ...filter,
  });
  const [data, setData] = React.useState([]);
  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    const _types = [...countries];
    setData(_types);
  }, []);

  useEffect(() => {
    if (filter === null) {
      setFormData({
        dataset_types: [],
        regions: [],
        languages: [],
        countries: [],
        dataset_ids: [],
        doc_types: [],
      });
    } else {
      setFormData(filter);
    }
  }, [filter]);

  useEffect(() => {
    arrangeData();
  }, [data]);

  useEffect(() => {
    const filterContainer = document.querySelector("#result-filter-container");
    filterContainer.scrollTo({ top: 0 });
  }, [data]);

  const arrangeData = () => {
    if (data) {
      const temp = [...data];
      temp.sort((a, b) => a.label.localeCompare(b.label));
      setFiltered(temp);
    }
  };

  const handleCheck = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: ListItem
  ) => {
    const vals = formData.countries ? [...formData.countries] : [];
    if (e.target.checked) {
      vals.push(item.value);
    } else {
      const index = vals.indexOf(item.value);
      vals.splice(index, 1);
    }

    setFormData({ ...formData, countries: [...vals] });
    dispatch(actions.setFilter({ ...formData, countries: [...vals] }));
  };

  const handleFilter = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value == "") {
      arrangeData();
    } else {
      const ds = [...data];
      const _filtered = ds.filter((item) =>
        item.label.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setFiltered(_filtered);
    }
  };

  return (
    <StyledFilterCollapseForm>
      <StyledFilterContainer
        sx={{ height: editable ? "calc(100% - 180px)" : "calc(100% - 20px)" }}
      >
        <StyledSearchBox>
          <StyledSearchIcon />
          <SearchInput placeholder="Search country" onChange={handleFilter} />
          <StyledImageButton>
            <ImageIcon src={ImgIconSortByAZ} sx={{ width: 20, height: 20 }} />
          </StyledImageButton>
        </StyledSearchBox>
        <Box
          sx={{
            height: "calc(100% - 24px)",
            overflowY: "auto",
            pr: 2,
            pl: 2,
            pt: 1,
            pb: 1,
          }}
          id={"result-filter-container"}
        >
          {filtered?.map((item: ListItem, index: number) => {
            if (formData.countries?.includes(item["value"]))
              return (
                <StyledInlineFormControl key={index}>
                  <Checkbox
                    sx={{ padding: 0, width: "16px", marginRight: "8px" }}
                    color={"secondary"}
                    checked={formData.countries?.includes(item["value"])}
                    onChange={(e) => handleCheck(e, item)}
                  />
                  <Typography variant="caption">{item["label"]}</Typography>
                </StyledInlineFormControl>
              );
          })}
          {filtered?.map((item: ListItem, index: number) => {
            if (!formData.countries?.includes(item["value"]))
              return (
                <StyledInlineFormControl key={index}>
                  <Checkbox
                    sx={{ padding: 0, width: "16px", marginRight: "8px" }}
                    color={"secondary"}
                    checked={formData.countries?.includes(item["value"])}
                    onChange={(e) => handleCheck(e, item)}
                  />
                  <Typography variant="caption">{item["label"]}</Typography>
                </StyledInlineFormControl>
              );
          })}
        </Box>
      </StyledFilterContainer>
    </StyledFilterCollapseForm>
  );
};

export default CountryFilter;
