import { createContext } from "react";
import { User } from "src/types/Auth";
import { LogoutOptions } from "@auth0/auth0-react";

export interface UserContextInterface {
  user: User | null;
  logout: (options?: LogoutOptions) => void;
}

const UserContext = createContext<UserContextInterface | null>(null);

UserContext.displayName = "UserContext";

export default UserContext;
