import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Document, Page, pdfjs, Thumbnail } from "react-pdf";
import type { PDFDocumentProxy } from "pdfjs-dist";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  styled,
  Typography,
} from "@mui/material";
import {
  renderContent,
  StyledContainer,
} from "src/view/search-result/components/Preview/Source/SourcePreviews";
import ControlBar from "src/view/search-result/components/Preview/Source/ControlBar";
import theme from "src/theme";
import { isStringRTL } from "src/utils";
import { getHighlightText } from "src/utils/get-highlight-text";
import { PreviewDocListProps } from "src/modules/search/view/searchViewReducers";
import {
  fixedLayoutHeights,
  PreviewContext,
} from "src/view/search-result/components/Preview/Source/PreviewContext";
import { OnItemClickArgs } from "react-pdf/dist/cjs/shared/types";
import HighlightActionMenu from "src/view/components/HighlightActionMenu";
import SearchService from "src/modules/search/searchService";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface Props {
  fileUrl?: string;
  data: PreviewDocListProps | any[];
  loading: boolean;
  handleClosePreview: (shouldSetURLParam?: boolean) => void;
}

const PdfPreview: React.FC<Props> = ({ data, loading, handleClosePreview }) => {
  const { tab, headerHeight, previewDocInfo } = useContext(PreviewContext);
  const [linkedSourceData, setLinkedSourceData] = useState(null);
  const displayData = linkedSourceData ?? data;

  useEffect(() => {
    // fetch fileUrl separately for Document entities in modeled data
    if (data?.["entity"]?.["_source"]?.["schema"] === "Document") {
      getFileFromDocumentEntity();
    }
  }, [data]);

  const getFileFromDocumentEntity = useCallback(async () => {
    try {
      const sourceData = await SearchService.getDocumentPreview(
        data["entity"]["_source"].wikidataId[0]
      );
      setLinkedSourceData(sourceData);
    } catch (err) {
      console.error("failed to fetch linked document", err);
    }
  }, [data]);

  const scrollableContainerHeight = useMemo(() => {
    const headerOffset =
      headerHeight +
      fixedLayoutHeights.controlBar +
      fixedLayoutHeights.padding * 8;
    return `calc(100vh - ${headerOffset}px)`;
  }, [headerHeight, fixedLayoutHeights.controlBar, fixedLayoutHeights.padding]);

  return (
    <StyledContainer sx={{ position: "sticky", top: 0, mt: "20px" }}>
      <ControlBar />
      <Divider
        sx={{
          marginTop: "20px",
        }}
      />
      {renderContent(
        displayData,
        loading,
        <Box
          sx={{
            display: "block",
            borderRadius: "6px",
            padding: " 20px",
            overflow: "auto",
          }}
          aria-label="document preview"
        >
          <Box
            className="PDF-viewer"
            id={"PDF-viewer"}
            sx={{
              display: tab === 0 ? "block" : "none",
            }}
          >
            <PDFDocument fileUrl={displayData?.[0]?.["file_url"]} />
          </Box>
          <Box
            sx={{
              height: scrollableContainerHeight,
              overflow: "auto",
              justifyContent: "center",
              display: tab === 1 ? "flex" : "none",
            }}
          >
            {Array.isArray(displayData) &&
              displayData.map((row: any, index: number) => {
                let rawText = row?.["content"]?.join("");
                previewDocInfo?.highlight?.["content"]?.map((row: string) => {
                  const h_txt = getHighlightText(row);
                  const r_txt = row.replace(/<\/em>/g, "");
                  rawText = rawText?.replace(r_txt, h_txt) || "";
                });
                const isRTL = isStringRTL(rawText);

                return (
                  <HighlightActionMenu
                    onSearchInTab={handleClosePreview}
                    key={index}
                  >
                    <TextPreview
                      sx={{ direction: isRTL ? "rtl" : "ltr" }}
                      dangerouslySetInnerHTML={{ __html: rawText }}
                    />
                  </HighlightActionMenu>
                );
              })}
          </Box>
        </Box>
      )}
    </StyledContainer>
  );
};

const StyledDocument = styled(Document)(() => ({
  display: "flex",
  width: "100%",
}));

const PageContainer = styled(Box)(() => ({
  width: "100%",
  overflow: "auto",
  position: "relative",
}));

const StyledPage = styled(Page)(() => ({
  marginBottom: "20px",
  "& canvas": {
    display: "flex",
    border: "1px solid #E1E4E8",
    maxWidth: "unset",
    maxHeight: "unset",
    margin: "0 auto",
  },
}));

const ThumbBar = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "102px",
}));

const ThumbnailWrap = styled(Box)(() => ({
  padding: "7px 13px",
  background: "#F0F1F3",
  "& canvas": {
    height: "100% !important",
    maxWidth: "100%",
    maxHeight: "100%",
  },
}));

const ThumbnailNumber = styled(Box)(() => ({
  marginTop: "3px",
  textAlign: "center",
  width: "100%",
  fontSize: "10px",
  fontWeight: 700,
}));

const ThumbBarWrap = styled(Box)(() => ({
  overflowY: "auto",
  overflowX: "hidden",
  scrollbarWidth: "thin",
  scrollbarGutter: "stable both-edges",
  paddingRight: "33px",
}));

const TextPreview = styled(Box)(() => ({
  width: "70vw",
  padding: "30px 50px",
  fontSize: "12px",
  lineHeight: "15px",
  color: "#000000",
  whiteSpace: "pre-wrap",
}));

const PDFDocument: React.FC<{ fileUrl: string }> = ({ fileUrl }) => {
  const {
    page,
    setPage,
    numPages,
    setNumPages,
    headerHeight,
    scale,
    contentHeight,
    setContentHeight,
    split,
    originalPage,
  } = useContext(PreviewContext);
  const [isPDFRendering, setIsPDFRendering] = useState(true);

  const pageCanvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const thumbListRef = useRef<HTMLCanvasElement[]>([]);
  const pageListRef = useRef<HTMLDivElement[]>([]);

  const headerOffset =
    headerHeight +
    fixedLayoutHeights.controlBar +
    fixedLayoutHeights.padding * 8;
  const scrollableContainerHeight = `calc(100vh - ${headerOffset}px)`;

  useEffect(() => {
    if (!containerRef?.current) return;
    const observer = new ResizeObserver((observerEntry) =>
      handleResizePreviewContainer(observerEntry)
    );
    observer.observe(containerRef?.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  const handleResizePreviewContainer = (
    observerEntry: ResizeObserverEntry[]
  ) => {
    const prevContainer = document.getElementById("PDF-viewer");
    if (
      observerEntry[0].borderBoxSize[0].blockSize &&
      prevContainer.offsetHeight > fixedLayoutHeights.content
    ) {
      // prevent setting height on tab switch when display is none
      // or content has not loaded yet
      setContentHeight(
        prevContainer.offsetHeight - fixedLayoutHeights.padding * 4
      );
    }
  };

  const handleThumbClick = (args: OnItemClickArgs) => {
    setPage(args.pageNumber);
  };

  // handles blank page between document fetching and initial render
  useEffect(() => {
    const pageCanvas = pageCanvasRef.current;
    if (!pageCanvas) {
      setIsPDFRendering(true);
    }
  }, [pageCanvasRef.current]);

  // scroll thumbnail and page views based on selected page
  useEffect(() => {
    if (isPDFRendering) {
      return;
    }
    if (thumbListRef.current.length) {
      setTimeout(
        () =>
          thumbListRef.current[page - 1]?.scrollIntoView({
            behavior: "auto",
            block: "center",
            inline: "nearest",
          }),
        100
      );
    }
    if (pageListRef.current.length) {
      setTimeout(
        () =>
          pageListRef.current[page - 1]?.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "nearest",
          }),
        200
      );
    }
  }, [
    isPDFRendering,
    page,
    pageListRef.current.length,
    thumbListRef.current.length,
  ]);

  // hook up to visibility: hidden as a way to check when the pdf page is blank when rerendering
  useLayoutEffect(() => {
    const pageCanvas = pageCanvasRef.current;

    if (pageCanvas) {
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (
            mutation.type === "attributes" &&
            mutation.attributeName === "style"
          ) {
            const newStyles = pageCanvas.attributes.getNamedItem("style");
            setIsPDFRendering(newStyles.value.includes("visibility: hidden"));
          }
        }
      });

      const observerConfig = { attributes: true };
      observer.observe(pageCanvas, observerConfig);

      return () => {
        observer.disconnect();
      };
    }
  }, [contentHeight]);

  const onDocumentLoadSuccess = ({
    numPages: nextNumPages,
  }: PDFDocumentProxy): void => {
    setNumPages(nextNumPages);
    setIsPDFRendering(false);
  };

  const setPageListRef = (index: number) => (ref: HTMLDivElement) => {
    pageListRef.current[index] = ref;
  };
  const setThumbListRef = (index: number) => (instance: HTMLCanvasElement) => {
    thumbListRef.current[index] = instance;
  };

  return (
    <Box
      ref={containerRef}
      id="preview-container"
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {fileUrl && (
        <StyledDocument
          onLoadSuccess={onDocumentLoadSuccess}
          file={fileUrl}
          loading={() => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <CircularProgress size={20} sx={{ marginRight: "10px" }} />
              Loading...
            </Box>
          )}
          error={() =>
            "Failed to get document. Please try closing and reopening the preview"
          }
        >
          <ThumbBarWrap>
            <ThumbBar height={scrollableContainerHeight}>
              {Array.from(Array(numPages).keys(), (index) => {
                const isMatched = originalPage === index + 1;
                return (
                  <ThumbnailWrap
                    key={`preview_${index + 1}`}
                    sx={{
                      "& canvas": {
                        ...(isMatched && {
                          border: "2px solid #F4F82B",
                        }),
                        ...(page === index + 1
                          ? { opacity: 1 }
                          : { opacity: 0.4 }),
                      },
                    }}
                  >
                    <Thumbnail
                      canvasRef={setThumbListRef(index)}
                      pageNumber={index + 1}
                      scale={0.2}
                      onItemClick={handleThumbClick}
                    />
                    <ThumbnailNumber>{index + 1}</ThumbnailNumber>
                  </ThumbnailWrap>
                );
              })}
            </ThumbBar>
          </ThumbBarWrap>

          <PageContainer height={scrollableContainerHeight}>
            <Backdrop
              open={isPDFRendering}
              sx={{
                position: "sticky",
                "& .MuiBackdrop-root": {
                  top: 0,
                },
                zIndex: theme.zIndex.drawer + 1,
                backgroundColor: "#00000080",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: isPDFRendering ? scrollableContainerHeight : 0,
                }}
              >
                <CircularProgress
                  size={50}
                  sx={{
                    color: "#FFF",
                  }}
                />
              </Box>
            </Backdrop>

            <Box
              sx={{
                ...(split
                  ? {
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      columnGap: "6px",
                    }
                  : { display: "flex", flexDirection: "column" }),
              }}
            >
              {Array.from(Array(numPages).keys(), (index) => (
                <Box
                  id={`pdf-page-${index}`}
                  key={`page_${index + 1}`}
                  ref={setPageListRef(index)}
                >
                  <StyledPage
                    canvasRef={pageCanvasRef}
                    width={0}
                    height={(contentHeight / 100) * scale}
                    pageNumber={index + 1}
                    renderTextLayer={false}
                    scale={1}
                  />
                  <Typography
                    sx={{
                      display: isPDFRendering ? "none" : "block",
                      fontWeight: 700,
                      textAlign: "center",
                      marginTop: "-20px",
                      marginBottom: "24px",
                    }}
                  >
                    {index + 1}
                  </Typography>
                </Box>
              ))}
            </Box>
          </PageContainer>
        </StyledDocument>
      )}
    </Box>
  );
};

export default PdfPreview;
