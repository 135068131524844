import React, { useContext, useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import regions from "src/assets/json/regions.json";
import languages from "src/assets/json/languages.json";
import { getHighlightText } from "src/utils/get-highlight-text";
import "react-json-pretty/themes/monikai.css";
import DownloadMenu from "./DownloadMenu";
import SearchResultCardHeader, {
  SearchResultCardHeaderProps,
} from "./SearchResultCardHeader";
import SearchResultCardPreview from "./SearchResultCardPreview";
import { format, isValid } from "date-fns";
import { useKnowledgeWiki } from "src/modules/api/knowledgeWiki";
import { PreviewDocProps } from "src/modules/search/view/searchViewReducers";
import { SelectOption } from "src/types/Shared";
import { useFilterOptions } from "src/modules/api/filters";
import SearchResultCardActions from "src/view/search-result/components/SearchResultCard/SearchResultCardActions";
import { useSearch } from "src/modules/api/search";
import { DataIndex, GetDownloadDocumentURLParams } from "src/types/Search";
import { usePostHog } from "posthog-js/react";
import { PreviewContext } from "src/view/search-result/components/Preview/Source/PreviewContext";
import { useNavigate } from "react-router-dom";
import {
  formatVariablesSearchParam,
  formatPreviewSearchParam,
} from "src/modules/api/search/SearchProvider";
import { HogEvent } from "src/types/PosthogEvents";

const StyledCard = styled(Box)(() => ({
  backgroundColor: "white",
  boxShadow: "0px 0px 54px rgba(160, 185, 208, 0.3)",
  borderRadius: 4,
  padding: 8,
  marginBottom: 12,
  height: "auto",
}));

interface Props {
  type?: string;
  fileName?: string;
  data?: any;
  datasetId: string;
  id?: string;
  source_type?: number;
  highlight?: any;
}

const SearchResultCard: React.FC<Props> = ({
  type,
  fileName,
  data,
  datasetId,
  id,
  source_type,
  highlight,
}) => {
  const posthog = usePostHog();
  const navigate = useNavigate();
  const { setSelectedResult } = useContext(PreviewContext);
  const { getDownloadDocumentUrl, variables } = useSearch();
  const { getKnowledgeWikis } = useKnowledgeWiki();
  const { data: knowledgeWiki } = getKnowledgeWikis();
  const { countries } = useFilterOptions();
  const [gettingDownloadLink, setGettingDownloadLink] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [resultMetadata, setResultMetadata] = useState<PreviewDocProps>({
    _id: "",
    data: undefined,
    dataset: undefined,
    highlight: undefined,
    source_type: undefined,
    title: "",
    type: "",
    updatedOn: "",
    wikiItem: undefined,
  });

  useEffect(() => {
    if (knowledgeWiki?.length) {
      const _wiki = knowledgeWiki.find(({ dataset }) => dataset === datasetId);
      const _dataset: unknown = {};
      const _countries = _wiki?.countries.map(
        (country: unknown) =>
          countries.filter((c: SelectOption) => c.value === country)[0]?.label
      );
      const _languages = _wiki?.languages.map(
        (lang: unknown) =>
          languages.filter((c: SelectOption) => c.label === lang)[0]?.label
      );
      const _regions = _wiki?.regions.map(
        (region: unknown) =>
          regions.filter((c: SelectOption) => c.label === region)[0]?.label
      );

      const title = data?.name ?? _wiki?.name ?? "";
      const updatedOn =
        source_type === 0
          ? data.last_updated
          : _wiki?.updated
          ? new Date(_wiki.updated)
          : null;
      _dataset["Country"] = _countries?.join(",");
      _dataset["Language"] = _languages?.join(",");
      _dataset["Region"] = _regions?.join(",");
      _dataset["Dataset Type"] = _wiki?.dataset_type ?? "None";
      _dataset["Sensitivity"] = _wiki?.sensitivity ?? "None";
      _dataset["Data Dictionary"] = _wiki?.name;
      _dataset["title"] = title;
      _dataset["fileName"] = fileName;

      setResultMetadata({
        title,
        type: type,
        source_type: source_type,
        dataset: _dataset,
        updatedOn,
        data: data,
        _id: id,
        wikiItem: _wiki,
        highlight: highlight,
      });
    }
  }, [knowledgeWiki, datasetId]);

  const [downloadParams, setDownloadParams] =
    useState<GetDownloadDocumentURLParams | null>(null);

  const dataIndex = source_type === 0 ? DataIndex.modeled : DataIndex.source;

  const { data: downloadUrl, status: downloadUrlStatus } =
    getDownloadDocumentUrl(downloadParams);

  if (downloadUrlStatus === "success") {
    setDownloadParams(null);
    setGettingDownloadLink(false);
    if (downloadUrl) {
      const downloadWindow = window.open(downloadUrl, "_blank");
      downloadWindow?.focus();
    }
  }

  if (downloadUrlStatus === "error") {
    setDownloadParams(null);
    setGettingDownloadLink(false);
  }

  const handleDownloadDoc = async (entireFile?: boolean) => {
    setGettingDownloadLink(true);
    posthog.capture(
      source_type === 1
        ? HogEvent.SOURCE_DATA_DOWNLOADED
        : HogEvent.MODEL_DATA_DOWNLOADED
    );
    setDownloadParams({
      id,
      dataIndex,
      entireFile,
    });
  };

  if (!data) return null;

  const getDocumentPreview = () => {
    posthog.capture(
      source_type === 1
        ? HogEvent.SOURCE_DATA_PREVIEWED
        : HogEvent.MODEL_DATA_PREVIEWED
    );
    setSelectedResult(data[0]);
    navigate(
      `/search?q=${formatVariablesSearchParam({
        ...variables,
      })}&preview=${formatPreviewSearchParam(resultMetadata)}`
    );
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const cardHeaderProps: SearchResultCardHeaderProps | null = useMemo(() => {
    if (!knowledgeWiki) {
      return { loading: true };
    }
    if (source_type === 0) {
      return {
        loading: false,
        title:
          highlight && highlight?.name ? (
            <>{getHighlightText(highlight?.name)}</>
          ) : (
            resultMetadata.title
          ),
        subtitle: type,
        type: type,
        onClick: getDocumentPreview,
        tags: Object.entries(data)
          .filter(
            // eslint-disable-next-line
            ([k]) =>
              ![
                "file_name",
                "schema",
                "dataset_id",
                "sourceUrl",
                "ingest_timestamp",
              ].includes(k)
          )
          .map(([k, v]) => {
            const label = k;
            let icon;
            let value = v;
            const labelHighlight = highlight?.[k];
            if (typeof v === "number") {
              value = v.toString();
            }
            if (typeof v === "string" && isValid(v)) {
              value = format(new Date(v), "yyyy-MM-dd");
            }
            if (typeof v === "object") {
              if (Array.isArray(v)) {
                value = v.join(", ");
              } else {
                value = JSON.stringify(v);
              }
            }
            if (
              ["jurisdiction", "nationality", "main_country"].includes(label) &&
              Array.isArray(v)
            ) {
              const iconCode = v[0].toString().toUpperCase();
              icon = (
                <img
                  alt={iconCode}
                  src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${iconCode}.svg`}
                  style={{
                    width: 20,
                    height: 16,
                    objectFit: "contain",
                    marginRight: 5,
                  }}
                />
              );
              value = countries.find(
                (country) => country.value.toLowerCase() == v[0]
              )?.label;
              if (v.length > 1) {
                const extra = v.length - 1;
                value = `${value} +${extra}`;
              }
            }
            return { label, icon, value, hasMatchHighlight: !!labelHighlight };
          }),
        cardActions: (
          <SearchResultCardActions
            resultMetadata={resultMetadata}
            handleOpenMenu={handleOpenMenu}
            gettingDownloadLink={gettingDownloadLink}
          />
        ),
        source_type,
        schema: data.schema,
      } as SearchResultCardHeaderProps;
    }

    if (source_type === 1) {
      return {
        title: resultMetadata?.title,
        subtitle: resultMetadata?.dataset?.fileName,
        type: type,
        dateAdded: resultMetadata?.wikiItem?.created,
        dateSnapshot: resultMetadata?.wikiItem?.date_snapshot,
        onClick: getDocumentPreview,
        tags: [
          {
            label: "Type",
            value: resultMetadata?.dataset?.["Dataset Type"] || "--",
            disableHighlight: true,
          },
          {
            label: "Region",
            value: resultMetadata?.dataset?.["Region"] || "--",
            disableHighlight: true,
          },
          {
            label: "Language",
            value: resultMetadata?.dataset?.["Language"] || "--",
            disableHighlight: true,
          },
          {
            label: "Sensitivity",
            value: resultMetadata?.dataset?.["Sensitivity"] || "--",
            disableHighlight: true,
          },
        ],
        cardActions: (
          <SearchResultCardActions
            resultMetadata={resultMetadata}
            handleOpenMenu={handleOpenMenu}
            gettingDownloadLink={gettingDownloadLink}
          />
        ),
      } as SearchResultCardHeaderProps;
    }

    return null;
  }, [resultMetadata, source_type, type, getDocumentPreview]);

  return (
    <StyledCard className="search_result_card_item">
      <DownloadMenu
        type={type}
        handleDownloadDoc={handleDownloadDoc}
        handleCloseMenu={handleCloseMenu}
        isMenuOpen={!!anchorEl}
        anchorEl={anchorEl}
        source_type={source_type}
      />
      {cardHeaderProps && <SearchResultCardHeader {...cardHeaderProps} />}
      {source_type == 1 && (
        <>
          <SearchResultCardPreview
            id={id}
            data={data}
            type={type}
            getDocumentPreview={getDocumentPreview}
            hasMatches={!!highlight}
            highlight={highlight?.content}
          />
        </>
      )}
    </StyledCard>
  );
};

export default SearchResultCard;
