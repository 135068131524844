import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ConfirmDialog from "src/view/components/ConfirmDialog";
import { Colors } from "src/view/constants/colors";
import GroupAdminColumn from "./GroupAdminColumn";
import { useUserGroups } from "src/modules/api/userGroups";
import { useIntersectionObserver } from "usehooks-ts";
import { Role } from "src/types/Auth";
import { useUser } from "src/modules/api/auth";

const HeadCell = styled(TableCell)({
  whiteSpace: "nowrap",
  fontSize: "12px",
  paddingTop: "34px",
  paddingBottom: "10px",
  fontWeight: "700",
  color: "#9BA5B1",
  borderBottom: "none",
});

const Cell = styled(TableCell)({
  whiteSpace: "nowrap",
  paddingTop: 16,
  paddingBottom: 16,
  fontSize: "16px",
  lineHeight: "20px",
  color: "#122945",
});

const StyledLoadingContainer = styled("div")({
  width: "100%",
  height: "100%",
  minHeight: 200,
  backgroundColor: "#FFFFFF88",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
interface Props {
  searchKey: string;
}

const GroupsTable = ({ searchKey }: Props) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { getGroupsInfinite, deleteGroup } = useUserGroups();
  const {
    data: groups,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = getGroupsInfinite({ searchKey });
  const { mutateAsync: deleteGroupAsync } = deleteGroup();

  const availableGroups = useMemo(() => {
    const allGroups = groups?.pages?.flatMap((page) => page?.results) || [];
    if (user?.role === Role.ADMIN) {
      return allGroups;
    }
    return allGroups.filter((group) =>
      user?.group_admins?.includes(group.group_id)
    );
  }, [groups, user]);

  const { ref: endOfListRef, isIntersecting } = useIntersectionObserver();

  useEffect(() => {
    if (isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  }, [isIntersecting, hasNextPage]);

  if (isLoading)
    return (
      <StyledLoadingContainer>
        <CircularProgress />
      </StyledLoadingContainer>
    );

  if (!availableGroups?.length)
    return (
      <StyledLoadingContainer>
        <Typography fontSize="12px" fontWeight="700" color={Colors.textDark}>
          No results
        </Typography>
      </StyledLoadingContainer>
    );

  return (
    <Box position="relative">
      <Table>
        <TableHead>
          <TableRow>
            <HeadCell>Organization Name</HeadCell>
            <HeadCell>Users</HeadCell>
            <HeadCell>Group Admin(s)</HeadCell>
            <HeadCell sx={{ textAlign: "center" }}>Actions</HeadCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {availableGroups.map((group) => (
            <TableRow
              key={group.group_id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <Cell>
                <Box
                  onClick={() => navigate(`edit/${group.group_id}`)}
                  sx={{
                    maxWidth: "400px",
                    cursor: "pointer",
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {group.name || "--"}
                </Box>
              </Cell>
              <Cell>{group.num_users}</Cell>
              <Cell>
                <GroupAdminColumn admins={group.group_admins} />
              </Cell>
              <Cell>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ConfirmDialog
                    title="Delete Group"
                    description="Are you sure you want to delete this group? This action cannot be undone."
                    confirmText="Confirm"
                    cancelText="Cancel"
                    onConfirm={() => deleteGroupAsync(group.group_id)}
                  >
                    <IconButton size="small" sx={{ height: "100%" }}>
                      <img
                        src={require("src/assets/images/delete.png")}
                        alt="delete icon"
                        width="20"
                        height="20"
                      />
                    </IconButton>
                  </ConfirmDialog>
                </Box>
              </Cell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <div ref={endOfListRef} />

      {isFetchingNextPage && (
        <Box
          sx={{
            display: "flex",
            padding: "30px",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default GroupsTable;
