import { AnyAction, Dispatch } from "redux";
import { SearchFilter } from "src/types/SearchFilter";
import { QueryItem } from "src/types/BulkSearch";

const prefix = "SEARCH_FILTER";

const searchFilterActions: AnyAction = {
  SET_FILTER: `${prefix}_SET_FILTER`,
  SET_TEMP_FILTER: `${prefix}_SET_TEMP_FILTER`,
  SET_QUERY: `${prefix}_SET_QUERY`,
  CLEAR: `${prefix}_CLEAR`,
  setFilter: (data: SearchFilter) => async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: searchFilterActions.SET_FILTER,
      payload: data,
    });
  },
  setTempFilter:
    (data: SearchFilter) => async (dispatch: Dispatch<AnyAction>) => {
      dispatch({
        type: searchFilterActions.SET_TEMP_FILTER,
        payload: data,
      });
    },
  setQuery: (data: QueryItem[]) => async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: searchFilterActions.SET_QUERY,
      payload: data,
    });
  },
  clear: (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: searchFilterActions.CLEAR,
    });
  },
  type: undefined,
};

export default searchFilterActions;
