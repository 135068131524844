import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import { Colors } from "../constants/colors";
import { SelectOption } from "src/types/Shared";
import React from "react";

const StyledTextFieldWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "invalid",
})<{ invalid?: boolean }>(({ invalid }) => ({
  width: "100%",
  background: "#FFFFFF",
  borderRadius: 4,
  marginBottom: 10,
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  padding: 12,
  border: `1px solid ${invalid ? "#F75151" : "#A0B9D0"} `,
}));

interface Props {
  ref?: React.ForwardedRef<any>;
  border?: boolean;
  placeholder?: string;
  prefixIcon?: JSX.Element;
  suffixIcon?: JSX.Element;
  type?: string;
  label?: string;
  helperText?: string;
  options?: Array<SelectOption>;
  value?: string;
  name?: string;
  containerStyle?: React.CSSProperties;
  tabIndex?: number;
  autoFocus?: boolean;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  invalid?: boolean;
}

const CustomTextField = React.forwardRef((props: Props, ref) => {
  const {
    type = "text",
    label,
    name,
    value,
    onChange,
    onKeyPress,
    helperText,
    prefixIcon,
    suffixIcon,
    placeholder,
    containerStyle = {},
    tabIndex = 0,
    autoFocus = false,
    invalid,
  } = props;

  return (
    <StyledTextFieldWrapper style={containerStyle} invalid={invalid}>
      {prefixIcon}
      <TextField
        inputRef={ref as React.ForwardedRef<any>}
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        helperText={helperText}
        type={type}
        placeholder={placeholder}
        // variant="filled"
        fullWidth
        sx={{ border: "none", background: "none" }}
        InputLabelProps={{
          style: {
            fontSize: "14px",
            fontWeight: 400,
            zIndex: 0,
          },
        }}
        InputProps={{
          // disableUnderline: true,
          sx: {
            background: "none",
            fontSize: "14px",
            lineHeight: "17px",
            fontWeight: 700,
            color: Colors.twilight,
            outline: "unset",
            border: "none",
            "& input": {
              marginTop: "0px",
              padding: 0,
              marginLeft: "10px",
            },
            "&:hover": {
              background: "none",
            },
            "& fieldset": {
              border: "none",
            },
          },
        }}
        tabIndex={tabIndex}
        autoFocus={autoFocus}
      />
      {suffixIcon}
    </StyledTextFieldWrapper>
  );
});

export default CustomTextField;
