import { memo, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Colors } from "src/view/constants/colors";
import MatchSelect from "./MatchSelect";
import { QueryItem } from "../type";

const Wrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  width: 290,
  height: 52,
  fontWeight: "700",
  fontSize: 14,
  color: Colors.twilight,
  borderLeft: "1px solid #DFE5E9",
  boxSizing: "border-box",
  overflow: "hidden",
});

const InputWrapper = styled(Box)({
  flex: 1,
  height: "100%",
  input: {
    width: "100%",
    height: "100%",
    paddingLeft: 16,
    fontFamily: "inherit",
    fontWeight: "inherit",
    fontSize: "inherit",
    color: "inherit",
    background: "transparent",
    border: "none",
    outline: "none",
    boxSizing: "border-box",
  },
});

interface Props {
  termId: string;
  index: number;
  item: QueryItem;
  onChange: (termId: string, index: number, updatedInfo: QueryItem) => void;
}

const Query = ({ termId, index, item, onChange }: Props) => {
  const wrapperRef = useRef(null);
  const [editing, setEditing] = useState(false);
  const { value, match } = item;

  useEffect(() => {
    const changeEditing = (e: unknown) => {
      if (e["detail"]["index"] === index) {
        setEditing(true);
      }
    };
    window.addEventListener("editing-query", changeEditing);
    return () => {
      window.removeEventListener("editing-query", changeEditing);
    };
  }, [index]);

  useEffect(() => {
    if (!editing) return;
    const clickOutside = (e: MouseEvent) => {
      if (wrapperRef.current?.contains(e.target)) return;
      if (document.querySelector("#menu-")?.contains(e.target as Node)) return;
      setEditing(false);
    };
    document.addEventListener("mousedown", clickOutside);
    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, [wrapperRef, editing]);

  return (
    <Wrapper
      ref={wrapperRef}
      bgcolor={editing ? "rgba(4, 61, 93, 0.05)" : "#FFFFFF"}
      onClick={() => setEditing(true)}
    >
      {editing ? (
        <>
          <InputWrapper>
            <input
              value={value}
              autoFocus
              onChange={(e) =>
                onChange(termId, index, { value: e.target.value })
              }
              placeholder="Enter Term"
            />
          </InputWrapper>
          <MatchSelect
            value={match}
            onChange={(value) => onChange(termId, index, { match: value })}
          />
        </>
      ) : (
        <Box px="16px">{value}</Box>
      )}
    </Wrapper>
  );
};

export default memo(Query);
