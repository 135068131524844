import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactComponent as ListSvg } from "src/assets/icons/list.svg";
import { ReactComponent as TableSvg } from "src/assets/icons/table.svg";

const Wrapper = styled(Box)({
  display: "flex",
  gap: 8,
  padding: "4px 8px",
  borderRadius: 12,
  background: "rgba(18, 41, 69, 0.08)",
  svg: {
    cursor: "pointer",
  },
});

export enum VIEW_MODE {
  LIST = "list",
  TABLE = "table",
}

const modes = [
  { id: VIEW_MODE.LIST, icon: ListSvg, description: "List View" },
  { id: VIEW_MODE.TABLE, icon: TableSvg, description: "Table View" },
];

interface Props {
  value: VIEW_MODE;
  onChange: (value: VIEW_MODE) => void;
}

const ViewModeSwitch = (props: Props) => {
  return (
    <Wrapper>
      {modes.map(({ id, icon: Icon, description }) => (
        <Icon
          key={id}
          color={id === props.value ? "#000" : "#9BA5B1"}
          onClick={() => props.onChange(id)}
          title={description}
        />
      ))}
    </Wrapper>
  );
};

export default ViewModeSwitch;
