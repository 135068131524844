import { Box, Button, InputBase } from "@mui/material";
import { styled, Theme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import * as React from "react";
import theme from "src/theme";

const StyledContainer = styled("form")(() => ({
  borderRadius: 0,
  width: "100%",
  marginBottom: "34px",
}));

const Search = styled("div")(({ theme }) => ({
  boxSizing: "border-box",
  borderRadius: 4,
  border: "1px solid #A0B9D0",
  backgroundColor: "white",
  width: "100%",
  height: 60,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled(Button)(({ theme }) => ({
  height: 60,
  minWidth: 60,
  zIndex: 2,
  backgroundColor: theme.palette.secondary.main,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  borderRadius: 4,
  color: "white",
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
  },
  marginRight: "-1px",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    transition: theme.transitions.create("width"),
    width: "100%",
    fontSize: 16,
    lineHeight: "20px",
  },
}));

interface Props {
  value: string;
  onChange: (value: string) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

export default function SearchBox({ value, onChange, onSubmit }: Props) {
  return (
    <StyledContainer onSubmit={onSubmit}>
      <Search>
        <Box
          sx={{
            display: "flex",
            paddingLeft: "20px",
            paddingRight: "6px",
            color: theme.palette.grey[800],
          }}
        >
          <SearchIcon color="inherit" />
        </Box>
        <StyledInputBase
          name="search"
          id="data-dictionary-search-input"
          placeholder="Search the data dictionary"
          inputProps={{
            "aria-label": "search",
            sx: {
              "::placeholder": {
                color: ({ palette }: Theme) => palette.grey[800],
                opacity: 1,
              },
            },
          }}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
        <SearchIconWrapper type="submit">
          <img
            src={require("src/assets/images/search.png")}
            style={{ width: 20, height: 20 }}
            alt="Search icon"
          />
        </SearchIconWrapper>
      </Search>
    </StyledContainer>
  );
}
