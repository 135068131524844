import { Box } from "@mui/material";
import { useEffect, useState } from "react";

import { Group } from "src/types/UserGroup";
import { useKnowledgeWiki } from "src/modules/api/knowledgeWiki";
import { KnowledgeWiki } from "src/types/KnowledgeWiki";

import SensitivityGroup from "./SensitivityGroup";

enum Sensitivity {
  NONSENSITIVE = "Nonsensitive",
  PRIVILEGED = "Privileged",
  SENSITIVE = "Sensitive",
  RESTRICTED = "Restricted",
}

interface Props {
  handleUpdate: (checked: boolean, key: string, value: string) => void;
  data: Group;
}

const Datasets: React.FC<Props> = ({ handleUpdate, data }) => {
  const { getKnowledgeWikis } = useKnowledgeWiki();
  const { data: datasets } = getKnowledgeWikis();

  const [sensitivityGroups, setSensitivityGroups] = useState<
    Record<Sensitivity, KnowledgeWiki[]>
  >({
    [Sensitivity.NONSENSITIVE]: [],
    [Sensitivity.PRIVILEGED]: [],
    [Sensitivity.SENSITIVE]: [],
    [Sensitivity.RESTRICTED]: [],
  });

  useEffect(() => {
    const newSensitivityGroups: Record<Sensitivity, KnowledgeWiki[]> = {
      [Sensitivity.NONSENSITIVE]: [],
      [Sensitivity.PRIVILEGED]: [],
      [Sensitivity.SENSITIVE]: [],
      [Sensitivity.RESTRICTED]: [],
    };

    datasets.forEach((item) => {
      const key = item.sensitivity as unknown as Sensitivity;
      if (
        key === Sensitivity.RESTRICTED &&
        !data?.datasets?.includes(item.dataset) &&
        newSensitivityGroups[key].indexOf(item) !== -1
      ) {
        /* Not accessible */
        return;
      }
      newSensitivityGroups[key].push(item);
    });

    setSensitivityGroups(newSensitivityGroups);
  }, [datasets, data]);

  return (
    <Box
      sx={{
        width: "calc(100% - 40px)",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {Object.keys(sensitivityGroups).map((key) => {
        const item = sensitivityGroups[key as Sensitivity];
        if (!item.length) {
          return null;
        }
        return (
          <SensitivityGroup
            key={key}
            title={key}
            data={data}
            datasets={item}
            handleUpdate={handleUpdate}
          />
        );
      })}
    </Box>
  );
};

export default Datasets;
