import { useState } from "react";
import SearchIconUrl from "src/assets/images/search-primary.png";
import CustomTextField from "src/view/components/CustomTextField";

interface Props {
  onSearch: (key: string) => void;
}

const SearchBox = ({ onSearch }: Props) => {
  const [searchKey, setSearchKey] = useState("");

  const handleSearch = () => {
    onSearch(searchKey.trim().toLowerCase());
  };

  return (
    <CustomTextField
      border
      placeholder="Search user"
      prefixIcon={
        <img
          src={SearchIconUrl}
          width="20"
          height="20"
          style={{ cursor: "pointer" }}
          onClick={handleSearch}
        />
      }
      containerStyle={{
        backgroundColor: "#F0F1F3",
        border: "1px solid #CED3D9",
        marginBottom: 0,
      }}
      type="text"
      value={searchKey}
      onChange={(e) => setSearchKey(e.target.value)}
      onKeyPress={(e) => e.key == "Enter" && handleSearch()}
    />
  );
};

export default SearchBox;
