import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import { Colors } from "src/view/constants/colors";
import { User } from "src/types/UserGroup";
import { getFullName } from "src/utils";

import UserListActions from "./UserListActions";

const StyledTableRow = styled(TableRow)(() => ({
  "&:hover": {
    backgroundColor: "#F9F9FA",
  },
}));

const TableHeadCell = styled(TableCell)(() => ({
  textTransform: "capitalize",
  fontSize: 11,
  lineHeight: "20px",
  color: "rgba(20, 74, 104, 0.5)",
  paddingTop: 8,
  paddingBottom: 8,
  border: "none",
}));

const TableBodyCell = styled(TableCell)(() => ({
  fontSize: 14,
  fontWeight: "700",
  lineHeight: "20px",
  color: Colors.twilight,
  border: "none",
  borderTop: "1px solid #E5E5E5",
  paddingTop: 12,
  paddingBottom: 12,
}));

interface Props {
  userList: User[];
  handleRemoveUser?: (user: User) => void;
  handleResendEmail?: (data: { user_id: string }) => void;
}

const TableItself = ({
  userList,
  handleRemoveUser,
  handleResendEmail,
}: Props) => {
  return (
    <Table sx={{ borderCollapse: "separate", overflow: "hidden", my: 2 }}>
      <TableHead>
        <TableRow>
          <TableHeadCell>
            <Box sx={{ display: "flex", alignItems: "flex-start" }}>Name</Box>
          </TableHeadCell>
          <TableHeadCell>
            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
              Email Address
            </Box>
          </TableHeadCell>
          <TableHeadCell sx={{ width: 80 }}>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>Action</Box>
          </TableHeadCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {userList.map((user: User, index: number) => {
          return (
            <StyledTableRow key={index}>
              <TableBodyCell>{getFullName(user)}</TableBodyCell>
              <TableBodyCell>{user.email}</TableBodyCell>
              <TableBodyCell>
                <UserListActions
                  user={user}
                  handleRemoveUser={handleRemoveUser}
                  handleResendEmail={handleResendEmail}
                />
              </TableBodyCell>
            </StyledTableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default TableItself;
