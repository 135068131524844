import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import CropFreeOutlinedIcon from "@mui/icons-material/CropFreeOutlined";
import ViewModeSwitch, { VIEW_MODE } from "./ViewModeSwitch";
import TableView from "./TableView";
import ListView from "./ListView";
import HighlightActionMenu from "src/view/components/HighlightActionMenu";
import theme from "src/theme";

const StyledWrapper = styled(Box)({
  boxSizing: "border-box",
  width: "100%",
  borderRadius: "6px",
  border: "1px solid #CED3D9",
  backgroundColor: "#FFFFFF",
  overflowX: "hidden",
});

const StyledContainer = styled(Box)({
  width: "100%",
  position: "relative",
  overflowX: "auto",
});

const StyledHeader = styled(Box)({
  width: "calc(100% - 24px)",
  height: 43,
  display: "flex",
  alignItems: "center",
  paddingLeft: 12,
  paddingRight: 12,
});

const Label = styled(Typography)({
  fontSize: 14,
  color: theme.palette.primary.light,
  fontWeight: "700",
  marginRight: "auto",
});

const PreviewButton = styled(Button)({
  backgroundColor: "transparent",
  borderRadius: 4,
  color: "#52A8F9",
  height: 32,
  textTransform: "capitalize",
  fontSize: 12,
  fontWeight: "bold",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "transparent",
  },
});

interface Props {
  data: Array<unknown>;
  docId?: string;
  getDocumentPreview?: () => void;
}

const DataTableView: React.FC<Props> = ({ data, getDocumentPreview }) => {
  const [viewMode, setViewMode] = useState(VIEW_MODE.TABLE);

  return (
    <StyledWrapper>
      <StyledHeader>
        <Label>
          <Typography
            style={{
              color: theme.palette.grey[600],
              fontWeight: 700,
              fontSize: 12,
            }}
          >
            {`• ${data?.length || 0} Record${data?.length === 1 ? "" : "s"}`}
          </Typography>
        </Label>
        <ViewModeSwitch value={viewMode} onChange={setViewMode} />
        <PreviewButton
          variant="contained"
          endIcon={<CropFreeOutlinedIcon color="inherit" />}
          onClick={getDocumentPreview}
          disableRipple
        >
          Preview Table Excerpt
        </PreviewButton>
      </StyledHeader>
      <StyledContainer>
        {data?.length ? (
          <HighlightActionMenu>
            <>
              {viewMode === VIEW_MODE.LIST && <ListView data={data} />}
              {viewMode === VIEW_MODE.TABLE && <TableView data={data} />}
            </>
          </HighlightActionMenu>
        ) : (
          <Typography color="primary" variant="h4">
            No Records
          </Typography>
        )}
      </StyledContainer>
    </StyledWrapper>
  );
};

export default DataTableView;
