import { useCallback, useMemo } from "react";
import { useSearch } from "src/modules/api/search";
import { Box, Pagination, Typography } from "@mui/material";
import theme from "src/theme";

export default function SearchResultPagination() {
  const {
    data,
    activePageIndex,
    setActivePageIndex,
    variables,
    fetchNextPage,
  } = useSearch();

  const handleChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      if (value === 1) {
        setActivePageIndex(0);
      } else {
        const nextPageIndex = data.pageParams.indexOf(value);
        if (nextPageIndex < 0) {
          fetchNextPage({ pageParam: value });
          setActivePageIndex(data.pageParams.length);
        } else {
          setActivePageIndex(nextPageIndex);
        }
      }
    },
    [data, setActivePageIndex]
  );

  const {
    pageCount,
    total_counts,
    size,
  }: {
    pageCount: number;
    total_counts: number;
    size: number;
  } = useMemo(() => {
    let pageCount, total_counts, size;
    if (data.pages[0]) {
      const page = data.pages[0];
      total_counts = page.total_counts;
      size = page.size;
      pageCount = Math.ceil(total_counts / size);
    }

    return { pageCount, total_counts, size };
  }, [data, variables]);

  const activePage = (data.pageParams[activePageIndex] as number) ?? 1;

  const fromIndex = size * (activePage - 1) + 1;
  const toIndex = Math.min(size * (activePage - 1) + size, total_counts);

  if (total_counts === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: theme.palette.grey[500],
      }}
    >
      <Typography variant="caption">
        Showing {fromIndex} - {toIndex} of {total_counts}
      </Typography>
      <Pagination
        shape="rounded"
        count={pageCount}
        page={activePage}
        onChange={handleChange}
      />
      <Typography variant="caption">Show {size} results</Typography>
    </Box>
  );
}
