import React from "react";
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import wiki_primary from "src/assets/icons/knowledge_wiki.svg";
import link_primary from "src/assets/images/link_primary.svg";
import download_icon from "src/assets/images/download_icon.png";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { PreviewDocProps } from "src/modules/search/view/searchViewReducers";
import { usePostHog } from "posthog-js/react";
import { HogEvent } from "src/types/PosthogEvents";

const StyledIcon = styled("img")(() => ({
  objectFit: "contain",
  cursor: "pointer",
  width: "15px",
  height: "15px",
}));

const StyledIconButton = styled(IconButton)(() => ({
  background: "#F0F1F3",
  borderRadius: "4px",
  mx: "auto",
  padding: "4px",
  display: "flex",
  "& .MuiTouchRipple-root .MuiTouchRipple-child": {
    borderRadius: "4px",
  },
}));

interface Props {
  resultMetadata?: PreviewDocProps;
  gettingDownloadLink?: boolean;
  handleOpenMenu?: (event: React.MouseEvent<HTMLElement>) => void;
  skeleton?: boolean;
}

const SearchResultCardActions = ({
  resultMetadata,
  gettingDownloadLink,
  handleOpenMenu,
  skeleton,
}: Props) => {
  const navigate = useNavigate();
  const posthog = usePostHog();

  const handleOpenWiki = () => {
    if (skeleton) return;
    posthog.capture(HogEvent.DATA_COLLECTION_OPENED);
    return navigate("/data-dictionary/data/" + resultMetadata?.wikiItem?.id, {
      replace: false,
      state: { id: resultMetadata?.wikiItem?.id },
    });
  };

  const handleOpenWikiInNewTab = () => {
    if (skeleton) return;
    posthog.capture(HogEvent.DATA_COLLECTION_OPENED);
    return window.open(
      `${window.location.origin}/data-dictionary/data/${resultMetadata?.wikiItem?.id}`
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
      {(skeleton || resultMetadata?.wikiItem) && (
        <>
          <Tooltip
            title={`View Dataset: ${resultMetadata?.wikiItem?.name} in Data Dictionary`}
            placement="top"
            enterDelay={250}
            PopperProps={{ style: { zIndex: 1501 } }}
          >
            <StyledIconButton onClick={handleOpenWiki}>
              <StyledIcon src={wiki_primary} alt="Wiki" />
            </StyledIconButton>
          </Tooltip>

          <Tooltip
            title={`View Dataset: ${resultMetadata?.wikiItem?.name} in Data Dictionary (New Tab)`}
            placement="top"
            enterDelay={250}
            PopperProps={{ style: { zIndex: 1501 } }}
          >
            <StyledIconButton onClick={handleOpenWikiInNewTab}>
              <StyledIcon src={link_primary} alt="Wiki in new tab" />
            </StyledIconButton>
          </Tooltip>
        </>
      )}

      {gettingDownloadLink ? (
        <Box
          sx={{
            p: "4px",
          }}
        >
          <CircularProgress size={15} color="primary" />
        </Box>
      ) : (
        <StyledIconButton onClick={handleOpenMenu}>
          <StyledIcon src={download_icon} alt="Download" />
        </StyledIconButton>
      )}
    </Box>
  );
};

export default SearchResultCardActions;
